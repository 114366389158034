import React, { useCallback, useEffect, useState } from 'react';
import AnalyticsButton from '../AnalyticsComponents/Button';
import styles from './ButtonShare.styl';
import FormattedMessage from '../../localization/FormatMessage';
import MoveModal from './FolderManagement/MoveModal';
import CreateRenameModal, { CreateRenameModalAction } from './FolderManagement/CreateRenameModal';
import { ReactComponent as IconMove } from '../../images/iconMove.svg';
import analytics from '../../analytics/firebaseAnalytics';
import { FileManagementCategory } from '../../analytics/analyticsConstants';
import InvalidShareExportModal, { InvalidShareExportType } from './DataViewModal/InvalidShareExportModal';
import { useDataViewContext } from './DataViewProvider';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import { useMeasurements } from '../../hooks/useProductData';

interface ButtonMoveProps {
    mIDs: string[];
    setSelectedKeys: (keys: string[]) => void;
}

const ButtonMove: React.FunctionComponent<ButtonMoveProps> = (props) => {
    const { mIDs, setSelectedKeys } = props;
    const { product } = useDataViewContext();
    const measurements = useMeasurements();
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [addNewFolder, setAddNewFolder] = useState(false);
    const [invalidMoveModal, setInvalidMoveModal] = useState(false);
    const { getInvalidMeasurementIDs } = useSelectedContractFeatures(measurements, mIDs);
    const invalidMeasurementIDs = getInvalidMeasurementIDs();

    useEffect(() => {
        return () => {
            setShowMoveModal(false);
            setAddNewFolder(false);
        };
    }, []);

    const handleExcludeAndMove = useCallback(() => {
        setSelectedKeys(mIDs.filter((id) => !invalidMeasurementIDs.includes(id)));
        setInvalidMoveModal(false);
        setShowMoveModal(true);
        analytics.logFileManagement(FileManagementCategory.excludeAndMove, product ?? '');
    }, [invalidMeasurementIDs, mIDs, product, setSelectedKeys]);

    return (
        <>
            <AnalyticsButton
                onClick={() => {
                    if (invalidMeasurementIDs.length > 0) {
                        setInvalidMoveModal(true);
                    } else {
                        setShowMoveModal(true);
                    }
                }}
                className={styles.button}
                ghost
                type="primary"
            >
                <div className={styles.icon}>
                    <IconMove />
                </div>
                {<FormattedMessage id="App.Move" />}
            </AnalyticsButton>
            <MoveModal
                visible={showMoveModal}
                onCancel={() => setShowMoveModal(false)}
                addNewFolder={() => setAddNewFolder(true)}
                measurementIDs={mIDs}
            />
            <CreateRenameModal
                action={CreateRenameModalAction.newFolder}
                visible={addNewFolder}
                onCancel={() => setAddNewFolder(false)}
            />
            <InvalidShareExportModal
                visible={invalidMoveModal}
                onClose={() => {
                    setInvalidMoveModal(false);
                    analytics.logFileManagement(FileManagementCategory.moveCancel, product ?? '');
                }}
                invalidType={InvalidShareExportType.move}
                invalidMeasurementIDs={invalidMeasurementIDs}
                onExclude={handleExcludeAndMove}
                selectedKeysCount={mIDs.length}
            />
        </>
    );
};

export default ButtonMove;
