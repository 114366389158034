import React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonProps as AntdButtonProps } from 'antd/lib/button';
import analytics from '../../analytics/firebaseAnalytics';

interface AnalyticsButtonProps extends AntdButtonProps {
    logAnalytics?: boolean;
}

const AnalyticsButton: React.FunctionComponent<AnalyticsButtonProps> = ({ logAnalytics = false, ...props }) => {
    const onClicking = (event: React.PointerEvent<HTMLElement>) => {
        const buttonText = event?.currentTarget?.getAttribute('name') || event?.currentTarget?.textContent;
        if (logAnalytics && buttonText) analytics.logButtonClick(buttonText);
        if (props.onClick) props.onClick(event);
    };

    // default to this button type if not provided
    const buttonTypeProp: AntdButtonProps =
        (!props.type && props.ghost === undefined) || props.type === 'default' ? { type: 'primary', ghost: true } : {};

    return (
        <AntdButton {...props} {...buttonTypeProp} onClick={onClicking}>
            {props.children}
        </AntdButton>
    );
};

export default AnalyticsButton;
