import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

export const useCurrentUserID = () => {
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const userID = userProfile.id;
    return userID;
};

export const useCurrentUserEmail = () => {
    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    return userProfile.email;
};

export const useRoleState = () => {
    const roleState = useSelector((state: RootState) => state.profile.roleState);
    return roleState;
};

export const useUserLicense = () => {
    const license = useSelector((state: RootState) => state.profile.userLicense);
    const activeLicenseKeys = useSelector((state: RootState) => state.profile.userActiveLicenseKeys);
    const expiredLicenseKeys = useSelector((state: RootState) => state.profile.userExpiredLicenseKeys);
    return { license, activeLicenseKeys, expiredLicenseKeys };
};

export default useCurrentUserID;
