export const uuidRegex = /^[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}$/i;
export const incompleteUUIDRegex = /^(?![0-9a-f]{4}-)([0-9a-f]{8}-)?([0-9a-f]{4}-){0,3}([0-9a-f]{0,12})$/i;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;

export const websiteRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const genericPhoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;

export const escapeRegexExpression = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
