import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { LicenseProduct } from '../types/license';
import { ProductCode } from '../types/proceq';

export const useProceqAppConfigs = () => {
    return useSelector((state: RootState) => state.config.proceqAppConfigs);
};

export const useAppsStatus = () => {
    const appsStatus = useSelector((state: RootState) => state.config.appsStatus);
    const appsList = useSelector((state: RootState) => state.config.appsList);
    return { appsStatus, appsList };
};

export const useMaxFileDownloadSize = () => {
    return useSelector((state: RootState) => state.config.maxFileDownloadSize);
};

export const usePlanTiers = (product: LicenseProduct | ProductCode) => {
    return useSelector((state: RootState) => state.config.planTiers[product]);
};
