import React from 'react';
import { useIntl } from 'react-intl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { getLabelFromGlobalDataKey } from '../../../RegisteredInfo/helper';
import { getSchmidtCompositeSurfaceId } from '../../utils/conversionsHelper';

export const SurfaceCorrection: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    const formatMessage = useIntl().formatMessage;

    const oldCompositeSurfaceId =
        getSchmidtCompositeSurfaceId(log.content.oldStrengthId, log.content.oldSurfaceCorrectionId) ?? '';
    const updatedCompositeSurfaceId =
        getSchmidtCompositeSurfaceId(log.content.updatedStrengthId, log.content.updatedSurfaceCorrectionId) ?? '';

    const updatedCompositeSurfaceName = formatMessage({
        id: getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', updatedCompositeSurfaceId) ?? ' ',
    });

    if (oldCompositeSurfaceId) {
        const oldCompositeSurfaceName = formatMessage({
            id: getLabelFromGlobalDataKey('surfacestrengthandcorrschmidt', oldCompositeSurfaceId) ?? ' ',
        });
        return (
            <span>
                <FormattedMessage
                    id="Proceq.LogbookPropertySurfaceCorrectionChangedFromTo"
                    values={{
                        oldValue: oldCompositeSurfaceName,
                        newValue: updatedCompositeSurfaceName,
                    }}
                />
            </span>
        );
    }

    return (
        <span>
            <FormattedMessage
                id="Proceq.LogbookPropertySurfaceCorrectionChangedTo"
                values={{
                    newValue: updatedCompositeSurfaceName,
                }}
            />
        </span>
    );
};

export default {
    element: SurfaceCorrection,
};
