import React from 'react';
import Spinner from '../shared/Spinner';
import styles from './ProbeImage.styl';
import srcGP8000 from '../../images/probes/GP8000.png';
import srcGP8800 from '../../images/probes/GP8800.png';
import srcLiveLeebD from '../../images/probes/LiveLeebD.png';
import srcLiveUCI from '../../images/probes/LiveUCI.png';
import srcOS8000 from '../../images/probes/OS8000.png';
import srcOS8200 from '../../images/probes/OS8200.png';
import srcPD8000 from '../../images/probes/PD8000.png';
import srcUT8000 from '../../images/probes/UT8000.png';
import srcZG8000 from '../../images/probes/ZG8000.png';
import srcGS8000 from '../../images/probes/GS8000.png';
import srcGS9000 from '../../images/probes/GS9000.png';
import srcGP8100 from '../../images/probes/GP8100.png';
import srcPD8050 from '../../images/probes/PD8050.png';
import srcPI8000 from '../../images/probes/PI8000.png';
import srcPM8000 from '../../images/probes/PM8000.png';
import srcPM8500 from '../../images/probes/PM8500.png';
import srcGM8000 from '../../images/probes/GM8000.png';
import imgPlaceholder from '../../images/imagePlaceholder.svg';

interface ProbeImageProps {
    name?: string;
    pModel?: string;
    isLoading?: boolean;
    width?: number;
    height?: number;
}

export const ProbeImage: React.FunctionComponent<ProbeImageProps> = (props) => {
    const { name, pModel, isLoading, width, height } = props;
    const imageSrc = pModel ? modelImageMap[pModel?.toUpperCase()] ?? imgPlaceholder : '';
    return (
        <div className={styles.card_image} style={{ width, height }}>
            {isLoading && <Spinner size="default" />}
            {imageSrc && <img src={imageSrc} alt={name} />}
        </div>
    );
};

export const modelImageMap: { [key: string]: string | undefined } = {
    GPR: srcGP8000,
    GPR_PALM: srcGP8800,
    GPR_SOIL: srcGS8000,
    GS9000: srcGS9000,
    GPR_GP8100: srcGP8100,
    LEEBD: srcLiveLeebD,
    LEEBD_RNT: srcLiveLeebD,
    UCI: srcLiveUCI,
    SCHMIDT: srcOS8000,
    SSL: srcOS8200,
    GLM: srcZG8000,
    PUNDIT: srcPD8000,
    PUNDIT_PD8050: srcPD8050,
    FDL: srcUT8000,
    PI8000: srcPI8000,
    RS8000: srcOS8200,
    PM8000: srcPM8000,
    PM8500: srcPM8500,
    GM8000: srcGM8000,
};
