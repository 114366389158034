import React, { useCallback, useRef, useState } from 'react';
import Tooltip from './Tooltip';

// this component will show the tooltip only if text is longer than given width ie truncated
const TooltipText: React.FunctionComponent<{ text: string | React.ReactNode }> = ({ text }) => {
    const spanRef = useRef<HTMLSpanElement>(null);
    const [showTooltip, setShowTooltip] = useState(
        spanRef.current ? spanRef.current.scrollWidth > spanRef.current.clientWidth : false
    );
    const onMouseOver = useCallback(
        () => setShowTooltip(spanRef.current ? spanRef.current.scrollWidth > spanRef.current.clientWidth : false),
        []
    );
    return (
        <Tooltip title={showTooltip ? text : undefined}>
            <span onMouseOver={onMouseOver} onFocus={onMouseOver} ref={spanRef}>
                {text}
            </span>
        </Tooltip>
    );
};

export default TooltipText;
