import { Log } from '../../../../../types/logs';

export enum SnapshotType {
    ExportedSnapshot = 'snapshot',
    SavedSnapshot = 'saveSnapshot',
    LatestViewedSnapshot = 'snapshotsAndCSV',
}

export interface SnapshotEntry {
    imageURL?: string;
    imageName?: string;
    mID: string;
    type: SnapshotType;
    log?: Log;
}
