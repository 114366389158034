import { isConsumerValid } from '../config/consumers';

const types = {
    personalProfileUpdated: '',
    userLicenseUpdated: '',
};

export type PopupMessageType = 'warn' | 'success' | 'error';
export type PostMessageDataType = keyof typeof types;

class PostMessenger {
    parentOrigin: string = '';

    setParentOrigin = (domain: string) => {
        if (isConsumerValid(domain)) {
            this.parentOrigin = domain;
        } else {
            // eslint-disable-next-line no-console
            console.error(`${domain} is not a valid consumer for Eagle ID`);
        }
    };

    post(type: PostMessageDataType, data: any) {
        if (this.parentOrigin !== '') {
            window.parent.postMessage({ type, data }, this.parentOrigin);
        }
    }

    postPopupMessage(messageType: PopupMessageType, messageContent: string) {
        if (this.parentOrigin !== '') {
            const popupMessage = {
                type: 'popupMessage',
                data: {
                    messageType,
                    messageContent,
                },
            };
            window.parent.postMessage(popupMessage, this.parentOrigin);
        } else {
            // eslint-disable-next-line no-console
            console.error('Missing origin for PostMessenger');
        }
    }

    postPageHeight(height?: number) {
        if (this.parentOrigin !== '') {
            window.parent.postMessage(
                { type: 'postPageHeight', data: height ?? window.document.body.scrollHeight },
                this.parentOrigin
            );
        }
    }
}

const postMessenger = new PostMessenger();

export default postMessenger;
