import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import App from '../App';
import Dashboard from '../Dashboard';
import DataView from '../DataView';
import Profile from '../Profile';
import AppMeasurementView from '../StandaloneMeasurementView/AppMeasurementView';
import routeURLs from './urls';
import * as landingUtils from '../../utils/landingUtils';
import RootRedirection from './RootRedirection';
import MeasurementImport from '../MeasurementImport/MeasurementImport';
import Masquerade from '../Auth/Masquerade';
import ToolFrame from '../DataView/Tools/ToolFrame';
import ToolInsights from '../DataView/Tools/ToolInsights';
import LicenseRedirection from '../Profile/UserLicense/LicenseRedirection';
import UserProfile from '../Profile/UserProfile';
import Security from '../Profile/Security';
import ProbeList from '../Probes/ProbeList';
import UserLicense from '../Profile/UserLicense';

const AppRoute: React.FunctionComponent = () => {
    return (
        <App>
            <Routes>
                <Route path={routeURLs.MASQUERADE} element={<Masquerade />} />
                <Route path={routeURLs.DASHBOARD} element={<Dashboard />} />
                <Route path={routeURLs.PROFILE} element={<Profile />}>
                    <Route path={routeURLs.PROFILE_PERSONAL} element={<UserProfile />} />
                    <Route path={routeURLs.PROFILE_SECURITY} element={<Security />} />
                    <Route path={routeURLs.PROFILE_PROBES} element={<ProbeList />} />
                    <Route path={routeURLs.PROFILE_SUBSCRIPTION} element={<UserLicense />} />
                    <Route index element={<Navigate to={routeURLs.PROFILE_PERSONAL} />} />
                </Route>
                <Route path={routeURLs.DATA_PRODUCT} element={<DataView />} />
                <Route path={routeURLs.MEASUREMENT} element={<AppMeasurementView />} />
                <Route path={routeURLs.TOOL_PRODUCT} element={<ToolFrame />} />
                <Route path={routeURLs.APP_PRODUCT} element={<ToolInsights />} />
                <Route path={routeURLs.LICENSE_ACTIVATION} element={<LicenseRedirection />} />
                <Route path={routeURLs.MEASUREMENT_IMPORT} element={<MeasurementImport />} />
                <Route path="/" element={<RootRedirection />} />
                <Route path="*" element={<UnknownRedirect />} />
            </Routes>
        </App>
    );
};

const UnknownRedirect: React.FunctionComponent = () => {
    // For unknown path, we need to clean up the landing path
    landingUtils.restoreLandingPath();
    return <Navigate to={routeURLs.DASHBOARD} />;
};

export default AppRoute;
