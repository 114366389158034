import React, { useState } from 'react';
import { App } from 'antd';
import { useIntl } from 'react-intl';
import { ProductCode } from '../../types/proceq';
import { useCustomMaterials, useMeasurements } from '../../hooks/useProductData';
import DeleteConfirmationModal, { DeletedType } from './FolderManagement/DeleteConfirmationModal';
import { BluetoothFileType } from '../../types/measurement';
import { deleteRestoreMeasurements } from '../../api/measurementService';
import MultipleFailureMessage from '../shared/MultipleFailureMessage';
import { getMeasurementFolders } from '../../api/folderService';
import { useDataViewContext } from './DataViewProvider';
import analytics from '../../analytics/firebaseAnalytics';
import { FileManagementCategory, MeasurementMenuCategory } from '../../analytics/analyticsConstants';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import DeleteButton from '../shared/Buttons/DeleteButton';
import { useMeasurementContext } from './MeasurementContext/MeasurementProvider';

interface ButtonDeleteProps {
    product: ProductCode;
    mIDs: string[];
    fileType: string;
    isPermanentDelete?: boolean;
    primaryStyle?: boolean;
    isTableButton?: boolean; // true if button is within the table
}

const MeasurementButtonDelete: React.FunctionComponent<ButtonDeleteProps> = (props) => {
    const { product, mIDs, isPermanentDelete, primaryStyle, fileType, isTableButton = false } = props;
    const measurements = useMeasurements();
    const customMaterials = useCustomMaterials();
    const formatMessage = useIntl().formatMessage;
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const { withUnsynced } = useDataViewContext();
    const { fetchMeasurementList, setSelectedKeys, setNonEditableMeasurements } = useMeasurementContext();
    const { getInvalidMeasurementIDs, demoMeasurementIDs } = useSelectedContractFeatures(measurements, mIDs);
    const invalidMeasurementIDs = getInvalidMeasurementIDs().filter((id) => !demoMeasurementIDs.includes(id));
    const { message } = App.useApp();

    const firstFileName =
        fileType === BluetoothFileType.CustomMaterial
            ? customMaterials[mIDs[0]].name
            : measurements[mIDs[0]]?.measurement.name;

    const handleOpenModal = async (e: React.MouseEvent) => {
        e.stopPropagation();
        if (isPermanentDelete) {
            setShowDeleteConfirmationModal(true);
            analytics.logFileManagement(FileManagementCategory.permanentDeleteBulk, product);
        } else if (invalidMeasurementIDs.length) {
            setNonEditableMeasurements({
                allInvalid: invalidMeasurementIDs.length === mIDs.length,
                measurementIds: invalidMeasurementIDs,
            });
            analytics.logNonEditableMeasurement(MeasurementMenuCategory.delete, product);
        } else {
            const response = await deleteRestoreMeasurements({ ids: mIDs, product, isDelete: true });
            if (response.failed.length > 0) {
                const failureReasons = response.failed.map((failure) => {
                    return {
                        ...failure,
                        name:
                            measurements && measurements[failure.id]
                                ? measurements[failure.id].measurement.name
                                : failure.id,
                    };
                });
                message.error(<MultipleFailureMessage title="DataView.Delete.Failure" failures={failureReasons} />);
            } else {
                message.success(
                    formatMessage(
                        { id: 'DataView.Measurement.Delete.Success' },
                        { name: firstFileName, count: mIDs.length }
                    )
                );
            }
            fetchMeasurementList(true);
            getMeasurementFolders({ product, withUnsynced });
            setSelectedKeys([]);
            analytics.logFileManagement(FileManagementCategory.deleteBulk, product);
        }
    };

    return (
        <>
            <DeleteButton
                onClick={handleOpenModal}
                isPermanentDelete={isPermanentDelete}
                isTableButton={isTableButton}
                primaryStyle={primaryStyle}
            />
            {isPermanentDelete && (
                <DeleteConfirmationModal
                    visible={showDeleteConfirmationModal}
                    onCancel={() => setShowDeleteConfirmationModal(false)}
                    deletedType={DeletedType.file}
                    ids={mIDs}
                    name={firstFileName}
                />
            )}
        </>
    );
};

export default MeasurementButtonDelete;
