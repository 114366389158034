import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { convertAndDisplay } from '../../utils/conversionRates';
import StaticMap from '../StaticMap';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { ScanType } from '../../../../../types/proceq';
import ConvertedUnits from '../../shared/ConvertedUnits';

export const Creation: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const { latitude, longitude, measurementInfo } = log.content;
    const coordinates = { lat: +latitude, lng: +longitude };
    const formatMessage = useFormatMessage();
    const isMetric = log.content.measurementInfo.unit?.toLowerCase() === 'metric';
    if (isMetric === undefined || scanType === undefined) {
        return null;
    }
    const { folderName, fileName, isHighPassFilterOn, isLowPassFilterOn, highPass, lowPass, mode, timeGain, velocity } =
        measurementInfo;

    const convert = (value: number, unitId: string) => {
        return isFinite(+value) ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(+value) : '-';
    };

    return (
        <div>
            <b>
                <FormattedMessage
                    id="Proceq.LogbookPropertyCreatedFileInFolder"
                    values={{
                        fileName,
                        folderName,
                    }}
                />
            </b>
            <br />
            <FormattedMessage
                id="App.Logbook.PI8000.IsHighPassFilterOn"
                values={{
                    value: isHighPassFilterOn
                        ? formatMessage({ id: 'Proceq.On' })
                        : formatMessage({ id: 'Proceq.Off' }),
                }}
            />
            <br />
            <FormattedMessage id="App.Logbook.PI8000.HighPass" />
            {': '}
            {convert(highPass, 'PIT_IE.Settings Panel.High Pass Filter')}
            <br />
            <FormattedMessage
                id="App.Logbook.PI8000.IsLowPassFilterOn"
                values={{
                    value: isLowPassFilterOn ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
                }}
            />
            <br />
            <FormattedMessage id="App.Logbook.PI8000.LowPass" />
            {': '}
            {convert(lowPass, 'PIT_IE.Settings Panel.Low Pass Filter')}
            <br />
            <FormattedMessage id="App.Logbook.PI8000.Mode" />
            {`: ${mode}`}
            <br />
            {scanType === ScanType.AScan && (
                <>
                    <FormattedMessage id="App.Logbook.PI8000.TimeGain" />
                    {`: ${timeGain}`}
                    <br />
                </>
            )}
            <ConvertedUnits
                id="App.Logbook.FDL.Item.velocity"
                unitId="PIT_IE.Settings Panel.Pulse Velocity"
                scanType={scanType}
                isMetric={isMetric}
                values={[velocity]}
            />
            {`: ${convert(velocity, 'PIT_IE.Settings Panel.Pulse Velocity')}`}
            <br />
            <StaticMap coordinates={coordinates} />
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
