import React from 'react';
import { has } from 'lodash';
import { Card } from 'antd';
import { MeasurementFullData } from '../../../../types/measurement';
import { DerivedProps } from '../SharedTypes';
import Photo from '../../../Attachments/Photo';
import ConvertedUnits from '../shared/ConvertedUnits';
import { formatWithIdentation } from '../utils/handleFormatMessage';
import { FormatIDs } from '../../../../types';
import FormattedMessage from '../../../../localization/FormatMessage';
import { nullCallback } from '../../../../hooks/useCheckInviewPort';
import { ProductCode } from '../../../../types/proceq';
import { SnapshotAction } from '../../../../analytics/analyticsConstants';
import SectionTable from '../shared/SectionTable';
import { ReadingPanel } from './ReadingComponents';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="Proceq.DashboardSnapshots" />
    </>
);

type SavedSnapshotsProps = { data: MeasurementFullData; showPropertiesTable?: boolean } & DerivedProps;

export const SavedSnapshots: React.FunctionComponent<SavedSnapshotsProps> = ({
    data,
    scanType,
    product,
    isMetric,
    convert,
    showTitle,
    showPropertiesTable = true,
}) => {
    const contentSettings = data.settings[0].content.settings;
    const backgroundRemovalDepth: number | undefined = contentSettings?.process.surfaceWaveRemoval;
    const maxScanDepth: number | undefined = contentSettings?.preset.maxScanDepth;
    const separationSliderDepthPercentage: number | undefined = contentSettings?.process.shallowSeparatorFactor;
    const separationSliderDepth =
        separationSliderDepthPercentage && maxScanDepth ? separationSliderDepthPercentage * maxScanDepth : '';

    const logs = data.logs
        ?.filter((log) => log.type === 'saveSnapshot')
        .sort((a, b) => a.clientCreated - b.clientCreated);

    return logs.length > 0 ? (
        <ReadingPanel key="savedSnapshots" header={title}>
            <Card title={showTitle ? title : undefined} className="saved-snapshots">
                {logs.map((log) => {
                    const dataSourceRaw: {
                        label: FormatIDs;
                        value: string | number | (string | number)[];
                        enable: boolean;
                        multiple?: boolean;
                        unitId?: string;
                    }[] = showPropertiesTable
                        ? [
                              {
                                  label: 'App.HTML.GPR.MeasurementPresets.FileName',
                                  value: log.content.attachmentName,
                                  enable: true,
                              },
                              {
                                  label: 'App.HTML.GPR.Objects.Line',
                                  value: log.content.metadata.lineId,
                                  enable: has(log, 'content.metadata.lineId'),
                              },
                              {
                                  label: 'Proceq.LogbookSettingsDielectricConstant',
                                  unitId: 'GPR.Logbook Panel.Dielectric Constant',
                                  value: log.content.metadata.dielectricConstant,
                                  enable: product === ProductCode.GPR,
                              },
                              {
                                  label: 'App.HTML.GPR.Settings.ImageProcessing.LinearGain',
                                  unitId: `${product}.CSV.Linear Gain`,
                                  value: log.content.metadata.linearGain,
                                  enable: product === ProductCode.GPR,
                              },
                              {
                                  label: 'App.HTML.GPR.Settings.ImageProcessing.TimeGain',
                                  unitId: `${product}.CSV.Time Gain Compensation`,
                                  value: log.content.metadata.timeGainCompensation,
                                  enable: product === ProductCode.GPR,
                              },
                              {
                                  label: 'Proceq.LogbookSettingsDielectricConstant',
                                  unitId: 'GPR.Logbook Panel.Dielectric Constant',
                                  value: [
                                      log.content.metadata.dielectricConstant,
                                      log.content.metadata.dielectricConstant,
                                  ],
                                  enable: product === ProductCode.GPR_SOIL,
                                  multiple: true,
                              },
                              {
                                  label: 'App.HTML.GPR.Settings.ImageProcessing.LinearGain',
                                  unitId: `${product}.CSV.Linear Gain`,
                                  value: [log.content.metadata.linearGainLF, log.content.metadata.linearGain],
                                  enable: product === ProductCode.GPR_SOIL,
                                  multiple: true,
                              },
                              {
                                  label: 'App.HTML.GPR.Settings.ImageProcessing.TimeGain',
                                  unitId: `${product}.CSV.Time Gain Compensation`,
                                  value: [
                                      log.content.metadata.timeGainCompensationLF,
                                      log.content.metadata.timeGainCompensation,
                                  ],
                                  enable: product === ProductCode.GPR_SOIL,
                                  multiple: true,
                              },
                              {
                                  label: 'App.HTML.GPR_SOIL.Setting.Background.Removal.Depth',
                                  unitId: 'GPR_SOIL.Snapshot.Depth',
                                  value: backgroundRemovalDepth || '-',
                                  enable: product === ProductCode.GPR_SOIL,
                              },
                              {
                                  label: 'App.HTML.GPR_SOIL.Setting.Sepration.Slider.Depth',
                                  unitId: 'GPR_SOIL.Snapshot.Depth',
                                  value: separationSliderDepth,
                                  enable: product === ProductCode.GPR_SOIL,
                              },
                              {
                                  label: 'App.HTML.PUNDIT.ImageProcessing.LinearGain',
                                  unitId: 'PUNDIT.Settings Panel.Digital Gain',
                                  value: log.content.metadata.digitalGain,
                                  enable: product === ProductCode.PUNDIT,
                              },
                              {
                                  label: 'App.HTML.PUNDIT.ImageProcessing.PulseVelocity',
                                  unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
                                  value: log.content.metadata.pulseVelocity,
                                  enable: product === ProductCode.PUNDIT,
                              },
                              {
                                  label: 'App.HTML.PUNDIT.Snapshot.CenterFrequency',
                                  unitId: 'PUNDIT.Snapshot.Center Frequency',
                                  value: log.content.metadata.centerFrequency,
                                  enable: product === ProductCode.PUNDIT,
                              },
                          ]
                        : [];

                    const dataSource =
                        showPropertiesTable && log.content.metadata
                            ? dataSourceRaw
                                  .filter(({ enable }) => enable)
                                  .map((row) => ({
                                      key: row.label,
                                      label: row.unitId ? (
                                          <ConvertedUnits
                                              id={row.label}
                                              unitId={row.unitId}
                                              scanType={scanType}
                                              isMetric={isMetric}
                                          />
                                      ) : (
                                          <FormattedMessage id={row.label} />
                                      ),
                                      value: row.unitId
                                          ? Array.isArray(row.value)
                                              ? formatWithIdentation(
                                                    convert(row.value[0], row.unitId).toString(),
                                                    convert(row.value[1], row.unitId).toString()
                                                )
                                              : convert(row.value, row.unitId)
                                          : row.value,
                                  }))
                            : [];

                    return (
                        <div key={log.id} className="snapshot">
                            <Photo
                                mID={log.mID}
                                aID={log.content.attachmentId}
                                attachmentName={log.content.attachmentName}
                                getScrollParent={nullCallback}
                                analyticsCategory={SnapshotAction.detail}
                                analyticsProduct={product}
                                analyticsType={log.type}
                            />
                            {showPropertiesTable && log.content.metadata && (
                                <SectionTable showTitle={false} title={undefined} dataSource={dataSource} />
                            )}
                        </div>
                    );
                })}
            </Card>
        </ReadingPanel>
    ) : null;
};

export default SavedSnapshots;
