import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import StaticMap from '../StaticMap';

export const Creation: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const { latitude, longitude, measurementInfo, locationAuthorization } = log.content;
    const coordinates = { lat: +latitude, lng: +longitude };
    const { fileName, folderName, mode } = measurementInfo;
    const newMode = mode === 'Conventional' ? 'A-scan' : mode === 'Corrosion Map' ? 'Grid' : mode;
    return (
        <div>
            <b>
                <FormattedMessage
                    id="Proceq.LogbookPropertyCreatedFileInFolder"
                    values={{
                        fileName,
                        folderName,
                    }}
                />
            </b>
            <br />
            <FormattedMessage id="Proceq.LogbookCreationMode" values={{ value: newMode }} />
            <br />
            {locationAuthorization !== 'denied' && <StaticMap coordinates={coordinates} />}
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
