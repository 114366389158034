import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { App, Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FormItem, objectUtils, routeUtils, useFormCheckErrors } from 'tds-common-fe';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import AppWrapper from '../AppWrapper';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import FormattedMessage from '../../../localization/FormatMessage';
import AuthInput from '../FormElements/AuthInput';
import EditProfileImage from '../ProfileImage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { RootState } from '../../../reducers';
import countries from '../../../resources/countries.json';
import * as userServices from '../../../api/userService';
import { UpdateUserProfileParams } from '../../../api/userService';
import { FieldsKeys, FieldsType, rules } from './personalProfileRules';
import postMessenger from '../../../utils/PostMessenger';
import InitialsPlaceholder from './InitialsPlaceholder';
import styles from './UserProfile.styl';
import analytics from '../../../analytics/firebaseAnalytics';
import { PersonalProfileAction } from '../../../analytics/analyticsConstants';
import DeleteAccount from './DeleteAccount';
import { AccountDeleteStatus } from '../../../types/profile';
import { useUserDeleteAccount } from '../../../queries/userQueries';
import { ReactQueryKeys } from '../../../queries/queryKeys';

class Item extends FormItem<FieldsType> {}

type MutableKey = 'firstName' | 'lastName' | 'companyName' | 'phoneNumber' | 'postcode' | 'address';
const mutableFields: MutableKey[] = ['firstName', 'lastName', 'companyName', 'phoneNumber', 'postcode', 'address'];
const requiredMutableFields: MutableKey[] = ['firstName', 'lastName', 'companyName'];

const UserProfile: React.FunctionComponent = () => {
    const formatMessage = useFormatMessage();
    const [form] = Form.useForm();
    const { setFieldsValue } = form;
    const { message } = App.useApp();

    const params = routeUtils.parseQuery<{ edit: string }>(location.search);
    const initEditable = params.edit === 'true';

    // Start Component related code

    const userProfile = useSelector((state: RootState) => state.profile.userProfile);
    const userID = userProfile.id;

    const inputs = useRef<HTMLInputElement[]>([]);
    const [editEnabled, setEditEnabled] = useState(initEditable);

    const [accountDeleteStatus, setAccountDeleteStatus] = useState<undefined | AccountDeleteStatus>();

    useEffect(() => {
        if (userProfile.id) {
            postMessenger.post('personalProfileUpdated', userProfile);
        }
    }, [userProfile]);

    useEffect(() => {
        if (!editEnabled && userProfile.id) {
            setFieldsValue(userProfile);
        }
    }, [userProfile, editEnabled, initEditable, setFieldsValue]);

    const { data: accountDeletionInfo, isError: accountDeletionError } = useUserDeleteAccount();

    useEffect(() => {
        if (accountDeletionInfo) {
            setAccountDeleteStatus(accountDeletionInfo.response.data.status);
        }
        if (accountDeletionError) {
            setAccountDeleteStatus(AccountDeleteStatus.doesNotExist);
        }
    }, [accountDeletionInfo, accountDeletionError]);

    const handleSuccessMessage = (messageContent: string) => {
        if (postMessenger.parentOrigin) {
            postMessenger.postPopupMessage('success', messageContent);
        } else {
            message.success(messageContent);
        }
    };

    const { mutate: updateUserProfile, isLoading: submitting } = useMutation({
        mutationFn: ({ userID, newUserData }: { userID: string; newUserData: UpdateUserProfileParams }) =>
            userServices.updateUserProfile(userID.toString(), newUserData),
        onSuccess: () => handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.UserProfile' })),
    });

    const fields = {
        firstName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.FirstName.Placeholder" />}
                className={styles.form_item}
                name="firstName"
                rules={editEnabled ? rules.firstName : []}
                initialValue={userProfile.firstName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.FirstName.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),

        lastName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.LastName.Placeholder" />}
                className={styles.form_item}
                name="lastName"
                rules={editEnabled ? rules.lastName : []}
                initialValue={userProfile.lastName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.LastName.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),

        email: (
            <Item
                label={<FormattedMessage id="App.Email" />}
                className={styles.form_item}
                name="email"
                initialValue={userProfile.email}
            >
                <AuthInput
                    type="email"
                    placeholder={formatMessage({ id: 'Login.Field.Email.Placeholder' })}
                    inputs={inputs}
                    disabled
                    style={{ WebkitAppearance: 'none' }}
                />
            </Item>
        ),
        companyName: (
            <Item
                label={<FormattedMessage id="SignUp.Field.CompanyName" />}
                className={styles.form_item}
                name="companyName"
                rules={editEnabled ? rules.companyName : []}
                initialValue={userProfile.companyName}
            >
                <AuthInput
                    placeholder={formatMessage({ id: 'SignUp.Field.CompanyName.Placeholder' })}
                    inputs={inputs}
                    disabled={!editEnabled || submitting}
                />
            </Item>
        ),
        country: (
            <Item
                label={<FormattedMessage id="SignUp.Field.Country" />}
                className={styles.form_item}
                style={{ paddingBottom: 4 }}
                initialValue={userProfile.countryCode}
            >
                <AuthInput
                    value={countries.find((country) => country.code === userProfile.countryCode)?.name ?? ''}
                    disabled
                />
            </Item>
        ),
        phoneNumber: (
            <Item
                label={<FormattedMessage id="Profile.Field.PhoneNumber" />}
                className={styles.form_item}
                name="phoneNumber"
                rules={rules.phoneNumber}
                initialValue={userProfile.phoneNumber}
            >
                <AuthInput inputs={inputs} disabled={!editEnabled || submitting} />
            </Item>
        ),
        postcode: (
            <Item
                label={<FormattedMessage id="Profile.Field.Postcode" />}
                className={styles.form_item}
                name="postcode"
                initialValue={userProfile.postcode}
            >
                <AuthInput inputs={inputs} disabled={!editEnabled || submitting} />
            </Item>
        ),
        address: (
            <Item
                label={<FormattedMessage id="Profile.Field.Address" />}
                className={styles.form_item}
                name="address"
                initialValue={userProfile.address}
            >
                <AuthInput inputs={inputs} disabled={!editEnabled || submitting} />
            </Item>
        ),
    };

    const handleSubmit = useCallback(
        async (values: { [Key in FieldsKeys]: string }) => {
            if (!userID) {
                return;
            }
            const oldUserData = objectUtils.trimObjectWithKeys(userProfile, mutableFields);
            const newUserData = objectUtils.trimObjectWithKeys(values, mutableFields);
            const shouldProfileUpdate = !isEqual(oldUserData, newUserData);

            if (shouldProfileUpdate) {
                updateUserProfile({ userID: userID.toString(), newUserData });
            }
            setEditEnabled(false);
        },
        [userID, userProfile, updateUserProfile]
    );

    const queryClient = useQueryClient();

    const updateUserPicture = useMutation({
        mutationFn: ({ userID, file }: { userID: string; file: RcFile }) =>
            userServices.uploadUserProfilePicture(userID, file),
        onSuccess: () => {
            handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.ProfilePicture' }));
            queryClient.invalidateQueries({ queryKey: ReactQueryKeys.userProfile(userID!) });
        },
    });

    const removeUserPicture = useMutation({
        mutationFn: ({ userID }: { userID: string }) => userServices.removeUserProfilePicture(userID),
        onSuccess: () => {
            handleSuccessMessage(formatMessage({ id: 'Profile.SuccessMsg.ProfilePicture' }));
            queryClient.invalidateQueries({ queryKey: ReactQueryKeys.userProfile(userID!) });
        },
    });

    const initialsImage = (
        <InitialsPlaceholder firstName={userProfile.firstName} lastName={userProfile.lastName} size={120} />
    );

    const requiredFields: FieldsKeys[] = [...requiredMutableFields];
    const { checkErrors } = useFormCheckErrors(form, requiredFields);

    const buttonArea = (
        <div className={styles.button_area}>
            {editEnabled ? (
                <Item shouldUpdate>
                    {() => (
                        <AnalyticsButton
                            type="primary"
                            htmlType="submit"
                            className={styles.submit_button}
                            disabled={submitting || checkErrors()}
                            onClick={() => analytics.logPersonalProfileEvent(PersonalProfileAction.done)}
                        >
                            <FormattedMessage id="App.Done" />
                        </AnalyticsButton>
                    )}
                </Item>
            ) : (
                <Item>
                    <AnalyticsButton
                        type="primary"
                        className={styles.submit_button}
                        onClick={() => {
                            analytics.logPersonalProfileEvent(PersonalProfileAction.edit);
                            setEditEnabled(true);
                        }}
                    >
                        <FormattedMessage id="App.Edit" />
                    </AnalyticsButton>
                </Item>
            )}
        </div>
    );

    const UserInfoForm = () => {
        return (
            <div className={styles.inner_frame}>
                <EditProfileImage
                    src={userProfile.pictureURL}
                    shape="round"
                    onUpload={(file) => {
                        if (!userID) return;
                        updateUserPicture.mutate({ userID: userID.toString(), file });
                    }}
                    onRemove={() => {
                        if (!userID) return;
                        removeUserPicture.mutate({ userID: userID.toString() });
                    }}
                    placeholder={initialsImage}
                    title={formatMessage({ id: 'Profile.ProfileImage.Title' })}
                    fullImage
                />
                <div>
                    <Form form={form} layout="vertical" className={styles.form} onFinish={handleSubmit as any}>
                        <div className={styles.inline_container}>
                            {fields.firstName}
                            <div className={styles.inline_space} />
                            {fields.lastName}
                        </div>
                        {fields.email}
                        <div className={styles.inline_container}>
                            {fields.companyName}
                            <div className={styles.inline_space} />
                            {fields.country}
                        </div>
                        <div className={styles.inline_container}>
                            {fields.phoneNumber}
                            <div className={styles.inline_space} />
                            {fields.postcode}
                        </div>
                        {fields.address}
                        {buttonArea}
                    </Form>
                    {accountDeleteStatus !== undefined && <DeleteAccount accountDeleteStatus={accountDeleteStatus} />}
                </div>
            </div>
        );
    };

    return (
        <AppWrapper className={styles.app_wrapper}>
            {userID ? (
                <UserInfoForm />
            ) : (
                <div className={styles.app_wrapper}>
                    <FormattedMessage id="Error.Profile.FetchPersonalProfile" />
                </div>
            )}
        </AppWrapper>
    );
};

export default UserProfile;
