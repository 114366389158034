import React from 'react';
import { Tooltip } from 'antd';
import StyledModal from '../../../shared/StyledModal';
import FormattedMessage from '../../../../localization/FormatMessage';
import styles from './Guidelines.styl';
import './Guidelines.global.styl';
import BackButton from '../../../shared/Buttons/BackButton';
import curveSampleImage from '../../../../images/customCurveSample.png';
import { ReactComponent as InfoIcon } from '../../../../images/iconInfo.svg';

interface GuidelinesProps {
    visible: boolean;
    onClose: () => void;
}

const GUIDELINE_TEXTS = [
    {
        title: <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1" />,
        steps: [
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point1"
                key="step1point1"
                values={{
                    softwareName: (
                        <b>
                            <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point1.SoftwareName" />
                        </b>
                    ),
                }}
            />,
            <div key="step1point2">
                <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point2" />
                <div>
                    <ul style={{ listStyleType: 'disc' }}>
                        <li>
                            <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point2.SubPoint" />
                        </li>
                    </ul>
                </div>
            </div>,
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point3"
                key="step1point3"
            />,
            <div key="step1point4" className={styles.sample_tooltip_step}>
                <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point4" />
                <Tooltip
                    trigger={['click', 'hover']}
                    placement="right"
                    title={
                        <div className={styles.sample_tooltip_step}>
                            <img src={curveSampleImage} alt="curveSampleImage" />
                        </div>
                    }
                >
                    <InfoIcon className={styles.tooltip_info} />
                </Tooltip>
            </div>,
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point5"
                key="step1point5"
            />,
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point6"
                key="step1point6"
                values={{
                    fileType: (
                        <b>
                            <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step1.Point6.FileType" />
                        </b>
                    ),
                }}
            />,
        ],
    },
    {
        title: <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step2" />,
        steps: [
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step2.Point1"
                key="step2point1"
            />,
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step2.Point2"
                key="step2point2"
            />,
        ],
    },
    {
        title: <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step3" />,
        steps: [
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step3.Point1"
                key="step3point1"
            />,
            <FormattedMessage
                id="DataView.Upload.Guidelines.Instructions.PrepareFiles.Step3.Point2"
                key="step3point2"
            />,
        ],
    },
];

const Guidelines: React.FunctionComponent<GuidelinesProps> = (props) => {
    const { visible, onClose } = props;

    return (
        <StyledModal
            width={856}
            closable={false}
            open={visible}
            destroyOnClose
            title={<BackButton onClick={onClose} buttonText="App.Back" />}
            footer={null}
            className="modal_reduced_header"
        >
            <div className={styles.subtitle3}>
                <FormattedMessage id="DataView.Upload.Guidelines.Instructions.PrepareFiles" />
            </div>
            <br />
            {GUIDELINE_TEXTS.map((point, index) => {
                return (
                    <div key={`point-${index}`}>
                        <div>{point.title}</div>
                        {point.steps && (
                            <div>
                                <ul>
                                    {point.steps.map((step, index) => (
                                        <li key={`step-${index}`}>{step}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                );
            })}
        </StyledModal>
    );
};

export default Guidelines;
