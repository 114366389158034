import getEquotipLogElement, { EquotipEventLogElement } from '../Equotip/getEquotipLogElement';
import elementAngles from './Angles';
import elementLimits from './Limits';
import elementProbe from '../shared/Probe';
import elementCreation from './Creation';
import elementMeasurements from './Measurements';

enum GLMLogType {
    Angles = 'Angles',
    Limits = 'Limits',
    Probe = 'Probe',
    Creation = 'Creation',
    Measurements = 'Measurements',
}

const getGLMLogElement = (logType: string | GLMLogType): EquotipEventLogElement => {
    switch (logType) {
        case GLMLogType.Angles:
            return elementAngles;
        case GLMLogType.Limits:
            return elementLimits;
        case GLMLogType.Probe:
            return elementProbe;
        case GLMLogType.Creation:
            return elementCreation;
        case GLMLogType.Measurements:
            return elementMeasurements;

        default:
            return getEquotipLogElement(logType);
    }
};

export default getGLMLogElement;
