import { ProductModel } from '../../../../types/proceq';

const getSchmidtPrimaryScaleUnit = (productModel: ProductModel) => {
    switch (productModel) {
        case ProductModel.SSL:
            return 'Q';
        case ProductModel.SCHMIDT:
        case ProductModel.RS8000:
            return 'R';
        default:
            return '';
    }
};

export default getSchmidtPrimaryScaleUnit;
