import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import StaticMap from '../StaticMap';

export const Creation: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const { latitude, longitude, measurementInfo } = log.content;
    const coordinates = { lat: +latitude, lng: +longitude };
    const {
        folderName,
        fileName,
        measurementMode,
        geometries,
        unit,
        upperLimitFor20,
        upperLimitFor60,
        lowerLimitFor20,
        lowerLimitFor60,
    } = measurementInfo;
    return (
        <div>
            <b>
                <FormattedMessage
                    id="Proceq.LogbookPropertyCreatedFileInFolder"
                    values={{
                        fileName,
                        folderName,
                    }}
                />
            </b>
            <br />
            <FormattedMessage id="App.Logbook.GLM.Geometries" values={{ value: geometries }} />
            <br />
            <FormattedMessage id="App.Logbook.GLM.Creation.Unit" values={{ value: unit }} />
            <br />
            <FormattedMessage id="App.Logbook.GLM.Creation.MeasurementMode" values={{ value: measurementMode }} />
            <br />
            <FormattedMessage id="App.Logbook.GLM.Creation.20LowerLimit" values={{ value: lowerLimitFor20 }} />
            <br />
            <FormattedMessage id="App.Logbook.GLM.Creation.20UpperLimit" values={{ value: upperLimitFor20 }} />
            <br />
            <FormattedMessage id="App.Logbook.GLM.Creation.60LowerLimit" values={{ value: lowerLimitFor60 }} />
            <br />
            <FormattedMessage id="App.Logbook.GLM.Creation.60UpperLimit" values={{ value: upperLimitFor60 }} />
            <br />
            <StaticMap coordinates={coordinates} />
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
