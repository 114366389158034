import React from 'react';
import { get, has } from 'lodash';
import styles from '../../DataViewers.styl';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import FormattedMessage from '../../../../../localization/FormatMessage';
import SectionTable from '../../shared/SectionTable';
import { isEqualOrAfterPD8050Launch } from '../../../../Common/Pundit/exportConstants';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="Proceq.Settings.ImageProcessing" />
    </>
);

export const ImageProcessing: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    isMetric,
    showTitle,
}) => {
    const isIQSAFT = get(data, 'settings.0.content.process.isIQSAFT');
    const version = data?.measurement.content?.info?.version;
    const formatMessage = useFormatMessage();
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.PUNDIT.ImageProcessing.FocusDepth',
            unitId: 'PUNDIT.Settings Panel.Focus Depth',
            value: get(data, 'settings.0.content.process.focusDepth'),
            enable:
                isIQSAFT && has(data, 'settings.0.content.process.focusDepth') && !isEqualOrAfterPD8050Launch(version),
        },
        {
            label: 'App.HTML.PUNDIT.ImageProcessing.LinearGain',
            unitId: 'PUNDIT.Settings Panel.Digital Gain',
            value: get(data, 'settings.0.content.process.digitalGain'),
        },
        {
            label: 'App.HTML.PUNDIT.ImageProcessing.SurfaceWaveCancellation',
            value: get(data, 'settings.0.content.process.surfaceWaveCancellation')
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
        },
        {
            label: 'App.HTML.PUNDIT.ImageProcessing.SignalZeroOffset',
            unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
            value: get(data, 'settings.0.content.process.signalZeroOffset'),
            enable: !isEqualOrAfterPD8050Launch(version),
        },
        {
            label: 'App.HTML.PUNDIT.ImageProcessing.RawDataOffset',
            unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
            value: get(data, 'settings.0.content.process.rawDataOffset'),
            enable: isEqualOrAfterPD8050Launch(version),
        },
        {
            label: 'App.HTML.PUNDIT.ImageProcessing.PulseVelocity',
            unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
            value: get(data, 'settings.0.content.process.pulseVelocity'),
        },
    ];

    const presets = getConvertedSectionTableData(presetsRaw, scanType, isMetric);

    return (
        <SectionTable
            showTitle={showTitle}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={presets}
        />
    );
};

export default ImageProcessing;
