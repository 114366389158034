import { ProceqUser, UserAccountDeleteInfo, UserProfileInfo } from '../types/profile';
import { post, thunkGet, thunkPost, thunkPut } from './apiService';
import { getApiURL } from './ApiURL';
import { handleError } from './error';
import * as profileActions from '../actions/profile';
import * as userActions from '../actions/user';
import { APIResponse } from '../types/api';
import { ProductCode } from '../types/proceq';
import { EagleCloudUser, PictureInfo } from '../types/profile';
import { fileToDataBlob } from '../utils/fileUtils';
import { LicenseProduct, UserLicenseInfo } from '../types/license';

export const getEagleIDProfile = async (userID: string, errorHandler = handleError) => {
    type Response = APIResponse<UserProfileInfo>;
    const url = getApiURL('EAGLE_ID_PROFILE').replace('{userID}', userID);
    const { response, dispatch, getState } = await thunkGet<Response>(url, { errorHandler });
    if (response.data) {
        const currentProfile = getState().profile.userProfile;
        if (currentProfile.updated !== response.data.updated) {
            dispatch(profileActions.receivedUserProfile(response.data));
        }
    }

    return response;
};

type UserLicenseListResponse = APIResponse<UserLicenseInfo[]>;

export const getLicenseList = async () => {
    const url = getApiURL('USER_LICENSE_LIST');
    const { response, dispatch } = await thunkGet<UserLicenseListResponse>(url);
    if (response.data) {
        dispatch(profileActions.receivedUserLicenseList({ data: response.data }));
    }
    return response;
};

export const getLicenses = async (product: LicenseProduct | ProductCode) => {
    type Response = { data: UserLicenseInfo };

    const url = getApiURL('USER_LICENSE');
    const { response, dispatch } = await thunkGet<Response>(url, {
        params: {
            product,
        },
    });
    if (response.data) {
        dispatch(
            profileActions.receivedUserLicense({
                product,
                license: response.data,
            })
        );
    }
};

export const getProceqUser = async (userID: number, isCurrentUser?: boolean) => {
    type Response = ProceqUser;
    const url = getApiURL('GET_ACCOUNT');
    const params =
        userID !== undefined
            ? {
                  filterKey: 'id',
                  filterValue: userID,
              }
            : {};

    const { response, dispatch } = await thunkGet<Response>(url, {
        injectToken: true,
        params,
    });

    if (response) {
        dispatch(userActions.receivedProceqUsers({ userList: [response] }));

        if (isCurrentUser) {
            dispatch(profileActions.receivedRoleInfo(response?.roles ?? ''));
        }
    }
    return response;
};

export const getAllProceqUsers = async () => {
    type Response = {
        data: ProceqUser[];
        draw: number;
        recordsFiltered: number;
        recordsTotal: number;
    };

    const url = getApiURL('GET_ALL_ACCOUNTS');

    const { response, dispatch } = await thunkGet<Response>(url, {
        injectToken: true,
    });

    if (Array.isArray(response?.data)) {
        dispatch(userActions.receivedProceqUsers({ userList: response.data }));
    }

    return response;
};

interface GetSharedProceqUsersParams {
    ids: number[];
    product?: ProductCode;
}

export const getSharedProceqUsers = async (params: GetSharedProceqUsersParams) => {
    type Response = ProceqUser[];
    const url = getApiURL('GET_SHARED_ACCOUNTS');

    const { response, dispatch } = await thunkPost<Response>(url, {
        injectToken: true,
        params: { ...params },
    });

    if (Array.isArray(response)) {
        dispatch(userActions.receivedProceqUsers({ userList: response }));
    }

    return response;
};

export interface ChangePasswordParams {
    passwordOld: string;
    password: string;
    passwordConfirm: string;
}

export const changeUserPassword = async (params: ChangePasswordParams) => {
    const url = getApiURL('PASSWORD_CHANGE');
    return post<{}>(url, {
        params: { ...params },
        errorHandler: handleError,
    });
};

export interface UpdateUserProfileParams {
    firstName: string;
    lastName: string;
    companyName: string;
}

export const updateUserProfile = async (userID: string, params: UpdateUserProfileParams) => {
    type Response = { data: EagleCloudUser };
    const url = getApiURL('EAGLE_ID_PROFILE').replace('{userID}', userID.toString());
    const { response, dispatch } = await thunkPut<Response>(url, {
        params: { ...params },
        errorHandler: handleError,
    });
    dispatch(profileActions.receivedUserProfile(response.data));
};

export const uploadUserProfilePicture = async (userID: string, file: File) => {
    type Response = { data: PictureInfo };
    const dataBlob = await fileToDataBlob(file);
    const url = getApiURL('EAGLE_ID_PROFILE_PICTURE').replace('{userID}', userID.toString());
    const { response } = await thunkPost<Response>(url, {
        params: { dataBlob },
        errorHandler: handleError,
    });
    return response;
};

export const removeUserProfilePicture = async (userID: string) => {
    type Response = PictureInfo;
    const url = getApiURL('EAGLE_ID_PROFILE_PICTURE').replace('{userID}', userID.toString());
    const { dispatch, response } = await thunkPost<Response>(url, {
        params: { dataBlob: '' },
        errorHandler: handleError,
    });
    dispatch(profileActions.removedProfilePicture({ pictureURL: undefined }));
    return response;
};

export const getUserDeleteAccount = async () => {
    type Response = APIResponse<UserAccountDeleteInfo>;
    const url = getApiURL('GET_DELETE_ACCOUNT');
    return thunkGet<Response>(url);
};
