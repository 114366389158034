import React, { useEffect } from 'react';
import StyledModal from '../../../../shared/StyledModal';
import { SnapshotEntry } from '../SnapshotsViewer/SnapshotTypes';
import Spinner from '../../../../shared/Spinner';
import Button from '../../../../AnalyticsComponents/Button';
import { ReactComponent as RightIcon } from '../../../../../images/arrowRightFilled.svg';
import { ReactComponent as LeftIcon } from '../../../../../images/arrowLeftFilled.svg';
import styles from './SnapshotModal.styl';
import useSnapshotInfo from '../../../../../hooks/useSnapshotInfo';
import FormattedMessage from '../../../../../localization/FormatMessage';
import analytics from '../../../../../analytics/firebaseAnalytics';
import { SnapshotModalAction } from '../../../../../analytics/analyticsConstants';
import { ProductCode } from '../../../../../types/proceq';

interface SnapshotModalProps {
    attachmentID?: string;
    setAttachmentID: React.Dispatch<React.SetStateAction<string | undefined>>;
    entries: { [p: string]: SnapshotEntry };
    entryIDs: string[];
    onLazyLoad: (slides: number[]) => void;
    allowShortlistForReport: boolean;
    product: ProductCode;
}

const SnapshotModal: React.FunctionComponent<SnapshotModalProps> = (props) => {
    const { product, attachmentID, setAttachmentID, entries, entryIDs, onLazyLoad, allowShortlistForReport } = props;
    const index = attachmentID ? entryIDs.indexOf(attachmentID) : undefined;
    const hasMoreEntries = entryIDs.length > 1;

    const { title, initials, downloadComponent } = useSnapshotInfo({
        entry: attachmentID ? entries[attachmentID] : undefined,
        onDownload: () => {
            analytics.logSnapshotModal(SnapshotModalAction.download, product);
        },
    });

    useEffect(() => {
        if ((attachmentID && entries[attachmentID].imageURL) || index === undefined || index < 0) return;
        onLazyLoad([index]);
    }, [attachmentID, entries, index, onLazyLoad]);

    return (
        <StyledModal
            defaultTitle
            title={`${title}${initials && ` (${initials})`}`}
            open={!!attachmentID}
            onCancel={() => {
                analytics.logSnapshotModal(SnapshotModalAction.cancel, product);
                setAttachmentID(undefined);
            }}
            styles={{ body: { minHeight: 150, alignItems: 'center' } }}
            width={'80%'}
            footer={
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
                    {allowShortlistForReport && (
                        <>
                            {/* <Button */}
                            {/*    danger */}
                            {/*    ghost */}
                            {/*    onClick={() => { */}
                            {/*        analytics.logSnapshotModal(SnapshotModalAction.removeFromReport, product); */}
                            {/*    }} */}
                            {/* > */}
                            {/*    <FormattedMessage id="Snapshots.RemoveFromReport" /> */}
                            {/* </Button> */}
                            <Button
                                onClick={() => {
                                    analytics.logSnapshotModal(SnapshotModalAction.shortlistForReport, product);
                                }}
                            >
                                <FormattedMessage id="Snapshots.ShortlistForReport" />
                            </Button>
                        </>
                    )}
                    {downloadComponent}
                </div>
            }
        >
            {hasMoreEntries && (
                <Button
                    onClick={() => {
                        setAttachmentID(entryIDs[(index ?? 0) - 1]);
                        analytics.logSnapshotModal(SnapshotModalAction.leftButton, product);
                    }}
                    type="link"
                    className={styles.left_button}
                    disabled={index === 0}
                >
                    <LeftIcon />
                </Button>
            )}
            {attachmentID && entries[attachmentID].imageURL ? (
                <img className={styles.snapshot} src={entries[attachmentID].imageURL} alt={'snapshot'} />
            ) : (
                <Spinner />
            )}
            {hasMoreEntries && (
                <Button
                    onClick={() => {
                        setAttachmentID(entryIDs[(index ?? 0) + 1]);
                        analytics.logSnapshotModal(SnapshotModalAction.rightButton, product);
                    }}
                    type="link"
                    className={styles.right_button}
                    disabled={index === entryIDs.length - 1}
                >
                    <RightIcon />
                </Button>
            )}
        </StyledModal>
    );
};

export default SnapshotModal;
