import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { RootState } from '../reducers';
import { getContracts } from '../api/contractService';
import { useMeasurements } from './useProductData';
import { SingleMeasurementState } from '../reducers/measurement';
import { FeatureCode } from '../types/contract';
import { ProductCode } from '../types/proceq';
import { ProductFeature, useProductContext } from '../components/DataView/ProductContextProvider';

export const useContracts = () => {
    const contracts = useSelector((state: RootState) => state.contract.contracts);
    return contracts;
};

const retrievingContractIDs = new Set<string>();
export const useContractRetriever = (mIDs: string[]) => {
    const contracts = useContracts();
    const measurements = useMeasurements();

    const handleFetchContracts = async (ids: string[]) => {
        ids.forEach((id) => retrievingContractIDs.add(id));

        try {
            await getContracts(ids);
        } catch {
            ids.forEach((id) => retrievingContractIDs.delete(id));
        }
    };

    useEffect(() => {
        if (mIDs.length === 0) {
            return;
        }
        const contractIDs = Array.from(
            new Set(mIDs.map((mID) => measurements[mID].measurement.contractID.toLowerCase()))
        ).filter((id) => !!id && !(id in contracts) && !retrievingContractIDs.has(id));

        if (contractIDs.length > 0) {
            handleFetchContracts(contractIDs);
        }
    }, [measurements, mIDs, contracts]);
};

export const useSelectedContractFeatures = (
    measurements: { [key: string]: SingleMeasurementState },
    mIDs?: string[]
) => {
    const contracts = useContracts();

    const { isFeatureEnabled } = useProductContext();
    const isIgnoreInvalidMeasurements = isFeatureEnabled(ProductFeature.IGNORE_INVALID_MEASUREMENTS);

    const {
        validateFeature,
        isContractValid,
        hasDemoFile,
        getInvalidMeasurementIDs,
        getInvalidMeasurementIDsMultiple,
        demoMeasurementIDs,
    } = useMemo(() => {
        let hasDemoFile = false;
        const demoMeasurementIDs = new Set<string>();
        const invalidMeasurementIDs = new Set<string>();
        const contractIDMap: Record<string, string[]> = {};
        mIDs?.forEach((mID) => {
            const measurement = measurements[mID]?.measurement;
            if (measurement?.isDemoFile) {
                hasDemoFile = true;
                invalidMeasurementIDs.add(mID);
                demoMeasurementIDs.add(mID);
            }
            const contractID = measurement?.contractID;
            if (contractIDMap[contractID]) {
                contractIDMap[contractID] = [...contractIDMap[contractID], mID];
            } else {
                contractIDMap[contractID] = [mID];
            }
        });

        const selectedContractIDs = Object.keys(contractIDMap);

        const agg = selectedContractIDs.reduce<{
            featureCounts: { [product: string]: { [feature: string]: number } };
            allFeatureCounts: { [feature: string]: number };
            availableContractCount: { [product: string]: number };
            allAvailableContractCount: number;
            selectedContracts: number;
            productToContractMap: { [product: string]: string[] };
        }>(
            (agg, contractID) => {
                const contract = contracts[contractID.toLowerCase()];

                const isExpired =
                    !!contract?.expirationDate && dayjs().isAfter(dayjs(contract.expirationDate).endOf('day'));

                if (!contract || isExpired) {
                    contractIDMap[contractID].forEach((id) => invalidMeasurementIDs.add(id));
                }

                if (contract && !isExpired) {
                    const product = contract.product?.toUpperCase();

                    if (agg.productToContractMap[product]) {
                        agg.productToContractMap[product] = [...agg.productToContractMap[product], contractID];
                    } else {
                        agg.productToContractMap[product] = [contractID];
                    }

                    if (!(product in agg.featureCounts)) {
                        agg.featureCounts[product] = {};
                    }
                    for (const feature of contract.featureList ?? []) {
                        agg.featureCounts[product][feature.code] = (agg.featureCounts[product][feature.code] ?? 0) + 1;
                        agg.allFeatureCounts[feature.code] = (agg.allFeatureCounts[feature.code] ?? 0) + 1;
                    }
                    agg.availableContractCount[product] = (agg.availableContractCount[product] ?? 0) + 1;
                    agg.allAvailableContractCount = (agg.allAvailableContractCount ?? 0) + 1;
                }
                return agg;
            },
            {
                featureCounts: {},
                allFeatureCounts: {},
                availableContractCount: {},
                allAvailableContractCount: 0,
                selectedContracts: selectedContractIDs.length,
                productToContractMap: {},
            }
        );

        const isContractValid = agg.allAvailableContractCount === agg.selectedContracts;

        const getInvalidMeasurementIDsMultiple = (productFeatures: { [productModel: string]: string }) => {
            for (const [productModel, feature] of Object.entries(productFeatures)) {
                getInvalidMeasurementIDs(feature, productModel);
            }
            return Array.from(invalidMeasurementIDs);
        };

        const getInvalidMeasurementIDs = (feature?: string, productModel?: string, product?: string) => {
            if (!feature) return Array.from(invalidMeasurementIDs);

            if (feature === FeatureCode.COMMON.SHARE_URL && product) {
                // allow share for equotip as long as not demo and valid contract
                if (product === ProductCode.EQUOTIP) {
                    return Array.from(invalidMeasurementIDs);
                }
                // GLM and SCHMIDT contracts are lifetime
                if (product === ProductCode.SCHMIDT || product === ProductCode.GLM) {
                    return Array.from(demoMeasurementIDs);
                }
            }

            const featureInvalidMeasurementIDs = new Set<string>(invalidMeasurementIDs);
            const contractIDs = productModel ? agg.productToContractMap[productModel] ?? [] : selectedContractIDs;
            contractIDs.forEach((contractID) => {
                if (
                    !contracts[contractID.toLowerCase()]?.featureList?.map((feature) => feature.code)?.includes(feature)
                ) {
                    contractIDMap[contractID].forEach((id) => featureInvalidMeasurementIDs.add(id));
                }
            });
            return Array.from(featureInvalidMeasurementIDs);
        };

        const validateFeature = (feature: string, product?: string) => {
            if (!isContractValid || hasDemoFile) {
                return false;
            }
            if (product) {
                const featuresCount = agg.featureCounts[product]?.[feature] ?? 0;
                return featuresCount === (agg.availableContractCount[product] ?? 0);
            }
            const featureCount = agg.allFeatureCounts[feature] ?? 0;
            return featureCount === agg.allAvailableContractCount;
        };

        return {
            validateFeature,
            isContractValid,
            hasDemoFile,
            getInvalidMeasurementIDs,
            getInvalidMeasurementIDsMultiple,
            demoMeasurementIDs: Array.from(demoMeasurementIDs),
        };
    }, [contracts, mIDs, measurements]);

    return {
        validateFeature: isIgnoreInvalidMeasurements ? () => true : validateFeature,
        isContractValid: isIgnoreInvalidMeasurements ? true : isContractValid,
        hasDemoFile,
        getInvalidMeasurementIDs: isIgnoreInvalidMeasurements ? () => [] : getInvalidMeasurementIDs,
        getInvalidMeasurementIDsMultiple: isIgnoreInvalidMeasurements ? () => [] : getInvalidMeasurementIDsMultiple,
        demoMeasurementIDs,
    };
};
