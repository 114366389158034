import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import Photo from '../../../../Attachments/Photo';
import { formatDateTimeDefault } from '../../../../../utils/dateUtils';
import styles from './UserText.styl';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';

export const UserPhoto: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, product }) =>
    log.content.deletedAt ? (
        <div className={styles.deleted}>
            <FormattedMessage
                id="Proceq.LogbookPhotoDeleted"
                values={{
                    date: formatDateTimeDefault(log.content.deletedAt),
                }}
            />
        </div>
    ) : (
        <div>
            <b>
                <FormattedMessage id="Proceq.LogbookPropertyPhotoAdded" />
            </b>
            <Photo
                mID={log.mID}
                aID={log.content.attachmentId}
                attachmentName={log.content.attachmentName}
                analyticsCategory={SnapshotAction.logbook}
                analyticsProduct={product}
                analyticsType={log.type}
            />
        </div>
    );

export const icon = (log: Log) => (log.content.deletedAt ? 'log_deleted' : 'log');

export default {
    element: UserPhoto,
    icon,
};
