import { ProductCode } from '../../types/proceq';

export const productAppStoreURL: { [key in ProductCode]?: string } = {
    [ProductCode.GPR]: 'https://apps.apple.com/app/proceq-gpr-live/id1240095180',
    [ProductCode.GPR_SOIL]: 'https://apps.apple.com/app/proceq-gpr-subsurface/id1524783895',
    [ProductCode.PUNDIT]: 'https://apps.apple.com/app/pundit-live-array/id1345390633',
    [ProductCode.EQUOTIP]: 'https://apps.apple.com/app/equotip-live/id1144114836',
    [ProductCode.SCHMIDT]: 'https://apps.apple.com/app/schmidt-live/id1239435876',
    [ProductCode.FDL]: 'https://apps.apple.com/app/proceq-flaw-detector-live/id1489727542',
    [ProductCode.GLM]: 'https://apps.apple.com/app/glossmeter-live/id1491697382',
    [ProductCode.PIT_IE]: 'https://apps.apple.com/app/pundit-impact-live/id1548430882',
};

export const SCREENING_EAGLE_APP_STORE_URL =
    'https://apps.apple.com/developer/screening-eagle-technologies/id1515215362';
