import { get } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { getLabelFromGlobalDataKey } from '../../../RegisteredInfo/helper';
import FormattedMessage from '../../../../../localization/FormatMessage';

export const ConversionCurve: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    const formatMessage = useIntl().formatMessage;
    const updatedConversionCustom = get(log, 'content.updatedCustomMaterialName');
    const updatedConversionId = getLabelFromGlobalDataKey(
        'convcurveschmidt',
        get(log, 'content.updatedConversionCurveId')
    );
    const updatedConversionName = updatedConversionId
        ? formatMessage({ id: updatedConversionId })
        : updatedConversionCustom;

    const oldConversionCustom = get(log, 'content.oldCustomMaterialName');
    const oldConversionID = getLabelFromGlobalDataKey('convcurveschmidt', get(log, 'content.oldConversionCurveId'));
    const oldConversionName = oldConversionID ? formatMessage({ id: oldConversionID }) : oldConversionCustom;

    if (oldConversionName) {
        return (
            <span>
                <FormattedMessage
                    id="Proceq.LogbookPropertyConversionCurveChangedFromTo"
                    values={{
                        oldValue: oldConversionName,
                        newValue: updatedConversionName,
                    }}
                />
            </span>
        );
    }

    return (
        <span>
            <FormattedMessage
                id="Proceq.LogbookPropertyConversionCurveChangedTo"
                values={{ newValue: updatedConversionName }}
            />
        </span>
    );
};

export default {
    element: ConversionCurve,
};
