import { ContractInfo } from '../types/contract';
import actionTypes, { PayloadAction } from './actionTypes';

export interface ReceivedContractsPayload {
    contracts: ContractInfo[];
}

export const receivedContracts = (payload: ReceivedContractsPayload): PayloadAction<ReceivedContractsPayload> => {
    return {
        type: actionTypes.RECEIVED_CONTRACTS,
        payload,
    };
};
