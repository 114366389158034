import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { formatTime, formatTimeWithToday } from '../../utils/dateUtils';
import { NotificationRecord } from '../../types/notification';
import { productCodeToIconMap } from '../shared/AppIcon';
import { ProductCode } from '../../types/proceq';

export function useNotificationUnreadCount() {
    return useSelector((state: RootState) => state.notification.unreadCount);
}

export function useNotificationListData() {
    const notificationState = useSelector((state: RootState) => state.notification);

    const data = useMemo(() => {
        const { notifications: notificationsMap, notificationIDs } = notificationState;
        const notifications = notificationIDs.filter((id) => notificationsMap[id]).map((id) => notificationsMap[id]);

        const groupedNotifications: { [key: string]: NotificationRecord[] } = groupBy(notifications, (value) =>
            formatTimeWithToday(value.created)
        );
        const dates = Object.keys(groupedNotifications);

        return { groupedNotifications, dates };
    }, [notificationState]);

    const { unreadCount, syncIDX, meta } = notificationState;

    return { ...data, unreadCount, syncIDX, meta };
}

export function useNotificationItemData(notification: NotificationRecord) {
    const { read, product, title, message, created, data, type } = notification;
    const productName = productCodeToIconMap[product.toUpperCase() as ProductCode];

    let redirectURL = data.redirect === 'external' && data.url ? data.url : undefined;
    if (redirectURL && !redirectURL?.match(/^https?:\/\//i)) {
        redirectURL = `http://${redirectURL}`;
    }

    const messages = data.url ? message.split(data.url) : [message];
    // Only have messageURL if messageURL is part of message
    const messageURL = messages.length > 1 ? data.url : undefined;

    const date = formatTime(created, 'h:mm a');

    return {
        read,
        productName,
        title,
        redirectURL,
        date,
        messageURL,
        messages,
        type,
    };
}
