import { useEffect } from 'react';
import { focusManager } from '@tanstack/react-query';

// https://github.com/TanStack/query/issues/2960
// Needed to prevent the refetch from causing the component to be re-mounted and thus skipping the onChangeHandlers
export function useDisableRefetchOnFocus() {
    useEffect(() => {
        focusManager.setFocused(false);
        return () => focusManager.setFocused(undefined);
    }, []);
}
