import {
    AxisProps,
    Chart,
    HighchartsChart,
    HighchartsProvider,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import React from 'react';
import { labelStyleConfig, tooltipStyleProps } from '../../DataView/DataViewers/Readings/HighchartsConfigs';

function formatter(
    sourceUnit: string | number,
    targetUnit: string,
    formatTooltipValue?: (value: number | undefined) => number | string | undefined
) {
    return function unitFormatter(this: Highcharts.TooltipFormatterContextObject) {
        const header = `<b>${formatTooltipValue ? formatTooltipValue(this.x) : this.x} ${sourceUnit}</b><br/>`;
        let content = '';
        if (this.points) {
            for (let indexSeries = 0; indexSeries < this.points.length; indexSeries++) {
                const thisPoint = this.points?.[indexSeries];
                if (indexSeries !== 0) {
                    content += '<br/>';
                }
                content += `<span style="color:${thisPoint?.color}">\u25CF</span> ${thisPoint?.series.name}:`;
                content += `${formatTooltipValue ? formatTooltipValue(thisPoint?.y) : thisPoint.y} ${targetUnit}`;
            }
        }
        return header + content;
    };
}

interface HighchartsLineSeriesProps {
    sourceUnit: string | number;
    targetUnit: string;
    xAxisTitle?: string | number;
    yAxisTitle?: string | number;
    xAxisProps?: AxisProps<Highcharts.XAxisOptions>;
    yAxisProps?: AxisProps<Highcharts.YAxisOptions>;
    formatTooltipValue?: (value: number | undefined) => number | string | undefined;
}

const HighchartsLineSeries: React.FunctionComponent<React.PropsWithChildren<HighchartsLineSeriesProps>> = (props) => {
    const { sourceUnit, targetUnit, xAxisTitle, yAxisTitle, yAxisProps, xAxisProps, formatTooltipValue } = props;
    return (
        <HighchartsProvider Highcharts={Highcharts}>
            <HighchartsChart>
                <Chart backgroundColor="transparent" animation={false} />

                <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="top"
                    itemStyle={{ color: 'currentColor' }}
                    itemHoverStyle={{ color: 'currentColor' }}
                />

                <Tooltip
                    formatter={formatter(sourceUnit, targetUnit, formatTooltipValue)}
                    {...tooltipStyleProps}
                    shared
                />

                <XAxis title={labelStyleConfig} labels={labelStyleConfig} {...xAxisProps}>
                    <XAxis.Title>{xAxisTitle}</XAxis.Title>
                </XAxis>

                <YAxis id="number" type="linear" title={labelStyleConfig} labels={labelStyleConfig} {...yAxisProps}>
                    <YAxis.Title>{yAxisTitle}</YAxis.Title>
                    {props.children}
                </YAxis>
            </HighchartsChart>
        </HighchartsProvider>
    );
};

export default HighchartsLineSeries;
