import { SingleMeasurementState } from '../../../../reducers/measurement';
import { FormatMessageFunc } from '../../../../types';

export const listMeasurementNames = (
    invalidMeasurementIDs: string[],
    measurements: { [p: string]: SingleMeasurementState },
    formatMessage: FormatMessageFunc
) => {
    const measurementNames: string[] = [];
    const totalIDs = invalidMeasurementIDs.length;
    for (let index = 0; index < totalIDs && index < 5; index++) {
        if (index < 4 || (index === 4 && totalIDs === 5)) {
            measurementNames.push(measurements[invalidMeasurementIDs[index]].measurement.name);
        } else {
            measurementNames.push(
                formatMessage({ id: 'DataView.Share.Invalid.MoreMeasurements' }, { count: totalIDs - 4 })
            );
        }
    }
    return measurementNames;
};
