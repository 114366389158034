import { compact } from 'lodash';
import { ApiURLConfig } from './urls';

export interface URLConfig {
    baseURL: string | (() => string);
    domain: string;
    subDomain?: string;
    version?: string;
}

export const formAPIURL = (baseURL: string, config: URLConfig): string => {
    return compact([baseURL, config.version ?? 'v1', config.domain, config.subDomain]).join('/');
};

export const getApiURL = (
    key: keyof typeof ApiURLConfig,
    urlParams: { [key: string]: string | number } = {}
): string => {
    const api: URLConfig = ApiURLConfig[key];
    const baseURL = typeof api.baseURL === 'string' ? api.baseURL : api.baseURL();
    let apiURL = formAPIURL(baseURL, api);

    // Replace the URL path with params.
    // Exmaple: /profile/{userID} -> /profile/123
    for (const key of Object.keys(urlParams)) {
        apiURL = apiURL.replace(`{${key}}`, urlParams[key].toString());
    }

    return apiURL;
};
