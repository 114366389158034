import { routeUtils } from 'tds-common-fe/src/lib/index';
import config from '../../config';
import { ProductCode } from '../../types/proceq';
import { ViewType } from '../../types/measurement';

const routeURLs = {
    LOGOUT: '/logout',
    LOGIN: '/login',
    AUTH: '/auth',
    DASHBOARD: '/dashboard',
    DATA_PRODUCT: '/data/:productName/:viewType?/:folderID?',
    MEASUREMENT: '/measurement/:measurementID',
    TOOL_PRODUCT: '/tool/:productName',
    STANDALONE_TOOL: '/standaloneTool/:productName',
    APP_PRODUCT: '/app/:productName',
    PERMISSION_DENIED: '/permissionDenied',
    MASQUERADE: '/masquerade',
    URI_REDIRECT: '/uriRedirect',

    PROFILE: '/profile',
    PROFILE_PERSONAL: '/profile/personal',
    PROFILE_PROBES: '/profile/probes',
    PROFILE_SECURITY: '/profile/security',
    PROFILE_SUBSCRIPTION: '/profile/subscriptions',
    LICENSE_ACTIVATION: '/licenseActivation',

    STANDALONE_MEASUREMENT: '/standaloneMeasurement',
    STANDALONE_MEASUREMENT_LISTING: '/standaloneMeasurement/:productCode/listing',
    STANDALONE_MEASUREMENT_DETAIL: '/standaloneMeasurement/:productCode/:measurementID',

    MEASUREMENT_IMPORT: '/import/:product',
    SHARED_URL: '/m/:product',
    EXPORT_HTML: '/h/:product',

    EAGLE_ID_LOGIN_UI: `${config.EAGLE_ID_DOMAIN}/login`,
    EAGLE_ID_SIGN_UP_UI: `${config.EAGLE_ID_DOMAIN}/signUp`,
    EAGLE_ID_RESET_PASSWORD_UI: `${config.EAGLE_ID_DOMAIN}/resetPassword`,
    EAGLE_ID_LICENSE_FEATURE: `${config.EAGLE_ID_DOMAIN}/user/:userID/license/featureInfo`,
    EAGLE_ID_CONTACT_SALES: `${config.EAGLE_ID_DOMAIN}/user/:userID/license/contactSales`,
    EAGLE_ID_REQUEST_FREE_TRIAL: `${config.EAGLE_ID_DOMAIN}/user/:userID/license/requestFreeTrial`,
    EAGLE_ID_INITIATE_ACCOUNT_DELETION: `${config.EAGLE_ID_DOMAIN}/initiateAccountDeletion`,
    EAGLE_ID_SWITCH_ACCOUNT: `${config.EAGLE_ID_DOMAIN}/switchAccount`,
    EAGLE_ID_RESET_TWO_FA: `${config.EAGLE_ID_DOMAIN}/2FA/reset`,
    EAGLE_ID_VERIFY_PASSWORD: `${config.EAGLE_ID_DOMAIN}/verifyPassword`,
    EAGLE_ID_RECOVERY_KEY: `${config.EAGLE_ID_DOMAIN}/2FA/recoveryKey`,

    PROCEQ_IMPORT: `${config.PROCEQ_DOMAIN}/m/:product`,
};

export const getProceqImportURL = (product: string) => routeURLs.PROCEQ_IMPORT.replace(':product', product);

export const getEagleIDFeatureInfo = (userID: string) => routeURLs.EAGLE_ID_LICENSE_FEATURE.replace(':userID', userID);

export const getEagleIDContactSales = (userID: string) => routeURLs.EAGLE_ID_CONTACT_SALES.replace(':userID', userID);

export const getEagleIDRequestFreeTrial = (userID: string) =>
    routeURLs.EAGLE_ID_REQUEST_FREE_TRIAL.replace(':userID', userID);

export const getMeasurementPath = (measurementID: string) => {
    return routeURLs.MEASUREMENT.replace(':measurementID', measurementID);
};

export const getDataViewRoute = (args: { productName: string; viewType?: ViewType; folderID?: string }) => {
    const { productName, viewType, folderID } = args;
    return routeURLs.DATA_PRODUCT.replace(':productName', productName)
        .replace(':viewType?', viewType ?? ViewType.Active)
        .replace(':folderID?', folderID ?? '');
};

export const getToolRoute = (productName: string) => {
    return routeURLs.TOOL_PRODUCT.replace(':productName', productName);
};

export const getAppRoute = (productName: string) => {
    return routeURLs.APP_PRODUCT.replace(':productName', productName);
};

export const getStandaloneMeasurementListingPath = (productCode: ProductCode) => {
    return routeURLs.STANDALONE_MEASUREMENT_LISTING.replace(':productCode', productCode);
};

export const getStandaloneToolPath = (productName: string) => {
    return routeURLs.STANDALONE_TOOL.replace(':productName', productName);
};

export const getStandaloneMeasurementDetailPath = (productCode: ProductCode, measurementID: string) => {
    return routeURLs.STANDALONE_MEASUREMENT_DETAIL.replace(':productCode', productCode).replace(
        ':measurementID',
        measurementID
    );
};

export const getMeasurementImportPath = (product: ProductCode) => {
    return routeURLs.MEASUREMENT_IMPORT.replace(':product', product.toLowerCase());
};

export const getHTMLViewerMeasurementPath = (
    product: ProductCode,
    queryParams: { [key: string]: string | number | undefined }
) => {
    const path = routeURLs.EXPORT_HTML.replace(':product', product.toLowerCase());
    return routeUtils.makeQueryPath(path, queryParams);
};

export default routeURLs;
