import React, { useEffect } from 'react';
import { Button, Drawer } from 'antd';
import FormattedMessage from '../../localization/FormatMessage';
import './Notification.global.styl';
import colors from '../../styles/colors.json';
import { useNotificationListData } from './useNotifcationData';
import MyEmpty from '../shared/MyEmpty';
import styles from './Notification.styl';
import NotificationList from './NotificationList';
import { ReactComponent as IconClose } from '../../images/iconClose.svg';
import { useNotifications } from '../../queries/notificationQueries';

interface NotificationDrawerProps {
    visible: boolean;
    toggleDrawer: () => void;
    isPushNotificationEnabled: boolean;
    requestPushNotification: () => void;
}

const NotificationDrawer: React.FunctionComponent<NotificationDrawerProps> = (props) => {
    const { visible, toggleDrawer, isPushNotificationEnabled, requestPushNotification } = props;

    const { groupedNotifications, dates, unreadCount, syncIDX, meta } = useNotificationListData();
    const { refetch } = useNotifications({ sync: true, idx: syncIDX }, false);

    useEffect(() => {
        // Only fetch if syncIDX is not undefined. For undefined case, the syncIDX is not ready yet
        if (!visible || syncIDX === undefined) {
            return () => {};
        }

        const syncNotifications = () => refetch();

        // Perform sync pull when open drawer
        syncNotifications();

        // Perform sync pull when receive new push notification
        // This is to update the notification listing in drawer
        const OneSignal = window.OneSignal;
        if (OneSignal) {
            OneSignal.push(() => {
                OneSignal.on('notificationDisplay', syncNotifications);
            });
            return () => {
                OneSignal.push(() => {
                    OneSignal.off('notificationDisplay', syncNotifications);
                });
            };
        }

        return () => {};
    }, [visible, syncIDX, refetch]);

    const drawerTitle = (
        <>
            <FormattedMessage id="Notification.Title" />
            {!isPushNotificationEnabled && (
                <Button type="link" size="small" onClick={requestPushNotification}>
                    Allow
                </Button>
            )}
        </>
    );

    return (
        <Drawer
            className="notification_drawer"
            width={400}
            placement="right"
            open={visible}
            onClose={toggleDrawer}
            title={drawerTitle}
            closeIcon={<IconClose style={{ height: 24, width: 24, color: 'white' }} />}
            styles={{
                header: {
                    height: 56,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingRight: 8,
                    backgroundColor: colors.gray200,
                },
                body: {
                    height: '100%',
                    padding: '8px 16px 8px 0',
                    overflow: 'hidden',
                    backgroundColor: colors.gray100,
                },
            }}
        >
            {dates.length > 0 ? (
                <NotificationList
                    groupedNotifications={groupedNotifications}
                    dates={dates}
                    unreadCount={unreadCount}
                    meta={meta}
                />
            ) : (
                <div className={styles.empty_data}>
                    <MyEmpty />
                </div>
            )}
        </Drawer>
    );
};

export default NotificationDrawer;
