import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import FormattedMessage from '../../../../../localization/FormatMessage';
import styles from '../../DataViewers.styl';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { FDL_METRIC_UNITS, MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { convertAndDisplay, roundToNDigit } from '../../utils/conversionRates';

export const title = (
    <>
        <FormattedMessage id="App.HTML.FDL.Severity.BScanThickness" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.FDL.Severity" />
    </>
);

interface SeverityTableValues {
    severityColor: string;
    label: string;
    valueRange: string;
    percentage: string;
}

const Severity: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, scanType } = props;
    const formatMessage = useFormatMessage();
    const indicationStatus = data.settings[0]?.content?.indicationStatus;
    const severityInfo = indicationStatus?.severityInfo;
    const sliderValue = indicationStatus?.bscanInfo?.sliderValue;

    const unit = FDL_METRIC_UNITS.has(indicationStatus?.measurementSetupFile?.unit ?? 'metric');
    const { unit: thicknessUnit, convertFunction } = convertAndDisplay('FDL.Severity.Thickness', scanType, unit);

    const redStart = convertFunction((sliderValue?.minValue ?? 0) / 1000);
    const yellowStart = convertFunction((sliderValue?.values?.[0] ?? 0) / 1000);
    const greenStart = convertFunction((sliderValue?.values?.[1] ?? 0) / 1000);
    const greenEnd = convertFunction((sliderValue?.maxValue ?? 0) / 1000);

    const dataSource: SeverityTableValues[] = [
        {
            severityColor: formatMessage({ id: 'App.HTML.FDL.Severity.SeverityColor.Green' }),
            label: severityInfo?.healthyTitle ?? formatMessage({ id: 'App.HTML.FDL.Severity.Label.Healthy' }),
            valueRange: `${greenStart} - ${greenEnd}`,
            percentage: `${roundToNDigit(severityInfo?.severityPercents?.lowSeverityPercent ?? 0, 2)}%`,
        },
        {
            severityColor: formatMessage({ id: 'App.HTML.FDL.Severity.SeverityColor.Yellow' }),
            label: severityInfo?.lowSeverityTitle ?? formatMessage({ id: 'App.HTML.FDL.Severity.Label.LowSeverity' }),
            valueRange: `${yellowStart} - ${greenStart}`,
            percentage: `${roundToNDigit(severityInfo?.severityPercents?.mediumSeverityPercent ?? 0, 2)}%`,
        },
        {
            severityColor: formatMessage({ id: 'App.HTML.FDL.Severity.SeverityColor.Red' }),
            label: severityInfo?.highSeverityTitle ?? formatMessage({ id: 'App.HTML.FDL.Severity.Label.HighSeverity' }),
            valueRange: `${redStart} - ${yellowStart}`,
            percentage: `${roundToNDigit(severityInfo?.severityPercents?.highSeverityPercent ?? 0, 2)}%`,
        },
    ];

    const columns: ColumnsType<SeverityTableValues> = [
        {
            title: formatMessage({ id: 'App.HTML.FDL.Severity.SeverityColor' }),
            dataIndex: 'severityColor',
            key: 'severityColor',
            width: '25%',
        },
        {
            title: formatMessage({ id: 'App.HTML.FDL.Severity.Label' }),
            dataIndex: 'label',
            key: 'label',
            width: '25%',
        },
        {
            title: formatMessage({ id: 'App.HTML.FDL.Severity.ValueRange' }, { unit: thicknessUnit }),
            dataIndex: 'valueRange',
            key: 'valueRange',
            width: '25%',
        },
        {
            title: formatMessage({ id: 'App.HTML.FDL.Severity.Percentage' }),
            dataIndex: 'percentage',
            key: 'percentage',
            width: '25%',
        },
    ];

    return (
        <Table
            title={() => <span className={styles.sub_header}>{title}</span>}
            rowKey="severityColor"
            className={styles.table}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
            locale={tableLocaleSetting}
        />
    );
};

export default Severity;
