import React from 'react';
import { Rule } from 'antd/lib/form';
import * as passwordUtils from '../../../utils/passwordUtils';
import * as inputUtils from '../../../utils/inputUtils';
import FormattedMessage from '../../../localization/FormatMessage';
import { PASSWORD_MAX_LENGTH } from '../constants';
import { genericPhoneNumberRegex } from '../../../utils/regex';

export interface FieldsType {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    passwordOld: string;
    passwordConfirm: string;
    companyName: string;
    countryCode: string;
    phoneNumber: string;
    postcode: string;
    address: string;
}

export type FieldsKeys = keyof FieldsType;

export const rules: { [K in keyof FieldsType]?: Rule[] } = {
    firstName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.FirstName.Error.Empty" />,
        },
    ],
    lastName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.LastName.Error.Empty" />,
        },
    ],
    companyName: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.CompanyName.Error.Empty" />,
        },
    ],
    password: [
        {
            required: true,
            message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
        },
        passwordUtils.getStrongPasswordValidator<FieldsKeys>(
            ['email', 'firstName', 'lastName'],
            <FormattedMessage id="SignUp.Field.Password.Error.Weak" />
        ),
        inputUtils.getMaxLengthValidator(
            PASSWORD_MAX_LENGTH,
            <FormattedMessage id="SignUp.Field.Input.Error.MaxLength" values={{ count: PASSWORD_MAX_LENGTH }} />
        ),
    ],
    passwordConfirm: [
        {
            required: true,
            message: <FormattedMessage id="SignUp.Field.ConfirmPassword.Error.Empty" />,
        },
        passwordUtils.getConfirmPasswordValidator<FieldsKeys>(
            'password',
            <FormattedMessage id="ResetPassword.Field.ConfirmPassword.Error.NotSame" />
        ),
    ],
    passwordOld: [
        {
            required: true,
            message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
        },
    ],
    phoneNumber: [
        { pattern: genericPhoneNumberRegex, message: <FormattedMessage id="Contact.Field.Phone.Error.Invalid" /> },
    ],
};
