import { IntlShape } from 'react-intl';
import { FormatMessageFunc } from '../../../../types';
import { ProductCode, ProductModel } from '../../../../types/proceq';
import { getLabelFromGlobalDataKey } from '../../RegisteredInfo/helper';
import handleFormatMessage from './handleFormatMessage';
import { GlobalInfoKey } from '../../RegisteredInfo/data';
import { ROCK_SCHMIDT_PROBE, SILVER_SCHMIDT_PROBE } from '../../../../types/measurement';

export function rgbToHex(r: number, g: number, b: number) {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}
export function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
}

export const getMinMaxValue = (min: number, max: number) => {
    const range = max - min;
    const margin = range * 0.05;
    const minValue = min - margin;
    return { maxValue: max + margin, minValue: minValue < 0 ? 0 : minValue };
};

export function getResolutionForScale(
    scaleUnits: any,
    value: string | null,
    typeValue?: string,
    showDashIf0: boolean = false,
    product?: ProductCode
): string {
    if (value === null || isNaN(parseInt(value, 10))) {
        // Like -- or null values
        return '';
    }
    const toReturn = parseFloat(value);
    if (toReturn === 0 && showDashIf0) {
        return '--';
    }
    switch (typeValue) {
        case 'count':
            return toReturn.toFixed(0);
        default:
            if (toReturn < 1) {
                if (product === ProductCode.SCHMIDT) {
                    return toReturn.toFixed(3);
                }
            } else if (toReturn < 100) {
                return toReturn.toFixed(1);
            } else {
                return toReturn.toFixed(0);
            }
            break;
    }
    return String(toReturn);
}
export function getScaleUnitsOfScaleID(productModel: ProductModel, formatMessage: FormatMessageFunc, scaleID: number) {
    // changed
    const scaleInfo = getScaleInfoOfScaleID(formatMessage, scaleID, productModel);
    return scaleInfo.scaleUnits;
}
export function getScaleNameOfScaleId(productModel: ProductModel, formatMessage: FormatMessageFunc, scaleID: number) {
    // changed
    const scaleInfo = getScaleInfoOfScaleID(formatMessage, scaleID, productModel);
    return scaleInfo.scaleName;
}
export function getStandardNameOfStandardID(
    globalregister: null,
    formatMessage: FormatMessageFunc,
    standardID: number,
    productType: ProductCode
) {
    // changed
    let standardGlobalCategory: GlobalInfoKey = 'standards';
    if (productType === 'SCHMIDT') {
        standardGlobalCategory = 'standardsschmidt';
    }
    const standardLabel = getLabelFromGlobalDataKey(standardGlobalCategory, standardID);
    if (standardLabel === null) {
        return standardID;
    }
    return handleFormatMessage(standardLabel, formatMessage);
}
export function getOffsetForProduct(productModel: ProductModel) {
    switch (productModel) {
        case 'SSL':
        case 'SCHMIDT':
            return 1;
        default:
            return 0;
    }
}
export function getMaterialNameOfMaterialID(
    globalregister: null,
    formatMessage: FormatMessageFunc,
    materialID: number
) {
    // changed
    const materialLabel = getLabelFromGlobalDataKey('materials', materialID);
    if (materialLabel === null) {
        return materialID;
    }
    return handleFormatMessage(materialLabel, formatMessage);
}
export function getSchmidtCompositeSurfaceId(surfaceStrengthId?: number, surfaceConversionId?: number) {
    if (typeof surfaceStrengthId === 'undefined' || surfaceStrengthId === null) {
        return null;
    }
    if (typeof surfaceConversionId === 'undefined' || surfaceConversionId === null) {
        return null;
    }
    return `${surfaceStrengthId}_${surfaceConversionId}`;
}

export function getSchmidtScaleIdOfFromFactorAndUnitID(unitId?: number | null, formFactorId?: number | null) {
    if (typeof formFactorId === 'undefined' || formFactorId === null) {
        formFactorId = 0;
    }
    if (typeof unitId === 'undefined' || unitId === null) {
        unitId = 0;
    }
    return 1 * 1000000 + formFactorId * 100 + unitId;
}

export function getScaleInfoOfScaleID(
    formatMessage: IntlShape['formatMessage'],
    scaleID: number | undefined,
    productModel?: ProductModel,
    skipFormFactor: boolean = false
) {
    // changed
    let scaleUnits: string | null = '';
    let scaleName: string = '';
    if (scaleID === undefined) {
        return { scaleUnits, scaleName };
    }
    if (scaleID >= 1000000) {
        const productNumId = Math.floor(scaleID / 1000000);
        if (productNumId === 1) {
            const formFactorAndUnit = scaleID - productNumId * 1000000;
            const formFactorId = Math.floor(formFactorAndUnit / 100);
            const unitId = formFactorAndUnit % 100;
            scaleUnits = getLabelFromGlobalDataKey('unitsschmidt', unitId);
            if (scaleUnits === null) {
                scaleUnits = getLabelFromGlobalDataKey('unitsschmidt', 0) ?? '';
            }

            if (unitId === 0) {
                scaleName =
                    productModel === ROCK_SCHMIDT_PROBE
                        ? formatMessage({ id: 'Proceq.SchmidtFormFactorRAngle' })
                        : productModel === SILVER_SCHMIDT_PROBE
                          ? formatMessage({ id: 'Proceq.SchmidtFormFactorQAngle' })
                          : formatMessage({ id: 'App.SchmidtFormFactorRAngle' });
            } else if (unitId === 6) {
                scaleName = formatMessage({ id: 'Proceq.SchmidtFormFactorQAngle' });
            } else if (skipFormFactor) {
                scaleName = scaleUnits;
            } else {
                const formFactorName = getLabelFromGlobalDataKey('formfactorschmidt', formFactorId);
                scaleName = `${scaleUnits} ${formFactorName}`;
            }
        }
        return {
            scaleUnits,
            scaleName,
        };
    }
    const scaleLabel = getLabelFromGlobalDataKey('scales', scaleID);
    if (scaleLabel === null) {
        scaleName = String(scaleID);
    } else {
        scaleName = formatMessage({ id: scaleLabel });
    }

    scaleUnits = getLabelFromGlobalDataKey('units', scaleID);
    if (scaleUnits === null) {
        scaleUnits = getLabelFromGlobalDataKey('units', 2);
    }

    return {
        scaleUnits,
        scaleName,
    };
}

export function isKeyDefinedAndNotNull(object: { [key: string]: any }, keyToSearch: string) {
    if (!(keyToSearch in object)) {
        return false;
    }

    if (object[keyToSearch] === null) {
        return false;
    }
    return true;
}

export function morrisToHighcharts(
    formatMessage: IntlShape['formatMessage'],
    data: { label: string; value: number; ff: number }[]
) {
    const series: { data: [string, number][]; innerSize: string; name: string } = {
        data: [],
        innerSize: '50%',
        name: '',
    };

    for (const i in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(i)) {
            series.data.push([formatMessage({ id: data[i].label }), data[i].value]);
        }
    }
    return [series];
}

export function extractLabelsFromSeries(series: { [key: string]: any }[]) {
    const labels = [];
    for (const s in series[0].data) {
        // eslint-disable-next-line no-prototype-builtins
        if (series[0].data.hasOwnProperty(s)) {
            labels.push(series[0].data[s][0].toString());
        }
    }
    return labels;
}

export const INFINITY_VALUE = '3.402823466385289e+38'; // fixed in app side
export const INFINITY_SYMBOL = '∞';

export function isInfinity(num: number | undefined) {
    if (num === undefined || typeof num !== 'number') {
        return false;
    }
    return num.toPrecision(8) === parseFloat(INFINITY_VALUE).toPrecision(8);
}
