import actionTypes, { Action, PayloadAction } from './actionTypes';
import { NotificationAggregation, NotificationRecord } from '../types/notification';
import { SyncMeta } from '../types/api';

export interface NotificationPayload {
    notifications: NotificationRecord[];
    aggregation: NotificationAggregation;
}

export interface ReceiveNotificationPayload extends NotificationPayload {
    meta: SyncMeta;
    sync: boolean;
}
export const receiveNotifications = (
    payload: ReceiveNotificationPayload
): PayloadAction<ReceiveNotificationPayload> => ({
    type: actionTypes.RECEIVED_NOTIFICATIONS,
    payload,
});

export const updateAllNotifications = (
    payload: ReceiveNotificationPayload
): PayloadAction<ReceiveNotificationPayload> => ({
    type: actionTypes.UPDATE_ALL_NOTIFICATIONS,
    payload,
});

export const readNotification = (payload: NotificationPayload): PayloadAction<NotificationPayload> => ({
    type: actionTypes.READ_NOTIFICATION,
    payload,
});

export const readAllNotifications = (): Action => ({
    type: actionTypes.READ_ALL_NOTIFICATIONS,
});

export const clearAllNotifications = (): Action => ({
    type: actionTypes.CLEAR_ALL_NOTIFICATIONS,
});
