import React from 'react';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';
import { MyMessageDescriptor } from '../types';

interface Props extends MyMessageDescriptor {
    values?: { [key: string]: any };
}

const FormattedMessage: React.FunctionComponent<Props> = (props) => {
    return <IntlFormattedMessage id={props.id} values={props.values} />;
};

export default FormattedMessage;
