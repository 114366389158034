import { RegionProfileState } from '../reducers/profile';
import { LicenseProduct, UserLicenseInfo } from '../types/license';
import { UserProfileInfo } from '../types/profile';
import actionTypes, { Action, PayloadAction } from './actionTypes';
import { ProductCode } from '../types/proceq';

export const removedProfilePicture = (
    userProfile: Partial<UserProfileInfo>
): PayloadAction<Partial<UserProfileInfo>> => {
    return {
        type: actionTypes.REMOVED_PROFILE_PICTURE,
        payload: {
            ...userProfile,
        },
    };
};

export const receivedUserProfile = (userProfile: Partial<UserProfileInfo>): PayloadAction<Partial<UserProfileInfo>> => {
    return {
        type: actionTypes.RECEIVED_USER_PROFILE,
        payload: {
            ...userProfile,
        },
    };
};

export interface ReceivedUserLicenseListPayload {
    data: UserLicenseInfo[];
}

export const receivedUserLicenseList = (
    payload: ReceivedUserLicenseListPayload
): PayloadAction<ReceivedUserLicenseListPayload> => {
    return {
        type: actionTypes.RECEIVED_USER_LICENSE_LIST,
        payload,
    };
};

export interface ReceivedUserLicensePayload {
    product: LicenseProduct | ProductCode;
    license: UserLicenseInfo;
}

export const receivedUserLicense = (payload: ReceivedUserLicensePayload): PayloadAction<ReceivedUserLicensePayload> => {
    return {
        type: actionTypes.RECEIVED_USER_LICENSE,
        payload,
    };
};

export interface ReceivedRoleInfoPayload {
    roleString: string;
}

export const receivedRoleInfo = (roleString: string): PayloadAction<ReceivedRoleInfoPayload> => {
    return {
        type: actionTypes.RECEIVED_ROLE_INFO,
        payload: {
            roleString,
        },
    };
};

export const receivedRegionInfo = (regionInfo: RegionProfileState): PayloadAction<RegionProfileState> => {
    return {
        type: actionTypes.RECEIVED_REGION_INFO,
        payload: {
            ...regionInfo,
        },
    };
};

export const cleanUpData = (): Action => {
    return {
        type: actionTypes.CLEAN_UP,
    };
};
