import React from 'react';
import { FormattedMessage } from 'react-intl';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const ImportFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.LogbookImported"
        values={{
            comment: log.content.comment,
        }}
    />
);

export default {
    element: ImportFile,
};
