import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Table } from 'antd';
import { MeasurementFullData, PunditDisplayedVelocity, TagObjectType } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { ScanType } from '../../../../../types/proceq';
import { Log } from '../../../../../types/logs';
import styles from '../../DataViewers.styl';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { calculateTransmissionTime, MeasurementObjectWithInfo, ObjectColumnType } from './Objects';
import { iconMap as tagNameMap } from '../../LogEvent/LogEventInfo';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';

export const title = <FormattedMessage id="App.HTML.PUNDIT.VelocityObjects" />;

const getLocalVelocityPWave = (derivedLocalVelocity: number, poissonRatio: number) => {
    const dividend = 2 * derivedLocalVelocity * derivedLocalVelocity * (poissonRatio - 1);
    const divisor = 2 * poissonRatio - 1;
    return Math.sqrt(dividend / divisor);
};

export const VelocityObjects: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    product,
    isMetric,
    convert,
    showTitle,
}) => {
    // we only want to use the most recent log entry
    const formatMessage = useFormatMessage();
    const sortedLogs = data.logs.slice().sort((a, b) => b.clientCreated - a.clientCreated);

    const isGridScan = data.measurement.type === ScanType.GridScan;
    const showLocalVelocityPWave =
        data.settings[0].content?.display?.displayVelocityWave === PunditDisplayedVelocity.ESTIMATE_P_WAVE;

    const objects: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'object' && object.content.type === TagObjectType.Velocity)
        .map((object) => {
            const extraKeys: {
                logContent?: Log['content'];
                gridCell?: string;
                transmissionTime?: number | string;
                tagName?: string;
                localVelocityPWave?: number | string;
            } = {};

            if (tagNameMap[object.content.type]) {
                extraKeys.tagName = formatMessage({ id: tagNameMap[object.content.type].text });
            } else {
                extraKeys.tagName = object.content.type;
            }

            const log = sortedLogs.find((l) => +l.content.sequence === object.content.number);
            if (log) {
                extraKeys.logContent = log.content;
            }

            const reading = isGridScan ? data.readings.find((reading) => reading.id === object.rID) : undefined;
            if (reading) {
                extraKeys.gridCell =
                    String.fromCharCode('A'.charCodeAt(0) + reading.content.columnIndex) +
                    (reading.content.rowIndex + 1);
            }

            const transmissionTime = isGridScan ? calculateTransmissionTime(object, data.settings) : undefined;
            if (transmissionTime !== undefined) {
                extraKeys.transmissionTime = transmissionTime < 0 ? '-' : transmissionTime;
            }

            const derivedLocalVelocity = object.content.extraContent?.velocityConfig?.derivedLocalVelocity;
            if (
                showLocalVelocityPWave &&
                object.content.extraContent?.velocityConfig?.derivedLocalVelocity !== undefined
            ) {
                const poissonRatio =
                    data.settings[0].content?.process?.compressiveStrength === 'custom'
                        ? data.settings[0].content?.preset?.lastCustomPoissonRatio
                        : data.settings[0].content?.process?.poissonRatio;
                if (poissonRatio !== undefined) {
                    extraKeys.localVelocityPWave = getLocalVelocityPWave(derivedLocalVelocity, poissonRatio);
                }
            }

            return {
                ...extraKeys,
                ...object,
            };
        })
        .sort((a, b) => a.content.number - b.content.number);

    const gridScanColumns: ObjectColumnType = isGridScan
        ? [
              {
                  title: 'App.HTML.PUNDIT.GridCell',
                  dataIndex: 'gridCell',
              },
          ]
        : [];

    const localVelocityPWaveColumns: ObjectColumnType = showLocalVelocityPWave
        ? [
              {
                  title: 'App.HTML.PUNDIT.Display.LocalVelocityPWave',
                  unitId: `${product}.CSV.Tag objects local velocity`,
                  dataIndex: ['localVelocityPWave'],
              },
          ]
        : [];

    const columnsRaw: ObjectColumnType = [
        {
            title: 'App.HTML.GPR.Objects.TagNumber',
            dataIndex: ['content', 'number'],
        },
        {
            title: 'App.HTML.GPR.Objects.TagType',
            dataIndex: ['tagName'],
        },
        ...gridScanColumns,
        {
            title: 'App.HTML.GPR.Objects.DistanceX',
            unitId: `${product}.CSV.Tag objects distance X`,
            dataIndex: ['logContent', 'locationX'],
        },
        {
            title: 'App.HTML.GPR.Objects.DistanceY',
            unitId: `${product}.CSV.Tag Object Distance Y`,
        },
        {
            title: 'App.HTML.PUNDIT.TransmissionTime',
            dataIndex: 'transmissionTime',
            unitId: `${product}.GridScan.TransmissionTime`,
        },
        {
            title: 'App.HTML.PUNDIT.Display.LocalVelocity',
            unitId: `${product}.CSV.Tag objects local velocity`,
            dataIndex: ['content', 'extraContent', 'velocityConfig', 'derivedLocalVelocity'],
        },
        ...localVelocityPWaveColumns,
        {
            title: 'App.HTML.PUNDIT.Display.LocalDepth',
            unitId: `${product}.Logbook Panel.Set Depth`,
            dataIndex: ['content', 'extraContent', 'velocityConfig', 'localDepthMeter'],
        },

        {
            title: 'Proceq.LogbookSettingsComment',
            dataIndex: ['logContent', 'comment'],
        },
        {
            title: 'Proceq.LogbookSettingsName',
            dataIndex: 'uID',
        },
    ];

    return (
        <div className="table-objects">
            <Table
                title={showTitle ? () => <span className={styles.main_header}>{title}</span> : undefined}
                className={styles.table}
                columns={columnsRaw.map((columnConfig) => ({
                    render: (text) => (columnConfig.unitId ? convert(text, columnConfig.unitId) : text),
                    ...columnConfig,
                    key: columnConfig.title,
                    title: columnConfig.unitId ? (
                        <ConvertedUnits
                            id={columnConfig.title}
                            unitId={columnConfig.unitId}
                            scanType={scanType}
                            isMetric={isMetric}
                        />
                    ) : (
                        <FormattedMessage id={columnConfig.title} />
                    ),
                }))}
                dataSource={objects}
                pagination={false}
                size="small"
                locale={tableLocaleSetting}
                rowKey="id"
            />
        </div>
    );
};

export default VelocityObjects;
