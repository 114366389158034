import React from 'react';
import { Table } from 'antd';
import ConvertedUnits from '../../shared/ConvertedUnits';
import FormattedMessage from '../../../../../localization/FormatMessage';
import styles from '../../DataViewers.styl';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { ProductCode, ScanType } from '../../../../../types/proceq';
import { getMarkersTableData as getGPRMarkersTableData } from '../GPR/getMarkersData';
import { getMarkersTableData as getPunditMarkersTableData } from '../Pundit/getMarkersData';
import { getMarkersTableData as getPunditImpactMarkersTableData } from '../PunditImpact/getMarkersData';
import { getMarkersTableData as getProfometerMarkersTableData } from '../Profometer/getMarkersData';
import { FormatIDs } from '../../../../../types';

export interface MarkersColumnRaw {
    title: FormatIDs;
    unitId?: string;
    dataIndex?: string | string[];
    width?: number;
    render?: any;
}

export const title = <FormattedMessage id="Markers" />;

const getMarkersTableData = (
    data: MeasurementFullData,
    product: ProductCode,
    scanType: ScanType,
    positionID: string | undefined
) => {
    switch (product) {
        case ProductCode.GPR:
        case ProductCode.GPR_SOIL:
            return getGPRMarkersTableData(data, product, scanType);
        case ProductCode.PUNDIT:
            return getPunditMarkersTableData(data, product);
        case ProductCode.PIT_IE:
            return getPunditImpactMarkersTableData(data, positionID);
        case ProductCode.PROFOMETER:
            return getProfometerMarkersTableData(data, scanType);
        default:
            return { markers: [], columnsRaw: [] };
    }
};

const Markers: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, product, scanType, convert, isMetric, showTitle, positionID } = props;
    const { markers, columnsRaw } = getMarkersTableData(data, product, scanType, positionID);
    const columns = columnsRaw.map((columnConfig) => ({
        render: (text: string) => (columnConfig.unitId ? convert(text, columnConfig.unitId) : text),
        ...columnConfig,
        title: columnConfig.unitId ? (
            <ConvertedUnits
                id={columnConfig.title}
                unitId={columnConfig.unitId}
                scanType={scanType}
                isMetric={isMetric}
            />
        ) : (
            <FormattedMessage id={columnConfig.title} />
        ),
    }));

    return (
        <div className="table-markers">
            <Table
                title={showTitle ? () => <span className={styles.main_header}>{title}</span> : undefined}
                className={styles.table}
                columns={columns}
                rowKey="id"
                dataSource={markers}
                pagination={false}
                size="small"
                bordered
                locale={tableLocaleSetting}
            />
        </div>
    );
};

export default Markers;
