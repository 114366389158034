import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../DataViewers.styl';
import { filterReadings } from './ScanDistance';
import { DerivedProps } from '../../SharedTypes';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { FormatIDs } from '../../../../../types';
import {
    LineStatus,
    LineStatusDirection,
    MeasurementFullData,
    MeasurementObject,
    MeasurementReading,
} from '../../../../../types/measurement';
import { Log } from '../../../../../types/logs';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { ProductCode, ScanType } from '../../../../../types/proceq';
import getMarkerObjectDistance, { AntennaLabel, isProductModelGP8100 } from './getMarkerObjectDistance';

export type MeasurementObjectWithInfo = MeasurementObject & {
    readingContent: MeasurementReading['content'];
    logContent?: Log['content'];
    sequenceNo: number;
    originCoordinateX?: number | string;
    originCoordinateY?: number | string;
    localX?: number | string;
    localY?: number | string;
    objectDistanceX?: number | string;
    objectDistanceY?: number | string;
};

export const title = <FormattedMessage id="Proceq.Objects" />;

interface ObjectColumnType {
    [key: string]: any;

    title: FormatIDs;
}

export const getLocationCoordinates = (object: MeasurementObject) => {
    const extraKeys: { [key: string]: any } = {};
    extraKeys.convertedCoordinateX = object.content.convertedCoordinate?.x ?? '-';
    extraKeys.convertedCoordinateY = object.content.convertedCoordinate?.y ?? '-';
    extraKeys.convertedCoordinateZ = object.content.convertedCoordinate?.z ?? '-';
    return extraKeys;
};

export const getLocalXY = (data: MeasurementFullData, object: MeasurementObject) => {
    const extraKeys: { [key: string]: any } = {};
    const reading: MeasurementReading | undefined = data.readings.find((reading) => reading.id === object.rID);
    const lineStatus: LineStatus = data.measurement.content.areaScanStatus.lineStatus.find((r: LineStatus) => {
        if (r.scanId === object.rID) {
            return true;
        }
        return !!(
            reading &&
            r.position.sequence === reading.content.sequence &&
            r.position.dimension === reading.content.dimension
        );
    });
    if (lineStatus) {
        const distanceMeter = object.content.location?.distanceMeter;
        const gridSpacingY =
            data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters.gridSpacingY;
        const gridSpacingX =
            data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters.gridSpacingX;
        switch (lineStatus.direction) {
            case LineStatusDirection.right:
                extraKeys.localX = distanceMeter;
                extraKeys.localY = (lineStatus.position.sequence - 1) * gridSpacingY;
                break;
            case LineStatusDirection.up:
                extraKeys.localY = distanceMeter;
                extraKeys.localX = (lineStatus.position.sequence - 1) * gridSpacingX;
                break;
            case LineStatusDirection.left:
                extraKeys.localX =
                    gridSpacingX *
                        (data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters
                            .numberOfHorizontalLines -
                            1) -
                    distanceMeter;
                extraKeys.localY = (lineStatus.position.sequence - 1) * gridSpacingY;
                break;
            case LineStatusDirection.down:
                extraKeys.localY =
                    gridSpacingY *
                        (data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters
                            .numberOfVerticalLines -
                            1) -
                    distanceMeter;
                extraKeys.localX = (lineStatus.position.sequence - 1) * gridSpacingX;
                break;
        }
    } else {
        extraKeys.localX = '-';
        extraKeys.localY = '-';
    }
    return extraKeys;
};

export const sortBySequenceNo = (a: MeasurementObjectWithInfo, b: MeasurementObjectWithInfo) => {
    if (a.readingContent?.sequenceNo !== undefined && b.readingContent?.sequenceNo !== undefined) {
        const aLine = a.readingContent.sequenceNo;
        const bLine = b.readingContent.sequenceNo;

        // first sort by line number
        if (aLine !== bLine) {
            return aLine - bLine;
        }
    }
    return undefined;
};

export const sortObjectData = (a: MeasurementObjectWithInfo, b: MeasurementObjectWithInfo) => {
    return sortBySequenceNo(a, b) ?? a.content.number - b.content.number;
};

const SPEED_OF_LIGHT = 299792458;

const calculateDepthMeter = (depthSeconds?: number, maxDepthSeconds?: number, dielectricConstant?: number) => {
    if (!dielectricConstant) return '-';
    const depthSecondsToUse =
        !!maxDepthSeconds && !!depthSeconds ? Math.min(depthSeconds, maxDepthSeconds) : depthSeconds;
    if (!depthSecondsToUse) return '-';
    return (SPEED_OF_LIGHT * depthSecondsToUse) / Math.sqrt(dielectricConstant) / 2;
};

export const Objects: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    product,
    isMetric,
    convert,
    showTitle,
}) => {
    const isSoil = product.toUpperCase() === 'GPR_SOIL';
    const isGP8100 = isProductModelGP8100(data.measurement.productModel);
    const calibratedObjectID = data.measurement.content.tagMetaData?.calibratedObjectId;
    const filteredReadings = filterReadings(data, scanType);

    const objects: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'object')
        .map((object) => {
            const extraKeys: { [key: string]: any } = {};

            const reading = filteredReadings.find((r) => r.id === object.rID);
            if (reading) {
                extraKeys.readingContent = { ...reading.content };
                const antennaLabel =
                    isGP8100 && object.content.antennaIndex !== undefined
                        ? AntennaLabel[object.content.antennaIndex]
                        : '';
                extraKeys.readingContent.sequenceNo = `${reading.sequenceNo}${antennaLabel}`;
            }

            const { depthSeconds, maxDepthSeconds } = object.content.location;
            const dielectricConstant = data.settings[0]?.content?.settings?.process?.dielectricConstant;
            const calculatedDepthMeter = calculateDepthMeter(depthSeconds, maxDepthSeconds, dielectricConstant);

            // there is only 1 set depth among all objects
            if (isSoil) {
                if (object.id === calibratedObjectID) {
                    extraKeys.setDepth = calculatedDepthMeter;
                } else {
                    extraKeys.calculatedDepth = calculatedDepthMeter;
                }
                extraKeys.setSize = object.content.sizeMeter ?? '-';
            } else if (object.content.id === calibratedObjectID) {
                extraKeys.setDepth = calculatedDepthMeter;
            } else {
                extraKeys.calculatedDepth = calculatedDepthMeter;
            }

            if (product === ProductCode.GPR) {
                ({ objectDistanceX: extraKeys.objectDistanceX, objectDistanceY: extraKeys.objectDistanceY } =
                    getMarkerObjectDistance(data, object, reading, scanType));
            }

            return {
                ...(isSoil && scanType === ScanType.AreaScan ? getLocalXY(data, object) : {}),
                ...(isSoil ? getLocationCoordinates(object) : {}),
                ...extraKeys,
                ...object,
            } as MeasurementObjectWithInfo;
        })
        .sort(sortObjectData);

    const getDistanceLocationColumns = () => {
        const distanceColumns: ObjectColumnType[] = [];
        if (isSoil) {
            distanceColumns.push(
                {
                    title: 'App.HTML.GPR_SOIL.Objects.Easting',
                    unitId: `${product}.CSV.LocationCoordinates`,
                    dataIndex: ['convertedCoordinateX'],
                    width: 80,
                },
                {
                    title: 'App.HTML.GPR_SOIL.Objects.Northing',
                    unitId: `${product}.CSV.LocationCoordinates`,
                    dataIndex: ['convertedCoordinateY'],
                    width: 80,
                }
            );
            if (scanType === ScanType.AreaScan) {
                distanceColumns.push(
                    {
                        title: 'App.HTML.GPR.Objects.LocalX',
                        unitId: `${product}.CSV.Tag objects Local XY`,
                        dataIndex: ['localX'],
                        width: 120,
                    },
                    {
                        title: 'App.HTML.GPR.Objects.LocalY',
                        unitId: `${product}.CSV.Tag objects Local XY`,
                        dataIndex: ['localY'],
                        width: 120,
                    }
                );
            } else {
                distanceColumns.push({
                    title: 'App.HTML.GPR_SOIL.Objects.DistanceAlongLine',
                    unitId: `${product}.CSV.Tag objects distance X`,
                    dataIndex: ['content', 'location', 'distanceMeter'],
                    width: 120,
                });
            }
        } else {
            distanceColumns.push(
                {
                    title: 'App.HTML.GPR.Objects.DistanceX',
                    unitId: `${product}.CSV.Tag objects distance X`,
                    dataIndex: ['objectDistanceX'],
                    width: 120,
                },
                {
                    title: 'App.HTML.GPR.Objects.DistanceY',
                    unitId: `${product}.CSV.Tag Object Distance Y`,
                    dataIndex: ['objectDistanceY'],
                    width: 120,
                }
            );
        }
        return distanceColumns;
    };

    const columnsRaw: ObjectColumnType[] = [
        {
            title: 'Line',
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return record.readingContent !== undefined ? record.readingContent.sequenceNo : '';
            },
            width: 40,
        },
        {
            title: 'Tag',
            dataIndex: ['content', 'number'],
            width: 40,
        },
        {
            title: 'Tag Type',
            dataIndex:
                `${product.toUpperCase()}` === 'GPR_SOIL' ? ['content', 'category', 'name'] : ['content', 'type'],

            width: 80,
        },
        ...getDistanceLocationColumns(),
        {
            title: 'Calculated Depth',
            unitId: `${product}.Logbook Panel.Calculated Depth`,
            dataIndex: 'calculatedDepth',
            width: 180,
        },
        {
            title: 'Set Depth',
            unitId: `${product}.Logbook Panel.Set Depth`,
            dataIndex: 'setDepth',
            width: 80,
        },
        {
            title: 'Set Size',
            unitId: `${product}.Logbook Panel.Set Size`,
            dataIndex: isSoil ? 'setSize' : ['content', 'sizeMeter'],
            width: 70,
        },
        {
            title: 'Comment',
            dataIndex: ['content', 'comment'],
        },
        {
            title: 'Name',
            dataIndex: 'content.name',
            width: 150,
        },
    ];

    const columns = columnsRaw.map((columnConfig) => ({
        render: (text: string | number) => (columnConfig.unitId ? convert(text, columnConfig.unitId) : text),
        ...columnConfig,
        key: columnConfig.title,
        title: columnConfig.unitId ? (
            <ConvertedUnits
                id={columnConfig.title}
                unitId={columnConfig.unitId}
                scanType={scanType}
                isMetric={isMetric}
            />
        ) : (
            <FormattedMessage id={columnConfig.title} />
        ),
    }));

    return (
        <div className="table-objects">
            <Table
                title={showTitle ? () => <span className={styles.main_header}>{title}</span> : undefined}
                className={styles.table}
                columns={columns}
                rowKey="id"
                dataSource={objects}
                pagination={false}
                size="small"
                bordered
                locale={tableLocaleSetting}
            />
        </div>
    );
};

export default Objects;
