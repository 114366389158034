import React from 'react';
import { Table } from 'antd';
import styles from '../DataViewers.styl';
import { tableLocaleSetting } from '../../../shared/MyEmpty';

interface SectionTableProps {
    showTitle: boolean | undefined;
    title: React.ReactNode;
    dataSource: { key: string | number; value: React.ReactNode | undefined; label: React.ReactNode | undefined }[];
    className?: string;
}

const SectionTable: React.FunctionComponent<SectionTableProps> = (props) => {
    const { showTitle, title, dataSource, className } = props;

    return (
        <Table
            title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : undefined}
            className={className ?? styles.table}
            dataSource={dataSource}
            pagination={false}
            showHeader={false}
            size="small"
            locale={tableLocaleSetting}
        >
            <Table.Column dataIndex="label" className={styles.column} />
            <Table.Column dataIndex="value" />
        </Table>
    );
};

export default SectionTable;
