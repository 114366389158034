import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FlawDetectorComponentType } from '../../../../../types/measurement';
import styles from '../../DataViewers.styl';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import SectionTable from '../../shared/SectionTable';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { FormatIDs } from '../../../../../types';
import { ScanType } from '../../../../../types/proceq';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.FDL.Baseline" />
    </>
);

enum CurveType {
    convex,
    concave,
}

const CURVE_TYPE: Record<CurveType, FormatIDs> = {
    [CurveType.convex]: 'App.Logbook.FDL.CSCApplied.Thickness.Convex',
    [CurveType.concave]: 'App.Logbook.FDL.CSCApplied.Thickness.Concave',
};

const COMPONENT_TYPE: Record<FlawDetectorComponentType, FormatIDs> = {
    [FlawDetectorComponentType.flatSurface]: 'App.HTML.FDL.Baseline.ComponentType.FlatSurface',
    [FlawDetectorComponentType.curvedSurface]: 'App.HTML.FDL.Baseline.ComponentType.CurvedSurface',
};

interface BaselineProps {
    number?: number;
    scanType: ScanType;
    setupFile: any;
}

const Baseline: React.FunctionComponent<BaselineProps> = (props) => {
    const { setupFile, scanType, number } = props;
    const formatMessage = useFormatMessage();
    const isFlatSurface = setupFile?.componentType === FlawDetectorComponentType.flatSurface;

    const titleNumbered = (
        <>
            {title}
            {number ? ` ­­» ${number}` : number}
        </>
    );

    const rawData: RawSectionTableData[] = [
        {
            label: 'App.Logbook.FDL.Item.componentType',
            value: Object.values(FlawDetectorComponentType).includes(setupFile?.componentType)
                ? formatMessage({
                      id: COMPONENT_TYPE[setupFile?.componentType as FlawDetectorComponentType],
                  })
                : '',
        },
        ...(isFlatSurface
            ? []
            : ([
                  {
                      label: 'Proceq.ExportFieldCurveType',
                      value: Object.values(CurveType).includes(setupFile?.cscCurveType)
                          ? formatMessage({
                                id: CURVE_TYPE[setupFile?.cscCurveType as CurveType],
                            })
                          : '',
                  },
                  {
                      label: 'App.HTML.FDL.Baseline.ExternalRadius',
                      value: (setupFile?.externalRadius ?? 0) / 1000,
                      unitId: 'FDL.Baseline.Radius',
                  },
                  {
                      label: 'App.HTML.FDL.Baseline.InternalRadius',
                      value: (setupFile?.internalRadius ?? 0) / 1000,
                      unitId: 'FDL.Baseline.Radius',
                  },
              ] as RawSectionTableData[])),
        {
            label: 'App.HTML.FDL.Baseline.Thickness',
            value: (setupFile?.thickness ?? 0) / 1000,
            unitId: 'FDL.Baseline.Thickness',
        },
    ];

    const dataSource = getConvertedSectionTableData(rawData, scanType, setupFile?.unit);

    return (
        <SectionTable
            showTitle
            className={[styles.table, 'table-measurement-presets'].join(' ')}
            title={titleNumbered}
            dataSource={dataSource}
        />
    );
};

export default Baseline;
