import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import Photo from '../../../../Attachments/Photo';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';
import { ScanType } from '../../../../../types/proceq';

export const SaveSnapshot: React.FunctionComponent<GPRLogbookEntryProps> = ({
    productModel,
    log,
    product,
    scanType,
}) => (
    <div>
        <b>
            {productModel === 'FDL' && scanType !== ScanType.BScan && (
                <FormattedMessage
                    id="App.Logbook.FDL.Indication.Position"
                    values={{ position: log.content.position }}
                />
            )}
            <FormattedMessage id="Proceq.LogbookPropertySnapshotSaved" />
        </b>
        <Photo
            mID={log.mID}
            aID={log.content.attachmentId}
            attachmentName={log.content.attachmentName}
            analyticsCategory={SnapshotAction.logbook}
            analyticsProduct={product}
            analyticsType={log.type}
        />
    </div>
);

export default {
    element: SaveSnapshot,
};
