import React, { useCallback } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';
import { ColumnType } from 'antd/lib/table';
import { formatDateTimeDefault } from '../../../utils/dateUtils';
import { getResolutionForScale } from '../DataViewers/utils/conversionsHelper';
import { MeasurementListItem } from '../../../types/measurement';
import colors from '../../../styles/colors.json';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './ColumnConfig.styl';
import { getTextValue } from '../RegisteredInfo/helper';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { GlobalInfoKey } from '../RegisteredInfo/data';
import { ProductCode } from '../../../types/proceq';

export const SearchDropdown: ColumnType<MeasurementListItem>['filterDropdown'] = (props) => {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
    const value = selectedKeys[0];

    const onChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            setSelectedKeys([event.currentTarget.value]);
        },
        [setSelectedKeys]
    );

    const handleSubmit = useCallback(() => {
        if (value === '') {
            if (clearFilters) {
                clearFilters();
            }
        } else {
            confirm({ closeDropdown: true });
        }
    }, [clearFilters, confirm, value]);

    return (
        <div className={styles.column_search} onKeyDown={(e) => e.stopPropagation()}>
            <Input value={value} onChange={onChange} autoFocus onPressEnter={handleSubmit} />
            <AnalyticsButton type="link" disabled={!value} onClick={clearFilters}>
                <FormattedMessage id="App.Reset" />
            </AnalyticsButton>
            <AnalyticsButton type="primary" onClick={handleSubmit}>
                <FormattedMessage id="App.Search" />
            </AnalyticsButton>
        </div>
    );
};

enum RangeKeys {
    Max = 'max',
    Min = 'min',
}

export const RangeDropdown: ColumnType<MeasurementListItem>['filterDropdown'] = (props) => {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;

    const formatMessage = useFormatMessage();

    const min = selectedKeys[0];
    const max = selectedKeys[1];

    const handleSubmit = useCallback(() => {
        if (max === '' && min === '') {
            if (clearFilters) {
                clearFilters();
            }
        } else {
            confirm({ closeDropdown: true });
        }
    }, [max, min, clearFilters, confirm]);

    const onChange = useCallback(
        (event: React.FormEvent<HTMLInputElement>) => {
            const elem = event.currentTarget;
            const value = elem.value;
            const name = elem.getAttribute('name') as RangeKeys;

            let [min = '', max = '']: React.Key[] = selectedKeys;
            switch (name) {
                case RangeKeys.Min: {
                    min = value as React.Key;
                    break;
                }
                case RangeKeys.Max: {
                    max = value as React.Key;
                    break;
                }
            }

            setSelectedKeys([min, max]);
        },
        [selectedKeys, setSelectedKeys]
    );

    return (
        <Form className={styles.column_search} onSubmitCapture={handleSubmit}>
            <div className={styles.range_input}>
                <Input
                    value={min ?? ''}
                    name={RangeKeys.Min}
                    type="number"
                    onChange={onChange}
                    placeholder={formatMessage({ id: 'Proceq.ExportFieldMinimum' })}
                />
                {' - '}
                <Input
                    value={max ?? ''}
                    name={RangeKeys.Max}
                    type="number"
                    onChange={onChange}
                    placeholder={formatMessage({ id: 'Proceq.ExportFieldMaximum' })}
                />
            </div>
            <AnalyticsButton type="link" disabled={!max && !min} onClick={clearFilters}>
                <FormattedMessage id="App.Reset" />
            </AnalyticsButton>
            <AnalyticsButton type="primary" htmlType="submit">
                <FormattedMessage id="App.Search" />
            </AnalyticsButton>
        </Form>
    );
};

export const renderDate = (value: number, measurement: any) =>
    value && !measurement.isImport ? formatDateTimeDefault(value) : '';

export const renderMean = (text: string, product?: ProductCode) =>
    getResolutionForScale(null, text || '0', 'avg', true, product);

export const getBinProps = (bin: number) => {
    switch (bin) {
        case 1:
            return { color: colors.red300, text: '20°' };
        case 2:
            return { color: colors.green300, text: '60°' };
        default:
            return { color: colors.blue300, text: '' };
    }
};

export const generateFilter = (keys: GlobalInfoKey[], localized?: boolean) => {
    const rawFilters = getTextValue(keys);

    const filters: {
        text: string | JSX.Element;
        value: any;
    }[] = localized
        ? rawFilters.map((filter) => ({
              text: <IntlFormattedMessage id={filter.text} />,
              value: filter.value,
          }))
        : rawFilters;

    return {
        filters,
        onFilter: () => true,
        filterMultiple: false,
    };
};
