import React, { useContext } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';

import { ProductCode } from '../../types/proceq';
import * as measurementActions from '../../actions/measurement';
import * as userActions from '../../actions/user';
import { getStandaloneMeasurementDetailPath, getStandaloneMeasurementListingPath } from '../Routes/urls';
import { LocalizationContext } from '../../localization/LocalizedIntlProvider';
import { getHTMLExportLang } from '../../localization';
import { setUTCOffset } from '../../utils/dateUtils';

enum ViewMode {
    LISTING,
    DETAIL,
}

interface QueryParams {
    viewMode: string;
    measurementID?: string;
}

const Root: React.FunctionComponent = () => {
    const query = routeUtils.parseQuery<QueryParams>(location.search);
    const { measurementID } = query;
    const viewMode: ViewMode = query.viewMode ? Number(query.viewMode) : ViewMode.LISTING;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { setLanguage } = useContext(LocalizationContext);

    const handleClick = () => {
        const data = window.htmlData;
        if (!data) {
            window.viewerError = new Error('Missing htmlData');
            return;
        }

        const { measurements, users, shortlang, timeZoneOffset } = data;
        setLanguage(getHTMLExportLang(shortlang));
        setUTCOffset(timeZoneOffset);

        const product = measurements[0]?.productFamily?.toUpperCase() as ProductCode;

        dispatch(measurementActions.receivedHTMLMeasurements({ product, data }));
        dispatch(userActions.receivedProceqUsers({ userList: users }));

        switch (viewMode) {
            case ViewMode.LISTING: {
                navigate(getStandaloneMeasurementListingPath(product));
                break;
            }
            case ViewMode.DETAIL: {
                navigate(getStandaloneMeasurementDetailPath(product, measurementID || ''));
                break;
            }
        }
    };

    return (
        <Button id="ready_button" onClick={handleClick}>
            Ready
        </Button>
    );
};

export default Root;
