import { noop } from 'lodash';
import React, { createContext } from 'react';
import { DataType, DEFAULT_DATA_TYPE, ViewType } from '../../types/measurement';
import { ProductCode } from '../../types/proceq';

export interface DataViewContextState {
    product: ProductCode | undefined;
    setProduct: (productCode: ProductCode, folderID?: string) => void;

    viewType: ViewType;
    setViewType: (viewType: ViewType) => void;

    activeFolder: string;
    setActiveFolder: React.Dispatch<React.SetStateAction<string>>;

    // this affects folders and measurement listing
    withUnsynced: boolean;
    setWithUnsynced: (value: ((prevState: boolean) => boolean) | boolean) => void;

    // used to identify when user navigates to folders that differ from the usual measurement listing
    dataType: DataType;
    setDataType: React.Dispatch<React.SetStateAction<DataType>>;
}

export const DataViewContext = createContext<DataViewContextState>({
    product: undefined,
    setProduct: noop,

    viewType: ViewType.Active,
    setViewType: noop,

    activeFolder: '',
    setActiveFolder: noop,

    withUnsynced: false,
    setWithUnsynced: noop,

    dataType: DEFAULT_DATA_TYPE,
    setDataType: noop,
});
