import { Button } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { routeUtils } from 'tds-common-fe';
import { useNavigate } from 'react-router-dom';
import styles from './UriRedirect.styl';
import FormattedMessage from '../../localization/FormatMessage';
import appIcon from '../../images/appIconGPRMountedUpload.png';
import analytics from '../../analytics/firebaseAnalytics';
import config from '../../config';
import { ProductCode } from '../../types/proceq';
import routeURLs from '../Routes/urls';

// page to redirect to app, currently only supports GM upload app
const UriRedirect = () => {
    const { app_uri: appUri, ...otherQueries } = routeUtils.parseQuery<{ app_uri: string }>(location.search);
    const navigate = useNavigate();

    const handleLaunchApp = useCallback(() => {
        if (appUri) {
            const uri = routeUtils.makeQueryPath(appUri, otherQueries);
            window.open(uri, '_self');
        }
    }, [appUri, otherQueries]);

    useEffect(() => {
        const openApp = () => {
            const regex = new RegExp(`^${config.GM_URL_SCHEME}:\\/\\/.*`, 'gm');
            if (appUri && appUri.match(regex)) {
                handleLaunchApp();
            } else {
                // navigate to dashboard if app is not supported
                navigate(routeURLs.DASHBOARD);
            }
        };

        // firefox aborts pending calls (ie image will not load) if app is opened immediately
        const readyStateInterval = setInterval(() => {
            if (document.readyState === 'complete') {
                openApp();
                clearInterval(readyStateInterval);
            }
        }, 500);
        return () => clearInterval(readyStateInterval);
    }, [appUri, handleLaunchApp, navigate]);

    return (
        <div className={styles.container}>
            <div className={styles.content_container}>
                <img src={appIcon} alt="appIcon" className={styles.logo} />
                <h2 style={{ margin: 0 }}>
                    <FormattedMessage id="UriRedirect.Title" />
                </h2>
                <div>
                    <FormattedMessage
                        id="UriRedirect.Body"
                        values={{
                            browserButton: (
                                <b>
                                    <FormattedMessage
                                        id="UriRedirect.BrowserButton"
                                        values={{ appName: 'GM Upload' }}
                                    />
                                </b>
                            ),
                            button: (
                                <b>
                                    <FormattedMessage id="Import.LaunchApp" />
                                </b>
                            ),
                        }}
                    />
                </div>
                <Button
                    className={styles.launch_button}
                    type="primary"
                    onClick={() => {
                        analytics.logLaunchApp(ProductCode.GPR_MOUNTED, 'launch_app');
                        handleLaunchApp();
                    }}
                >
                    <FormattedMessage id="Import.LaunchApp" />
                </Button>
            </div>
        </div>
    );
};

export default UriRedirect;
