import React from 'react';
import { get } from 'lodash';
import styles from '../../DataViewers.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { BScanPresentationMode, FDL_SCAN_TYPE, MeasurementFullData } from '../../../../../types/measurement';
import { renderFolderName } from '../../utils/renderFolderName';
import { FormatIDs } from '../../../../../types';
import SectionTable from '../../shared/SectionTable';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { ScanType } from '../../../../../types/proceq';

const BSCAN_PRESENTATION_MODE: Record<BScanPresentationMode, FormatIDs> = {
    [BScanPresentationMode.crossSectional]: 'App.HTML.FDL.MeasurementPresets.PresentationMode.BScan.CrossSectional',
    [BScanPresentationMode.singleValue]: 'App.HTML.FDL.MeasurementPresets.PresentationMode.BScan.SingleValue',
    [BScanPresentationMode.thicknessProfile]: 'App.HTML.FDL.MeasurementPresets.PresentationMode.BScan.ThicknessProfile',
};

enum BScanVisualisationSetUp {
    timeBased,
    encoder,
}

const BSCAN_VISUALISATION_SETUP: Record<BScanVisualisationSetUp, FormatIDs> = {
    [BScanVisualisationSetUp.timeBased]: 'App.HTML.FDL.MeasurementPresets.VisualisationSetup.TimeBased',
    [BScanVisualisationSetUp.encoder]: 'App.HTML.FDL.MeasurementPresets.VisualisationSetup.Encoder',
};

enum BScanEncoderDirection {
    clockwise,
    counterclockwise,
}

const BSCAN_ENCODER_DIRECTION: Record<BScanEncoderDirection, FormatIDs> = {
    [BScanEncoderDirection.clockwise]: 'App.HTML.FDL.MeasurementPresets.EncoderDirection.Clockwise',
    [BScanEncoderDirection.counterclockwise]: 'App.HTML.FDL.MeasurementPresets.EncoderDirection.Counterclockwise',
};

interface RawData {
    label: FormatIDs;
    value: string | number;
}

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.GPR.Settings.MeasurementPresets" />
    </>
);

export const File: React.FunctionComponent<{ data: MeasurementFullData; showTitle: boolean }> = ({
    data,
    showTitle,
}) => {
    const formatMessage = useFormatMessage();
    const scanType = data.measurement.type as ScanType;
    const isBScan = scanType === ScanType.BScan;
    const measurementSetupFile = data.settings[0]?.content.indicationStatus?.measurementSetupFile;

    const bScanPresentationMode = isBScan
        ? BSCAN_PRESENTATION_MODE[measurementSetupFile?.bScanMeasurementScanMode as BScanPresentationMode]
        : undefined;
    const visualisationSetup = isBScan
        ? BSCAN_VISUALISATION_SETUP[measurementSetupFile?.visualisationSetup as BScanVisualisationSetUp]
        : undefined;
    const encoderDirection =
        isBScan && measurementSetupFile?.visualisationSetup === BScanVisualisationSetUp.encoder
            ? BSCAN_ENCODER_DIRECTION[measurementSetupFile?.encoderDirection as BScanEncoderDirection]
            : undefined;

    const dataSourceRaw: RawData[] = [
        {
            label: 'App.HTML.GPR.MeasurementPresets.FileName',
            value: get(data, 'measurement.name'),
        },
        {
            label: 'App.HTML.GPR.MeasurementPresets.FileID',
            value: get(data, 'measurement.id'),
        },
        {
            label: 'Proceq.StatisticsFolder',
            value: renderFolderName(data, formatMessage),
        },
        {
            label: 'App.HTML.FDL.MeasurementPresets.PresentationMode',
            value:
                scanType && FDL_SCAN_TYPE[scanType]
                    ? `${formatMessage({ id: FDL_SCAN_TYPE[scanType]! })}${
                          bScanPresentationMode ? `, ${formatMessage({ id: bScanPresentationMode })}` : ''
                      }`
                    : '',
        },
        ...(visualisationSetup
            ? [
                  {
                      label: 'App.HTML.FDL.MeasurementPresets.VisualisationSetup',
                      value: formatMessage({ id: visualisationSetup }),
                  } as RawData,
              ]
            : []),
        ...(encoderDirection
            ? [
                  {
                      label: 'App.HTML.FDL.MeasurementPresets.EncoderDirection',
                      value: formatMessage({ id: encoderDirection }),
                  } as RawData,
              ]
            : []),
    ];

    const dataSource = dataSourceRaw.map((row) => ({
        key: row.label,
        label: <FormattedMessage id={row.label} />,
        value: row.value,
    }));

    return (
        <SectionTable
            showTitle={showTitle}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={dataSource}
        />
    );
};

export default File;
