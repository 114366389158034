import { get, isUndefined } from 'lodash';
import React from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { FormattedMessage as IntlFormattedMessage } from 'react-intl';
import FormattedMessage from '../../../localization/FormatMessage';
import { getLabelFromGlobalDataKey } from '../RegisteredInfo/helper';
import { MeasurementListItem } from '../../../types/measurement';
import { generateFilter, getBinProps, renderDate, renderMean, SearchDropdown } from './columnUtils';
import styles from '../DataView.styl';
import { classNames } from '../../../utils/styleUtils';
import { intlProxy } from '../../../localization/IntlProxy';
import { getScaleInfoOfScaleID, getSchmidtScaleIdOfFromFactorAndUnitID } from '../DataViewers/utils/conversionsHelper';
import { ProductCode, ProductModel, productModelToFamilyMap } from '../../../types/proceq';
import NameColumn from './NameColumn';
import { GlobalInfoKey } from '../RegisteredInfo/data';
import FlagColumn, { FlagColumnType } from './FlagColumn';
import { convertBytes } from '../../../utils/mathUtils';
import { GPRProbeInfo } from '../../../types/probe';
import { getMeasurementMode } from '../../../utils/getMeasurementMode';

export enum QueryKey {
    Name = 'name',
    Flagged = 'flagged',
    MeanSecondary = 'measurement.$.statistics.secondary.avg',
    MeanPrimary = 'measurement.$.statistics.primary.avg',
    ScaleUnit = 'settings.$.secondary.scaleId',
    Created = 'clientCreated',
    Updated = 'clientUpdated',
    Type = 'type',
    Material = 'settings.$.materialId',
    Standard = 'settings.$.standardId',
    Geometries = 'geometries',
    Unit = 'settings.$.unit',
    MeasurementMode = 'settings.$.measureMode',
    VerificationDate = 'measurement.$.properties.verificationResult',
    Probe = 'probe',
    BluetoothProbe = 'probeinfo.typeId',
    Size = 'size',
}

export const getFlagColumn = (allowClick: boolean) => {
    const nameColumn: ColumnType<MeasurementListItem> = {
        title: '',
        dataIndex: QueryKey.Flagged,
        key: QueryKey.Flagged,
        fixed: 'left',
        width: 40,
        render: (value: string, record: MeasurementListItem) => (
            <FlagColumn
                measurement={record}
                allowClick={allowClick && !record.isImport && !record.isCreate}
                flagColumnType={FlagColumnType.measurement}
            />
        ),
    };
    return nameColumn;
};

interface GetNameColumnParams {
    isHTMLView: boolean;
    isRecentData?: boolean;
    allowExpansion?: boolean;
    isExpanded?: (id: string) => boolean;
    onExpand?: (id: string, isExpanded: boolean) => void;
}

export const getNameColumn = ({
    isHTMLView,
    isRecentData,
    allowExpansion,
    isExpanded,
    onExpand,
}: GetNameColumnParams) => {
    const nameColumn: ColumnType<MeasurementListItem> = {
        title: <FormattedMessage id="App.Name" />,
        dataIndex: QueryKey.Name,
        key: QueryKey.Name,
        fixed: 'left',
        width: 250,
        render: (_value: string, record: MeasurementListItem) => (
            <NameColumn
                measurement={record}
                allowExpansion={allowExpansion}
                isExpanded={isExpanded?.(record.id)}
                onExpand={onExpand}
                hideEditButton={isRecentData || isHTMLView}
            />
        ),
        filterDropdown: isHTMLView ? undefined : SearchDropdown,
        sorter: isHTMLView ? (a: MeasurementListItem, b: MeasurementListItem) => a.name.localeCompare(b.name) : true,
    };
    return nameColumn;
};

export const getSizeColumn = () => {
    const sizeColumn: ColumnType<MeasurementListItem> = {
        title: <FormattedMessage id="App.AttachmentSize" />,
        dataIndex: QueryKey.Size,
        key: QueryKey.Size,
        width: 120,
        render: (value: number | undefined) => (value ? convertBytes(value) : ''),
        sorter: true,
    };
    return sizeColumn;
};

export const generateSorter = (key: string | string[]) => (a: MeasurementListItem, b: MeasurementListItem) => {
    return get(a, key, 0) - get(b, key, 0);
};

export const getClientCreatedColumn = (isHTMLView: boolean) => {
    const clientCreatedSorter = generateSorter(QueryKey.Created);
    const clientCreatedColumn: ColumnType<MeasurementListItem> = {
        dataIndex: QueryKey.Created,
        key: QueryKey.Created,
        title: <FormattedMessage id="App.Created" />,
        width: 180,
        render: renderDate,
        sorter: isHTMLView ? clientCreatedSorter : true,
    };
    return clientCreatedColumn;
};

export const getClientUpdatedColumn = (isHTMLView: boolean) => {
    const clientUpdatedSorter = generateSorter(QueryKey.Updated);
    const clientUpdatedColumn: ColumnType<MeasurementListItem> = {
        title: <FormattedMessage id="App.Modified" />,
        dataIndex: QueryKey.Updated,
        key: QueryKey.Updated,
        width: 180,
        render: renderDate,
        sorter: isHTMLView ? clientUpdatedSorter : true,
    };
    return clientUpdatedColumn;
};

export const getWifiProbeColumn = (isHTMLView: boolean, dataKey: GlobalInfoKey) => {
    return {
        title: <FormattedMessage id="App.Probe" />,
        dataIndex: ['productModel'],
        key: QueryKey.Probe,
        width: 120,
        render: (value: string) => (value ? getLabelFromGlobalDataKey(dataKey, value.toUpperCase()) : ''),
        ...(!isHTMLView && generateFilter([dataKey])),
    };
};

// for GM8000 and GS9000
const getProbeColumnGA = (isHTMLView: boolean, dataKey: GlobalInfoKey) => {
    return {
        title: <FormattedMessage id="App.Probe" />,
        dataIndex: ['productModel'],
        key: QueryKey.Probe,
        width: 120,
        render: (value: string, record: MeasurementListItem) => {
            const probeIndo = record.probeinfo as GPRProbeInfo;
            const gprModules = probeIndo?.gprModules ? ` ${probeIndo?.gprModules}` : '';
            return value ? `${getLabelFromGlobalDataKey(dataKey, value.toUpperCase())}${gprModules}` : '';
        },
        ...(!isHTMLView && generateFilter([dataKey])),
    };
};

export const getBluetoothProbeColumn = (isHTMLView: boolean, dataKey: GlobalInfoKey) => {
    return {
        title: <FormattedMessage id="App.Probe" />,
        dataIndex: ['probeinfo', 'typeId'],
        key: QueryKey.BluetoothProbe,
        width: 120,
        render: (value: string) => (value !== undefined ? getLabelFromGlobalDataKey(dataKey, value) : ''),
        ...(!isHTMLView && generateFilter([dataKey])),
    };
};

const getCommonColumns = (isHTMLView: boolean) => {
    return [
        getClientCreatedColumn(isHTMLView),
        getClientUpdatedColumn(isHTMLView),
        ...(isHTMLView ? [] : [getSizeColumn()]),
    ];
};

export const getColumnsGPR = (isHTMLView: boolean) => {
    const columnsGPR: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 120,
            render: (value, record) => (!record.isImport ? getMeasurementMode(value) : ''),
            ...(!isHTMLView && generateFilter(['gpr_scan_type'])),
        },
        getWifiProbeColumn(isHTMLView, 'gprprobes'),
    ];
    return columnsGPR;
};

export const getColumnsGPRSoil = (isHTMLView: boolean) => {
    const probeColumn = getProbeColumnGA(isHTMLView, 'gprsoilprobes');
    const scanType = 'gpr_soil_scan_type';
    const columnsGPR: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 120,
            render: (value, record) => (!record.isImport ? getMeasurementMode(value) : ''),
            ...(!isHTMLView && generateFilter([scanType])),
        },
        probeColumn,
    ];
    return columnsGPR;
};

export const getColumnsGPRMounted = (isHTMLView: boolean) => {
    const columnsGPR: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 120,
            render: (value, record) => (!record.isImport ? getMeasurementMode(value) : ''),
            ...(!isHTMLView && generateFilter(['gpr_mounted_scan_type'])),
        },
        getProbeColumnGA(isHTMLView, 'gprmountedprobes'),
    ];
    return columnsGPR;
};

export const getColumnsEquotip = (isHTMLView: boolean) => {
    const columnsEquotip: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Average" />,
            dataIndex: ['content', 'statistics', 'secondary', 'avg'],
            key: QueryKey.MeanSecondary,
            width: 130,
            render: (value: string, record) => {
                if (record.isImport) {
                    return '';
                }
                const scaleUnit = get(record, 'settings.0.content.secondary.scaleId');
                return `${renderMean(value)} ${getLabelFromGlobalDataKey('units', scaleUnit)}`;
            },
        },
        {
            title: <FormattedMessage id="App.Material" />,
            dataIndex: ['settings', '0', 'content', 'materialId'],
            key: QueryKey.Material,
            width: 180,
            render: (value: string, record) => {
                if (record.isImport) {
                    return '';
                }
                const isCustomMaterial = record.settings[0].content.customMaterial !== undefined;
                const materialID = getLabelFromGlobalDataKey('materials', String(value || 2)) ?? '';
                return <IntlFormattedMessage id={isCustomMaterial ? 'Proceq.MaterialEnumCustom' : materialID} />;
            },
            ...(!isHTMLView && generateFilter(['materials'], true)),
        },
        getBluetoothProbeColumn(isHTMLView, 'equotipprobes'),
    ];
    return columnsEquotip;
};

export const getColumnsPundit = (isHTMLView: boolean) => {
    const columnsPundit: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 120,
            render: (value, record) => (!record.isImport ? getMeasurementMode(value) : ''),
            ...(!isHTMLView && generateFilter(['pundit_scan_type'])),
        },
        getWifiProbeColumn(isHTMLView, 'punditprobes'),
    ];
    return columnsPundit;
};

export const getColumnsSchmidt = (isHTMLView: boolean) => {
    const columnsSchmidt: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Average" />,
            dataIndex: ['content', 'statistics', 'secondary', 'avg'],
            key: QueryKey.MeanSecondary,
            width: 130,
            render: (value: string, record) => {
                if (record.isImport) {
                    return '';
                }
                const scaleUnit = get(record, 'settings.0.content.secondary.scaleId');
                return `${renderMean(value, ProductCode.SCHMIDT)} ${getLabelFromGlobalDataKey(
                    'unitsschmidt',
                    scaleUnit
                )}`;
            },
        },
        {
            title: <FormattedMessage id="Proceq.ExportFieldStandard" />,
            dataIndex: ['settings', '0', 'content', 'standardId'],
            key: QueryKey.Standard,
            width: 140,
            render: (value, record) =>
                !record.isImport ? (
                    <IntlFormattedMessage id={getLabelFromGlobalDataKey('standardsschmidt', value) ?? ' '} />
                ) : (
                    ''
                ),
            ...(!isHTMLView && generateFilter(['standardsschmidtFilter'], true)),
        },
        getBluetoothProbeColumn(isHTMLView, 'schmidtprobes'),
    ];
    return columnsSchmidt;
};

export const getColumnsGLM = (isHTMLView: boolean) => {
    const columnsGLM: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="Geometries" />,
            dataIndex: ['settings', '0', 'content', 'angleBinList'],
            key: QueryKey.Geometries,
            width: 140,
            render: (binList, record) => {
                if (isUndefined(binList) || record.isImport) {
                    return null;
                }
                return (
                    <span>
                        {binList
                            ?.filter((bin: number) => bin !== 3)
                            .map((bin: number) => {
                                const { text } = getBinProps(bin);
                                return text;
                            })
                            .join(', ')}
                    </span>
                );
            },
        },
        getBluetoothProbeColumn(isHTMLView, 'glossmeterprobes'),
    ];
    return columnsGLM;
};

export const getColumnsPI = (isHTMLView: boolean) => {
    const columnsPI: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 180,
            render: (value, record) =>
                !record.isImport
                    ? getMeasurementMode(record.type, ProductCode.PIT_IE, { count: record.content.piles.length })
                    : '',
            ...(!isHTMLView && generateFilter(['pi_mode'])),
        },
        getBluetoothProbeColumn(isHTMLView, 'punditimpactprobes'),
    ];
    return columnsPI;
};

export const getColumnsProfometer = (isHTMLView: boolean) => {
    const columnsPI: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 120,
            render: (value, record) => (!record.isImport ? getMeasurementMode(value) : ''),
            ...(!isHTMLView && generateFilter(['profometer_mode'])),
        },
        getBluetoothProbeColumn(isHTMLView, 'profometerprobes'),
    ];
    return columnsPI;
};

export const getColumnsFDL = (isHTMLView: boolean) => {
    const columnsFDL: ColumnsType<MeasurementListItem> = [
        ...getCommonColumns(isHTMLView),
        {
            title: <FormattedMessage id="App.Mode" />,
            dataIndex: QueryKey.Type,
            key: QueryKey.Type,
            width: 120,
            render: (value, record) => (!record.isImport ? getMeasurementMode(value) : ''),
            ...(!isHTMLView && generateFilter(['fdl_mode'])),
        },
        getWifiProbeColumn(isHTMLView, 'fdlprobes'),
    ];
    return columnsFDL;
};

export const columnsCustomCurve: ColumnsType<MeasurementListItem> = [getClientCreatedColumn(false)];

export const columnsVerification: ColumnsType<MeasurementListItem> = [
    getClientCreatedColumn(false),
    {
        title: <FormattedMessage id="App.Average" />,
        dataIndex: ['content', 'statistics', 'primary', 'avg'],
        key: QueryKey.MeanPrimary,
        width: 130,
        render: (value: string, record) => {
            const productModel = record.productModel?.toUpperCase() as ProductModel;
            const productCode = productModelToFamilyMap[productModel];
            const settings = get(record, 'settings.0.content');
            const scaleID = get(settings, 'secondary.scaleId');
            let unit: string | null = '';
            if (productCode === ProductCode.SCHMIDT) {
                const formFactorId = get(settings, 'secondary.formFactorId');
                unit = getScaleInfoOfScaleID(
                    intlProxy.formatMessage,
                    getSchmidtScaleIdOfFromFactorAndUnitID(scaleID, formFactorId),
                    productModel
                ).scaleUnits;
            } else {
                unit = getScaleInfoOfScaleID(intlProxy.formatMessage, scaleID, productModel).scaleUnits;
            }
            return `${renderMean(value)} ${unit}`;
        },
    },
    {
        title: <FormattedMessage id="Proceq.TableColumnVerificationResult" />,
        dataIndex: ['content', 'properties', 'verificationResult'],
        key: QueryKey.VerificationDate,
        width: 100,
        render: (value) => (
            <span
                className={classNames(
                    styles.result_tag,
                    value === 'failed' && styles.failed,
                    value === 'passed' && styles.passed
                )}
            >
                <IntlFormattedMessage id={getLabelFromGlobalDataKey('verificationstatuses', value) ?? ' '} />
            </span>
        ),
        ...generateFilter(['verificationstatuses'], true),
    },
];
