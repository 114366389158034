import React from 'react';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import { MeasurementReading } from '../../../../../types/measurement';
import { Log } from '../../../../../types/logs';
import { ScanType } from '../../../../../types/proceq';
import Photo from '../../../../Attachments/Photo';
import SetupFile from './SetupFile';
import { nullCallback } from '../../../../../hooks/useCheckInviewPort';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';
import DGSCalibrationAndSnapshot from './DGSCalibrationAndSnapshot';
import SectionTable from '../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import Baseline from './Baseline';

export const KeyFrameSnapshot: React.FunctionComponent<
    { reading: MeasurementReading; log?: Log; scanType: ScanType } & FDLMeasurementPresetsProps
> = (props) => {
    const { reading, log, unit, showTitle, scanType } = props;
    const formatMessage = useIntl().formatMessage;

    const number = +reading.content.indicationPosition.indicationPosition + 1;
    const title = (
        <>
            <FormattedMessage id="Proceq.ExportFieldSettings" />
            {' ­­» '}
            <FormattedMessage id="App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot" />
            {' ­­» '}
            {number}
        </>
    );
    const hasIndicationInfo = !!reading.content?.indicationSettings?.info?.dgsIndicationInfo;

    const dataSourceRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.IndicationID',
            value: number,
        },
        {
            label: 'Proceq.ExportColumnProbeType',
            value: formatMessage({
                id: `App.Logbook.FDL.Item.type.${get(reading, 'content.indicationSettings.info.type')}`,
            }),
        },
        {
            label: 'Proceq.TableDescription',
            value: get(reading, 'content.indicationSettings.info.description'),
        },
        {
            label: 'App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.GlobalGain',
            value: get(reading, 'content.indicationSetupFile.gain'),
            unitId: 'FDL.KeyFrameSnapshot.GlobalGain',
        },
    ];

    const dataSource = getConvertedSectionTableData(dataSourceRaw, ScanType.Indication, unit);

    return (
        <div className="key-frame-snapshot">
            <SectionTable title={title} showTitle={showTitle} dataSource={dataSource} />
            {log && (
                <Photo
                    mID={reading.mID}
                    aID={log.content.attachmentId}
                    attachmentName={log.content.attachmentName}
                    getScrollParent={nullCallback}
                    analyticsCategory={SnapshotAction.detail}
                    analyticsProduct={log.content.product}
                    analyticsType={log.type}
                />
            )}
            <SetupFile
                setupFile={reading.content.indicationSetupFile}
                number={number}
                showTitle={showTitle}
                unit={unit}
            />
            <Baseline scanType={scanType} number={number} setupFile={reading.content?.indicationSetupFile} />
            {hasIndicationInfo && (
                <DGSCalibrationAndSnapshot
                    setupFile={reading.content.indicationSetupFile}
                    indicationInfo={reading.content.indicationSettings.info.dgsIndicationInfo}
                    number={number}
                    showTitle={showTitle}
                    unit={unit}
                />
            )}
            <br />
        </div>
    );
};

export default KeyFrameSnapshot;
