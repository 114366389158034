import { Action as ReduxAction } from 'redux';
import { objectUtils } from 'tds-common-fe';

const actionTypes = {
    RECEIVED_USER_PROFILE: '',
    REMOVED_PROFILE_PICTURE: '',
    RECEIVED_USER_LICENSE_LIST: '',
    RECEIVED_USER_LICENSE: '',
    RECEIVED_REGION_INFO: '',
    RECEIVED_ROLE_INFO: '',
    RECEIVED_PROCEQ_USERS: '',

    RECEIVED_MEASUREMENT_FOLDERS: '',
    RECEIVED_MEASUREMENT_LIST: '',
    RECEIVED_POLLED_MEASUREMENTS: '',
    RECEIVED_RECENT_MEASUREMENT: '',
    CLEAR_MEASUREMENT_LIST: '',
    CREATE_MEASUREMENT: '',
    CLEAR_CREATED_MEASUREMENT: '',
    RECEIVED_MEASUREMENT_FULL_DATA: '',
    RECEIVED_MEASUREMENT_LOGS: '',
    RECEIVED_MEASUREMENT_ATTACHMENTS: '',
    CLEAR_MEASUREMENT_TIMESTAMPS: '',
    RECEIVED_HTML_MEASUREMENTS: '',
    RECEIVED_HTML_EXPORT_MEASUREMENTS: '',
    IMPORT_MEASUREMENTS: '',
    RECEIVED_IMPORT_MEASUREMENTS_STATUS: '',
    REMOVE_IMPORT_MEASUREMENT: '',

    RECEIVED_CUSTOM_MATERIAL_LIST: '',
    RECEIVED_CUSTOM_MATERIAL: '',

    RECEIVED_PROBE_LIST: '',
    RECEIVED_PROBE_INFO: '',

    RECEIVED_PROCEQ_APP_CONFIG: '',
    RECEIVED_APPS_STATUS: '',
    RECEIVED_PLAN_TIERS: '',

    RECEIVED_CONTRACTS: '',

    CLEAN_UP: '',

    RECEIVED_NOTIFICATIONS: '',
    READ_NOTIFICATION: '',
    READ_ALL_NOTIFICATIONS: '',
    UPDATE_ALL_NOTIFICATIONS: '',
    CLEAR_ALL_NOTIFICATIONS: '',
};

type ActionTypes = typeof actionTypes;

// Convert the values to be the same as the keys
const compiledActions = objectUtils.populateKeysToValue(actionTypes);

export type Action = ReduxAction<keyof ActionTypes>;

export interface PayloadAction<T = void> extends Action {
    payload: T;
}

export default compiledActions;
