import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import FormattedMessage from '../../../../localization/FormatMessage';
import UserComponent, { title as titleUserComponent } from '../Common/User';
import MeasurementPresets, { title as titleMeasurementPresets } from './shared/MeasurementPresets';
import { DerivedProps } from '../SharedTypes';
import { convertAndDisplay } from '../utils/conversionRates';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import { ProductModel, ScanType } from '../../../../types/proceq';
import FilteringPresets, { title as titleFilteringPresets } from './PunditImpact/FilteringPresets';
import PilesSummary, { title as titlePilesSummary } from './PunditImpact/PilesSummary';
import SpotsSummary, { title as titleSpotsSummary } from './PunditImpact/SpotsSummary';
import PilePositions from './PunditImpact/PilePositions';
import Grid, { title as GridTitle } from './PunditImpact/GridInfo';

const PunditImpactReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data, isStandalone } = props;
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = get(data, 'measurement.type');
    const isMetric = true;

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data || !scanType) {
        return null;
    }

    const derivedProps: DerivedProps & { productModel: ProductModel } = {
        product,
        productModel,
        scanType,
        isMetric,
        convert: convertor,
        showTitle: isStandalone,
    };

    return (
        <ReadingContainer>
            <ReadingPanel key="user" header={titleUserComponent}>
                <UserComponent data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="measurement" header={titleMeasurementPresets}>
                <MeasurementPresets data={data} {...derivedProps} />
            </ReadingPanel>

            {scanType === ScanType.ImpactEchoGrid && (
                <ReadingPanel key="gridSettings" header={GridTitle}>
                    <Grid data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            <ReadingPanel key="filtering" header={titleFilteringPresets}>
                <FilteringPresets data={data} {...derivedProps} />
            </ReadingPanel>

            {scanType === ScanType.AScan ? (
                <>
                    <h1>
                        <FormattedMessage id="App.HTML.PI8000.Piles.Piles" />
                    </h1>
                    <ReadingPanel key="pilesSummary" header={titlePilesSummary}>
                        <PilesSummary data={data} {...derivedProps} />
                    </ReadingPanel>
                    <ReadingPanel key="pilesPosition" header="">
                        <PilePositions data={data} {...derivedProps} />
                    </ReadingPanel>{' '}
                </>
            ) : (
                <>
                    <h1>
                        <FormattedMessage id="App.HTML.PI8000.Spots" />
                    </h1>
                    <ReadingPanel key="spotsSummary" header={titleSpotsSummary}>
                        <SpotsSummary data={data} {...derivedProps} />
                    </ReadingPanel>
                </>
            )}
        </ReadingContainer>
    );
};

export default PunditImpactReadings;
