import { union } from 'lodash';
import { AnyAction, Reducer } from 'redux';
import { produce } from 'immer';

import actionTypes, { PayloadAction } from '../actions/actionTypes';
import { ProceqUser } from '../types/profile';
import { ReceivedProceqUserPayload } from '../actions/user';
import { normalizer } from '../utils/genericNormalizer';

export interface UserState {
    userIDs: string[];
    users: {
        [key: string]: ProceqUser;
    };
}

export const initialState: UserState = {
    userIDs: [],
    users: {},
};

export const makeUserReducer = (myInitialState: UserState = initialState): Reducer<UserState, AnyAction> =>
    produce((draft = myInitialState, action) => {
        switch (action.type) {
            case actionTypes.RECEIVED_PROCEQ_USERS: {
                const { payload } = action as PayloadAction<ReceivedProceqUserPayload>;
                const { userList } = payload;
                const { entityIds, entities } = normalizer(userList, 'id');

                draft.userIDs = union(draft.userIDs, entityIds);
                for (const id of entityIds) {
                    draft.users[id] = entities[id];
                }
                return draft;
            }

            default: {
                return draft;
            }
        }
    }, myInitialState);

const user = makeUserReducer();

export default user;
