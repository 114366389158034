import React, { Fragment } from 'react';
import { upperFirst } from 'lodash';
import StaticMap from '../StaticMap';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';
import { isBeforeHDFRemoval, isEqualOrAfterPD8050Launch } from '../../../../Common/Pundit/exportConstants';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { getLabelFromGlobalDataKey } from '../../../RegisteredInfo/helper';

export const Creation: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, isMetric, scanType, version }) => {
    const { latitide, longitude, measurementInfo } = log.content;
    const coordinates = { lat: +latitide, lng: +longitude };
    const formatMessage = useFormatMessage();
    if (isMetric === undefined || scanType === undefined) {
        return null;
    }
    const ver = version || '';
    const dataRaw: {
        id: FormatIDs;
        value: number | string;
        enable?: boolean;
        unitId?: string;
        type?: string;
    }[] = [
        {
            id: 'Proceq.LogbookCreationMode',
            value: getLabelFromGlobalDataKey('pundit_scan_type', measurementInfo.mode) ?? '',
        },
        {
            id: 'Proceq.LogbookEntryDepthOfField',
            value: measurementInfo.macro,
            enable: measurementInfo.isIQSAFT,
        },
        {
            id: 'Proceq.LogbookEntryTxFrequency',
            value: measurementInfo.transmitFrequency / 1000,
            unitId: 'PUNDIT.Snapshot.Center Frequency',
            enable: isEqualOrAfterPD8050Launch(ver),
        },
        {
            id: 'Proceq.LogbookEntryTxVoltage',
            unitId: 'PUNDIT.MeasurementPresets.Energy',
            value: measurementInfo.transmitVoltage,
            enable: isEqualOrAfterPD8050Launch(ver),
        },
        {
            id: 'Proceq.LogbookEntryHalfCycle',
            value: measurementInfo.halfCycle ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: isEqualOrAfterPD8050Launch(ver),
        },
        {
            id: 'Proceq.LogbookEntryMaxTransmissionTime',
            value: measurementInfo.transmissionTime,
            unitId: 'PUNDIT.Logbook Panel.Max Transmission Time',
            enable: !measurementInfo.isIQSAFT || isEqualOrAfterPD8050Launch(ver),
        },
        {
            id: 'Proceq.LogbookEntryChannels',
            value: measurementInfo.channels,
        },
        {
            id: 'Proceq.LogbookEntrySignalZeroOffset',
            value: measurementInfo.signalZeroOffset,
            unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
            enable: !isEqualOrAfterPD8050Launch(ver),
        },
        {
            id: 'Proceq.LogbookEntryRawDataOffset',
            unitId: 'PUNDIT.Settings Panel.Signal Zero Offset',
            value: measurementInfo.rawDataOffset,
            enable: isEqualOrAfterPD8050Launch(ver),
        },
        {
            id: 'Proceq.LogbookEntryPulseDelay',
            value: measurementInfo.pulseDelay,
            unitId: 'PUNDIT.Settings Panel.Pulse Delay',
        },
        {
            id: 'Proceq.LogbookEntryPulseVelocity',
            value: measurementInfo.pulseVelocity,
            unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
        },
        {
            id: 'Proceq.LogbookEntryAnalogGain',
            value: measurementInfo.probeGain,
            unitId: 'PUNDIT.Settings Panel.Analogue Gain',
        },
        {
            id: 'App.HTML.PUNDIT.MeasurementPresets.AnalogTGC.Value',
            value: measurementInfo.timeGain,
            unitId: 'PUNDIT.Settings Panel.Time Gain Compensation',
        },
        {
            id: 'Proceq.LogbookEntryLinearGain',
            value: measurementInfo.digitalGain,
            unitId: 'PUNDIT.Settings Panel.Digital Gain',
        },
        {
            id: 'Proceq.LogbookEntryDigitalTGC',
            value: measurementInfo.digitalTGC ?? 0,
            unitId: 'PUNDIT.Settings Panel.Digital Gain',
        },
        {
            id: 'Proceq.LogbookEntryImageStabilizer',
            value: measurementInfo.averaging,
        },
        {
            id: 'Proceq.LogbookEntryHighDefinitionFocus',
            value: measurementInfo.isIQSAFT ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: isBeforeHDFRemoval(ver),
        },
        {
            id: 'Proceq.LogbookEntrySurfaceWaveCancellation',
            value: measurementInfo.surfaceWaveCancellation,
        },
        {
            id: 'Proceq.LogbookEntryYSpacing',
            value: measurementInfo.overlapX, // both stripe scan and line scan use overlapX; overlapY is never used
            unitId: 'PUNDIT.Logbook Panel.Y Spacing',
            type: 'Stripe Scan',
            enable: !measurementInfo.aiMode,
        },
        {
            id: 'Proceq.LogbookEntryXSpacing',
            value: measurementInfo.overlapX,
            unitId: 'PUNDIT.Logbook Panel.X Spacing',
            type: 'Line Scan',
            enable: !measurementInfo.aiMode && measurementInfo.overlapX >= 0,
        },
        {
            id: 'Proceq.LogbookEntryXSpacing',
            value: measurementInfo.overlapX,
            unitId: 'PUNDIT.Logbook Panel.X Spacing Channel', // only Line Scans have cases where unit is channel
            type: 'Line Scan',
            enable: !measurementInfo.aiMode && measurementInfo.overlapX < 0, // logic ported from iOS LogbookPunditCreationCell.processGapAndOverlap
        },
        {
            id: 'Proceq.LogbookEntryAIMode',
            value: measurementInfo.aiMode ? formatMessage({ id: 'Proceq.On' }) : formatMessage({ id: 'Proceq.Off' }),
            enable: measurementInfo.aiMode,
        },
    ];

    return (
        <div>
            <b>
                <FormattedMessage
                    id="Proceq.LogbookPropertyCreatedFileInFolder"
                    values={{
                        fileName: measurementInfo.fileFolder.fileName,
                        folderName: measurementInfo.fileFolder.folderName,
                    }}
                />
            </b>
            <br />
            {dataRaw
                .filter((row) => row.enable === undefined || row.enable)
                .map((config) => {
                    let ele;
                    if (config.unitId) {
                        if (config.type && config.type !== scanType) {
                            return null;
                        }

                        ele = (
                            <ConvertedUnits
                                id={config.id}
                                unitId={config.unitId}
                                scanType={scanType}
                                isMetric={isMetric}
                                values={[Number(config.value)]}
                            />
                        );
                    } else if (config.value === null) {
                        return null;
                    } else {
                        ele = <FormattedMessage id={config.id} values={{ value: upperFirst(String(config.value)) }} />;
                    }
                    return (
                        <Fragment key={config.id}>
                            {ele}
                            <br />
                        </Fragment>
                    );
                })}
            <StaticMap coordinates={coordinates} />
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
