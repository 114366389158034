import { routeUtils } from 'tds-common-fe';
import { AxiosResponse } from 'axios';
import { RcFile } from 'antd/lib/upload';
// @ts-ignore
import { Convertor } from 'encryption-utils';
import {
    getChunkAtIndex,
    GetChunkAtIndexParams,
    getOffset,
    getSha256Hash,
} from 'tds-common-fe/src/lib/utils/fileUtils';
import { DEFAULT_MAX_FILE_SIZE } from '../types/userFiles';
import { fileToArrayBuffer, generateZipFile } from '../utils/fileUtils';
import { getApiURL } from './ApiURL';
import { post, put } from './apiService';
import { handleError } from './error';
import { store } from '../store';

const reduxStore = store.getState();

interface UploadZipFileParams {
    file: RcFile | File;
    product: string;
    progressCallback?: (progress: number) => void;
    zipName?: string;
}

interface UploadParams {
    file: RcFile | File;
    product: string;
    progressCallback?: (progress: number) => void;
}

interface UploadChunkParams {
    file: RcFile | File;
    fileID: string;
    startByte: number;
    chunkSize: number;
    progressCallback?: (progress: number) => void;
}

const simpleUpload = async (params: UploadParams) => {
    const { file, product, progressCallback } = params;
    const arrayBuffer = (await fileToArrayBuffer(file)) as ArrayBuffer;
    const hash = Convertor.bytesToBase64(Convertor.sha256(arrayBuffer));

    const url = routeUtils.makeQueryPath(getApiURL('UPLOAD_FILE'), { product, type: 'simple', hash });
    const formData = new FormData();
    formData.append('file', file, file.name);

    return post<{ fileID: string }>(url, {
        errorHandler: handleError,
        params: formData,
        injectToken: true,
        config: {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: (progressEvent) => {
                if (progressCallback && progressEvent.total) {
                    const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                    progressCallback(percentCompleted);
                }
            },
        },
    });
};

const uploadChunk = async (params: UploadChunkParams): Promise<{ fileID: string }> => {
    const { file, startByte, fileID, progressCallback, chunkSize } = params;
    const chunkParams: GetChunkAtIndexParams = {
        file,
        index: 0,
        offset: startByte,
        chunkSize,
    };
    const chunk = getChunkAtIndex(chunkParams) as Blob;
    const endByte = startByte + chunk.size - 1;
    const url = routeUtils.makeQueryPath(getApiURL('UPLOAD_FILE'), { fileID });
    const response = await put(
        url,
        {
            errorHandler: handleError,
            injectToken: true,
            params: await chunk.arrayBuffer(),
            config: {
                headers: { 'Content-Range': `bytes ${startByte}-${endByte}/${file.size}` },
                validateStatus: (status) => (status >= 200 && status < 300) || status === 308,
            },
        },
        true
    );

    const { headers, status } = response;
    if (status === 308) {
        const percentage = Math.floor((endByte / file.size) * 100);
        progressCallback?.(percentage);
        const newStartByte = getOffset(headers as any);
        return uploadChunk({
            file,
            fileID,
            startByte: newStartByte,
            chunkSize,
            progressCallback,
        });
    }
    if (status === 200) {
        progressCallback?.(100);
        return Promise.resolve({ fileID });
    }
    return Promise.reject();
};

const resumableUpload = async (params: UploadParams) => {
    const { file, product, progressCallback } = params;
    const hash = await getSha256Hash(file);
    const url = routeUtils.makeQueryPath(getApiURL('UPLOAD_FILE'), { product, type: 'resumable' });

    const response: AxiosResponse<{ fileID: string }> = await post(
        url,
        {
            errorHandler: handleError,
            injectToken: true,
            params: { hash, name: file.name, product },
            config: {
                headers: { 'X-Upload-Content-Length': file.size },
                validateStatus: (status) => (status >= 200 && status < 300) || status === 308,
            },
        },
        true
    );
    const { data, status, headers } = response;
    if (status === 308 || status === 201) {
        const chunkSize = parseInt(headers['chunk-size'], 10) ?? DEFAULT_MAX_FILE_SIZE;
        const startByte = getOffset(headers as any);
        return uploadChunk({
            file,
            fileID: data.fileID,
            startByte,
            chunkSize,
            progressCallback,
        });
    }
    if (status === 200) {
        progressCallback?.(100);
        return Promise.resolve(data);
    }
    return Promise.reject();
};

export const uploadFile = async (params: UploadParams) => {
    const maxFileUploadSize = reduxStore.config.maxFileUploadSize;
    if (params.file.size < maxFileUploadSize) {
        return simpleUpload(params);
    }
    return resumableUpload(params);
};

export const uploadZipFile = async (params: UploadZipFileParams) => {
    const { file: originalFile, product, progressCallback, zipName } = params;
    const file = zipName ? await generateZipFile(originalFile, zipName) : originalFile;
    return uploadFile({ file, product, progressCallback });
};
