import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const ResumeScan: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => <>{log.content.comment}</>;

export const color = () => 'red';

export default {
    element: ResumeScan,
    color,
};
