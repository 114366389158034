import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { convertAndDisplay } from '../../utils/conversionRates';
import FormattedMessage from '../../../../../localization/FormatMessage';

export const ChangeFileUnit: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, product, scanType, isMetric }) => {
    if (scanType === undefined || isMetric === undefined) {
        return null;
    }

    return (
        <FormattedMessage
            id="Proceq.ChangedFromTo"
            values={{
                changed: `${log.content.settingName} (${
                    convertAndDisplay(`${product}.Settings Panel.${log.content.settingName}`, scanType, isMetric).unit
                })`,
                from: log.content.old,
                to: log.content.new,
            }}
        />
    );
};

export default {
    element: ChangeFileUnit,
};
