import React from 'react';
import styles from './PlanFeature.styl';
import { ReactComponent as TickIcon } from '../../../images/iconTick.svg';

const PlanFeature: React.FunctionComponent<{ content: string }> = (props) => {
    const { content } = props;
    return (
        <div className={styles.plan_item}>
            <TickIcon />
            <div>{content}</div>
        </div>
    );
};

export default PlanFeature;
