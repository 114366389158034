import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { AnyAction, Reducer } from 'redux';
import { groupBy } from 'lodash';
import actionTypes, { PayloadAction } from '../actions/actionTypes';
import {
    ClearMeasurementListPayload,
    CreateMeasurementPayload,
    ImportMeasurementsPayload,
    ReceivedCustomMaterialListPayload,
    ReceivedCustomMaterialPayload,
    ReceivedHTMLExportPayload,
    ReceivedHTMLMeasurementsPayload,
    ReceivedImportMeasurementsStatusPayload,
    ReceivedMeasurementAttachmentsPayload,
    ReceivedMeasurementListPayload,
    ReceivedMeasurementLogsPayload,
    ReceivedMeasurementPayload,
    ReceivedPolledMeasurementsPayload,
    ReceivedRecentMeasurementsPayload,
    ReceiveFoldersPayload,
    RemoveImportMeasurementPayload,
} from '../actions/measurement';
import { CustomMaterialItem } from '../types/customMaterial';
import {
    BluetoothFileType,
    CreateMeasurementData,
    CustomCurveFileType,
    Folder,
    htmlViewExportFolder,
    ImportMeasurementData,
    MeasurementFullData,
    MeasurementListItem,
    SystemFolderTypesSet,
} from '../types/measurement';
import { normalizer } from '../utils/genericNormalizer';
import { ProductCode } from '../types/proceq';

export type SingleMeasurementState = MeasurementFullData & {
    id: string;
    fetchedTimestamp?: {
        full?: number;
        logs?: number;
        attachments?: number;
    };
};

export interface ImportInfo {
    product?: ProductCode;
    jobID?: string;
    totalCount?: number;
    hasSuccessfulImport?: boolean;
}

interface CommonProductData {
    measurementIDs?: {
        [key: string]: string[];
    };
    measurementCount?: {
        [key: string]: number;
    };
    folderIDs?: string[];
    userFolderIDs?: string[];
    folders?: { [key: string]: Folder };
}

export interface ProductData extends CommonProductData {
    verificationIDs?: string[];
    dgsccIDs?: string[];
    customMaterialIDs?: string[];
    bluetoothMenuTotalRecords?: {
        [key: string]: number;
    };
    importIDs?: string[];
    createMeasurementIDs?: string[];
    archivedData?: CommonProductData;
}

export type A = keyof ProductData;

export interface MeasurementState {
    products: { [key: string]: ProductData };
    measurements: { [key: string]: SingleMeasurementState };
    recentMeasurementIDs: string[];
    customMaterials: { [key: string]: CustomMaterialItem };
    importMeasurementsData: { [key: string]: ImportMeasurementData };
    createMeasurementsData: { [key: string]: CreateMeasurementData };
    importInfo: ImportInfo;
}

export const initialState: MeasurementState = {
    products: {},
    measurements: {},
    recentMeasurementIDs: [],
    customMaterials: {},
    importMeasurementsData: {},
    createMeasurementsData: {},
    importInfo: {},
};

const transformMeasurement = (data: MeasurementListItem): Partial<SingleMeasurementState> => {
    const { settings, ...measurement } = data;
    return {
        id: data.id,
        settings,
        measurement,
    };
};

function setupProductData(draft: WritableDraft<MeasurementState>, product: ProductCode) {
    if (!draft.products[product]) {
        draft.products[product] = {};
    }
    if (!draft.products[product].measurementIDs) {
        draft.products[product].measurementIDs = {};
    }
    if (!draft.products[product].measurementCount) {
        draft.products[product].measurementCount = {};
    }
    if (!draft.products[product].bluetoothMenuTotalRecords) {
        draft.products[product].bluetoothMenuTotalRecords = {};
    }
    if (!draft.products[product].folders) {
        draft.products[product].folders = {};
    }
    if (!draft.products[product].importIDs) {
        draft.products[product].importIDs = [];
    }
    if (!draft.products[product].createMeasurementIDs) {
        draft.products[product].createMeasurementIDs = [];
    }
    if (!draft.products[product].archivedData) {
        draft.products[product].archivedData = {
            measurementIDs: {},
            measurementCount: {},
            folders: {},
            folderIDs: [],
            userFolderIDs: [],
        };
    }
}

function setupMeasurement(draft: WritableDraft<MeasurementState>, measurementID: string) {
    if (!draft.measurements[measurementID]) {
        draft.measurements[measurementID] = {} as any;
    }
    if (!draft.measurements[measurementID].fetchedTimestamp) {
        draft.measurements[measurementID].fetchedTimestamp = {};
    }
}

function handleReceivedMeasurementFolders(draft: WritableDraft<MeasurementState>, payload: ReceiveFoldersPayload) {
    const { product, folders, archived } = payload;
    const { entityIds, entities } = normalizer(folders);
    setupProductData(draft, product);
    const pointer = archived ? draft.products[product].archivedData : draft.products[product];
    const existingFolders = pointer!.folders;
    pointer!.folderIDs = entityIds;
    pointer!.folders = { ...existingFolders, ...entities };
    pointer!.userFolderIDs = entityIds.filter((id) => !SystemFolderTypesSet.has(entities[id].type));
}

function handleArchivedMeasurements(draft: WritableDraft<MeasurementState>, payload: ReceivedMeasurementListPayload) {
    const { product, measurements, folderID, totalCount } = payload;
    const measurementFolder = folderID;
    setupProductData(draft, product);
    const { entityIds, entities } = normalizer(measurements.map(transformMeasurement));
    draft.products[product].archivedData!.measurementIDs![folderID] = entityIds;
    for (const id of entityIds) {
        const currentData = draft.measurements[id];
        draft.measurements[id] = {
            ...currentData,
            ...entities[id],
        };
    }
    draft.products[product].archivedData!.measurementCount![measurementFolder] = totalCount;
}

export const makeMeasurementReducer = (
    myInitialState: MeasurementState = initialState
): Reducer<MeasurementState, AnyAction> =>
    produce((draft = myInitialState, action) => {
        switch (action.type) {
            case actionTypes.RECEIVED_MEASUREMENT_FOLDERS: {
                const { payload } = action as PayloadAction<ReceiveFoldersPayload>;
                handleReceivedMeasurementFolders(draft, payload);
                return draft;
            }

            case actionTypes.RECEIVED_MEASUREMENT_LIST: {
                const { payload } = action as PayloadAction<ReceivedMeasurementListPayload>;
                const { product, measurements, folderID, totalCount, fileType, limit, archived } = payload;
                if (archived) {
                    handleArchivedMeasurements(draft, payload);
                    return draft;
                }
                const measurementFolder =
                    fileType === BluetoothFileType.VerificationData
                        ? BluetoothFileType.VerificationData
                        : fileType === CustomCurveFileType.dgscc
                          ? CustomCurveFileType.dgscc
                          : folderID;

                setupProductData(draft, product);

                // when limit = 1 refresh occurs only to update count, no need to update ids
                if (measurementFolder === BluetoothFileType.VerificationData && limit !== undefined && limit === 1) {
                    draft.products[product].bluetoothMenuTotalRecords![measurementFolder] = totalCount;
                    return draft;
                }

                const { entityIds, entities } = normalizer(measurements.map(transformMeasurement));
                const newlyImportedIDs = new Set<string>();

                if (fileType === BluetoothFileType.VerificationData) {
                    draft.products[product].verificationIDs = entityIds;
                } else if (fileType === CustomCurveFileType.dgscc) {
                    draft.products[product].dgsccIDs = entityIds;
                } else {
                    draft.products[product].measurementIDs![folderID] = entityIds;
                }
                for (const id of entityIds) {
                    const currentData = draft.measurements[id];
                    draft.measurements[id] = {
                        ...currentData,
                        ...entities[id],
                    };
                    // This measurement is previously in importing, remove from importing
                    if (draft.importMeasurementsData[id]) {
                        newlyImportedIDs.add(id);
                        delete draft.importMeasurementsData[id];
                    }
                }

                // Remove the new measurements from the list
                if (newlyImportedIDs.size > 0 && draft.products[product].importIDs) {
                    draft.products[product].importIDs = draft.products[product].importIDs!.filter(
                        (id) => !newlyImportedIDs.has(id)
                    );
                }

                draft.products[product].measurementCount![measurementFolder] = totalCount;

                return draft;
            }

            case actionTypes.RECEIVED_POLLED_MEASUREMENTS: {
                const { payload } = action as PayloadAction<ReceivedPolledMeasurementsPayload>;
                const { product, measurements } = payload;
                setupProductData(draft, product);
                const { entityIds, entities } = normalizer(measurements.map(transformMeasurement));
                for (const id of entityIds) {
                    const currentData = draft.measurements[id];
                    draft.measurements[id] = {
                        ...currentData,
                        ...entities[id],
                    };
                }
                return draft;
            }

            case actionTypes.RECEIVED_CUSTOM_MATERIAL_LIST: {
                const { payload } = action as PayloadAction<ReceivedCustomMaterialListPayload>;
                const { product, customMaterials, totalCount, limit } = payload;

                setupProductData(draft, product);

                draft.products[product].measurementCount![BluetoothFileType.CustomMaterial] = totalCount;
                draft.products[product].bluetoothMenuTotalRecords![BluetoothFileType.CustomMaterial] = totalCount;
                if (limit !== undefined && limit === 0) {
                    return draft;
                }

                const { entityIds, entities } = normalizer(customMaterials);

                draft.products[product].customMaterialIDs = entityIds;
                for (const id of entityIds) {
                    draft.customMaterials[id] = entities[id];
                }
                return draft;
            }

            case actionTypes.RECEIVED_CUSTOM_MATERIAL: {
                const { payload } = action as PayloadAction<ReceivedCustomMaterialPayload>;
                const { id, customMaterial } = payload;
                draft.customMaterials[id] = customMaterial;
                return draft;
            }

            case actionTypes.RECEIVED_RECENT_MEASUREMENT: {
                const { payload } = action as PayloadAction<ReceivedRecentMeasurementsPayload>;
                const { measurements } = payload;
                const { entityIds, entities } = normalizer(measurements.map(transformMeasurement));

                draft.recentMeasurementIDs = entityIds;
                for (const id of entityIds) {
                    const currentData = draft.measurements[id];
                    draft.measurements[id] = {
                        ...currentData,
                        ...entities[id],
                    };
                }
                return draft;
            }

            case actionTypes.CLEAR_MEASUREMENT_LIST: {
                const { payload } = action as PayloadAction<ClearMeasurementListPayload>;
                const { product, folderID } = payload;

                setupProductData(draft, product);
                draft.products[product].measurementIDs![folderID] = [];
                return draft;
            }

            case actionTypes.CREATE_MEASUREMENT: {
                const { payload } = action as PayloadAction<CreateMeasurementPayload>;
                const { id, product } = payload;
                setupProductData(draft, product);
                draft.products[product].createMeasurementIDs?.push(id);
                const existingData = draft.createMeasurementsData;
                draft.createMeasurementsData = { [id]: payload, ...existingData };
                return draft;
            }

            case actionTypes.CLEAR_CREATED_MEASUREMENT: {
                const { payload } = action;
                const { id, product } = payload;
                const createMeasurementIDs = draft.products[product].createMeasurementIDs;
                if (createMeasurementIDs) {
                    const index = createMeasurementIDs.findIndex((val) => val === id);
                    if (index !== -1) {
                        draft.products[product].createMeasurementIDs!.splice(index, 1);
                    }
                }
                if (draft.createMeasurementsData[id]) {
                    delete draft.createMeasurementsData[id];
                }
                return draft;
            }

            case actionTypes.RECEIVED_MEASUREMENT_FULL_DATA: {
                const { payload } = action as PayloadAction<ReceivedMeasurementPayload>;
                const { measurementID, measurement } = payload;

                // size is not returned from backend due to limitations LC-1482, keep existing info if it exists
                const existingMeasurementSize = draft.measurements[measurementID]?.measurement?.size;
                if (existingMeasurementSize) {
                    measurement.measurement.size = existingMeasurementSize;
                }

                const singleMeasurement: SingleMeasurementState = {
                    id: measurementID,
                    ...measurement,
                };
                if (!singleMeasurement.fetchedTimestamp) {
                    singleMeasurement.fetchedTimestamp = {};
                }
                singleMeasurement.fetchedTimestamp.full = Date.now();
                draft.measurements[measurementID] = singleMeasurement;
                return draft;
            }

            case actionTypes.CLEAR_MEASUREMENT_TIMESTAMPS: {
                const measurementIDs = Object.keys(draft.measurements);
                for (const id of measurementIDs) {
                    if (draft.measurements[id]) {
                        delete draft.measurements[id].fetchedTimestamp;
                    }
                }
                return draft;
            }

            case actionTypes.RECEIVED_MEASUREMENT_LOGS: {
                const { payload } = action as PayloadAction<ReceivedMeasurementLogsPayload>;
                const { measurementID, logs } = payload;

                setupMeasurement(draft, measurementID);
                draft.measurements[measurementID].logs = logs;
                draft.measurements[measurementID].fetchedTimestamp!.logs = Date.now();
                return draft;
            }

            case actionTypes.RECEIVED_MEASUREMENT_ATTACHMENTS: {
                const { payload } = action as PayloadAction<ReceivedMeasurementAttachmentsPayload>;
                const { measurementID, attachments } = payload;

                setupMeasurement(draft, measurementID);
                draft.measurements[measurementID].attachments = attachments;
                draft.measurements[measurementID].fetchedTimestamp!.attachments = Date.now();
                return draft;
            }

            case actionTypes.RECEIVED_HTML_MEASUREMENTS: {
                const { payload } = action as PayloadAction<ReceivedHTMLMeasurementsPayload>;
                const {
                    product,
                    data: { measurements },
                } = payload;

                const groupedMeasurements = groupBy(measurements, (measurement) => measurement.folder.id);
                const folders = Object.keys(groupedMeasurements).map(
                    (folderID) => groupedMeasurements[folderID][0].folder
                );

                // Setup folders
                setupProductData(draft, product);
                handleReceivedMeasurementFolders(draft, { product, folders });

                // Setup measurements
                Object.entries(groupedMeasurements).forEach(([folderID, myMeasurements]) => {
                    const transformedMeasurements = myMeasurements.map((measurement) => ({
                        id: measurement.measurement.id,
                        ...measurement,
                    }));
                    const { entityIds, entities } = normalizer(transformedMeasurements);
                    draft.products[product].measurementIDs![folderID] = entityIds;
                    for (const id of entityIds) {
                        draft.measurements[id] = entities[id];
                    }
                });
                return draft;
            }

            case actionTypes.RECEIVED_HTML_EXPORT_MEASUREMENTS: {
                const { payload } = action as PayloadAction<ReceivedHTMLExportPayload>;
                const { product, data } = payload;

                setupProductData(draft, product);

                const { entityIds, entities } = normalizer(
                    data.map((item) => {
                        return { id: item.measurement.id, ...item };
                    })
                );

                draft.products[product].measurementIDs![htmlViewExportFolder] = entityIds;

                for (const id of entityIds) {
                    const data = draft.measurements[id];
                    draft.measurements[id] = {
                        ...data,
                        ...entities[id],
                    };
                }
                return draft;
            }

            case actionTypes.IMPORT_MEASUREMENTS: {
                const { payload } = action as PayloadAction<ImportMeasurementsPayload>;
                const { product, measurements, jobID } = payload;
                const { entityIds, entities } = normalizer(measurements, 'newID');

                setupProductData(draft, product);
                draft.products[product].importIDs?.push(...entityIds);
                for (const id of entityIds) {
                    draft.importMeasurementsData[id] = entities[id];
                }
                draft.importInfo = {
                    jobID,
                    product,
                    totalCount: entityIds.length,
                    hasSuccessfulImport: false,
                };
                return draft;
            }

            case actionTypes.RECEIVED_IMPORT_MEASUREMENTS_STATUS: {
                const { payload } = action as PayloadAction<ReceivedImportMeasurementsStatusPayload>;
                const { product, measurements } = payload;
                let pendingImportsCount = 0;
                const successImportIDs = new Set<string>();

                // Handle the data based on the status
                measurements.forEach((importData) => {
                    const id = importData.newID;
                    if (!draft.importMeasurementsData[id]) {
                        return;
                    }
                    switch (importData.status) {
                        case 'success': {
                            delete draft.importMeasurementsData[id];
                            successImportIDs.add(id);
                            break;
                        }
                        case 'pending': {
                            pendingImportsCount++;
                            break;
                        }
                        case 'failure': {
                            draft.importMeasurementsData[id].status = 'failure';
                            break;
                        }
                    }
                });

                // Remove all the success imports
                if (successImportIDs.size > 0) {
                    setupProductData(draft, product);
                    draft.products[product].importIDs = draft.products[product].importIDs!.filter(
                        (id) => !successImportIDs.has(id)
                    );
                    draft.importInfo.hasSuccessfulImport = true;
                }

                // There are no pending imports, the whole job is completed.
                // Delete the jobID only to remove the polling.
                // Other info are needed for success message display and action.
                if (pendingImportsCount === 0) {
                    delete draft.importInfo.jobID;
                }
                return draft;
            }

            case actionTypes.REMOVE_IMPORT_MEASUREMENT: {
                const { payload } = action as PayloadAction<RemoveImportMeasurementPayload>;
                const { id, product } = payload;

                const importIDs = draft.products[product].importIDs;
                if (importIDs) {
                    const index = importIDs.findIndex((val) => val === id);
                    if (index !== -1) {
                        draft.products[product].importIDs!.splice(index, 1);
                    }
                }
                delete draft.importMeasurementsData[id];
                return draft;
            }

            default: {
                return draft;
            }
        }
    }, myInitialState);

const measurement = makeMeasurementReducer();

export default measurement;
