import { FormatIDs } from './reactIntl';

export enum CustomCurveFormFieldNames {
    minDistance = 'minDistance',
    maxDistance = 'maxDistance',
    minGain = 'minGain',
    maxGain = 'maxGain',
    transducerType = 'transducerType',
    deltaVk = 'deltaVk',
    effectiveDiameter = 'effectiveDiameter',
    effectiveWidth = 'effectiveWidth',
    effectiveLength = 'effectiveLength',
}

export const TRANSDUCER_FIELDS = [
    CustomCurveFormFieldNames.transducerType,
    CustomCurveFormFieldNames.effectiveDiameter,
    CustomCurveFormFieldNames.effectiveWidth,
    CustomCurveFormFieldNames.effectiveLength,
];

const COMMON_CURVE_REQUIRED_FIELDS = [
    CustomCurveFormFieldNames.minDistance,
    CustomCurveFormFieldNames.maxDistance,
    CustomCurveFormFieldNames.minGain,
    CustomCurveFormFieldNames.maxGain,
    CustomCurveFormFieldNames.transducerType,
];

export const CIRCULAR_REQUIRED_FIELDS = [
    ...COMMON_CURVE_REQUIRED_FIELDS,
    CustomCurveFormFieldNames.effectiveDiameter,
    CustomCurveFormFieldNames.deltaVk,
];

export const RECTANGULAR_REQUIRED_FIELDS = [
    ...COMMON_CURVE_REQUIRED_FIELDS,
    CustomCurveFormFieldNames.effectiveWidth,
    CustomCurveFormFieldNames.effectiveLength,
    CustomCurveFormFieldNames.deltaVk,
];

export const CUSTOM_CURVE_INPUT_INFO: Record<CustomCurveFormFieldNames, { label: FormatIDs; units?: string }> = {
    [CustomCurveFormFieldNames.minDistance]: { label: 'DataView.Upload.Inputs.MinDistance', units: 'mm' },
    [CustomCurveFormFieldNames.maxDistance]: { label: 'DataView.Upload.Inputs.MaxDistance', units: 'mm' },
    [CustomCurveFormFieldNames.minGain]: { label: 'DataView.Upload.Inputs.MinGain', units: 'dB' },
    [CustomCurveFormFieldNames.maxGain]: { label: 'DataView.Upload.Inputs.MaxGain', units: 'dB' },
    [CustomCurveFormFieldNames.deltaVk]: { label: 'DataView.Upload.Inputs.DeltaVk', units: 'dB' },
    [CustomCurveFormFieldNames.transducerType]: { label: 'App.Type' },
    [CustomCurveFormFieldNames.effectiveDiameter]: { label: 'DataView.Upload.Inputs.EffectiveDiameter', units: 'mm' },
    [CustomCurveFormFieldNames.effectiveWidth]: { label: 'DataView.Upload.Inputs.EffectiveWidth', units: 'mm' },
    [CustomCurveFormFieldNames.effectiveLength]: { label: 'DataView.Upload.Inputs.EffectiveLength', units: 'mm' },
};

export enum TransducerType {
    CIRCULAR_CRYSTAL = 'circularCrystal',
    RECTANGULAR_CRYSTAL = 'rectangularCrystal',
}

export const TRANSDUCER_TYPE_MAP: Record<TransducerType, FormatIDs> = {
    [TransducerType.CIRCULAR_CRYSTAL]: 'DataView.Upload.Inputs.Transducer.CircularCrystal',
    [TransducerType.RECTANGULAR_CRYSTAL]: 'DataView.Upload.Inputs.Transducer.RectangularCrystal',
};

export const SmoothenedCurveTransducerType: Record<TransducerType, number> = {
    [TransducerType.CIRCULAR_CRYSTAL]: 1,
    [TransducerType.RECTANGULAR_CRYSTAL]: 2,
};

export interface CustomCurveProbeInfo {
    minDistance: number;
    maxDistance: number;
    minGain: number;
    maxGain: number;
    deltaVk: number;
    transducerType: string | number;
    effectiveDiameter?: number;
    effectiveWidth?: number;
    effectiveLength?: number;
}

export enum ReadingsType {
    CSVSmoothened = 'CSVSmoothened',
    CSVOriginal = 'CSVOriginal',
    PhotoColor = 'PhotoColor',
    PhotoBW = 'PhotoBW',
    YAML = 'yaml',
}
