export interface EagleCloudUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    countryCode: string;
    companyName: string;
    verified: boolean;
    active: boolean;
    created: number;
    updated: number;
    phoneNumber: string;
    postcode: string;
    address: string;
    '2fa': boolean;
}

export interface PictureInfo {
    pictureURL: string;
}

export type ProceqUser = {
    companyName?: string;
    companySize?: string;
    companyWebSite?: string;
    countryCode?: string;
    created?: string;
    emailVerified?: number;
    firstName: string;
    id: number;
    industry?: string;
    jobTitle?: string;
    lastName: string;
    newsletter?: number;
    passwordUpdatedTime?: number;
    profilePicture?: string;
    roles?: string;
    updated?: string;
    username?: string;
};

export interface UserProfileInfo extends EagleCloudUser, PictureInfo {}

export enum AccountDeleteStatus {
    doesNotExist,
    pendingConfirmation,
    confirmed,
    completed,
}

export interface UserAccountDeleteInfo {
    id: string;
    userId: number;
    status: AccountDeleteStatus;
    created: number;
    updated: number;
}
