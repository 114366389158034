import React from 'react';
import { Button, Dropdown } from 'antd';
import FormattedMessage from '../../../localization/FormatMessage';
import { ReactComponent as IconSort } from '../../../images/iconSort.svg';
import { FormatIDs } from '../../../types';
import { ReactComponent as TickIcon } from '../../../images/iconTick.svg';
import styles from './SortFolder.styl';
import { Folder } from '../../../types/measurement';
import { AntdMenuItem } from '../../../types/antd';

export enum SortOption {
    dateCreated = 'dateCreated',
    dateModified = 'dateModified',
    nameAscending = 'nameAscending',
    nameDescending = 'nameDescending',
}

const SORT_OPTIONS_ARRAY = [
    SortOption.dateCreated,
    SortOption.dateModified,
    SortOption.nameAscending,
    SortOption.nameDescending,
];

export const SORT_OPTION_MAP: Record<SortOption, { name: FormatIDs; sort: (a: Folder, b: Folder) => number }> = {
    [SortOption.dateCreated]: {
        name: 'DataView.Folder.Sort.DateCreated',
        sort: (a, b) => b.created - a.created,
    },
    [SortOption.dateModified]: {
        name: 'DataView.Folder.Sort.DateModified',
        sort: (a, b) => b.updated - a.updated,
    },
    [SortOption.nameAscending]: {
        name: 'DataView.Folder.Sort.NameAscending',
        sort: (a, b) => a.name.localeCompare(b.name),
    },
    [SortOption.nameDescending]: {
        name: 'DataView.Folder.Sort.NameDescending',
        sort: (a, b) => b.name.localeCompare(a.name),
    },
};

interface SortFolderProps {
    selectedSortOption: SortOption;
    onChange: (sortOption: SortOption) => void;
}

const SortFolder: React.FunctionComponent<SortFolderProps> = (props) => {
    const { selectedSortOption, onChange } = props;

    const menuItems: AntdMenuItem[] = SORT_OPTIONS_ARRAY.map((option) => {
        return {
            key: option,
            label: (
                <div className={styles.menu_item}>
                    <div className={styles.tick}>{option === selectedSortOption && <TickIcon />}</div>
                    <FormattedMessage id={SORT_OPTION_MAP[option].name} />
                </div>
            ),
        };
    });

    return (
        <Dropdown
            trigger={['click']}
            menu={{ onClick: ({ key }) => onChange(key as SortOption), items: menuItems }}
            placement="bottomRight"
        >
            <Button type="link" className={styles.button}>
                <IconSort />
            </Button>
        </Dropdown>
    );
};

export default SortFolder;
