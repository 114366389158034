import React from 'react';
import { FormatIDs } from '../../../../../types';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';

const COMMENT_TYPE_MAP: Record<number, FormatIDs> = {
    0: 'App.Logbook.PI8000.AddSpotCommentText',
    1: 'App.Logbook.PI8000.AddSpotCommentAudio',
    2: 'App.Logbook.PI8000.AddSpotCommentPhoto',
};

const COMMENT_PILE_SPOT: Record<string, FormatIDs> = {
    addSpotComment: 'App.Logbook.PI8000.AddSpotComment',
    addPileComment: 'App.Logbook.PI8000.AddPileComment',
};

const AddPileSpotComment: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const content = log.content;
    return (
        <div>
            <FormattedMessage
                id={COMMENT_PILE_SPOT[log.type]}
                values={{
                    item: COMMENT_TYPE_MAP[content.commentType] ? (
                        <FormattedMessage id={COMMENT_TYPE_MAP[content.commentType]} />
                    ) : (
                        ''
                    ),
                    name: content.pileName ?? content.spotName,
                }}
            />
        </div>
    );
};

export default {
    element: AddPileSpotComment,
};
