import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const UpdateStatus: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.LogbookEntryUpdateStatus"
        values={{
            item: log.content.item,
            status: log.content.status,
        }}
    />
);

export default {
    element: UpdateStatus,
};
