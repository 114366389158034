import React from 'react';
import { FormatIDs } from '../../types';
import { intlProxy } from '../../localization/IntlProxy';

interface MultipleFailureMessageProps {
    title: FormatIDs;
    failures: { id: string; reason: string; name: string }[];
}

const MultipleFailureMessage: React.FunctionComponent<MultipleFailureMessageProps> = (props) => {
    const { failures, title } = props;
    const formatMessage = intlProxy.formatMessage;
    return (
        <span>
            {formatMessage({ id: title })}
            <ul style={{ textAlign: 'left' }}>
                {failures.map((failure) => (
                    <li key={failure.id}>
                        {failure.name}: {failure.reason}
                    </li>
                ))}
            </ul>
        </span>
    );
};

export default MultipleFailureMessage;
