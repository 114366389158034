import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';

enum CoverChangedType {
    maxCoverChanged = 'maxCoverChanged',
    minCoverChanged = 'minCoverChanged',
}

const COVER_CHANGED_TEXT: Record<CoverChangedType, FormatIDs> = {
    [CoverChangedType.minCoverChanged]: 'App.HTML.Profometer.Logbook.CoverChanged.Minimum',
    [CoverChangedType.maxCoverChanged]: 'App.HTML.Profometer.Logbook.CoverChanged.Maximum',
};

const CoverChanged: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id={COVER_CHANGED_TEXT[log.type as CoverChangedType]}
            values={{ oldValue: log.content.oldValue, newValue: log.content.newValue }}
        />
    );
};

export default {
    element: CoverChanged,
};
