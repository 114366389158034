import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const ArchiveFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id={log.content.status === true ? 'Proceq.LogbookEntryArchived' : 'Proceq.LogbookEntryUnarchived'}
    />
);

export const color = () => 'grey';

export default {
    element: ArchiveFile,
    color,
};
