import React, { useCallback, useState } from 'react';
import { Spin } from 'antd';
import JSZip from 'jszip';
import { find } from 'lodash';
import styles from './Attachment.styl';
import { getFile } from '../../api/utilsService';
import FormattedMessage from '../../localization/FormatMessage';
import useCheckInViewPort, { nullCallback } from '../../hooks/useCheckInviewPort';
import AuthButton from '../AnalyticsComponents/Button';
import { useMeasurementViewerContext } from '../StandaloneMeasurementView/MeasurementViewerContext';

interface AudioProps {
    mID: string;
    aID: string;
    attachmentName?: string;
    download?: boolean;
    getScrollParent?: () => HTMLElement | null;
}

export const Audio: React.FunctionComponent<AudioProps> = (props) => {
    const { mID, aID, attachmentName, download = true } = props;
    const { isStandalone, isHTMLExport } = useMeasurementViewerContext();
    const getScrollParent = isStandalone ? nullCallback : props.getScrollParent;

    const [loading, setLoading] = useState(true);
    const [audio, setAudio] = useState<string | undefined>(() => {
        if (!isHTMLExport) return undefined;
        return `media/${aID}.m4a`;
    });
    const [filename, setFilename] = useState<string | undefined>(() => {
        if (!isHTMLExport) return undefined;
        return `${aID}.m4a`;
    });

    const fetchData = useCallback(() => {
        if (isHTMLExport) {
            setLoading(false);
            return;
        }

        getFile({ mID, aID })
            .then((arrayBuffer) => JSZip.loadAsync(arrayBuffer))
            .then((zip) => {
                const audio = find(zip.files, (_file, fileName: string) => /\.(m4a|aac)/.test(fileName));
                if (audio) {
                    audio.async('blob').then((blob) => {
                        const audioURL = window.URL.createObjectURL(blob);
                        setAudio(audioURL);
                        setFilename(audio.name || attachmentName);
                    });
                } else {
                    throw new Error('No .m4a or .aac file in archive.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, [aID, attachmentName, mID, isHTMLExport]);

    const { ref } = useCheckInViewPort(fetchData, getScrollParent);

    return (
        <div className={styles.attachment_container} ref={ref}>
            {loading ? (
                <Spin className={styles.loading} />
            ) : (
                <>
                    {!!audio && (
                        <audio controls controlsList="nodownload" style={{ marginRight: 24 }}>
                            <source src={audio} type="audio/mp4" />
                            <FormattedMessage id="Proceq.BrowserNoSupportAudio" />
                        </audio>
                    )}

                    {filename && download && (
                        <AuthButton className={styles.audio_download} href={audio} download={filename}>
                            <FormattedMessage id="App.Download" />
                        </AuthButton>
                    )}
                </>
            )}
        </div>
    );
};

export default Audio;
