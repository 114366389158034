import { has } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';

export const Measurement: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    const formatMessage = useFormatMessage();
    const unit = log.content.unit;
    return (
        <div>
            {log.content.measurements.map(
                (
                    value: { index: number; valueFor20: number; valueFor60: number; valueForH: number },
                    index: number
                ) => {
                    const logIds = [];
                    if (has(value, 'valueFor20')) {
                        logIds.push(
                            formatMessage(
                                { id: 'App.Logbook.GLM.AngleChanged.Individual' },
                                { degree: '20°', value: value.valueFor20, unit }
                            )
                        );
                    }
                    if (has(value, 'valueFor60')) {
                        logIds.push(
                            formatMessage(
                                { id: 'App.Logbook.GLM.AngleChanged.Individual' },
                                { degree: '60°', value: value.valueFor60, unit }
                            )
                        );
                    }
                    if (has(value, 'valueForH')) {
                        const valueForH = value.valueForH;
                        if (valueForH >= 0 && log.content.unit === 'GU') {
                            logIds.push(
                                formatMessage(
                                    { id: 'App.Logbook.GLM.AngleChanged.Individual' },
                                    { degree: 'H', value: valueForH, unit: 'HU' }
                                )
                            );
                        }
                    }
                    return (
                        <div key={index}>
                            <FormattedMessage
                                id="App.Logbook.GLM.AngleChanged"
                                values={{
                                    index: value.index + 1,
                                    anglesChanged: logIds.join(' / '),
                                }}
                            />
                            <br />
                        </div>
                    );
                }
            )}
        </div>
    );
};

export const color = () => 'blue';

export default {
    element: Measurement,
    color,
};
