import { useResizeDetector } from 'react-resize-detector';
import React, { useEffect, useState } from 'react';
import styles from './Dashboard.styl';

interface DashboardContainerProps {
    mainColumn: React.ReactNode;
    sideColumn: React.ReactNode;
}

const DashboardContainer: React.FunctionComponent<DashboardContainerProps> = (props) => {
    const [paddingOffset, setPaddingOffset] = useState<number>(0);
    const { height: containerHeight, ref: containerElem } = useResizeDetector<HTMLDivElement>();

    useEffect(() => {
        const elem = containerElem.current;
        if (elem) {
            const offset = elem.offsetWidth - elem.clientWidth;
            setPaddingOffset(offset);
        }
    }, [containerHeight, containerElem]);

    return (
        <div className={styles.dashboard_container} style={{ paddingRight: 12 - paddingOffset }} ref={containerElem}>
            <div className={styles.column_wide}>{props.mainColumn}</div>
            <div className={styles.column_narrow}>{props.sideColumn}</div>
        </div>
    );
};

export default DashboardContainer;
