import React from 'react';
import { useParams } from 'react-router';
import FullMeasurementViewer from './FullMeasurementViewer';

const StandaloneMeasurementView: React.FunctionComponent = () => {
    const { measurementID } = useParams<{ measurementID: string }>();

    return measurementID ? (
        <div style={{ display: 'flex', width: '100%' }}>
            <FullMeasurementViewer measurementID={measurementID} isHTMLExport countryCode={window.countryCode} />
        </div>
    ) : null;
};

export default StandaloneMeasurementView;
