import { useEffect, useRef } from 'react';

export default function useAtScrollBottom(
    listContainer: React.MutableRefObject<HTMLElement | null>,
    action: () => void | Promise<any>,
    isEnable: boolean = true
) {
    const isPerformingAction = useRef(false);

    useEffect(() => {
        const containerElement = listContainer.current;
        if (!containerElement || !isEnable) {
            return () => {};
        }

        const handleScroll = () => {
            // Refer https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#determine_if_an_element_has_been_totally_scrolled
            if (
                containerElement.scrollHeight - Math.abs(containerElement.scrollTop) <= containerElement.clientHeight &&
                !isPerformingAction.current
            ) {
                isPerformingAction.current = true;
                const onFinish = () => {
                    isPerformingAction.current = false;
                };
                const promise = action();

                if (promise) {
                    promise.finally(onFinish);
                } else {
                    onFinish();
                }
            }
        };

        handleScroll();
        containerElement.addEventListener('scroll', handleScroll);
        return () => {
            containerElement.removeEventListener('scroll', handleScroll);
        };
    }, [action, isEnable, listContainer]);
}
