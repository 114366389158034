import { useContext, createContext } from 'react';
import { noop } from 'lodash';

export interface MeasurementViewerContextState {
    isStandalone: boolean;
    isHTMLExport: boolean;
    isHTMLView: boolean;
    countryCode?: string;
    setViewerReady: () => void;
}

const MeasurementViewerContext = createContext<MeasurementViewerContextState>({
    isStandalone: false,
    isHTMLExport: false,
    isHTMLView: false,
    setViewerReady: noop,
});

export function useMeasurementViewerContext() {
    return useContext(MeasurementViewerContext);
}

export default MeasurementViewerContext;
