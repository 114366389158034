import React from 'react';
import { get, noop } from 'lodash';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { ReactComponent as IconInfo } from '../../../../../images/iconAbout.svg';
import styles from './ObjectMarker.styl';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import { classNames } from '../../../../../utils/styleUtils';
import { FormatIDs } from '../../../../../types';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { iconMap as tagNameMap } from '../LogEventInfo';

const iconInfo = <IconInfo className={styles.info_icon} />;

export const ObjectMarker: React.FunctionComponent<
    GPRLogbookEntryProps & {
        highlighted?: boolean;
        toggleHighlight?: () => void;
    }
> = ({ log, isMetric, scanType, highlighted, toggleHighlight }) => {
    const { status, sequence, lineId, type, changes } = log.content;
    const size = get(changes, 'sizeCentimeter');
    const depth = get(changes, 'calibratedDepthString');

    let id: FormatIDs;
    switch (status) {
        case 'added':
            id = lineId ? 'Proceq.LogbookAddedAt' : 'Proceq.LogbookAdded';
            break;
        case 'edited':
            id = lineId ? 'Proceq.LogbookEditedAt' : 'Proceq.LogbookEdited';
            break;
        case 'deleted':
            id = lineId ? 'Proceq.LogbookDeletedAt' : 'Proceq.LogbookDeleted';
            break;
        default:
            return null;
    }

    return (
        <div
            className={classNames(
                styles.message_container,
                status !== 'deleted' && styles.highlightable,
                status !== 'deleted' && 'highlightable', // For HTML export
                highlighted && styles.highlighted
            )}
            onClick={status === 'deleted' ? noop : toggleHighlight}
            role="button"
            tabIndex={0}
        >
            <b>
                <FormattedMessage
                    id={id}
                    values={{
                        sequence,
                        lineId,
                    }}
                />
            </b>
            <br />
            {status === 'added' && (
                <FormattedMessage
                    id="Proceq.LogbookType"
                    values={{
                        type: tagNameMap[type] ? <FormattedMessage id={tagNameMap[type].text} /> : type,
                    }}
                />
            )}
            {status === 'edited' && !changes && <FormattedMessage id="Proceq.LogbookPositionMoved" />}
            {scanType !== undefined && isMetric !== undefined && (
                <>
                    {size && (
                        <div>
                            <ConvertedUnits
                                id="Proceq.LogbookSetSize"
                                unitId="GPR.Logbook Panel.Set Size"
                                scanType={scanType}
                                isMetric={isMetric}
                                values={[size]}
                            />
                        </div>
                    )}
                    {depth && (
                        <div>
                            <ConvertedUnits
                                id="Proceq.LogbookSetDepth"
                                unitId="GPR.Logbook Panel.Set Depth"
                                scanType={scanType}
                                isMetric={isMetric}
                                values={[depth]}
                            />
                        </div>
                    )}
                </>
            )}
            {status !== 'deleted' && iconInfo}
        </div>
    );
};

export const color = (log: Log) => (log.content.status === 'deleted' ? 'red' : 'green');

export default {
    element: ObjectMarker,
    color,
};
