import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { color } from './MarkerAudio';

const UTILITY_SEQUENCE_REGEX = /(\d+$)/;

const UtilityLine: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const isAdded = log.content.status === 'added';
    const sequenceNo = log.content.sequence.match(UTILITY_SEQUENCE_REGEX);
    return (
        <>
            <b>
                <FormattedMessage
                    id={isAdded ? 'Proceq.LogbookUtilityLineAdded' : 'Proceq.LogbookUtilityLineDeleted'}
                    values={{ sequenceNo: sequenceNo ? sequenceNo[0] : '' }}
                />
            </b>
            {isAdded && (
                <>
                    <br />
                    <FormattedMessage
                        id="Proceq.LogbookType"
                        values={{
                            type: log.content.type,
                        }}
                    />
                </>
            )}
        </>
    );
};

export default {
    element: UtilityLine,
    color,
};
