import React from 'react';
import { toPlainObject } from 'lodash';
import { convertAndDisplay } from '../utils/conversionRates';
import { ScanType } from '../../../../types/proceq';
import FormattedMessage from '../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../types';

export const ConvertedUnits = ({
    id,
    unitId,
    scanType,
    isMetric,
    values = [],
}: {
    id: FormatIDs;
    unitId: string;
    scanType: ScanType;
    isMetric: boolean | string;
    values?: number[];
}) => {
    const { convertFunction, unit } = convertAndDisplay(unitId, scanType, isMetric);

    const replacement = {
        ...toPlainObject(values.map(Number).map(convertFunction)),
        unit,
    };

    return <FormattedMessage id={id} values={replacement} />;
};

export default ConvertedUnits;
