import React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../DataViewers.styl';
import {
    MeasurementFullData,
    MeasurementObject,
    MeasurementSettings,
    TagObjectType,
} from '../../../../../types/measurement';
import { Log } from '../../../../../types/logs';
import { DerivedProps } from '../../SharedTypes';
import { FormatIDs } from '../../../../../types';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { ScanType } from '../../../../../types/proceq';

export const calculateTransmissionTime = (object: MeasurementObject, settings: MeasurementSettings[]) => {
    const contentProcess = settings[0].content.process;
    const totalScanDepthInTime: number | undefined = contentProcess.sampleParameters?.scanDepthInTimeMicrosecond;
    const depthPercentage: number | undefined = object.content.location.depthPercentage;
    const signalZeroOffset: number = contentProcess.signalZeroOffset || 0;
    if (!depthPercentage || !totalScanDepthInTime) {
        return -1;
    }
    return totalScanDepthInTime * depthPercentage - signalZeroOffset;
};

const calculateTagDepth = (object: MeasurementObject, settings: MeasurementSettings[]) => {
    const contentProcess = settings[0].content.process;
    const totalScanDepthInTime: number | undefined = contentProcess.sampleParameters?.scanDepthInTimeMicrosecond;
    const totalScanDepthInDis: number | undefined = contentProcess.sampleParameters?.scanDepthInDistanceMeter;
    const signalZeroOffset: number = contentProcess.signalZeroOffset || 0;
    const tagDepth = object.content.location.depthPercentage;
    if (!totalScanDepthInDis || !totalScanDepthInTime || !tagDepth) {
        return -1;
    }
    const truncatedDepth = (totalScanDepthInDis * signalZeroOffset) / totalScanDepthInTime;
    return totalScanDepthInDis * tagDepth - truncatedDepth;
};

export type MeasurementObjectWithInfo = MeasurementObject & {
    logContent?: Log['content'];
};

export const title = <FormattedMessage id="App.HTML.GPR.Objects" />;

export type ObjectColumnType = {
    title: FormatIDs;
    dataIndex?: string | string[];
    unitId?: string;
}[];

export const Objects: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    product,
    isMetric,
    convert,
    showTitle,
}) => {
    // we only want to use the most recent log entry
    const sortedLogs = data.logs.slice().sort((a, b) => b.clientCreated - a.clientCreated);
    const calibratedObjectID = data.measurement.content.tagMetaData?.calibratedObjectId;
    const calibratedDepthLog = sortedLogs.find((l) => l.content.changes?.calibratedDepthString);
    const setDepth = calibratedDepthLog ? +calibratedDepthLog.content.calibratedDepthString : null;

    const isGridScan = data.measurement.type === ScanType.GridScan;

    const objects: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'object' && object.content.type !== TagObjectType.Velocity)
        .map((object) => {
            const extraKeys: {
                setDepth?: number | string;
                calculatedDepth?: number | string;
                gridCell?: string;
                transmissionTime?: number | string;
            } = {};

            const reading = isGridScan ? data.readings.find((reading) => reading.id === object.rID) : undefined;
            if (reading) {
                extraKeys.gridCell =
                    String.fromCharCode('A'.charCodeAt(0) + reading.content.columnIndex) +
                    (reading.content.rowIndex + 1);
            }

            const transmissionTime = isGridScan ? calculateTransmissionTime(object, data.settings) : undefined;
            if (transmissionTime !== undefined) {
                extraKeys.transmissionTime = transmissionTime < 0 ? '-' : transmissionTime;
            }

            if (object.content.id === calibratedObjectID) {
                // user calibrated depth
                extraKeys.setDepth = setDepth ?? '-';
            } else {
                const calculatedTagDepth = calculateTagDepth(object, data.settings);
                extraKeys.calculatedDepth = calculatedTagDepth < 0 ? '-' : calculatedTagDepth;
            }

            return {
                ...extraKeys,
                ...object,
            };
        })
        .sort((a, b) => a.content.number - b.content.number);

    const gridScanColumns: ObjectColumnType = isGridScan
        ? [
              {
                  title: 'App.HTML.PUNDIT.GridCell',
                  dataIndex: 'gridCell',
              },
              {
                  title: 'App.HTML.PUNDIT.TransmissionTime',
                  dataIndex: 'transmissionTime',
                  unitId: `${product}.GridScan.TransmissionTime`,
              },
          ]
        : [];

    const columnsRaw: ObjectColumnType = [
        {
            title: 'App.HTML.GPR.Objects.TagNumber',
            dataIndex: ['content', 'number'],
        },
        {
            title: 'App.HTML.GPR.Objects.TagType',
            dataIndex: ['content', 'type'],
        },
        ...gridScanColumns,
        {
            title: 'App.HTML.GPR.Objects.DistanceX',
            unitId: `${product}.CSV.Tag objects distance X`,
            dataIndex: ['content', 'location', 'distanceMeter'],
        },
        {
            title: 'App.HTML.GPR.Objects.DistanceY',
            unitId: `${product}.CSV.Tag Object Distance Y`,
        },
        {
            title: 'Proceq.LogbookSettingsCalculatedDepth',
            unitId: `${product}.Logbook Panel.Calculated Depth`,
            dataIndex: 'calculatedDepth',
        },
        {
            title: 'Proceq.LogbookSettingsSetDepth',
            unitId: `${product}.Logbook Panel.Set Depth`,
            dataIndex: 'setDepth',
        },
        {
            title: 'Proceq.LogbookSettingsSetSize',
            unitId: `${product}.Logbook Panel.Set Size`,
            dataIndex: ['content', 'sizeMeter'],
        },
        {
            title: 'Proceq.LogbookSettingsComment',
            dataIndex: ['content', 'comment'],
        },
        {
            title: 'Proceq.LogbookSettingsName',
            dataIndex: 'uID',
        },
    ];

    return (
        <div className="table-objects">
            <Table
                title={showTitle ? () => <span className={styles.main_header}>{title}</span> : undefined}
                className={styles.table}
                columns={columnsRaw.map((columnConfig) => ({
                    render: (text) => (columnConfig.unitId ? convert(text, columnConfig.unitId) : text),
                    ...columnConfig,
                    key: columnConfig.title,
                    title: columnConfig.unitId ? (
                        <ConvertedUnits
                            id={columnConfig.title}
                            unitId={columnConfig.unitId}
                            scanType={scanType}
                            isMetric={isMetric}
                        />
                    ) : (
                        <FormattedMessage id={columnConfig.title} />
                    ),
                }))}
                dataSource={objects}
                pagination={false}
                size="small"
                locale={tableLocaleSetting}
                rowKey="id"
            />
        </div>
    );
};

export default Objects;
