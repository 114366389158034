import compareVersions from 'compare-versions';
import { MeasurementFullData } from '../../../types/measurement';

const PD8050InitialVersion = '5.0.0';

export const isEqualOrAfterPD8050Launch = (version: string) => {
    return compareVersions.compare(PD8050InitialVersion, version, '<=');
};

const HIGH_DEFINITION_FOCUS_CHANGE = '5.3.0';

export const isBeforeHDFRemoval = (version: string) => {
    return compareVersions.compare(HIGH_DEFINITION_FOCUS_CHANGE, version, '>');
};

export const isProContractType = (data: MeasurementFullData) => {
    const logs = data.logs;
    if (logs === undefined) {
        return false;
    }
    const probeLog = logs && logs.find((log) => log.type === 'probe');
    if (probeLog?.content?.contractType) {
        return probeLog.content.contractType.toLowerCase().includes('pro');
    }
    return false;
};
