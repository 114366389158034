import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import colors from '../../styles/colors.json';

const Tooltip: React.FunctionComponent<TooltipProps> = (props) => {
    return (
        <AntdTooltip color={colors.gray100} {...props}>
            {props.children}
        </AntdTooltip>
    );
};

export default Tooltip;
