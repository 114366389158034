import React, { useEffect } from 'react';
import { App } from 'antd';
import { eventPublisher } from 'tds-common-fe';
import { useNavigate } from 'react-router-dom';
import { PublisherEvent } from '../../types/eventPublisher';

/*
    This is the workaround to use methods that require being within a react component.
    Use eventPublisher.publish to publish events that will be subscribed to from here.
 */
const EventSubscriberWrapper: React.FunctionComponent = () => {
    const { message } = App.useApp();
    const navigate = useNavigate();

    // Antd removed message's static method https://ant.design/docs/blog/why-not-static
    useEffect(() => {
        eventPublisher.subscribe(PublisherEvent.ANTD_ERROR_MESSAGE, (text: string) => message.error(text));
    }, [message]);

    // react-router-dom v6 doesnt natively support accessing history outside of a react component https://github.com/remix-run/react-router/issues/8264#issuecomment-991271554
    useEffect(() => {
        eventPublisher.subscribe(PublisherEvent.NAVIGATE, (url: string) => navigate(url));
    }, [navigate]);

    return null;
};

export default EventSubscriberWrapper;
