import React from 'react';
import { classNames } from '../../../utils/styleUtils';
import { ReactComponent as IconArrowDown } from '../../../images/iconArrowDown.svg';
import styles from './ExpandButton.styl';
import Tooltip from '../Tooltip';
import { useFormatMessage } from '../../../localization/useFormatMessage';

interface ExpandButtonProps {
    isActive: boolean;
    className?: string;
    onClick?: (isActive: boolean) => void;
}

const ExpandButton: React.FunctionComponent<ExpandButtonProps> = (props) => {
    const { isActive, className, onClick } = props;
    const formatMessage = useFormatMessage();
    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
                onClick?.(isActive);
            }}
            className={className || styles.expand_icon}
        >
            <Tooltip title={formatMessage({ id: isActive ? 'DataView.Collapse' : 'DataView.Expand' })}>
                <IconArrowDown className={classNames(isActive && styles.flip)} />
            </Tooltip>
        </div>
    );
};

export default ExpandButton;
