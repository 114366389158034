import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';

const ConvertUnit: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const { settingName, new: newValue, old: oldValue } = log.content;
    return <FormattedMessage id="App.Logbook.Pundit.ConvertUnit" values={{ settingName, newValue, oldValue }} />;
};

export default {
    element: ConvertUnit,
};
