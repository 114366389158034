import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import { FormatIDs } from '../../../../../types';
import { FDL_METRIC_UNITS } from '../../../../../types/measurement';

enum EncoderTypes {
    quadrature = 'Quadrature',
}

const EncoderTypesFormatId: Record<EncoderTypes, FormatIDs> = {
    [EncoderTypes.quadrature]: 'App.Logbook.FDL.EncoderCalibrated.EncoderType.Quadrature',
};

const EncoderCalibration: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const { content } = log;
    const resolutionConvertor = convertAndDisplay(
        'FDL.CSCApplied.Radius',
        scanType ?? ScanType.Indication,
        FDL_METRIC_UNITS.has(content.encoderInfo?.unit ?? 'metric')
    );
    const resolution = content.encoderInfo?.resolution ?? 0;
    const encoderType = Object.values(EncoderTypes).includes(content.encoderInfo?.type) ? (
        <FormattedMessage id={EncoderTypesFormatId[content.encoderInfo.type as EncoderTypes]} />
    ) : (
        content.encoderInfo?.type
    );

    return (
        <div>
            <b>
                <FormattedMessage id="App.Logbook.FDL.EncoderCalibrated" />
            </b>
            <br />
            <FormattedMessage
                id="App.Logbook.FDL.EncoderCalibrated.Encoder"
                values={{ value: content.encoderInfo?.name }}
            />
            <br />
            <FormattedMessage
                id="App.Logbook.FDL.EncoderCalibrated.Axis"
                values={{ value: content.encoderInfo?.axis }}
            />
            <br />
            <FormattedMessage id="App.Logbook.FDL.EncoderCalibrated.EncoderType" values={{ value: encoderType }} />
            <br />
            <FormattedMessage
                id="App.Logbook.FDL.EncoderCalibrated.EncoderResolution"
                values={{ value: resolutionConvertor.convertFunction(resolution), unit: resolutionConvertor.unit }}
            />
            <br />
        </div>
    );
};

export default {
    element: EncoderCalibration,
    color: () => 'green',
};
