import { get, identity, isFinite } from 'lodash';
import { ProductCode, ScanType } from '../../../../types/proceq';
import { getUnitDocumentation } from '../../UnitDocumentions';

export const roundToNDigit = (d: number, n: number) => (Math.round(d * 10 ** n) / 10 ** n).toFixed(n);

const ftin = (v: number, n: number = 1): string => {
    let ft = v * 3.28084;
    const inches = roundToNDigit((ft % 1) * 12, n);
    ft = Math.floor(ft);
    return ft > 0 ? `${ft}' ${inches}"` : `${inches}"`;
};

const rates = {
    m: (v: number, n: number = 2): string => `${roundToNDigit(v, n)}`, // x.yz
    cm: (v: number, n: number = 0): string => `${roundToNDigit(v * 100, n)}`, // xy
    mm: (v: number, n: number = 0): string => `${roundToNDigit(v * 1000, n)}`, // xyz
    ft: (v: number, n: number = 2): string => `${roundToNDigit(v * 3.28084, n)}'`, // x.yz'
    in: (v: number, n: number = 1): string => `${roundToNDigit(v * 39.3701, n)}"`, // x.y"
    inch: (v: number, n: number = 1): string => `${roundToNDigit(v * 39.3701, n)}`, // x.y"
    // dB is dimensionless, so no unit. same for scan.
    'dB/in': (v: number, n: number = 1): string => `${roundToNDigit(v / 39.3701, n)}`, // x.y
    'dB/inch': (v: number, n: number = 1): string => `${roundToNDigit(v / 39.3701, n)}`, // x.y
    // we are converting from scan/cm -> scan/in.
    'scan/in': (v: number, n: number = 1): string => `${roundToNDigit((v * 100) / 39.3701, n)}`, // x.y
    'ft/s': (v: number, n: number = 1): string => `${roundToNDigit(v * 3.28084, n)}`, // x.y
    ftin,
    inft: ftin,
    bytes: (v: number): string => {
        switch (String(v).length) {
            case 1:
            case 2:
            case 3:
                return `${v} B`;
            case 4:
            case 5:
            case 6:
                return `${roundToNDigit(v / 1000, 1)} KB`;
            case 7:
            case 8:
            case 9:
                return `${roundToNDigit(v / 1000 / 1000, 1)} MB`;
            default:
                return `${roundToNDigit(v / 1000 / 1000 / 1000, 1)} GB`;
        }
    },
    megabytes: (v: number): string => `${roundToNDigit(v / 1000 / 1000, 1)} MB`,
    Channels: (v: number, n: number = 0): string => `${roundToNDigit(v / 3, n)}`,
};

export { rates };

export const convert = (v: number, n: number, unit: keyof typeof rates) => {
    if (!Number.isFinite(v)) {
        return '';
    }

    if (rates[unit]) {
        return rates[unit](v, n);
    }

    return roundToNDigit(v, n);
};

export const displayUnit = (str: string) => {
    if (str === 'ftin') {
        return 'ft';
    }

    if (str === 'inft') {
        return 'in';
    }

    return str;
};

export const convertAndDisplay = (unitId: string, scanType: ScanType, unitType: string | boolean) => {
    const unitDocumentation = getUnitDocumentation(scanType);
    let units = get(unitDocumentation, unitId);
    if (!units && unitId.includes(ProductCode.GPR_SOIL)) {
        unitId = unitId.replace(ProductCode.GPR_SOIL, ProductCode.GPR);
        units = get(unitDocumentation, unitId);
    }
    const defaultRoundNumber = (value: number | string) => value;

    if (!units) {
        // eslint-disable-next-line no-console
        console.error(`No entry found for ${unitId} in ${scanType} scan documentation`);
        return {
            unit: '',
            convertFunction: () => '-',
            roundNumber: defaultRoundNumber,
        };
    }

    // previously, unitType argument was isMetric
    if (typeof unitType === 'boolean') {
        unitType = unitType ? 'metric' : 'imperial';
    }

    // if no entry for imperial/time, then use metric
    const unitRef = get(units, unitType) || units.metric;
    if (unitRef) {
        return {
            unit: displayUnit(unitRef.unit),
            convertFunction: (value: number) =>
                convert((value * (unitRef.multiplier || 1)) / (unitRef.divider || 1), unitRef.digits, unitRef.unit),
            roundNumber: (value: number | string) => {
                if (isFinite(Number(value))) {
                    return roundToNDigit(+value, unitRef.digits);
                }
                return value;
            },
        };
    }

    return {
        unit: '',
        convertFunction: identity,
        roundNumber: defaultRoundNumber,
    };
};
