import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { Log } from '../../../../../types/logs';
import { ContractExpirationDate } from '../../utils/LogDate';
import { renderLogbookDate } from '../../../../../utils/dateUtils';
import { getProbeName } from '../../../../../hooks/useProbes';
import { ProductModel } from '../../../../../types/proceq';
import ProbeCommonContent from '../ProbeCommonContent';

export const GLMProbeContent: React.FunctionComponent<{
    content: Log['content'];
    productModel: ProductModel;
}> = (props) => {
    const { content, productModel } = props;
    const title = getProbeName(productModel, content.probeModel);
    return (
        <>
            <div>
                <b>{title}</b>
            </div>
            <div>
                <FormattedMessage id="Proceq.LogbookPropertySerialNumberShort" />
                {':'} {content.probeSerialNo}
            </div>
            <ProbeCommonContent content={content} />
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyBluetoothModuleShort" />
                {':'} {content.btmSN}
            </div>
            <div>
                {!!content.contractType && (
                    <div>
                        <FormattedMessage id="Proceq.LogbookContractType" />
                        {':'} {content.contractType}
                    </div>
                )}
                <FormattedMessage id="Proceq.LogbookPropertyContractValidUntil" />
                {': '}
                <ContractExpirationDate date={content.contractExpirationDate} />
            </div>
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyLastVerification" />
                {': '}
                {content.verifiedOn ? renderLogbookDate(content.verifiedOn) : <FormattedMessage id="Empty" />}
            </div>
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyLastAdjust" />
                {': '}
                {content.adjustedOn ? renderLogbookDate(content.adjustedOn) : <FormattedMessage id="Empty" />}
            </div>
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyLastPremiumCalibration" />
                {': '}
                {content.calibrationOn ? renderLogbookDate(content.calibrationOn) : <FormattedMessage id="Empty" />}
            </div>
        </>
    );
};
