import { useQuery } from '@tanstack/react-query';
import { ProductCode } from '../types/proceq';
import * as utilsService from '../api/utilsService';
import { ReactQueryKeys } from './queryKeys';

export const useProceqAppConfig = (productCode = ProductCode.GPR, enabled?: boolean) =>
    useQuery({
        queryKey: ReactQueryKeys.appProductConfig(productCode),
        queryFn: () => utilsService.getProceqAppConfig(productCode),
        enabled,
        refetchOnWindowFocus: false,
    });

export const useBackendVersionQuery = () =>
    useQuery({
        queryKey: ReactQueryKeys.backendVersion,
        queryFn: utilsService.getBackendVersion,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => data.data?.sversion,
    });
