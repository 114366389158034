import { LicenseProduct, LicenseTier } from '../types/license';
import { ProductCode } from '../types/proceq';
import actionTypes, { PayloadAction } from './actionTypes';

export interface ReceivedProceqAppConfigs {
    product: ProductCode;
    appConfigs: { [key: string]: string | number };
}

export const receivedProceqAppConfigs = (
    payload: ReceivedProceqAppConfigs
): PayloadAction<ReceivedProceqAppConfigs> => {
    return {
        type: actionTypes.RECEIVED_PROCEQ_APP_CONFIG,
        payload,
    };
};

export interface ReceivedAppsStatusPayload {
    productsStatus: { product: string; active: boolean; url?: string; eligibleFreeTrial?: boolean }[];
}

export const receivedAppsStatus = (payload: ReceivedAppsStatusPayload): PayloadAction<ReceivedAppsStatusPayload> => {
    return {
        type: actionTypes.RECEIVED_APPS_STATUS,
        payload,
    };
};

export interface ReceivedPlanTiersPayload {
    product: LicenseProduct | ProductCode;
    tiers: LicenseTier[];
}

export const receivedPlanTiers = (payload: ReceivedPlanTiersPayload) => {
    return {
        type: actionTypes.RECEIVED_PLAN_TIERS,
        payload,
    };
};
