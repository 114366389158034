import { MeasurementFullData, MeasurementObject } from '../../../../../types/measurement';
import { ProductModel, ScanType } from '../../../../../types/proceq';

// space between each antenna
const ANTENNA_SPACING = 0.05;

const ORIGIN_OFFSET = -0.125;

// GP8100 has 6 antennas, which means a max of 5 blocks of space
const NUMBER_OF_ANTENNA_SPACINGS = 5;

// currently only GP8100 has multiple antennas
export enum AntennaLabel {
    A,
    B,
    C,
    D,
    E,
    F,
}

export const isProductModelGP8100 = (productModel: string) => productModel.toUpperCase() === ProductModel.GPR_GP8100;

const getGPR8100OppositeCoord = (
    isHorizontalScan: boolean,
    sequenceNo: number,
    gridSpacing: number,
    offset: number,
    antennaIndex: number,
    noOfHorizontalLines?: number
) => {
    return isHorizontalScan
        ? ((noOfHorizontalLines ?? 0) - sequenceNo) * gridSpacing +
              (NUMBER_OF_ANTENNA_SPACINGS - antennaIndex) * ANTENNA_SPACING +
              offset
        : (sequenceNo - 1) * gridSpacing + antennaIndex * ANTENNA_SPACING + offset;
};

const getNonAntennaOppositeCoord = (sequenceNo: number, gridSpacing: number) => (sequenceNo - 1) * gridSpacing;

const getLineScanYCoord = (antenna?: number) => {
    return (antenna ?? 0) * ANTENNA_SPACING;
};

const getMarkerObjectDistance = (
    data: MeasurementFullData,
    object: MeasurementObject,
    reading: any,
    scanType: ScanType
) => {
    const isGP8100 = isProductModelGP8100(data.measurement.productModel);
    const offset = isGP8100 && scanType === ScanType.AreaScan ? ORIGIN_OFFSET : 0;
    const distanceMeter = (object.content?.distanceMeter ?? object.content?.location?.distanceMeter ?? 0) + offset;

    // for line scan only GP8100 will have y coord value due to the antennas
    if (scanType === ScanType.LineScan) {
        return {
            objectDistanceX: distanceMeter,
            objectDistanceY: getLineScanYCoord(isGP8100 ? object.content.antennaIndex : undefined),
        };
    }

    const isHorizontalScan = reading.content.dimension === 'X';
    const sequenceNo = reading.content.sequence;
    const areaScanParameters = data.settings[0].content.settings.preset.scanModeParameters.areaScanParameters;
    const gridSpacing = isHorizontalScan ? areaScanParameters.gridSpacingY : areaScanParameters.gridSpacingX;

    if (isHorizontalScan) {
        const noOfHorizontalLines = areaScanParameters.numberOfHorizontalLines;
        return {
            objectDistanceX: distanceMeter,
            objectDistanceY: isGP8100
                ? getGPR8100OppositeCoord(
                      isHorizontalScan,
                      sequenceNo,
                      gridSpacing,
                      offset,
                      object.content.antennaIndex,
                      noOfHorizontalLines
                  )
                : getNonAntennaOppositeCoord(sequenceNo, gridSpacing),
        };
    }
    return {
        objectDistanceY: distanceMeter,
        objectDistanceX: isGP8100
            ? getGPR8100OppositeCoord(isHorizontalScan, sequenceNo, gridSpacing, offset, object.content.antennaIndex)
            : getNonAntennaOppositeCoord(sequenceNo, gridSpacing),
    };
};

export default getMarkerObjectDistance;
