import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps, Log } from '../../../../../types/logs';

export const Result: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id={
            log.content?.result === 'success'
                ? 'Proceq.LogbookResultVerificationPassed'
                : 'Proceq.LogbookResultVerificationFailed'
        }
    />
);

export const color = (log: Log) => (log.content.result === 'success' ? 'green' : 'red');

export default {
    element: Result,
    color,
};
