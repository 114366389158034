import React from 'react';
import FormattedMessage from '../../../../localization/FormatMessage';
import { renderLogbookDate } from '../../../../utils/dateUtils';

export const ContractExpirationDate: React.FunctionComponent<{ date?: string }> = ({ date }) => {
    return date ? (
        date === 'Lifetime' ? (
            <FormattedMessage id="Proceq.Lifetime" />
        ) : (
            <>{renderLogbookDate(date)}</>
        )
    ) : (
        <FormattedMessage id="Proceq.Unknown" />
    );
};

export const VerifiedDate: React.FunctionComponent<{ date?: string }> = ({ date }) => {
    if (date && typeof date === 'string') {
        return <>{renderLogbookDate(date)}</>;
    }
    return <FormattedMessage id="Proceq.Unknown" />;
};
