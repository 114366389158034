import { getSessionItem, removeSessionItem, saveSessionItem } from './storageUtils';

export const saveLandingPath = () => {
    if (location.pathname && !getSessionItem('landingPathname')) {
        saveSessionItem('landingPathname', location.pathname);
    }
    if (location.search && !getSessionItem('landingQuery')) {
        saveSessionItem('landingQuery', location.search);
    }
};

export const restoreLandingPath = (): string => {
    const landingPathname = getSessionItem('landingPathname') ?? '';
    const landingQuery = getSessionItem('landingQuery') ?? '';
    removeSessionItem('landingPathname');
    removeSessionItem('landingQuery');
    return landingPathname ? landingPathname + landingQuery : '';
};
