import React, { useMemo } from 'react';
import styles from './StandaloneMeasurementView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import config from '../../config';
import { getLabelFromGlobalDataKey } from '../DataView/RegisteredInfo/helper';
import { ProductCode } from '../../types/proceq';
import ButtonExportWifi from '../MeasurementExport/ButtonExportWifi';
import { MeasurementPage } from '../../analytics/analyticsConstants';
import { ProductFeature, useProductContext } from '../DataView/ProductContextProvider';

interface ViewerWrapperProps {
    productCode: ProductCode;
    title?: string;
    exportMeasurementID?: string;
    measurementMode?: string;
}

const ViewerWrapper: React.FunctionComponent<React.PropsWithChildren<ViewerWrapperProps>> = (props) => {
    const { productCode, title, children, exportMeasurementID, measurementMode } = props;

    const appTitle = useMemo(() => getLabelFromGlobalDataKey('availproductsfull', productCode), [productCode]);
    const { isFeatureEnabled } = useProductContext();
    const isDocxExportEnabled = isFeatureEnabled(ProductFeature.PROFOMETER_DOCX);

    return (
        <div className={styles.measurement_container}>
            <div className="data-view-html">
                <div className={styles.header}>
                    <div className={styles.measurement_title_container}>
                        <h1>{title}</h1>
                        {measurementMode && (
                            <FormattedMessage
                                id="App.Logbook.GLM.Creation.MeasurementMode"
                                values={{ value: measurementMode }}
                            />
                        )}
                    </div>
                    <div className={styles.export_info}>
                        <div className={styles.caption}>{appTitle}</div>
                        <div className={styles.caption}>
                            <FormattedMessage id="Export HTML Format Version" />
                            {': '}
                            {config.APP_VERSION}
                        </div>
                        {isDocxExportEnabled && !!exportMeasurementID && (
                            <ButtonExportWifi
                                product={productCode}
                                mIDs={[exportMeasurementID]}
                                analyticsPage={MeasurementPage.viewInNewTab}
                                className={styles.export_button}
                            />
                        )}
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default ViewerWrapper;
