import React from 'react';
import { BluetoothFileType, CustomCurveFileType, MeasurementListItem, SystemFolderID } from '../../types/measurement';
import { CustomMaterialItem } from '../../types/customMaterial';
import getIsMeasurementUnsynced from './DataViewers/utils/getIsMeasurementUnsynced';
import { useDataViewContext } from './DataViewProvider';
import { useSelectedContractFeatures } from '../../hooks/useContract';
import { useMeasurement } from '../../hooks/useProductData';
import { ReactComponent as ViewInNewTabIcon } from '../../images/iconViewInNewTab.svg';
import AnalyticsButton from '../AnalyticsComponents/Button';
import styles from './DataView.styl';
import { useFormatMessage } from '../../localization/useFormatMessage';
import analytics from '../../analytics/firebaseAnalytics';
import { MeasurementMenuCategory } from '../../analytics/analyticsConstants';
import Tooltip from '../shared/Tooltip';
import useInvalidMeasurements from '../../hooks/useInvalidMeasurements';
import { useMeasurementContext } from './MeasurementContext/MeasurementProvider';

interface ViewInNewTabButtonProps {
    measurement: MeasurementListItem | CustomMaterialItem;
}

const ViewInNewTabButton: React.FunctionComponent<ViewInNewTabButtonProps> = (props) => {
    const { measurement } = props;

    const { activeFolder } = useDataViewContext();
    const { openMeasurementInNewTab, measurementListParams } = useMeasurementContext();
    const { fileType } = measurementListParams;
    const formatMessage = useFormatMessage();
    const measurementID = measurement.id;
    const measurementTest = useMeasurement(measurementID);
    const { getInvalidMeasurementIDs } = useSelectedContractFeatures({ [measurementID]: measurementTest }, [
        measurementID,
    ]);

    const { isInvalidMeasurement } = useInvalidMeasurements({
        invalidMeasurementIDs: getInvalidMeasurementIDs(),
        measurementID,
    });

    if (activeFolder === SystemFolderID.Trashed) return null;

    // return empty div so that the buttons will align across all rows even if button is not shown for this row
    if (
        measurement.isDemoFile ||
        fileType === CustomCurveFileType.dgscc ||
        fileType === BluetoothFileType.CustomMaterial ||
        isInvalidMeasurement ||
        getIsMeasurementUnsynced(measurement as MeasurementListItem)
    )
        return <div />;

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        analytics.logMeasurementMenu(MeasurementMenuCategory.newTab);
        openMeasurementInNewTab(measurementID);
    };

    return (
        <AnalyticsButton onClick={handleClick} type="link" className={styles.action_icon}>
            <Tooltip placement="top" title={formatMessage({ id: 'DataView.ViewInNewTab' })}>
                <ViewInNewTabIcon />
            </Tooltip>
        </AnalyticsButton>
    );
};

export default ViewInNewTabButton;
