import { LineSeries } from 'react-jsx-highcharts';
import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import Papa from 'papaparse';
import { App, Spin } from 'antd';
import JSZip from 'jszip';
import { chartColors, labelStyleConfig } from '../../DataViewers/Readings/HighchartsConfigs';
import HighchartsLineSeries from '../../../shared/Charts/HighchartsLineSeries';
import { roundToNDigit } from '../../DataViewers/utils/conversionRates';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { getFile } from '../../../../api/utilsService';
import { getMeasurementFullData } from '../../../../api/measurementService';
import { ProductCode } from '../../../../types/proceq';
import { TimestampType, useMeasurementReloadLimiter } from '../../../../hooks/useLimiter';
import { useMeasurements } from '../../../../hooks/useProductData';
import { CustomCurveProbeInfo, ReadingsType } from '../../../../types/customCurve';

interface CurveChartProps {
    product?: ProductCode;
    measurementID: string;
    probeInfo: CustomCurveProbeInfo;
}

function xAxisFormatter(this: Highcharts.AxisLabelsFormatterContextObject<number>) {
    // we are setting tickInterval, so just show 10 to the power of current position
    return `10^${this.pos}`;
}

const X_AXIS_UNIT = 'mm';
const Y_AXIS_UNIT = 'dB';

const CurveChart: React.FunctionComponent<CurveChartProps> = (props) => {
    const { product, measurementID, probeInfo } = props;
    const chartColorsLength = chartColors.length;
    const formatMessage = useFormatMessage();
    const [csvFile, setCsvFile] = useState<ArrayBuffer | undefined>();
    const [lines, setLines] = useState<string[] | undefined>();
    const [dataPoints, setDataPoints] = useState<any | undefined>();
    const measurements = useMeasurements();
    const data = measurements?.[measurementID];
    const { message } = App.useApp();

    const fetchMeasurementData = useCallback(() => {
        if (product) {
            getMeasurementFullData({ measurementID });
        }
    }, [measurementID, product]);

    useEffect(() => {
        const aID = data?.readings?.find((reading) => reading.type === ReadingsType.CSVSmoothened)?.aID;
        const csvAttachmentID = data?.attachments?.find((attachment) => attachment.id === aID);
        if (csvAttachmentID) {
            getFile({ mID: measurementID, aID: csvAttachmentID?.id }).then((res) => {
                setCsvFile(res);
            });
        }
    }, [data?.attachments, data?.readings, measurementID]);

    useMeasurementReloadLimiter({
        measurementID,
        timestampType: TimestampType.Full,
        callback: fetchMeasurementData,
    });

    useEffect(() => {
        if (!csvFile) return;
        const jsZip = new JSZip();
        jsZip
            .loadAsync(csvFile)
            .then((res) => {
                return res.file(Object.keys(res.files)?.[0])?.async('string');
            })
            .then((csvContent) => {
                if (!csvContent) return;
                Papa.parse(csvContent, {
                    header: true,
                    complete: (results) => {
                        const allColumns = [...(results.meta.fields ?? [])];
                        allColumns.splice(allColumns.indexOf('distance'), 1);
                        setLines(allColumns);
                        const dataReduced = results.data.reduce((previousValue: any, currentValue: any) => {
                            allColumns.forEach((column) => {
                                if (currentValue[column]) {
                                    const currentValueInt = currentValue[column] ? Number(currentValue[column]) : null;
                                    previousValue[column] = [
                                        ...(previousValue[column] ?? []),
                                        [Number(currentValue.distance), currentValueInt],
                                    ];
                                }
                            });
                            return previousValue;
                        }, {});
                        setDataPoints(dataReduced);
                    },
                    error: (error: any) => {
                        message.error(formatMessage({ id: 'DataView.CustomCurve.ErrorCSV' }));
                        // eslint-disable-next-line no-console
                        console.log('Error parsing csv ', error);
                    },
                });
            });
    }, [csvFile, formatMessage, message]);

    return lines && dataPoints ? (
        <HighchartsLineSeries
            yAxisProps={{ reversed: true, min: 0, max: 80 }}
            xAxisProps={{
                max: probeInfo.maxDistance,
                min: probeInfo.minDistance ?? 1,
                type: 'logarithmic',
                labels: {
                    ...labelStyleConfig,
                    formatter: xAxisFormatter,
                },
                tickInterval: 1,
            }}
            sourceUnit={X_AXIS_UNIT}
            targetUnit={Y_AXIS_UNIT}
            xAxisTitle={formatMessage({ id: 'Proceq.LogbookSettingsDistance' }, { unit: X_AXIS_UNIT })}
            yAxisTitle={formatMessage({ id: 'App.Logbook.FDL.Item.gain' }, { unit: Y_AXIS_UNIT })}
            formatTooltipValue={(value) => (value ? roundToNDigit(value, 2) : value)}
        >
            {lines.map((line, index) => (
                <LineSeries
                    key={line}
                    name={line}
                    data={dataPoints[line]}
                    color={chartColors[index % chartColorsLength]}
                />
            ))}
        </HighchartsLineSeries>
    ) : (
        <Spin style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }} />
    );
};

export default CurveChart;
