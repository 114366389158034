import React from 'react';
import { ReactComponent as AppIconEquotip } from '../../images/appIconEquotipLive.svg';
import { ReactComponent as AppIconFlawDetector } from '../../images/appIconProceqFlawDetectorLive.svg';
import { ReactComponent as AppIconGlossmeter } from '../../images/appIconZehtnerGlossmeterLive.svg';
import { ReactComponent as AppIconGPR } from '../../images/appIconProceqGPRLive.svg';
import { ReactComponent as AppIconGPRSubsurface } from '../../images/appIconProceqGSLive.svg';
import { ReactComponent as AppIconInspect } from '../../images/appIconScreeningEagleInspect.svg';
import { ReactComponent as AppIconPundit } from '../../images/appIconPunditLiveArray.svg';
import { ReactComponent as AppIconSchmidt } from '../../images/appIconOriginalSchmidtLive.svg';
import { ReactComponent as AppIconPunditImpact } from '../../images/appIconPunditImpact.svg';
import { ReactComponent as AppIconGPRInsights } from '../../images/appIconGPRInsights.svg';
import { ReactComponent as AppIconProfometer } from '../../images/appIconProfometer.svg';
import { ReactComponent as AppIconEnvelope } from '../../images/appIconEnvelope.svg';
import { ReactComponent as AppIconGPRMounted } from '../../images/appIconGPRMounted.svg';
import imagePlaceholderSrc from '../../images/imagePlaceholder.svg';
import { ProductCode } from '../../types/proceq';
import colors from '../../styles/colors.json';

export enum ProductName {
    Equotip = 'equotip',
    FlawDetector = 'flawDetector',
    Glossmeter = 'glossmeter',
    GPR = 'gpr',
    GPRSurface = 'gprSurface',
    GPRMounted = 'gprMounted',
    Pundit = 'pundit',
    Schmidt = 'schmidt',
    PunditImpact = 'punditImpact',
    Inspect = 'inspect',
    Workspace = 'workspace',
    GPRInsights = 'gprInsights',
    Profometer = 'profometer',
}

export const productMap: {
    [key: string]: {
        name: string;
        icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        productCode?: ProductCode;
        iconColorCode?: string;
    };
} = {
    [ProductName.Profometer]: {
        name: 'Profometer',
        icon: AppIconProfometer,
        productCode: ProductCode.PROFOMETER,
    },
    [ProductName.Equotip]: {
        name: 'Equotip',
        icon: AppIconEquotip,
        productCode: ProductCode.EQUOTIP,
    },
    [ProductName.FlawDetector]: {
        name: 'Flaw Detector',
        icon: AppIconFlawDetector,
        productCode: ProductCode.FDL,
    },
    [ProductName.Glossmeter]: {
        name: 'Glossmeter',
        icon: AppIconGlossmeter,
        productCode: ProductCode.GLM,
    },
    [ProductName.GPR]: {
        name: 'GPR',
        icon: AppIconGPR,
        productCode: ProductCode.GPR,
    },
    [ProductName.GPRSurface]: {
        name: 'GPR Surface',
        icon: AppIconGPRSubsurface,
        productCode: ProductCode.GPR_SOIL,
    },
    [ProductName.GPRMounted]: {
        name: 'GPR Mounted',
        icon: AppIconGPRMounted,
        productCode: ProductCode.GPR_MOUNTED,
    },
    [ProductName.Pundit]: {
        name: 'Pundit',
        icon: AppIconPundit,
        productCode: ProductCode.PUNDIT,
    },
    [ProductName.Schmidt]: {
        name: 'Schmidt',
        icon: AppIconSchmidt,
        productCode: ProductCode.SCHMIDT,
    },
    [ProductName.PunditImpact]: {
        name: 'Pundit Impact',
        icon: AppIconPunditImpact,
        productCode: ProductCode.PIT_IE,
    },
    [ProductName.Inspect]: {
        name: 'Inspect',
        icon: AppIconInspect,
    },
    [ProductName.Workspace]: {
        name: 'Workspace',
        icon: AppIconEnvelope,
    },
    [ProductName.GPRInsights]: {
        name: 'GPR Insights',
        icon: AppIconGPRInsights,
        productCode: ProductCode.GPR_INSIGHTS,
        iconColorCode: colors.icon_turquoise,
    },
};

export const productCodeToIconMap: { [key in ProductCode | 'INSPECT' | 'WORKSPACE']?: ProductName } = {
    GPR: ProductName.GPR,
    GPR_SOIL: ProductName.GPRSurface,
    GPR_MOUNTED: ProductName.GPRMounted,
    PUNDIT: ProductName.Pundit,
    EQUOTIP: ProductName.Equotip,
    SCHMIDT: ProductName.Schmidt,
    FDL: ProductName.FlawDetector,
    GLM: ProductName.Glossmeter,
    PIT_IE: ProductName.PunditImpact,
    INSPECT: ProductName.Inspect,
    WORKSPACE: ProductName.Workspace,
    GPR_INSIGHTS: ProductName.GPRInsights,
    PROFOMETER: ProductName.Profometer,
};

interface AppIconProps {
    product?: ProductName | string;
    size?: number;
    disabled?: boolean;
}

const AppIcon: React.FunctionComponent<AppIconProps> = (props) => {
    const { size = 32, product, disabled } = props;
    if (!product || !productMap[product]) return <Square size={size} />;
    const { icon: Icon, iconColorCode } = productMap[product];
    return <Icon width={size} height={size} style={{ color: disabled ? colors.gray500 : iconColorCode }} />;
};

const Square: React.FunctionComponent<{ size: number }> = (props) => {
    const { size } = props;
    return (
        <div
            style={{
                display: 'flex',
                width: size,
                height: size,
            }}
        >
            <img style={{ borderRadius: '15%' }} src={imagePlaceholderSrc} alt="" />
        </div>
    );
};

export default AppIcon;
