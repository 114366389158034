import { MeasurementListItem } from '../../../../types/measurement';
import { ProductCode, ProductModel, productModelToFamilyMap } from '../../../../types/proceq';

const getIsMeasurementUnsynced = (measurement: MeasurementListItem) => {
    if (!measurement.productModel) return false;
    const productCode = productModelToFamilyMap[measurement.productModel.toUpperCase() as ProductModel];
    if (productCode !== ProductCode.GPR_MOUNTED) return false;

    const uploadProgress = measurement.content?.info?.gmGpacUploadProgress;
    return uploadProgress !== undefined && uploadProgress !== null && uploadProgress < 1;
};

export default getIsMeasurementUnsynced;
