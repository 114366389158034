import React, { useEffect } from 'react';
import { App, Divider, Dropdown } from 'antd';
import { routeUtils } from 'tds-common-fe';
import { useSelector } from 'react-redux';
import FormattedMessage from '../../../localization/FormatMessage';
import { AntdMenuItem } from '../../../types/antd';
import styles from './Security.styl';
import { ReactComponent as DownArrowIcon } from '../../../images/iconArrowDown.svg';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import routeURLs from '../../Routes/urls';
import analytics from '../../../analytics/firebaseAnalytics';
import { SecurityAction } from '../../../analytics/analyticsConstants';
import EditPassword from './EditPassword';
import { RootState } from '../../../reducers';
import Tooltip from '../../shared/Tooltip';

const TWO_FA_MENU_ITEMS: AntdMenuItem[] = [
    {
        key: 'on',
        label: <FormattedMessage id="Proceq.On" />,
    },
    {
        key: 'off',
        label: <FormattedMessage id="Proceq.Off" />,
    },
];

const Security: React.FunctionComponent = () => {
    const { email, '2fa': showTwoFA } = useSelector((state: RootState) => state.profile.userProfile);
    const { message } = App.useApp();
    // backend will redirect here with error if 2fa reset fails
    const { error } = routeUtils.parseQuery<{ error?: string }>(location.search);
    useEffect(() => {
        if (error) {
            message.error(error);
        }
    }, [error, message]);

    // need to provide action for verify when confirmed by backend
    const resetTwoFAPath = routeUtils.makeQueryPath(routeURLs.EAGLE_ID_RESET_TWO_FA, {
        email,
        redirect_uri: location.href,
        cancel_redirect_uri: location.href,
        client: 'web',
        product: 'workspace',
    });
    const recoveryKeyPath = routeUtils.makeQueryPath(routeURLs.EAGLE_ID_RECOVERY_KEY, {
        cancel_redirect_uri: location.href,
        product: 'workspace',
    });
    const viewRecoveryKeysPath = routeUtils.makeQueryPath(routeURLs.EAGLE_ID_VERIFY_PASSWORD, {
        email,
        redirect_uri: recoveryKeyPath,
        cancel_redirect_uri: location.href,
        product: 'workspace',
        client: 'web',
        action: 'view_2fa_recovery_key',
    });

    return (
        <>
            <EditPassword />
            {showTwoFA && (
                <>
                    {' '}
                    <Divider />
                    <div className={styles.title_container}>
                        <div>
                            <FormattedMessage id="Profile.PasswordSecurity.TwoFactorAuthentication" />
                        </div>
                        <Tooltip
                            placement="left"
                            title={<FormattedMessage id="Profile.PasswordSecurity.DropdownTooltip" />}
                        >
                            <Dropdown
                                disabled
                                className={styles.dropdown}
                                menu={{ items: TWO_FA_MENU_ITEMS }}
                                trigger={['click']}
                            >
                                <div>
                                    <FormattedMessage id="Proceq.On" />
                                    <DownArrowIcon className={styles.dropdown_arrow} />
                                </div>
                            </Dropdown>
                        </Tooltip>
                    </div>
                    <div className={styles.caption2}>
                        <FormattedMessage id="Profile.PasswordSecurity.Subtitle" />
                    </div>
                    <div className={styles.action_container}>
                        <div>
                            <FormattedMessage id="Profile.PasswordSecurity.AuthenticatorApp" />
                            <AnalyticsButton
                                type="primary"
                                ghost
                                onClick={() => {
                                    analytics.logProfileSecurity(SecurityAction.resetAuthApp);
                                    window.open(resetTwoFAPath, '_self');
                                }}
                            >
                                <FormattedMessage id="App.Reset" />
                            </AnalyticsButton>
                        </div>
                        <div>
                            <FormattedMessage id="Profile.PasswordSecurity.RecoveryKeys" />
                            <AnalyticsButton
                                type="primary"
                                ghost
                                onClick={() => {
                                    analytics.logProfileSecurity(SecurityAction.viewRecoveryKey);
                                    window.open(viewRecoveryKeysPath, '_self');
                                }}
                            >
                                <FormattedMessage id="App.View" />
                            </AnalyticsButton>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Security;
