import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import LogEvent, { renderUserInitials } from '../LogEvent/LogEvent';
import { Probe } from '../LogEvent/shared/Probe';
import { ProductCode, ProductModel, ScanType } from '../../../../types/proceq';
import { renderLogbookDate } from '../../../../utils/dateUtils';
import './Logbook.global.styl';
import { Log } from '../../../../types/logs';
import useLogEventsSeparator from './useLogEventsSeparator';
import useProceqUsers from '../../../../hooks/useProceqUsers';
import AnalyticsButton from '../../../AnalyticsComponents/Button';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { useMeasurementViewerContext } from '../../../StandaloneMeasurementView/MeasurementViewerContext';

interface GPRLogbookProps {
    productModel: ProductModel;
    scanType: ScanType;
    product: ProductCode;
    logs: Log[];
    isMetric: boolean;
    version: string;
    highlight: Set<string>;
    toggleHighlight: (id: string, isHighlighted: boolean) => void;
    highlightHandler: (ids: Set<string>) => void;
}

const logSorter = (a: Log, b: Log) => {
    // Force creation log to be the very first
    if (a.type === 'creation') {
        return -1;
    }
    if (b.type === 'creation') {
        return 1;
    }
    return a.clientCreated - b.clientCreated;
};

const isFDLKeyFrame = (product: ProductCode, item: Log) => {
    return (
        product === 'FDL' &&
        item !== undefined &&
        item.content !== undefined &&
        item.type === 'changeKeyFrame' &&
        item.content.oldKeyFrameIndex === undefined
    );
};

export const GPRLogbook: React.FunctionComponent<GPRLogbookProps> = (props) => {
    const { productModel, scanType, product, logs, isMetric, version, highlight, toggleHighlight, highlightHandler } =
        props;

    const formatMessage = useFormatMessage();

    const allObjectMarkerIDs = useMemo(() => {
        return logs.filter((log) => log.type === 'objectMarker').map((log) => log.id);
    }, [logs]);

    const isAllHighlighted = highlight.size === allObjectMarkerIDs.length;

    const toggleAllHighlight = useCallback(() => {
        highlightHandler(isAllHighlighted ? new Set() : new Set([...allObjectMarkerIDs]));
    }, [allObjectMarkerIDs, isAllHighlighted, highlightHandler]);

    const { users } = useProceqUsers();

    const { probeLog, otherLogs } = useLogEventsSeparator(logs, logSorter);

    const { isStandalone } = useMeasurementViewerContext();

    return (
        <div id="logbookModal">
            {isStandalone && (
                <div className="title-wrapper">
                    <h1>
                        <FormattedMessage id="Logbook" />
                    </h1>
                    {allObjectMarkerIDs.length > 0 && (
                        <AnalyticsButton
                            className="logbook_toggle"
                            onClick={() => toggleAllHighlight()}
                            data-alt-text={formatMessage({ id: 'App.Logbook.CollapseEntries' })}
                        >
                            <FormattedMessage
                                id={isAllHighlighted ? 'App.Logbook.CollapseEntries' : 'App.Logbook.ExpandEntries'}
                            />
                        </AnalyticsButton>
                    )}
                </div>
            )}
            <div id="chat-body" className="chat-body-custom-scroll">
                {logs === null && <FormattedMessage id="App.LogbookNoEntries" />}
                {!!probeLog && <Probe product={product} log={probeLog} productModel={productModel} />}
                <ul>
                    {logs &&
                        otherLogs
                            .filter((item) => !isFDLKeyFrame(product, item))
                            .map((log, index, sortedLogs) => {
                                const isHighlighted = highlight.has(log.id);
                                return (
                                    <LogEvent
                                        key={log.id}
                                        log={log}
                                        product={product}
                                        showDate={
                                            index === 0 ||
                                            renderLogbookDate(log.clientCreated) !==
                                                renderLogbookDate(get(sortedLogs, [String(index - 1), 'clientCreated']))
                                        }
                                        date={log.clientCreated}
                                        productModel={productModel}
                                        isMetric={isMetric}
                                        version={version}
                                        scanType={scanType}
                                        user={renderUserInitials(users[log.uID])}
                                        highlighted={isHighlighted}
                                        toggleHighlight={() => toggleHighlight(log.id, isHighlighted)}
                                        probeLog={probeLog ?? undefined}
                                    />
                                );
                            })}
                </ul>
            </div>
        </div>
    );
};

export default GPRLogbook;
