import { get } from 'lodash';
import React from 'react';
import { filterReadings } from './ScanDistance';
import type { MeasurementObjectWithInfo } from './Objects';
import { getLocalXY, getLocationCoordinates, sortBySequenceNo } from './Objects';
import { MeasurementFullData, MeasurementObject } from '../../../../../types/measurement';
import { getFileNameWithExpectedExtensions } from '../../../../../utils/fileUtils';
import Audio from '../../../../Attachments/Audio';
import { ProductCode, ScanType } from '../../../../../types/proceq';
import { MarkersColumnRaw } from '../shared/Markers';
import getMarkerObjectDistance, { AntennaLabel, isProductModelGP8100 } from './getMarkerObjectDistance';

export const renderComment = (text: string, record: MeasurementObject, data: MeasurementFullData) => {
    if (record.content.type === 'Text') {
        return text;
    }

    const attachment = data.attachments.find((a) => a.id === text);
    const attachmentName = getFileNameWithExpectedExtensions(get(attachment, 'fileNames', []), ['m4a', 'aac']);

    return <Audio mID={record.mID} aID={text} attachmentName={attachmentName || `${text}.m4a`} download={false} />;
};

export const getMarkersTableData = (data: MeasurementFullData, product: ProductCode, scanType: ScanType) => {
    // we only want to use the most recent log entry
    const sortedLogs = data.logs.slice().sort((a, b) => b.clientCreated - a.clientCreated);
    const filteredReadings = filterReadings(data, scanType);
    const isSoil = product === ProductCode.GPR_SOIL;
    const isGP8100 = isProductModelGP8100(data.measurement.productModel);
    const markers: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'marker' && object.content.textType !== 'P')
        .map((object) => {
            const extraKeys: { [key: string]: any } = {};
            const reading = filteredReadings.find((r) => r.id === object.rID);
            const log = sortedLogs.find((l) => +l.content.sequence === +object.content.number);
            if (reading) {
                extraKeys.readingContent = { ...reading.content };
                const antennaLabel =
                    isGP8100 && object.content.antennaIndex !== undefined
                        ? AntennaLabel[object.content.antennaIndex]
                        : '';
                extraKeys.readingContent.sequenceNo = `${reading.sequenceNo}${antennaLabel}`;
            }
            if (product === ProductCode.GPR) {
                ({ objectDistanceX: extraKeys.objectDistanceX, objectDistanceY: extraKeys.objectDistanceY } =
                    getMarkerObjectDistance(data, object, reading, scanType));
            }
            return {
                ...extraKeys,
                ...object,
                ...(isSoil && scanType === ScanType.AreaScan ? getLocalXY(data, object) : {}),
                ...(isSoil ? getLocationCoordinates(object) : {}),
                logContent: log ? log.content : {},
            } as MeasurementObjectWithInfo;
        })
        .sort((a, b) => {
            // sort by line number first
            const sortBySequence = sortBySequenceNo(a, b);
            if (sortBySequence) {
                return sortBySequence;
            }

            // this is actually the last to be sorted, applies only when type is sorted
            if (a.content.type === b.content.type) {
                return a.content.number - b.content.number;
            }

            // then sort by tag type
            return a.content.type === 'Text' ? -1 : 1;
        });

    const getDistanceLocationColumns = () => {
        const distanceColumns: MarkersColumnRaw[] = [];
        if (isSoil) {
            distanceColumns.push(
                {
                    title: 'App.HTML.GPR_SOIL.Objects.Easting',
                    unitId: `${product}.CSV.LocationCoordinates`,
                    dataIndex: ['convertedCoordinateX'],
                    width: 80,
                },
                {
                    title: 'App.HTML.GPR_SOIL.Objects.Northing',
                    unitId: `${product}.CSV.LocationCoordinates`,
                    dataIndex: ['convertedCoordinateY'],
                    width: 80,
                }
            );
            if (scanType === ScanType.AreaScan) {
                distanceColumns.push(
                    {
                        title: 'App.HTML.GPR.Objects.LocalX',
                        unitId: `${product}.CSV.Tag objects Local XY`,
                        dataIndex: ['localX'],
                        width: 120,
                    },
                    {
                        title: 'App.HTML.GPR.Objects.LocalY',
                        unitId: `${product}.CSV.Tag objects Local XY`,
                        dataIndex: ['localY'],
                        width: 120,
                    }
                );
            } else {
                distanceColumns.push({
                    title: 'App.HTML.GPR_SOIL.Objects.DistanceAlongLine',
                    unitId: `${product}.CSV.Tag objects distance X`,
                    dataIndex: ['content', 'distanceMeter'],
                    width: 120,
                });
            }
        } else {
            distanceColumns.push(
                {
                    title: 'App.HTML.GPR.Objects.DistanceX',
                    unitId: `${product}.CSV.Marker distance X`,
                    dataIndex: ['objectDistanceX'],
                    width: 120,
                },
                {
                    title: 'App.HTML.GPR.Objects.DistanceY',
                    unitId: `${product}.CSV.Marker distance Y`,
                    dataIndex: ['objectDistanceY'],
                    width: 120,
                }
            );
        }
        return distanceColumns;
    };

    const columnsRaw: MarkersColumnRaw[] = [
        {
            title: 'Line',
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return record.readingContent !== undefined ? record.readingContent.sequenceNo : '';
            },
            width: 40,
        },
        {
            title: 'Marker Number',
            render: (text: string, record: MeasurementObjectWithInfo) =>
                `${record.content.type === 'Text' ? 'M' : 'V'}${record.content.number}`,
            width: 120,
        },
        ...getDistanceLocationColumns(),
        {
            title: 'Comment',
            dataIndex: ['content', 'content'],
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return renderComment(text, record, data);
            },
        },
    ];
    return { markers, columnsRaw };
};
