import staticKeys from './static.json';
import eagleCloud from './eagleCloud.json';
import proceqData from './proceqData.json';
import proceqCommon from './proceqCommon.json';
import eagleID from './eagleID.json';

export default {
    ...staticKeys,
    ...eagleID,
    ...proceqCommon,
    ...proceqData,
    ...eagleCloud,
};
