import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';

const ZeroingCompleted: React.FunctionComponent = () => (
    <FormattedMessage id="App.HTML.Profometer.Logbook.ZeroingCompleted" />
);

export default {
    element: ZeroingCompleted,
};
