import { useMemo } from 'react';
import { BluetoothFileType, CustomCurveFileType, SystemFolderID } from '../../../types/measurement';
import { useImportMeasurementsData } from '../../../hooks/useProductData';
import { ProductCode } from '../../../types/proceq';

interface Params {
    activeFolder: string;
    fileType: string;
    importIDs: string[] | undefined;
    page: number;
    product: ProductCode | undefined;
}

export default function useImportMeasurementData(params: Params) {
    const { activeFolder, importIDs, fileType, page, product } = params;

    const importMeasurementsData = useImportMeasurementsData();

    const pendingImportCount = importIDs?.length ?? 0;
    const isPendingImportEmpty = pendingImportCount === 0;

    const showImportingData =
        product &&
        (activeFolder === SystemFolderID.All || activeFolder === SystemFolderID.Imported) &&
        page === 1 &&
        fileType !== BluetoothFileType.VerificationData &&
        !isPendingImportEmpty;

    const dataSource = useMemo(() => {
        if (!importIDs || !showImportingData) {
            return [];
        }
        return importIDs
            .filter((id) => {
                if (fileType === CustomCurveFileType.dgscc) {
                    return importMeasurementsData[id] && importMeasurementsData[id].type === CustomCurveFileType.dgscc;
                }
                return importMeasurementsData[id] && importMeasurementsData[id].type !== CustomCurveFileType.dgscc;
            })
            .map((id) => {
                const importData = importMeasurementsData[id];
                return {
                    ...importData,
                    id: importData.newID,
                    productType: product,
                    isImport: true,
                    importStatus: importData.status,
                    key: `${importData.newID}-${importData.timestamp}`,
                } as any;
            });
    }, [importIDs, showImportingData, fileType, importMeasurementsData, product]);

    return dataSource;
}
