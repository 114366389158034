import { CustomMaterialItem } from '../types/customMaterial';
import { Log } from '../types/logs';
import {
    Attachment,
    Folder,
    ImportMeasurementData,
    MeasurementFullData,
    MeasurementListItem,
} from '../types/measurement';
import { ProductCode } from '../types/proceq';
import actionTypes, { PayloadAction } from './actionTypes';
import { HTMLData } from '../types/html';

export interface ReceiveFoldersPayload {
    product: ProductCode;
    folders: Folder[];
    archived?: boolean;
}

export const receivedFolders = (payload: ReceiveFoldersPayload): PayloadAction<ReceiveFoldersPayload> => {
    return {
        type: actionTypes.RECEIVED_MEASUREMENT_FOLDERS,
        payload,
    };
};

export interface ReceivedMeasurementListPayload {
    product: ProductCode;
    measurements: MeasurementListItem[];
    folderID: string;
    totalCount: number;
    offset: number;
    limit?: number;
    fileType?: string;
    archived?: boolean;
}

export const receivedMeasurementList = (
    payload: ReceivedMeasurementListPayload
): PayloadAction<ReceivedMeasurementListPayload> => {
    return {
        type: actionTypes.RECEIVED_MEASUREMENT_LIST,
        payload,
    };
};

export interface ReceivedPolledMeasurementsPayload {
    product: ProductCode;
    measurements: MeasurementListItem[];
}

export const receivedPolledMeasurements = (
    payload: ReceivedPolledMeasurementsPayload
): PayloadAction<ReceivedPolledMeasurementsPayload> => {
    return {
        type: actionTypes.RECEIVED_POLLED_MEASUREMENTS,
        payload,
    };
};

export interface ReceivedCustomMaterialListPayload {
    product: ProductCode;
    customMaterials: CustomMaterialItem[];
    totalCount: number;
    offset: number;
    limit?: number;
}

export const receivedCustomMaterialList = (
    payload: ReceivedCustomMaterialListPayload
): PayloadAction<ReceivedCustomMaterialListPayload> => {
    return {
        type: actionTypes.RECEIVED_CUSTOM_MATERIAL_LIST,
        payload,
    };
};

export interface ReceivedCustomMaterialPayload {
    id: string;
    customMaterial: CustomMaterialItem;
}

export const receivedCustomMaterial = (
    payload: ReceivedCustomMaterialPayload
): PayloadAction<ReceivedCustomMaterialPayload> => {
    return {
        type: actionTypes.RECEIVED_CUSTOM_MATERIAL,
        payload,
    };
};

export interface ReceivedRecentMeasurementsPayload {
    measurements: MeasurementListItem[];
}

export const receivedRecentMeasurements = (
    payload: ReceivedRecentMeasurementsPayload
): PayloadAction<ReceivedRecentMeasurementsPayload> => {
    return {
        type: actionTypes.RECEIVED_RECENT_MEASUREMENT,
        payload,
    };
};

export interface ReceivedMeasurementPayload {
    measurementID: string;
    measurement: MeasurementFullData;
}

export const receivedMeasurement = (payload: ReceivedMeasurementPayload): PayloadAction<ReceivedMeasurementPayload> => {
    return {
        type: actionTypes.RECEIVED_MEASUREMENT_FULL_DATA,
        payload,
    };
};

export const clearMeasurementTimestamps = (): PayloadAction => {
    return {
        type: actionTypes.CLEAR_MEASUREMENT_TIMESTAMPS,
        payload: undefined,
    };
};

export interface ReceivedMeasurementLogsPayload {
    measurementID: string;
    logs: Log[];
}

export const receivedMeasurementLogs = (
    payload: ReceivedMeasurementLogsPayload
): PayloadAction<ReceivedMeasurementLogsPayload> => {
    return {
        type: actionTypes.RECEIVED_MEASUREMENT_LOGS,
        payload,
    };
};

export interface ReceivedMeasurementAttachmentsPayload {
    measurementID: string;
    attachments: Attachment[];
}

export const receivedMeasurementAttachments = (
    payload: ReceivedMeasurementAttachmentsPayload
): PayloadAction<ReceivedMeasurementAttachmentsPayload> => {
    return {
        type: actionTypes.RECEIVED_MEASUREMENT_ATTACHMENTS,
        payload,
    };
};

export interface ClearMeasurementListPayload {
    product: ProductCode;
    folderID: string;
}

export const clearMeasurementList = (
    payload: ClearMeasurementListPayload
): PayloadAction<ClearMeasurementListPayload> => {
    return {
        type: actionTypes.CLEAR_MEASUREMENT_LIST,
        payload,
    };
};

export interface CreateMeasurementPayload {
    id: string;
    name: string;
    product: ProductCode;
}

export const createMeasurementAction = (payload: CreateMeasurementPayload): PayloadAction<CreateMeasurementPayload> => {
    return {
        type: actionTypes.CREATE_MEASUREMENT,
        payload,
    };
};

export const clearCreatedMeasurementAction = (id: string, product: ProductCode) => {
    return {
        type: actionTypes.CLEAR_CREATED_MEASUREMENT,
        payload: { id, product },
    };
};

export interface ReceivedHTMLMeasurementsPayload {
    product: ProductCode;
    data: HTMLData;
}

export const receivedHTMLMeasurements = (
    payload: ReceivedHTMLMeasurementsPayload
): PayloadAction<ReceivedHTMLMeasurementsPayload> => {
    return {
        type: actionTypes.RECEIVED_HTML_MEASUREMENTS,
        payload,
    };
};

export interface ReceivedHTMLExportPayload {
    product: ProductCode;
    data: MeasurementFullData[];
}

export const receivedHTMLExportMeasurements = (
    payload: ReceivedHTMLExportPayload
): PayloadAction<ReceivedHTMLExportPayload> => {
    return {
        type: actionTypes.RECEIVED_HTML_EXPORT_MEASUREMENTS,
        payload,
    };
};

export interface ImportMeasurementsPayload {
    measurements: ImportMeasurementData[];
    product: ProductCode;
    jobID: string;
}

export const importMeasurements = (payload: ImportMeasurementsPayload): PayloadAction<ImportMeasurementsPayload> => {
    return {
        type: actionTypes.IMPORT_MEASUREMENTS,
        payload,
    };
};

export interface ReceivedImportMeasurementsStatusPayload
    extends Omit<ImportMeasurementsPayload, 'measurements' | 'jobID'> {
    measurements: Omit<ImportMeasurementData, 'timestamp'>[];
}

export const receivedImportMeasurementsStatus = (
    payload: ReceivedImportMeasurementsStatusPayload
): PayloadAction<ReceivedImportMeasurementsStatusPayload> => {
    return {
        type: actionTypes.RECEIVED_IMPORT_MEASUREMENTS_STATUS,
        payload,
    };
};

export interface RemoveImportMeasurementPayload {
    id: string;
    product: ProductCode;
}

export const removeImportMeasurement = (
    payload: RemoveImportMeasurementPayload
): PayloadAction<RemoveImportMeasurementPayload> => {
    return {
        type: actionTypes.REMOVE_IMPORT_MEASUREMENT,
        payload,
    };
};
