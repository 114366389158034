// @flow
import { FormatMessageFunc } from '../../../../types';
import { CustomMaterialContent, CustomMaterialItem } from '../../../../types/customMaterial';
import { ProductCode } from '../../../../types/proceq';
import { getLabelFromGlobalDataKey } from '../../RegisteredInfo/helper';
import {
    getMaterialNameOfMaterialID,
    getResolutionForScale,
    getScaleInfoOfScaleID,
    getSchmidtScaleIdOfFromFactorAndUnitID,
    getStandardNameOfStandardID,
} from './conversionsHelper';
import handleFormatMessage from './handleFormatMessage';
import { ProbeTypeID } from '../../../../types/measurement';

export interface ValueSet {
    TITLE: string;
    VALUE: string | number;
}

export interface InfoForDataMeasurements extends CustomMaterialItem {
    json: CustomMaterialContent;
    SETTINGS: {
        [key in
            | 'SOURCEUNIT'
            | 'SOURCENAME'
            | 'TARGETUNIT'
            | 'TARGETNAME'
            | 'UPPERLIMIT'
            | 'LOWERLIMIT'
            | 'BASEMATERIAL'
            | 'BASESTANDARD'
            | 'UPPERLIMITWITHUNITS'
            | 'LOWERLIMITWITHUNITS'
            | 'CURVETYPE'
            | 'CURVEPARAMA'
            | 'CURVEPARAMC'
            | 'CURVEPARAMB']?: ValueSet;
    };
    MEASUREMENTS: {
        [key in 'SOURCEVALUE' | 'TARGETVALUE']?: ValueSet;
    };
}

export const getAllParsedDataNeeded = (
    data: CustomMaterialItem,
    productType: ProductCode,
    formatMessage: FormatMessageFunc
) => {
    const toReturn: InfoForDataMeasurements = {
        ...data,
        json: data.content,
        SETTINGS: {},
        MEASUREMENTS: {},
    };
    let sourceScaleUnit = null;
    let sourceScaleName = null;
    const probeTypeId = toReturn.json.probeTypeId;

    switch (productType) {
        case 'EQUOTIP':
            sourceScaleUnit = 'HLD';
            sourceScaleName = 'Leeb D';
            break;
        case 'SCHMIDT':
            sourceScaleUnit = handleFormatMessage(
                getLabelFromGlobalDataKey('schmidtsourcescale', probeTypeId) ?? '',
                formatMessage
            );
            sourceScaleName = handleFormatMessage(
                getLabelFromGlobalDataKey('schmidtsourcescalename', probeTypeId) ?? '',
                formatMessage
            );
            break;
    }

    let targetScaleUnit = sourceScaleUnit;
    let targetScaleName = sourceScaleName;
    if (toReturn.json.sourceScaleId !== undefined) {
        sourceScaleUnit = getScaleInfoOfScaleID(formatMessage, toReturn.json.sourceScaleId).scaleUnits;
        sourceScaleName = getScaleInfoOfScaleID(formatMessage, toReturn.json.sourceScaleId).scaleName;
    }

    if (toReturn.json.targetScaleId !== undefined) {
        targetScaleUnit = getScaleInfoOfScaleID(formatMessage, toReturn.json.targetScaleId).scaleUnits;
        targetScaleName = getScaleInfoOfScaleID(formatMessage, toReturn.json.targetScaleId).scaleName;
    } else if (toReturn.json.formFactorId !== undefined && toReturn.json.scaleId !== undefined) {
        const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitID(toReturn.json.scaleId, toReturn.json.formFactorId);
        targetScaleUnit = getScaleInfoOfScaleID(formatMessage, targetScaleId).scaleUnits;
        targetScaleName = getScaleInfoOfScaleID(formatMessage, targetScaleId).scaleName;
    } else {
        targetScaleUnit = 'R';
        targetScaleName = formatMessage({ id: 'App.SchmidtFormFactorRAngle' });
    }

    if (sourceScaleUnit !== null)
        toReturn.SETTINGS.SOURCEUNIT = { TITLE: 'Proceq.ExportFieldUnit', VALUE: sourceScaleUnit };
    if (sourceScaleName !== null)
        toReturn.SETTINGS.SOURCENAME = { TITLE: 'Proceq.ExportFieldName', VALUE: sourceScaleName };
    if (targetScaleUnit !== null)
        toReturn.SETTINGS.TARGETUNIT = { TITLE: 'Proceq.ExportFieldUnit', VALUE: targetScaleUnit };
    if (targetScaleName !== null)
        toReturn.SETTINGS.TARGETNAME = { TITLE: 'Proceq.ExportFieldName', VALUE: targetScaleName };

    if (toReturn.json.sourceValue !== undefined) {
        toReturn.MEASUREMENTS.SOURCEVALUE = {
            TITLE: ' ',
            VALUE: `${getResolutionForScale(null, String(toReturn.json.sourceValue))} ${
                toReturn.SETTINGS?.SOURCEUNIT?.VALUE
            }`,
        };
    }
    if (toReturn.json.targetValue !== undefined) {
        toReturn.MEASUREMENTS.TARGETVALUE = {
            TITLE: ' ',
            VALUE: `${getResolutionForScale(null, String(toReturn.json.targetValue))} ${
                toReturn.SETTINGS?.TARGETUNIT?.VALUE
            }`,
        };
    }

    if (toReturn.json.targetLimitMax !== undefined) {
        const upperLimValue = toReturn.json.targetLimitMax;
        const lowerLimValue = toReturn.json.targetLimitMin;

        if (targetScaleUnit) {
            toReturn.SETTINGS.UPPERLIMIT = {
                TITLE: 'Proceq.ExportFieldUpperLimit',
                VALUE: getResolutionForScale(toReturn.SETTINGS.TARGETUNIT?.VALUE, String(upperLimValue)),
            };
            toReturn.SETTINGS.LOWERLIMIT = {
                TITLE: 'Proceq.ExportFieldLowerLimit',
                VALUE: getResolutionForScale(toReturn.SETTINGS.TARGETUNIT?.VALUE, String(lowerLimValue)),
            };
            toReturn.SETTINGS.UPPERLIMITWITHUNITS = {
                TITLE: 'Proceq.ExportFieldUpperLimit',
                VALUE: `${toReturn.SETTINGS.UPPERLIMIT.VALUE} ${toReturn.SETTINGS.TARGETUNIT?.VALUE}`,
            };
            toReturn.SETTINGS.LOWERLIMITWITHUNITS = {
                TITLE: 'Proceq.ExportFieldLowerLimit',
                VALUE: `${toReturn.SETTINGS.LOWERLIMIT.VALUE} ${toReturn.SETTINGS.TARGETUNIT?.VALUE}`,
            };
        }
    }

    if (toReturn.json.curveTypeId !== undefined) {
        let curveTypeStr: string = '';
        if (probeTypeId === ProbeTypeID.SchmidtL || probeTypeId === ProbeTypeID.SchmidtN) {
            curveTypeStr = handleFormatMessage(
                getLabelFromGlobalDataKey('originalschmidtcurvetypes', toReturn.json.curveTypeId) ?? '',
                formatMessage
            );
        } else {
            curveTypeStr = handleFormatMessage(
                getLabelFromGlobalDataKey('silverschmidtcurvetypes', toReturn.json.curveTypeId) ?? '',
                formatMessage
            );
        }
        toReturn.SETTINGS.CURVETYPE = { TITLE: 'Proceq.ExportFieldCurveType', VALUE: curveTypeStr };
        switch (toReturn.json.curveTypeId) {
            case 0: // polynomial
                toReturn.SETTINGS.CURVEPARAMA = { TITLE: 'a', VALUE: toReturn.json.coefficients[0] };
                toReturn.SETTINGS.CURVEPARAMB = { TITLE: 'b', VALUE: toReturn.json.coefficients[1] };
                toReturn.SETTINGS.CURVEPARAMC = { TITLE: 'c', VALUE: toReturn.json.coefficients[2] };
                break;
            case 1: // exponencial
                toReturn.SETTINGS.CURVEPARAMA = { TITLE: 'a', VALUE: toReturn.json.coefficients[0] };
                toReturn.SETTINGS.CURVEPARAMB = { TITLE: 'b', VALUE: toReturn.json.coefficients[1] };
                break;
        }
    }

    if (toReturn.json.baseMaterialId !== undefined) {
        toReturn.SETTINGS.BASEMATERIAL = {
            TITLE: 'Proceq.ExportFieldMaterial',
            VALUE: getMaterialNameOfMaterialID(null, formatMessage, toReturn.json.baseMaterialId),
        };
    }
    if (toReturn.json.baseStandardId !== undefined) {
        toReturn.SETTINGS.BASESTANDARD = {
            TITLE: 'Proceq.ExportFieldStandard',
            VALUE: getStandardNameOfStandardID(null, formatMessage, toReturn.json.baseStandardId, productType),
        };
    }
    return toReturn;
};

export const getSettingsParametersForProduct = (productType: ProductCode) => {
    let parameters: string[] = [];
    switch (productType) {
        case 'EQUOTIP':
            parameters = [
                'SETTINGS.UPPERLIMITWITHUNITS',
                'SETTINGS.LOWERLIMITWITHUNITS',
                'SETTINGS.BASEMATERIAL',
                'SETTINGS.BASESTANDARD',
                'SETTINGS.TARGETNAME',
                'SETTINGS.TARGETUNIT',
            ];
            break;
        case 'SCHMIDT':
            parameters = [
                'SETTINGS.UPPERLIMITWITHUNITS',
                'SETTINGS.LOWERLIMITWITHUNITS',
                'SETTINGS.CURVETYPE',
                'SETTINGS.CURVEPARAMA',
                'SETTINGS.CURVEPARAMB',
                'SETTINGS.CURVEPARAMC',
                'SETTINGS.TARGETNAME',
                'SETTINGS.TARGETUNIT',
            ];
            break;
    }
    return parameters;
};
export const getPairValueForProduct = (productType: ProductCode) => {
    let parameters: string[] = [];
    switch (productType) {
        case 'EQUOTIP':
            parameters = ['MEASUREMENTS.SOURCEVALUE', 'MEASUREMENTS.TARGETVALUE'];
            break;
    }
    return parameters;
};
