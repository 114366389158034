export const GLOBAL_REGISTERED_INFO = {
    availproductsfull: {
        GPR: 'Proceq GPR Live',
        GPR_SOIL: 'Proceq GPR Subsurface',
        PUNDIT: 'Pundit Live Array',
        FDL: 'Proceq Flaw Detector Live',
        EQUOTIP: 'Equotip Live',
        GLM: 'Glossmeter Live',
        PIT_IE: 'Pundit Impact Live',
        SCHMIDT: 'Schmidt Live',
        GPR_INSIGHTS: 'GPR Insights',
        INSPECT: 'Inspect',
        PROFOMETER: 'Profometer',
        GPR_MOUNTED: 'GPR Mobile',
    },
    gprprobes: {
        GPR: 'GP8000',
        GPR_PALM: 'GP8800',
        GPR_GP8100: 'GP8100',
    },
    gprsoilprobes: {
        GPR_SOIL: 'GS8000',
        GS9000: 'GS9000',
    },
    gprmountedprobes: {
        GM8000: 'GM8000',
    },
    punditprobes: {
        PUNDIT: 'PD8000',
        PUNDIT_PD8050: 'PD8050',
    },
    fdlprobes: {
        FDL: 'UT8000',
    },
    schmidtprobes: {
        2: 'OS8000 L',
        3: 'OS8000 N',
        4: 'OS8200 L',
        5: 'OS8200 N',
        11: 'RS8000 L',
        12: 'RS8000 N',
    },
    equotipprobes: {
        0: 'LeebD',
        1: 'UCI',
    },
    glossmeterprobes: {
        6: 'ZG8000',
    },
    punditimpactprobes: {
        8: 'PI8000',
    },
    profometerprobes: {
        9: 'PM8000',
        13: 'PM8500',
    },
    latestavailproductsfull: {
        // schmidt not in this list as the names cannot be determined solely by product model
        GPR: 'GP8000',
        GPR_PALM: 'GP8800',
        GPR_GP8100: 'GP8100',
        GPR_SOIL: 'GS8000',
        GS9000: 'GS9000',
        GM8000: 'GM8000',
        PUNDIT: 'PD8000',
        PUNDIT_PD8050: 'PD8050',
        FDL: 'UT8000',
        LEEBD: 'Leeb D',
        UCI: 'UCI',
        GLM: 'ZG8000',
        PI8000: 'PI8000',
        PM8000: 'PM8000',
        PM8500: 'PM8500',
        UT8000: 'UT8000',
    },
    productmodel: {
        GPR: 'Proceq GP8000',
        GPR_PALM: 'Proceq GP8800',
        GPR_GP8100: 'Proceq GP8100',
        GPR_SOIL: 'Proceq GS8000',
        GS9000: 'Proceq GS9000',
        FDL: 'Proceq UT8000',
        PI8000: 'Pundit PI8000',
        PM8000: 'Proceq PM8000',
        PM8500: 'Proceq PM8500',
        GM8000: 'Proceq GM8000',
    },
    registerproducts: {
        leebd: 'Equotip Live Leeb D',
        leebd_rnt: 'Equotip Live Leeb D (Rental)',
        uci: 'Equotip Live UCI',
        schmidt: 'Original Schmidt Live',
    },
    gpr_scan_type: {
        'Line Scan': 'Line Scan',
        'Area Scan': 'Area Scan',
    },
    gpr_soil_scan_type: {
        'Line Scan': 'Line Scan',
        'Area Scan': 'Area Scan',
        'Free Path': 'Free Path',
        'Superline Scan': 'Superline Scan',
    },
    gpr_mounted_scan_type: {
        'Free Path': 'Free Path',
    },
    pundit_scan_type: {
        'Line Scan': 'Line Scan',
        'Stripe Scan': 'Full 3D Matrix',
        'Grid Scan': 'Grid Scan',
    },
    fdl_mode: {
        Conventional: 'A-scan',
        'Corrosion Map': 'Grid',
        'B-scan': 'B-scan',
    },
    pi_mode: {
        AScan: 'Pile Integrity',
        ImpactEchoSpot: 'Impact Echo Spot',
        ImpactEchoGrid: 'Impact Echo Grid',
    },
    profometer_mode: {
        'Line Scan': 'Line Scan',
        'Multi-Line Scan': 'Multi-Line Scan',
        'Spot Scan': 'Spot Scan',
        'Area Scan': 'Area Scan',
        Advanced: 'Advanced',
        Basic: 'Basic',
    },
    verificationstatuses: {
        failed: 'Proceq.ExportVerificationStatusFailed',
        passed: 'Proceq.ExportVerificationStatusPassed',
    },
    schmidtsourcescale: {
        2: 'Proceq.SchmidtSourceScaleR',
        3: 'Proceq.SchmidtSourceScaleR',
        4: 'Proceq.SchmidtSourceScaleQ',
        5: 'Proceq.SchmidtSourceScaleQ',
    },
    schmidtsourcescalename: {
        2: 'App.SchmidtFormFactorRAngle',
        3: 'App.SchmidtFormFactorRAngle',
        4: 'App.SchmidtFormFactorQAngle',
        5: 'App.SchmidtFormFactorQAngle',
    },
    standards: {
        1: 'Proceq.StandardEnumProceq',
        2: 'Proceq.StandardEnumISO18265',
        4: 'Proceq.StandardEnumASTME140',
        8: 'Proceq.StandardEnumJapan',
        15: 'Proceq.StandardEnumDefault',
        50: 'Proceq.eVerNStd_ISO_16859',
        51: 'Proceq.eVerNStd_ASTM_A956',
        52: 'Proceq.eVerNStd_ASTM_A1038',
        53: 'Proceq.eVerNStd_DIM_50195',
        999: 'Proceq.StandardEnumCustom',
    },
    standardsschmidt: {
        0: 'Proceq.eRebNStd_EN_12504_2',
        1: 'Proceq.eRebNStd_ASTM_C805',
        2: 'Proceq.eRebNStd_JGJ_T23',
        3: 'Proceq.eRebNStd_JSCE_G504',
        4: 'Proceq.eRebNStd_Mean',
        5: 'Proceq.Schmidt.Standard.Median',
        6: 'Proceq.Schmidt.Standard.ASTM',
        8: 'Proceq.eRebNStd_JIS',
        9: 'Proceq.Schmidt.Standard.ISRM',
        10: 'Proceq.Schmidt.Standard.WG',
        50: 'Proceq.eVerNStd_Proceq',
    },
    standardsschmidtFilter: {
        0: 'Proceq.eRebNStd_EN_12504_2',
        1: 'Proceq.eRebNStd_ASTM_C805',
        2: 'Proceq.eRebNStd_JGJ_T23',
        3: 'Proceq.eRebNStd_JSCE_G504',
        4: 'Proceq.eRebNStd_Mean',
        5: 'Proceq.Schmidt.Standard.Median',
        6: 'Proceq.Schmidt.Standard.ASTM',
        8: 'Proceq.eRebNStd_JIS',
        9: 'Proceq.Schmidt.Standard.ISRM',
        10: 'Proceq.Schmidt.Standard.WG',
    },
    unitsschmidt: {
        0: 'R',
        1: 'N/mm²',
        2: 'MPa',
        3: 'psi',
        4: 'kg/cm²',
        5: 'GPa',
        6: 'Q',
    },
    formfactorschmidt: {
        0: '150 mm cube',
        1: '2:1 cylinder',
        2: '1:1 core',
    },
    originalschmidtcurvetypes: {
        0: 'Proceq.SchmidtCurveTypePolynomialR',
        1: 'Proceq.SchmidtCurveTypeExponentialR',
    },
    silverschmidtcurvetypes: {
        0: 'Proceq.SchmidtCurveTypePolynomialQ',
        1: 'Proceq.SchmidtCurveTypeExponentialQ',
    },
    schmidtcurvetypesshort: {
        0: 'Proceq.SchmidtCurveTypePolynomialShort',
        1: 'Proceq.SchmidtCurveTypeExponencialShort',
    },
    units: {
        2: 'HLD',
        3: 'HLS',
        4: 'HLE',
        5: 'HLDL',
        6: 'HLC',
        7: 'HLG',
        8: 'HLSVP',
        9: 'HL',
        10: 'HB',
        11: 'HV',
        12: 'HRA',
        13: 'HRB',
        14: 'HRBN',
        15: 'HRC',
        16: 'HRE',
        17: 'HRH',
        18: 'HR15N',
        19: 'HR15T',
        20: 'HMMRC',
        21: 'HS',
        22: 'MPa',
        23: 'Custom',
        24: 'uM',
        25: 'HV(UCI)',
        26: 'HLU',
    },
    convcurveschmidt: {
        0: 'Proceq.eCC_None',
        1: 'Proceq.eCC_PROCEQ_B_N',
        2: 'Proceq.eCC_PROCEQ_A_N',
        3: 'Proceq.eCC_Portland_Cement_J_N',
        4: 'Proceq.eCC_Early_Strength_J_N',
        5: 'Proceq.eCC_Blast_Furnace_J_N',
        6: 'Proceq.eCC_Average_Curve_J_N',
        7: 'Proceq.eCC_M_JAP_N',
        8: 'Proceq.eCC_A_JAP_N',
        9: 'Proceq.eCC_T_JAP_N',
        10: 'Proceq.eCC_P_JAP_N',
        11: 'Proceq.eCC_PROCEQ_B_L',
        12: 'Proceq.eCC_PROCEQ_A_L',
        13: 'Proceq.eCC_SS_Low10_N',
        14: 'Proceq.eCC_SS_EU_N',
        15: 'Proceq.eCC_SS_CN_N',
        16: 'Proceq.eCC_SS_RUS_N',
        17: 'Proceq.eCC_SS_XX_L',
        18: 'Proceq.eCC_Characteristic_Strength_R',
        19: 'Proceq.eCC_Mean_Strength_Q',
        20: 'Proceq.eCC_Characteristic_Strength_Q',
        21: 'Proceq.eCC_Katz_Et_Al',
        22: 'Proceq.eCC_Aydin_Basu',
        999: 'Proceq.MaterialEnumCustom',
    },
    surfaceconditionschmidt: {
        0: 'Proceq.eSC_R1_dry',
        3: 'Proceq.eSC_R1_black_mark',
        5: 'Proceq.eSC_R1_wet_surface',
    },
    surfacestrengthandcorrschmidt: {
        '0_0': 'Proceq.eSCC_US_eCSC_None',
        '0_1': 'Proceq.eSCC_US_eCSC_Upper',
        '0_2': 'Proceq.eSCC_US_eCSC_Under',
        '1_0': 'Proceq.eSCC_PC_eCSC_None',
        '1_1': 'Proceq.eSCC_PC_eCSC_Upper',
        '1_2': 'Proceq.eSCC_PC_eCSC_Under',
    },
    materials: {
        1: 'Proceq.MaterialEnumIndependent',
        2: 'Proceq.MaterialEnumSteelAndCastSteel',
        3: 'Proceq.MaterialEnumWorkToolSteel',
        4: 'Proceq.MaterialEnumStainlessSteel',
        5: 'Proceq.MaterialEnumGrayCastIron',
        6: 'Proceq.MaterialEnumNodularCastIron',
        7: 'Proceq.MaterialEnumCastAlluminiumAlloys',
        8: 'Proceq.MaterialEnumBrassCopperZincAlloys',
        9: 'Proceq.MaterialEnumBronze',
        10: 'Proceq.MaterialEnumWroughtCopperAlloys',
        11: 'Proceq.MaterialEnumTitanium',
        999: 'Proceq.MaterialEnumCustom',
    },
    scales: {
        2: 'Proceq.ScaleEnumLeebD',
        3: 'Proceq.ScaleEnumLeebS',
        4: 'Proceq.ScaleEnumLeebE',
        5: 'Proceq.ScaleEnumLeebDL',
        6: 'Proceq.ScaleEnumLeebC',
        7: 'Proceq.ScaleEnumLeebG',
        8: 'Proceq.ScaleEnumLeebSVP',
        9: 'Proceq.ScaleEnumLeeb',
        10: 'Proceq.ScaleEnumBrinell',
        11: 'Proceq.ScaleEnumVickers',
        12: 'Proceq.ScaleEnumRockwellA',
        13: 'Proceq.ScaleEnumRockwellB',
        14: 'Proceq.ScaleEnumRockwellBN',
        15: 'Proceq.ScaleEnumRockwellC',
        16: 'Proceq.ScaleEnumRockwellE',
        17: 'Proceq.ScaleEnumRockwellH',
        18: 'Proceq.ScaleEnumRockwell15N',
        19: 'Proceq.ScaleEnumRockwell15T',
        20: 'Proceq.ScaleEnumRockwell',
        21: 'Proceq.ScaleEnumShore',
        22: 'Proceq.ScaleEnumMPa',
        23: 'Proceq.ScaleEnumCustom',
        24: 'Proceq.ScaleEnumPenetrationDepth',
        25: 'Proceq.ScaleEnumVickersUCI',
        26: 'Proceq.ScaleEnumLeebU',
    },
};

export type GlobalInfoKey = keyof typeof GLOBAL_REGISTERED_INFO;
