import React, { useMemo } from 'react';
import { Table } from 'antd';
import { has } from 'lodash';
import { MeasurementFullData, MeasurementObject, MeasurementReading } from '../../../../../types/measurement';
import styles from '../../DataViewers.styl';
import { Log } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { DerivedProps } from '../../SharedTypes';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import ConvertedUnits from '../../shared/ConvertedUnits';

export type MeasurementObjectWithInfo = MeasurementObject & {
    readingContent: MeasurementReading['content'];
    logContent: Log['content'];
    sequenceNo: number;
};

export const title = <FormattedMessage id="UtilitiesLine" />;

interface UtilityData {
    group?: string;
    number: string | number;
    color: string;
    setSize: string | number;
    comment: string;
    textColor: boolean;
    convert: boolean;
}

export const UtilitiesLine: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, isMetric, convert, showTitle, scanType } = props;

    const utilitiesData = useMemo(() => {
        const groupCount: { [key: string]: number } = {};
        const utilitiesData: UtilityData[] = [];
        data.objects.forEach((item) => {
            if (item.type === 'utility') {
                let color = '';
                let group = '';
                if (has(item, 'content.category.type.utilities')) {
                    color = item.content.category.type.utilities;
                }
                if (has(item, 'content.category.name')) {
                    group = item.content.category.name;
                }
                let number = groupCount[group] + 1;
                if (groupCount[group] === undefined) {
                    number = 1;
                }
                groupCount[group] = number;
                const setSize = item.content.sizeMeter ?? '-';
                const comment = item.content.comment ?? '-';

                utilitiesData.push({
                    group,
                    number,
                    color,
                    setSize,
                    comment,
                    textColor: false,
                    convert: false,
                });
            }
        });

        return utilitiesData.map((item, index) => ({ ...item, key: index }));
    }, [data.objects]);

    return (
        <div className="table-objects">
            <Table
                title={showTitle ? () => <span className={styles.main_header}>{title}</span> : undefined}
                className={styles.table}
                columns={[
                    {
                        title: 'Group',
                        dataIndex: 'group',
                        width: 60,
                    },
                    {
                        title: 'Number',
                        dataIndex: 'number',
                        width: 40,
                    },
                    {
                        title: 'Colour',
                        dataIndex: 'color',
                        render: (text, record: any) =>
                            record.convert ? convert(text, 'GPR_SOIL.Utilities.Line') : text,
                        width: 40,
                    },
                    {
                        title: (
                            <ConvertedUnits
                                id="App.HTML.GPR_SOIL.Utilities.SetSize"
                                unitId="GPR_SOIL.Logbook Panel.Set Size"
                                scanType={scanType}
                                isMetric={isMetric}
                            />
                        ),
                        dataIndex: 'setSize',
                        render: (text) => {
                            return convert(text, 'GPR_SOIL.Logbook Panel.Set Size');
                        },
                        width: 40,
                    },
                    {
                        title: 'Comment',
                        dataIndex: 'comment',
                        render: (text, record: any) =>
                            record.convert ? convert(text, 'GPR_SOIL.Utilities.Line') : text,
                        width: 80,
                    },
                ]}
                rowClassName={(record) => (record.textColor ? styles.utilities_line : '')}
                dataSource={utilitiesData}
                pagination={false}
                size="small"
                locale={tableLocaleSetting}
                bordered
            />
        </div>
    );
};

export default UtilitiesLine;
