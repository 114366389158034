import { produce } from 'immer';
import { AnyAction, Reducer } from 'redux';
import actionTypes, { PayloadAction } from '../actions/actionTypes';
import { ReceivedProbeInfoPayload, ReceivedProbeListPayload } from '../actions/probe';
import { ProbeBasicInfo, ProbeContractInfo } from '../types/probe';
import { normalizer } from '../utils/genericNormalizer';

export type ProbeInfoState = ProbeBasicInfo & Partial<ProbeContractInfo> & { fetchedTimestamp?: number };

export interface ProbeState {
    probeIDs: string[];
    probes: {
        [key: string]: ProbeInfoState;
    };
}

export const initialState: ProbeState = {
    probeIDs: [],
    probes: {},
};

export const makeProbeReducer = (myInitialState: ProbeState = initialState): Reducer<ProbeState, AnyAction> =>
    produce((draft = myInitialState, action) => {
        switch (action.type) {
            case actionTypes.RECEIVED_PROBE_LIST: {
                const { payload } = action as PayloadAction<ReceivedProbeListPayload>;
                const { probeList } = payload;
                probeList.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber));
                const { entityIds, entities } = normalizer(probeList, 'serialNumber');

                draft.probeIDs = entityIds;
                for (const id of entityIds) {
                    draft.probes[id] = entities[id];
                }
                return draft;
            }

            case actionTypes.RECEIVED_PROBE_INFO: {
                const { payload } = action as PayloadAction<ReceivedProbeInfoPayload>;
                const { probeSN, probeInfo } = payload;

                const currentInfo = draft.probes[probeSN];
                draft.probes[probeSN] = {
                    ...currentInfo,
                    ...probeInfo,
                    fetchedTimestamp: Date.now(),
                };
                return draft;
            }

            default: {
                return draft;
            }
        }
    }, myInitialState);

const probe = makeProbeReducer();

export default probe;
