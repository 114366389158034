import elementCreation from './Creation';
import elementAddRemoveImpact from './AddRemoveImpact';
import elementGeneric from './GenericLogEvent';
import elementMarkerChange from './MarkerChange';
import elementTextWithKeyValue from './TextWithKeyValue';
import elementImpactInAverage from './ImpactInAverage';
import elementGridInfo from './GridInfo';
import elementAddPileSpotComment from './AddPileSpotComment';
import elementAddRemoveSpot from './AddRemoveSpot';
import elementRenamePileSpot from './RenamePileSpot';
import elementSnapshot from './Snapshot';
import elementCalibrateSpotVelocity from './CalibrateSpotVelocity';
import getGPRLogElement, { EventLogElement } from '../GPR/getGPRLogElement';

enum PunditImpactLogType {
    Creation = 'Creation',
    AddNewPile = 'AddNewPile',
    RemovePile = 'RemovePile',
    RenamePile = 'RenamePile',
    AddNewPosition = 'AddNewPosition',
    RemovePosition = 'RemovePosition',
    ChangeExpectedLength = 'ChangeExpectedLength',
    ChangePosition = 'ChangePosition',
    AddNewImpact = 'AddNewImpact',
    RemoveImpact = 'RemoveImpact',
    PileStatusChange = 'PileStatusChange',
    MarkerChange = 'MarkerChange',
    TextWithKeyValues = 'TextWithKeyValues',
    CalibrateTimeGain = 'CalibrateTimeGain',
    CalibrateVelocity = 'CalibrateVelocity',
    CompareModeToggleSelectPosition = 'CompareModeToggleSelectPosition',
    CompareModeToggleSelectPile = 'CompareModeToggleSelectPile',
    DataExport = 'DataExport',
    ImpactInAverage = 'ImpactInAverage',
    GridInfo = 'GridInfo',
    AddPileComment = 'AddPileComment',
    AddSpotComment = 'AddSpotComment',
    AddNewSpot = 'AddNewSpot',
    RemoveSpot = 'RemoveSpot',
    RenameImpactEcho = 'RenameImpactEcho',
    Snapshot = 'Snapshot',
    CalibrateSpotVelocity = 'CalibrateSpotVelocity',
}

const getPunditImpactLogElement = (logType: string | PunditImpactLogType): EventLogElement => {
    switch (logType) {
        case PunditImpactLogType.Creation:
            return elementCreation;
        case PunditImpactLogType.AddNewPosition:
        case PunditImpactLogType.AddNewPile:
        case PunditImpactLogType.RemovePile:
        case PunditImpactLogType.ChangePosition:
        case PunditImpactLogType.RemovePosition:
        case PunditImpactLogType.ChangeExpectedLength:
        case PunditImpactLogType.CalibrateTimeGain:
        case PunditImpactLogType.CalibrateVelocity:
        case PunditImpactLogType.CompareModeToggleSelectPosition:
        case PunditImpactLogType.CompareModeToggleSelectPile:
        case PunditImpactLogType.DataExport:
        case PunditImpactLogType.PileStatusChange:
            return elementGeneric;
        case PunditImpactLogType.ImpactInAverage:
            return elementImpactInAverage;
        case PunditImpactLogType.MarkerChange:
            return elementMarkerChange;
        case PunditImpactLogType.RemoveImpact:
        case PunditImpactLogType.AddNewImpact:
            return elementAddRemoveImpact;
        case PunditImpactLogType.TextWithKeyValues:
            return elementTextWithKeyValue;
        case PunditImpactLogType.GridInfo:
            return elementGridInfo;
        case PunditImpactLogType.AddPileComment:
        case PunditImpactLogType.AddSpotComment:
            return elementAddPileSpotComment;
        case PunditImpactLogType.AddNewSpot:
        case PunditImpactLogType.RemoveSpot:
            return elementAddRemoveSpot;
        case PunditImpactLogType.RenameImpactEcho:
        case PunditImpactLogType.RenamePile:
            return elementRenamePileSpot;
        case PunditImpactLogType.Snapshot:
            return elementSnapshot;
        case PunditImpactLogType.CalibrateSpotVelocity:
            return elementCalibrateSpotVelocity;
        default:
            return getGPRLogElement(logType);
    }
};

export default getPunditImpactLogElement;
