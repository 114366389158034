import { useEffect } from 'react';
import useImportMeasurementCount from './useImportMeasurementCount';
import useRefWithChange from '../../../hooks/useRefWithChange';

export default function useImportMeasurementDidSuccess(handler: () => void) {
    const { importCount, prevImportCount } = useImportMeasurementCount();

    const handlerRef = useRefWithChange(handler);

    // Use ref - We want to ensure it's only called when prevPendingCount < pendingCount.
    // We do not want the changes in handler to dirty the useEffect.
    useEffect(() => {
        if (importCount < prevImportCount) {
            handlerRef.current();
        }
    }, [importCount, prevImportCount, handlerRef]);
}
