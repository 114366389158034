import React from 'react';
import styles from './InitialsPlaceholder.styl';

interface InitialsPlaceholderProps {
    firstName?: string;
    lastName?: string;
    size: number;
}

const InitialsPlaceholder: React.FunctionComponent<InitialsPlaceholderProps> = (props) => {
    const { firstName, lastName, size } = props;
    return (
        <div className={styles.initials_wrapper} style={{ fontSize: size }}>
            {firstName && <span className={styles.letter}>{firstName[0]}</span>}
            {lastName && <span className={styles.letter}>{lastName[0]}</span>}
        </div>
    );
};

export default InitialsPlaceholder;
