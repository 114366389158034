import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';

const LOG_TYPE_NAME: Record<string, FormatIDs> = {
    nrcEnabled: 'App.HTML.Profometer.MeasurementSettings.NRC',
    autoPotentialShiftEnabled: 'App.HTML.Profometer.Logbook.AutoPotentialShift',
};

const SettingsEnabled: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id={
            log.content.enabled
                ? 'App.HTML.Profometer.Logbook.SettingsEnabled'
                : 'App.HTML.Profometer.Logbook.SettingsDisabled'
        }
        values={{ settings: <FormattedMessage id={LOG_TYPE_NAME[log.type] ?? ' '} /> }}
    />
);

export default {
    element: SettingsEnabled,
};
