import React from 'react';
import { BluetoothFileType } from '../../types/measurement';
import FormattedMessage from '../../localization/FormatMessage';
import { stringEnumToArray } from '../../utils/generalUtils';
import { FolderItemProps } from './DataViewFolder';
import * as icons from './FolderIcons';
import MenuList from '../shared/MenuList';
import { useDataViewContext } from './DataViewProvider';
import FolderName from './FolderName';
import { useCustomMaterialDataCount, useVerificationDataCount } from '../../queries/measurementQueries';

export const bluetoothFolderTypes = stringEnumToArray(BluetoothFileType);

const bluetoothFiles: { [key: string]: FolderItemProps } = {
    [BluetoothFileType.VerificationData]: {
        icon: icons.IconVerification,
        title: <FormattedMessage id="DataView.Folder.VerificationData" />,
    },
    [BluetoothFileType.CustomMaterial]: {
        icon: icons.IconCustomMaterial,
        title: <FormattedMessage id="DataView.Folder.CustomMaterials" />,
    },
};

interface BluetoothMenuListProps {
    activeItem?: string;
    onSelect?: (key: string) => void;
}

const BluetoothMenuList: React.FunctionComponent<BluetoothMenuListProps> = (props) => {
    const { activeItem, onSelect } = props;

    const { product, withUnsynced } = useDataViewContext();

    const { data: customMaterialCount = 0 } = useCustomMaterialDataCount(product, withUnsynced);
    const { data: verificationCount = 0 } = useVerificationDataCount(product, withUnsynced);
    const bluetoothMenuTotalRecords = { customMaterial: customMaterialCount, verification: verificationCount };

    const bluetoothFileTypes = bluetoothFolderTypes.map((type) => {
        const staticFolder = bluetoothFiles[type];
        const Icon = staticFolder?.icon;
        const title = staticFolder?.title;
        return {
            id: type,
            title: <FolderName name={title} icon={<Icon />} />,
            count: bluetoothMenuTotalRecords ? bluetoothMenuTotalRecords[type] : 0,
        };
    });

    return <MenuList menuItems={bluetoothFileTypes} activeItem={activeItem} onSelect={onSelect} />;
};

export default BluetoothMenuList;
