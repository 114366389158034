import React from 'react';
import { Log } from '../../../../../types/logs';
import { useProbeImage } from '../../../../../hooks/useProbes';
import { GPRProbeContent } from '../GPR/GPRProbeContent';
import { ProductCode, ProductModel } from '../../../../../types/proceq';
import { EquotipProbeContent } from '../Equotip/EquotipProbeContent';
import { GLMProbeContent } from '../GLM/GLMProbeContent';
import { useMeasurement } from '../../../../../hooks/useProductData';

interface ProbeProps {
    log: Log;
    productModel: ProductModel;
    product: ProductCode;
}

export const Probe: React.FunctionComponent<ProbeProps> = (props) => {
    const { log, productModel, product } = props;
    const { content, mID } = log;
    const probeImage = useProbeImage(content.probeSerialNo, productModel);
    const measurement = useMeasurement(mID);

    const getProbeContent = () => {
        switch (product) {
            case ProductCode.GLM:
                return <GLMProbeContent content={content} productModel={productModel} />;
            case ProductCode.EQUOTIP:
            case ProductCode.SCHMIDT:
            case ProductCode.PROFOMETER:
                return <EquotipProbeContent content={content} productModel={productModel} />;
            case ProductCode.GPR_SOIL: {
                const isSoilExternalGPS = !!measurement.measurement.content.gnssInfo?.thirdPartyDevice;
                return (
                    <GPRProbeContent
                        content={content}
                        productModel={productModel}
                        isSoilExternalGPS={isSoilExternalGPS}
                    />
                );
            }
            default:
                return <GPRProbeContent content={content} productModel={productModel} />;
        }
    };

    return (
        <div className="full-container intro-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {probeImage && (
                    <div className="initial-image">
                        <img alt="" src={probeImage} />
                    </div>
                )}
                <div className="initial-content">{getProbeContent()}</div>
            </div>
        </div>
    );
};

export default {
    element: Probe,
};
