import React from 'react';
import { get } from 'lodash';
import { Table } from 'antd';
import styles from '../../DataViewers.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MeasurementFullData, MeasurementReading } from '../../../../../types/measurement';
import { FDLMeasurementPresetsProps } from './MeasurementPresets';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { DerivedProps } from '../../SharedTypes';
import { getGridFromNumber } from '../../utils/getGridFromNumber';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.DashboardCorrosionGrid" />
    </>
);

interface CorrosionGridProps extends FDLMeasurementPresetsProps, DerivedProps {
    data: MeasurementFullData;
}

export const CorrosionGrid: React.FunctionComponent<CorrosionGridProps> = ({ data, showTitle, unit, convert }) => {
    const startingX = get(data, 'settings.0.content.indicationStatus.corrosionGridInfo.startingPosition.xIndex', 0);
    const startingY = get(data, 'settings.0.content.indicationStatus.corrosionGridInfo.startingPosition.yIndex', 0) + 1;

    // the app view defaults to thickness, doesn't track if it was changed to amplitude
    const { convertFunction, unit: displayUnit } = convertAndDisplay(
        'FDL.CorrosionGrid.thickness',
        ScanType.Indication,
        unit
    );

    const columns = [
        {
            dataIndex: ['content', 'indicationPosition', 'gridPosition', 'xIndex'],
            title: <FormattedMessage id="App.HTML.FDL.MeasurementPresets.CorrosionGrid.Cells" />,
            render: (_value: any, record: MeasurementReading) => {
                const X = get(record, 'content.indicationPosition.gridPosition.xIndex', 0) + startingX;
                const Y = get(record, 'content.indicationPosition.gridPosition.yIndex', 0) + startingY;
                return getGridFromNumber(X) + Y;
            },
            className: styles.column,
        },
        {
            title: (
                <FormattedMessage
                    id="App.HTML.FDL.MeasurementPresets.gate1SoundPathDistanceWithUnits"
                    values={{ unit: displayUnit }}
                />
            ),
            render: (_value: any, record: MeasurementReading) => {
                const { thickness, time } = get(
                    record,
                    'content.indicationSettings.info.corrosionGridIndicationInfo',
                    {}
                );

                return unit === 'µs' ? convertFunction(time) : convertFunction(thickness);
            },
        },
        {
            title: <FormattedMessage id="App.HTML.FDL.MeasurementPresets.gate1CurrentAmplitude" />,
            render: (_value: any, record: MeasurementReading) => {
                const amplitude = record.content.indicationSettings.info.corrosionGridIndicationInfo.amplitude;
                return `${convert(amplitude, 'FDL.CorrosionGrid.amplitude')}%`;
            },
        },
        {
            title: (
                <FormattedMessage
                    id="App.HTML.FDL.MeasurementPresets.echoToEchoSoundPathWithUnits"
                    values={{ unit: displayUnit }}
                />
            ),
            render: (_value: any, record: MeasurementReading) => {
                const echoToEcho =
                    unit === 'µs'
                        ? record.content.indicationSettings.info.corrosionGridIndicationInfo.echoToEchoTime
                        : record.content.indicationSettings.info.corrosionGridIndicationInfo.echoToEchoDistance;
                return `${convertFunction(echoToEcho)}`;
            },
        },
    ];

    const dataSource = data.readings.slice().sort((a, b) => {
        const aX = get(a, 'content.indicationPosition.gridPosition.xIndex', 0);
        const aY = get(a, 'content.indicationPosition.gridPosition.yIndex', 0);

        const bX = get(b, 'content.indicationPosition.gridPosition.xIndex', 0);
        const bY = get(b, 'content.indicationPosition.gridPosition.yIndex', 0);

        if (aX === bX) {
            return aY - bY;
        }

        return aX - bX;
    });

    return (
        <Table
            title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : undefined}
            className={styles.table}
            columns={columns}
            dataSource={dataSource}
            rowKey="id"
            pagination={false}
            size="small"
            locale={tableLocaleSetting}
        />
    );
};

export default CorrosionGrid;
