import React from 'react';
import StyledModal from './StyledModal';
import FormattedMessage from '../../localization/FormatMessage';
import AnalyticsButton from '../AnalyticsComponents/Button';
import UploadDragger, { UploadDraggerProps } from './Upload/UploadDragger';
import styles from './Upload/UploadDragger.styl';

interface UploadModalProps extends UploadDraggerProps {
    visible: boolean;
    onClose: () => void;
    handlePreview: () => void;
    buttonDisabled: boolean;
    buttonLoading?: boolean;
}

const CustomCurveUploadModal: React.FunctionComponent<React.PropsWithChildren<UploadModalProps>> = (props) => {
    const { visible, onClose, supportedFormats, onDrop, handlePreview, buttonDisabled, buttonLoading } = props;

    return (
        <StyledModal
            width={856}
            forceRender
            onCancel={onClose}
            onOk={handlePreview}
            open={visible}
            title={<FormattedMessage id="DataView.Upload.UploadCustomCurves" />}
            footer={
                <AnalyticsButton
                    loading={buttonLoading}
                    disabled={buttonDisabled}
                    type="primary"
                    onClick={handlePreview}
                >
                    <FormattedMessage id="DataView.Upload.PreviewCurve" />
                </AnalyticsButton>
            }
            styles={{ body: { overflow: 'auto' } }}
        >
            <UploadDragger
                supportedFormats={supportedFormats}
                onDrop={onDrop}
                extraSubtitle={
                    <>
                        <br />
                        <p className={styles.guideline_text}>
                            <FormattedMessage
                                id="DataView.Upload.Guidelines"
                                values={{
                                    csv: <b>.csv</b>,
                                    yaml: <b>.yaml</b>,
                                }}
                            />
                        </p>
                    </>
                }
            />
            {props.children}
        </StyledModal>
    );
};

export default CustomCurveUploadModal;
