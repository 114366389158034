import config from '../config';

export const PROCEQ_MANAGEMENT_API_URL = `${config.PROCEQ_MANAGEMENT_DOMAIN}/api`;

class RegionalAPI {
    private region: string = '';
    private managementAPI: string = PROCEQ_MANAGEMENT_API_URL;
    private regionalAPI: string = '';

    getRegion = () => this.region;
    getRegionAPI = () => this.regionalAPI;
    getManagementAPI = () => this.managementAPI;

    init = (args: { region?: string; managementDomain?: string; regionalDomain?: string }) => {
        this.setRegion(args.region);
        this.setRegionAPI(args.regionalDomain);
        this.setManagementAPI(args.managementDomain);
    };

    setRegion = (region?: string) => {
        this.region = region || '';
    };

    setRegionAPI = (domain?: string) => {
        this.regionalAPI = domain ? `https://${domain}/api` : '';
    };

    setManagementAPI = (domain?: string) => {
        this.managementAPI = domain ? `https://${domain}/api` : PROCEQ_MANAGEMENT_API_URL;
    };
}

export const proceqURL = new RegionalAPI();
