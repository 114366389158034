import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';

export const TestLoad: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    const oldValue = `HV${log.content.oldTriggerLoadId}`;
    const newValue = `HV${log.content.updatedTriggerLoadId}`;
    return (
        <span>
            {log.content.oldTriggerLoadId ? (
                <FormattedMessage id="Proceq.LogbookPropertyTestLoadChangedFromTo" values={{ oldValue, newValue }} />
            ) : (
                <FormattedMessage id="Proceq.LogbookPropertyTestLoadChangedTo" values={{ newValue }} />
            )}
        </span>
    );
};

export default {
    element: TestLoad,
};
