import { useQuery } from '@tanstack/react-query';
import * as appService from '../api/appService';
import { ReactQueryKeys } from './queryKeys';

export const useActiveApps = (enabled?: boolean) =>
    useQuery({
        queryKey: ReactQueryKeys.activeApps,
        queryFn: appService.getActiveApps,
        enabled,
        refetchOnWindowFocus: false,
    });

export const useCMSNews = () =>
    useQuery({
        queryKey: ReactQueryKeys.cmsNews,
        queryFn: appService.getCMSNews,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) =>
            data?.data?.getNewsListing?.edges?.map((item) => ({
                title: item.node.title,
                id: item.node.id,
                imageURL: item.node.newsMainImage.fullpath,
            })) ?? [],
    });
