import React from 'react';
import { getProbeName } from '../../../../../hooks/useProbes';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { Log } from '../../../../../types/logs';
import { gprSoilProduct, ProductCode, ProductModel, productModelToFamilyMap } from '../../../../../types/proceq';
import { ContractExpirationDate } from '../../utils/LogDate';
import ProbeCommonContent from '../ProbeCommonContent';

interface SoilProbeContentProps {
    isSoilExternalGPS?: boolean;
}

interface GPRProbeContentProps extends SoilProbeContentProps {
    content: Log['content'];
    productModel?: ProductModel;
}

export const GPRProbeContent: React.FunctionComponent<GPRProbeContentProps> = (props) => {
    const { content, productModel, isSoilExternalGPS } = props;
    const title = getProbeName(productModel, content.probeModel);
    const isGPRSoil = productModel && productModelToFamilyMap[productModel] === ProductCode.GPR_SOIL;
    const showGPRModules = productModel === ProductModel.GS9000 || productModel === ProductModel.GM8000;

    return (
        <>
            <div>{productModel && <b>{title}</b>}</div>
            <div>
                <FormattedMessage
                    id={
                        productModel === 'FDL'
                            ? 'Proceq.LogbookPropertyBaseUnitNumber'
                            : 'Proceq.LogbookPropertyProbeSerialNumber'
                    }
                />
                {':'} {content.probeSerialNo}
            </div>
            <ProbeCommonContent content={content} />
            {!showGPRModules && (
                <div>
                    <FormattedMessage id="Proceq.LogbookPropertyMacAddress" />
                    {':'} {content.macAddress}
                </div>
            )}
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyContractType" />
                {':'} {content.contractType}
            </div>
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyContractValidity" />
                {':'} <ContractExpirationDate date={content.contractExpirationDate} />
            </div>
            {content.version && (
                <div>
                    <FormattedMessage id="Proceq.LogbookPropertyAppVersion" />
                    {':'} {content.version}
                </div>
            )}
            {showGPRModules &&
                content.modules &&
                content.modules.map((module: any, index: number) => (
                    <div key={`${module.name}-${index}`}>
                        <div>
                            <b>{module.name}</b>
                        </div>
                        <div>
                            <FormattedMessage id="Proceq.LogbookPropertyProbeSerialNumber" />
                            {':'} {module.serialNumber}
                        </div>
                    </div>
                ))}
            {isGPRSoil &&
                content.ma8000SerialNumber &&
                (isSoilExternalGPS ? (
                    <div>
                        <b>
                            <FormattedMessage id="App.HTML.GPR_SOIL.Product.ExternalPositioningDevice" />
                        </b>
                    </div>
                ) : (
                    <>
                        <div>
                            <b>{gprSoilProduct}</b>
                        </div>
                        <div>
                            <FormattedMessage id="Proceq.LogbookPropertyProbeSerialNumber" />
                            {':'} {content.ma8000SerialNumber}
                        </div>
                    </>
                ))}
        </>
    );
};
