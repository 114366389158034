import { FormattedMessage } from 'react-intl';
import React from 'react';
import styles from '../../DataViewers.styl';
import SectionTable from '../../shared/SectionTable';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.PUNDIT.Settings.Grid" />
    </>
);

export const getGridValue = (convert: (value: string | number, unitId: string) => string | number) => {
    return (row: RawSectionTableData) => {
        // skip conversion as grid spacing is not saved in default unit
        if (row.unitId && row.unitId === 'PUNDIT.GridScan.XYSpacing') {
            return row.value;
        }
        return row.unitId ? convert(row.value, row.unitId) : row.value;
    };
};

const Grid: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    isMetric,
    showTitle,
    convert,
}) => {
    const gridRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.PUNDIT.Grid.GridRows',
            value: data.measurement.content.gridScanData.layoutInfo.rows,
        },
        {
            label: 'App.HTML.PUNDIT.Grid.GridColumns',
            value: data.measurement.content.gridScanData.layoutInfo.columns,
        },
        {
            label: 'App.HTML.PUNDIT.Grid.GridStartingPointX',
            value: String.fromCharCode(
                'A'.charCodeAt(0) + data.measurement.content.gridScanData.layoutInfo.originOffset.xOffset
            ),
        },
        {
            label: 'App.HTML.PUNDIT.Grid.GridStartingPointY',
            value: data.measurement.content.gridScanData.layoutInfo.originOffset.yOffset + 1,
        },
        {
            label: 'App.HTML.PUNDIT.Grid.GridSpacingX',
            value: data.settings[0].content.display.gridDimension?.xSpacing,
            unitId: 'PUNDIT.GridScan.XYSpacing',
        },
        {
            label: 'App.HTML.PUNDIT.Grid.GridSpacingY',
            value: data.settings[0].content.display.gridDimension?.ySpacing,
            unitId: 'PUNDIT.GridScan.XYSpacing',
        },
    ];

    const grid = getConvertedSectionTableData(gridRaw, scanType, isMetric, getGridValue(convert));

    return (
        <SectionTable
            showTitle={showTitle}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={grid}
        />
    );
};

export default Grid;
