import { thunkGet } from './apiService';
import { getApiURL } from './ApiURL';
import { handleError } from './error';
import { ProbeBasicInfo, ProbeContractInfo } from '../types/probe';
import { receivedProbeInfo, receivedProbeList } from '../actions/probe';

export const getProbesList = async () => {
    type Response = ProbeBasicInfo[];
    const url = getApiURL('GET_PROBES');
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
        config: { timeout: 600000 },
    });
    if (Array.isArray(response)) {
        dispatch(receivedProbeList({ probeList: response }));
    }
    return response;
};

export const getProbeInfo = async (params: { probeSN: string; mLogID: string }) => {
    const { probeSN, mLogID } = params;
    type Response = ProbeContractInfo;
    const url = getApiURL('GET_PROBE_INFO').replace('{mLogID}', mLogID);
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        injectToken: true,
    });

    if (response) {
        dispatch(receivedProbeInfo({ probeSN, probeInfo: response }));
    }
};
