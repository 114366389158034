import React from 'react';
import { useIntl } from 'react-intl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import { ScanType } from '../../../../../types/proceq';
import { convertAndDisplay } from '../../utils/conversionRates';

export const Setting: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const formatMessage = useIntl().formatMessage;

    const { updatedContent } = log.content;
    const position = +log.content.position;

    const noUnits = [
        'type',
        'description',
        'measuringMode',
        'pulseWidth',
        'spatialAveraging',
        'unit',
        'rf',
        'triggerGate1',
        'triggerGate2',
        'setupFile',
    ];
    let unitToUse;
    if (!noUnits.includes(updatedContent.item) && updatedContent.unit) {
        const { unit } = convertAndDisplay(`FDL.${updatedContent.item}`, ScanType.Indication, updatedContent.unit);
        unitToUse = unit;
    }

    const item = formatMessage(
        {
            id: `App.Logbook.FDL.Item.${updatedContent.item}`,
        },
        {
            unit: unitToUse,
        }
    );

    let oldValue = updatedContent.oldValue;
    let newValue = updatedContent.newValue;
    const enumUnits = ['type', 'measuringMode', 'pulseWidth', 'rf', 'triggerGate1', 'triggerGate2'];
    if (enumUnits.includes(updatedContent.item)) {
        let itemType = updatedContent.item;
        if (updatedContent.item.includes('triggerGate')) {
            itemType = 'triggerGate';
        }

        if (oldValue !== undefined) {
            oldValue = formatMessage({ id: `App.Logbook.FDL.Item.${itemType}.${oldValue}` });
        }
        newValue = formatMessage({ id: `App.Logbook.FDL.Item.${itemType}.${newValue}` });
    }

    if (log.content.position === undefined) {
        return oldValue !== undefined ? (
            <FormattedMessage
                id="App.Logbook.FDL.Settings"
                values={{
                    item,
                    oldValue,
                    newValue,
                }}
            />
        ) : (
            <FormattedMessage
                id="App.Logbook.FDL.Indication.ChangeToNew"
                values={{
                    item,
                    newValue,
                }}
            />
        );
    }

    const indicationPosition =
        scanType === ScanType.BScan ? (
            ''
        ) : (
            <FormattedMessage id="App.Logbook.FDL.Indication.Position" values={{ position }} />
        );

    return oldValue !== undefined ? (
        <FormattedMessage
            id="App.Logbook.FDL.Indication.Changed"
            values={{
                indicationPosition,
                item,
                oldValue,
                newValue,
            }}
        />
    ) : (
        <FormattedMessage
            id="App.Logbook.FDL.Indication.Edited"
            values={{
                indicationPosition,
                item,
                newValue,
            }}
        />
    );
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');

export default {
    element: Setting,
    color,
};
