import React from 'react';
import { useProbeImage } from '../../../../../hooks/useProbes';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps, InitialInfoLog } from '../../../../../types/logs';
import { getLabelFromGlobalDataKey } from '../../../RegisteredInfo/helper';
import { renderLogbookDate } from '../../../../../utils/dateUtils';
import { VerifiedDate } from '../../utils/LogDate';

export const InitialInfo: React.FunctionComponent<EquotipLogbookEntryProps & { author?: string }> = (props) => {
    const log = props.log as unknown as InitialInfoLog;
    const { content } = log;
    const { author } = props;
    const productImage = useProbeImage(content.probeSerialNumber);

    return (
        <div className="full-container intro-container">
            <div>
                {productImage && (
                    <div className="initial-image">
                        <img alt="" src={productImage} />
                    </div>
                )}
                <div className="initial-content">
                    <div>
                        <b>{content.probeType}</b>
                    </div>
                    <div>
                        <FormattedMessage id="Proceq.LogbookPropertySerialNumberShort" />
                        {': '}
                        {content.probeSerialNumber}
                    </div>
                    <div>
                        <FormattedMessage id="Proceq.LogbookPropertyBluetoothModuleShort" />
                        {': '}
                        {content.btSerialNumber}
                    </div>
                    <div>
                        <FormattedMessage id="Proceq.LogbookPropertyVerified" />
                        {': '}
                        <VerifiedDate date={content.probeVerificationDate} />
                    </div>
                    {content.contractProductTypeID && (
                        <div>
                            <FormattedMessage id="Proceq.LogbookContractType" />
                            {getLabelFromGlobalDataKey('registerproducts', content.contractProductTypeID)}
                        </div>
                    )}
                    <div>
                        <FormattedMessage id="Proceq.LogbookPropertyContractValidUntil" />
                        {': '}
                        {content.contractExpirationDate &&
                            renderLogbookDate(content.contractExpirationDate.split(' ')[0])}
                    </div>
                    <div>
                        <FormattedMessage id="Proceq.LogbookPropertyOperator" />
                        {': '}
                        {author}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default {
    element: InitialInfo,
};
