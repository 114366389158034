import React from 'react';
import { useIntl } from 'react-intl';
import { FDLDGSIndicationInfo, FDLSetupFile } from '../../../../../types/measurement';
import { FDLMeasurementPresetsProps } from './MeasurementPresets';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatMessageFunc } from '../../../../../types';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import { INFINITY_SYMBOL, isInfinity } from '../../utils/conversionsHelper';
import SectionTable from '../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

const valueConversion = (unit: string) => {
    return (row: RawSectionTableData): string | number | React.ReactNode => {
        let value: string | number | undefined = row.value;
        if (row.unitId) {
            const { convertFunction } = convertAndDisplay(row.unitId, ScanType.Indication, unit);
            if (!row.escape && typeof row.value !== 'string') {
                value = convertFunction(Number(row.value));
            }
        }
        return value;
    };
};

function getCalibrationDataSource(
    setupFile: FDLSetupFile,
    indicationInfo: FDLDGSIndicationInfo,
    unit: string,
    formatMessage: FormatMessageFunc
) {
    const convertor = convertAndDisplay('FDL.setupFile.Attenuation', ScanType.Indication, unit);

    // For this section, the values are not saved in SI units in the measurement json.
    // However, the conversionRates expect it to be in SI. Thus, convert accordingly.
    // To check the unit of the saved parameter, refer : https://jira.screeningeagle.com/browse/FDL-958
    const dataSourceRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.FDL.IndicationInfo.ProbeType',
            value: setupFile.probeType,
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.ReferenceType',
            value: formatMessage({ id: 'App.HTML.FDL.IndicationInfo.Backwall' }),
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.calibratedDistance',
            value: indicationInfo.calibratedDistance ? indicationInfo.calibratedDistance / 1000 : '-',
            unitId: 'FDL.indicationInfo.CalibratedDistance',
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.CalibratedAmplitude',
            value: indicationInfo.calibratedAmplitude ?? '-',
            unitId: 'FDL.indicationInfo.CalibratedAmplitude',
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.Attenuation',
            value:
                unit === 'metric'
                    ? convertor.roundNumber((setupFile.dgsAttenuationCoeffCalib ?? 0) * 1000)
                    : convertor.roundNumber((setupFile.dgsAttenuationCoeffCalib ?? 0) * 25.4),
            unitId: 'FDL.setupFile.Attenuation',
            escape: true,
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.DeltaVk',
            value: setupFile.dgsDeltaVk,
            unitId: 'FDL.indicationInfo.CalibratedAmplitude',
        },
        {
            label: 'App.HTML.FDL.IndicationInfo.ErsMainCurve',
            value: setupFile.dgsErs / 1000,
            unitId: 'FDL.setupFile.DgsErs',
        },
    ];

    return getConvertedSectionTableData(
        dataSourceRaw,
        ScanType.Indication,
        unit,
        valueConversion(setupFile.unit ?? unit)
    );
}

function getSnapshotDataSource(setupFile: FDLSetupFile, indicationInfo: FDLDGSIndicationInfo, unit: string) {
    const convertor = convertAndDisplay('FDL.setupFile.Attenuation', ScanType.Indication, unit);

    // For this section, the values are not saved in SI units in the measurement json.
    // However, the conversionRates expect it to be in SI. Thus, convert accordingly.
    // To check the unit of the saved parameter, refer : https://jira.screeningeagle.com/browse/FDL-958
    const dataSourceRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.FDL.IndicationInfo.Attenuation',
            value:
                unit === 'metric'
                    ? convertor.roundNumber((setupFile.dgsAttenuationCoeffCalib ?? 0) * 1000)
                    : convertor.roundNumber((setupFile.dgsAttenuationCoeffCalib ?? 0) * 25.4),
            unitId: 'FDL.setupFile.Attenuation',
            escape: true,
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G1DistanceToDefect',
            value: indicationInfo.defectDistance ? indicationInfo.defectDistance / 1000 : '-',
            unitId: 'FDL.indicationInfo.CalibratedDistance',
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G1AmplitudeOfDefect',
            value: indicationInfo.defectAmplitude ?? '-',
            unitId: 'FDL.indicationInfo.CalibratedAmplitude',
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G1.Ers',
            value: isInfinity(indicationInfo.defectErs)
                ? INFINITY_SYMBOL
                : indicationInfo.defectErs
                  ? indicationInfo.defectErs / 1000
                  : '-',
            unitId: 'FDL.indicationInfo.CalibratedDistance',
            escape: isInfinity(indicationInfo.defectErs),
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G1DbToCurve',
            value: isInfinity(indicationInfo.defectDbToCurve) ? INFINITY_SYMBOL : indicationInfo.defectDbToCurve ?? '-',
            unitId: 'FDL.indicationInfo.CalibratedAmplitude',
            escape: isInfinity(indicationInfo.defectDbToCurve),
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G2DistanceToDefect',
            value: indicationInfo.defect2Distance ? indicationInfo.defect2Distance / 1000 : '-',
            unitId: 'FDL.indicationInfo.CalibratedDistance',
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G2AmplitudeOfDefect',
            value: indicationInfo.defect2Amplitude ?? '-',
            unitId: 'FDL.indicationInfo.CalibratedAmplitude',
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G2.Ers',
            value: isInfinity(indicationInfo.defect2Ers)
                ? INFINITY_SYMBOL
                : indicationInfo.defect2Ers
                  ? indicationInfo.defect2Ers / 1000
                  : '-',
            unitId: 'FDL.indicationInfo.CalibratedDistance',
            escape: isInfinity(indicationInfo.defect2Ers),
        },
        {
            label: 'App.HTML.FDL.DgsSnapshot.G2DbToCurve',
            value: isInfinity(indicationInfo.defect2DbToCurve)
                ? INFINITY_SYMBOL
                : indicationInfo.defect2DbToCurve ?? '-',
            unitId: 'FDL.indicationInfo.CalibratedAmplitude',
            escape: isInfinity(indicationInfo.defect2DbToCurve),
        },
    ];

    return getConvertedSectionTableData(
        dataSourceRaw,
        ScanType.Indication,
        unit,
        valueConversion(setupFile.unit ?? unit)
    );
}

const DGSCalibrationAndSnapshot: React.FunctionComponent<
    { setupFile: FDLSetupFile; indicationInfo: FDLDGSIndicationInfo } & FDLMeasurementPresetsProps
> = ({ setupFile, indicationInfo, number, showTitle, unit }) => {
    const formatMessage: FormatMessageFunc = useIntl().formatMessage;

    const calibrationTitle = (
        <>
            <FormattedMessage id="Proceq.ExportFieldSettings" />
            {' ­­» '}
            <FormattedMessage id="App.HTML.FDL.MeasurementPresets.DgsCalibration" />
            {' ­­» '}
            {number}
        </>
    );
    const calibrationDataSource = getCalibrationDataSource(setupFile, indicationInfo, unit, formatMessage);

    const snapshotTitle = (
        <>
            <FormattedMessage id="Proceq.ExportFieldSettings" />
            {' ­­» '}
            <FormattedMessage id="App.HTML.FDL.MeasurementPresets.DgsSnapshot" />
            {' ­­» '}
            {number}
        </>
    );
    const snapshotDataSource = getSnapshotDataSource(setupFile, indicationInfo, unit);

    return (
        <>
            <SectionTable showTitle={showTitle} title={calibrationTitle} dataSource={calibrationDataSource} />
            <SectionTable showTitle={showTitle} title={snapshotTitle} dataSource={snapshotDataSource} />
        </>
    );
};

export default DGSCalibrationAndSnapshot;
