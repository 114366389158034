import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const Limits: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const { newValue, oldValue, unit, angle, item } = log.content;
    return <FormattedMessage id="App.Logbook.GLM.LimitsChanged" values={{ oldValue, newValue, unit, angle, item }} />;
};

export const color = () => 'blue';

export default {
    element: Limits,
    color,
};
