import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { FormatIDs } from '../../../../../types';
import { EquotipLogbookEntryProps, Log } from '../../../../../types/logs';
import {
    getResolutionForScale,
    getScaleInfoOfScaleID,
    getSchmidtScaleIdOfFromFactorAndUnitID,
} from '../../utils/conversionsHelper';
import { ROCK_SCHMIDT_PROBE } from '../../../../../types/measurement';
import { ProductCode } from '../../../../../types/proceq';

enum OperationEvent {
    Added, // 0
    Excluded, // 1
    Included, // 2
    Deleted, // 3
}

export const Measurement: React.FunctionComponent<EquotipLogbookEntryProps> = ({
    log,
    product,
    productModel,
    isVerificationMode,
    primaryEquotipScaleId,
    primarySchmidtScaleUnit,
    primaryVerificationScaleId,
}) => {
    const formatMessage = useFormatMessage();
    const isRockSchmidt = productModel === ROCK_SCHMIDT_PROBE;
    const getScaleInfo = (scaleId: number | undefined) =>
        getScaleInfoOfScaleID(formatMessage, scaleId, productModel, isRockSchmidt);
    const primaryEquotipId = getScaleInfo(primaryEquotipScaleId).scaleUnits;
    const primaryVerificationId =
        product === ProductCode.SCHMIDT ? primarySchmidtScaleUnit : getScaleInfo(primaryVerificationScaleId).scaleUnits;
    const secondaryScaleId = getScaleInfo(log.content.measurementValue.secondaryScaleId).scaleUnits;

    let actionText: FormatIDs = ' ';
    switch (log.content.eventType) {
        case OperationEvent.Added:
            actionText = 'Proceq.LogbookPropertyMeasurementAdded';
            break;
        case OperationEvent.Excluded:
            actionText = 'Proceq.LogbookPropertyMeasurementExcluded';
            break;
        case OperationEvent.Included:
            actionText = 'Proceq.LogbookPropertyMeasurementIncluded';
            break;
        case OperationEvent.Deleted:
            actionText = 'Proceq.LogbookPropertyMeasurementDeleted';
            break;
        default:
            break;
    }

    let bodyContent = `#${log.content.measurementIndex} `;
    if (isVerificationMode) {
        bodyContent += `${getResolutionForScale(
            primaryVerificationId,
            log.content.measurementValue.primaryValue,
            'point'
        )} ${primaryVerificationId}`;
    } else {
        switch (product) {
            case 'EQUOTIP':
                bodyContent += `${getResolutionForScale(
                    primaryEquotipId,
                    log.content.measurementValue.primaryValue,
                    'point'
                )} ${primaryEquotipId} / ${getResolutionForScale(
                    secondaryScaleId,
                    log.content.measurementValue.secondaryValue,
                    'point'
                )} ${secondaryScaleId}`;

                break;
            case 'SCHMIDT':
                {
                    const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitID(
                        log.content.measurementValue.unitId,
                        log.content.measurementValue.formFactorId
                    );
                    bodyContent += `${getResolutionForScale(
                        primarySchmidtScaleUnit,
                        log.content.measurementValue.primaryValue,
                        'point',
                        false,
                        product
                    )} ${primarySchmidtScaleUnit} / ${getResolutionForScale(
                        getScaleInfo(targetScaleId).scaleUnits,
                        log.content.measurementValue.secondaryValue,
                        'point',
                        false,
                        product
                    )} ${getScaleInfo(targetScaleId).scaleName}`;
                }
                break;
            default:
                break;
        }
    }

    return (
        <span>
            {bodyContent} <FormattedMessage id={actionText} />
        </span>
    );
};

export const color = (log: Log) => {
    switch (log.content.eventType) {
        case OperationEvent.Added:
            return 'green';
        case OperationEvent.Excluded:
            return 'grey';
        case OperationEvent.Included:
            return 'green';
        case OperationEvent.Deleted:
            return 'red';
        default:
            return 'blue';
    }
};

export default {
    element: Measurement,
    color,
};
