import React from 'react';
import { useParams } from 'react-router';
import AppLayout from '../shared/AppLayout';
import FullMeasurementViewer from './FullMeasurementViewer';

const AppMeasurementView: React.FunctionComponent = () => {
    const { measurementID } = useParams<{ measurementID: string }>();

    return measurementID ? (
        <AppLayout>
            <FullMeasurementViewer measurementID={measurementID} />
        </AppLayout>
    ) : null;
};

export default AppMeasurementView;
