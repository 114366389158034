import { IntlShape } from 'react-intl';

export default function handleFormatMessage(
    messageId: string | number,
    formatMessage: IntlShape['formatMessage'],
    replaceStrings: string[] = []
) {
    const id = String(messageId);

    if (id) {
        if (id.indexOf('.') === -1) {
            const formattedString = formatMessage({ id: `Proceq.${id}` });
            if (formattedString === `Proceq.${id}`) {
                /* was not found. Remove the prefix */
                return id;
            }
            return replaceStringsIfNeeded(formattedString, replaceStrings);
        }
        return replaceStringsIfNeeded(formatMessage({ id }), replaceStrings);
    }
    return '';
}

export function replaceStringsIfNeeded(formattedString: string, replaceStrings: string[]) {
    let toReturnString = formattedString;
    if (typeof replaceStrings !== 'undefined' && replaceStrings instanceof Array) {
        for (let indexArray = 0; indexArray < replaceStrings.length; indexArray++) {
            toReturnString = toReturnString.replace(`**?${indexArray}**`, replaceStrings[indexArray]);
        }
    }
    return toReturnString;
}

export function formatWithIdentation(a: undefined | null | string, b: undefined | null | string) {
    if (a === undefined || a === null) {
        a = '';
    }
    const space = '\u00A0'.repeat((10 - a.length) * 2);
    return a + space + b;
}
