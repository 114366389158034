import { ProductCode, UnixMicroseconds } from '../types/proceq';
import { APIResponse, MeasurementAPIResponse } from '../types/api';
import { getApiURL } from './ApiURL';
import { thunkDelete, thunkGet, thunkPost } from './apiService';
import { handleError } from './error';
import { Folder } from '../types/measurement';
import * as measurementActions from '../actions/measurement';

interface GetMeasurementFoldersParams {
    product: ProductCode;
    withUnsynced?: boolean;
    archived?: boolean;
}

export const getMeasurementFolders = async (params: GetMeasurementFoldersParams) => {
    type Response = MeasurementAPIResponse<Folder[]>;
    const url = getApiURL('MEASUREMENT_FOLDERS');
    const { response, dispatch } = await thunkGet<Response>(url, {
        errorHandler: handleError,
        params: { ...params },
        injectToken: true,
    });

    dispatch(
        measurementActions.receivedFolders({
            product: params.product,
            folders: response.data,
            archived: params.archived,
        })
    );

    return response;
};

interface CreateFolderParams {
    product: ProductCode;
    name: string;
}

export const createFolder = async (params: CreateFolderParams) => {
    type Response = APIResponse<{ status: string }>;
    const url = getApiURL('MEASUREMENT_FOLDERS');
    await thunkPost<Response>(url, {
        errorHandler: handleError,
        params: { ...params },
        injectToken: true,
    });
};

export interface DeleteFolderResponse {
    passed: { id: string; fp: string; created: UnixMicroseconds; updated: UnixMicroseconds }[];
    failed: { id: string; reason: string }[];
}

export const deleteFolders = async (ids: string[]) => {
    const url = getApiURL('MEASUREMENT_FOLDERS');
    const { response } = await thunkDelete<DeleteFolderResponse>(url, {
        errorHandler: handleError,
        params: { ids },
        injectToken: true,
    });
    return response;
};

interface RenameFolderParams {
    id: string;
    name: string;
}

export const renameFolder = async (params: RenameFolderParams) => {
    const { id, name } = params;
    const url = getApiURL('RENAME_MEASUREMENT_FOLDER').replace('{folderID}', id);
    await thunkPost<Response>(url, {
        errorHandler: handleError,
        params: { name },
        injectToken: true,
    });
};

interface ArchiveRestoreFolderParams {
    id: string;
    product: ProductCode;
    isArchive: boolean;
}

export const archiveRestoreFolder = async (params: ArchiveRestoreFolderParams) => {
    const { id, product, isArchive } = params;
    const url = getApiURL('ARCHIVE_MEASUREMENT_FOLDER').replace('{folderID}', id);
    await thunkPost<ResponseType>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: {
            product,
            archived: isArchive,
        },
    });
};

export const deleteArchivedFolders = async (ids: string[]) => {
    const url = getApiURL('DELETE_ARCHIVED_MEASUREMENT_FOLDERS');
    const { response } = await thunkDelete<DeleteFolderResponse>(url, {
        errorHandler: handleError,
        injectToken: true,
        params: {
            ids,
        },
    });
    return response;
};
