import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './UserText.styl';
import Audio from '../../../../Attachments/Audio';
import { formatDateTimeDefault } from '../../../../../utils/dateUtils';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';

export const MarkerAudio: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    let id = 'Proceq.LogbookMarkerAudio';
    switch (log.content.status) {
        case 'added':
            id += 'Added';
            break;
        case 'deleted':
            id += 'Deleted';
            break;
    }

    if (scanType === 'Area Scan') {
        id += 'AtLine';
    }

    return (
        <div>
            {log.content.status === 'deleted' ? (
                <div className={styles.deleted}>
                    <FormattedMessage
                        id={id}
                        values={{
                            marker: log.content.sequence,
                            lineId: log.content.lineId,
                            date: formatDateTimeDefault(log.updated),
                        }}
                    />
                </div>
            ) : (
                <b>
                    <FormattedMessage
                        id={id}
                        values={{
                            marker: log.content.sequence,
                            lineId: log.content.lineId,
                        }}
                    />
                </b>
            )}

            {log.content.status === 'added' && (
                <>
                    <br />
                    <Audio mID={log.mID} aID={log.content.content} attachmentName={log.content.attachmentName} />
                </>
            )}
        </div>
    );
};

export const color = (log: Log) => (log.content.status === 'deleted' ? 'red' : 'green');

export default {
    element: MarkerAudio,
    color,
};
