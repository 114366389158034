import { useMemo } from 'react';
import { Log } from '../../../../types/logs';

const useLogEventsSeparator = (logs: Log[], sorter?: (a: Log, b: Log) => number) => {
    const { probeLog, otherLogs } = useMemo(() => {
        const otherLogs = [...logs];
        const probeLogIndex = otherLogs.findIndex((log) => log.type === 'probe');
        const probeLog = probeLogIndex > -1 ? otherLogs.splice(probeLogIndex, 1)[0] : null;
        if (sorter) {
            otherLogs.sort(sorter);
        }

        return {
            probeLog,
            otherLogs,
        };
    }, [logs, sorter]);

    return { probeLog, otherLogs };
};

export default useLogEventsSeparator;
