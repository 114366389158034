import React, { useState } from 'react';
import { FormItem, routeUtils } from 'tds-common-fe/src/lib/index';
import { Button, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './Masquerade.styl';
import { ReactComponent as EagleIDLogo } from '../../images/eagleWorkspaceVertical.svg';
import FormattedMessage from '../../localization/FormatMessage';
import AuthInput from '../Profile/FormElements/AuthInput';
import { useFormatMessage } from '../../localization/useFormatMessage';
import * as authService from '../../api/authService';
import config from '../../config';
import AuthButton from '../Profile/FormElements/AuthButton';
import { updateReadyUsers } from '../../utils/storageUtils';

interface MasqueradeFieldType {
    password: string;
}
class Item extends FormItem<MasqueradeFieldType> {}

interface MasqueradeParams {
    userID: string;
}

const Masquerade: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const { userID } = routeUtils.parseQuery<MasqueradeParams>(location.search);

    const onSubmit = async (values: any) => {
        setLoading(true);
        const { password } = values;
        if (password) {
            try {
                const data = await authService.getMasqueradeToken({
                    client_id: config.EAGLE_ID_CLIENT_ID || '',
                    password,
                    user_id: Number(userID),
                });
                if (data) {
                    await authService.validateToken(data, dispatch);
                    updateReadyUsers(userID);
                    navigate('/');
                }
            } catch {
                setLoading(false);
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.content_container}>
                <EagleIDLogo />
                {userID ? (
                    <div className={styles.content_wrapper}>
                        <div className={styles.body}>
                            <FormattedMessage id="Masquerade.User.Title" />
                        </div>
                        <Form form={form} layout="vertical" className={styles.form} onFinish={onSubmit as any}>
                            <Item
                                name="password"
                                className={styles.form_item}
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id="Login.Field.Password.Error.Empty" />,
                                    },
                                ]}
                            >
                                <AuthInput
                                    authInputType="password"
                                    placeholder={formatMessage({ id: 'Login.Field.Password.Placeholder' })}
                                />
                            </Item>
                            <Item shouldUpdate>
                                {() => (
                                    <AuthButton
                                        title="Masquerade.Sign.In"
                                        form={form}
                                        className={styles.button_container}
                                        loading={loading}
                                        requiredFields={['password']}
                                    />
                                )}
                            </Item>
                        </Form>
                    </div>
                ) : (
                    <div className={styles.content_wrapper} style={{ alignItems: 'center' }}>
                        <div className={styles.body}>
                            <FormattedMessage id={'Masquerade.No.User.ID.Error'} />
                        </div>
                        <Button className={styles.button} onClick={() => navigate('/')}>
                            <FormattedMessage id="Import.GotoHomepage" />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Masquerade;
