import React from 'react';
import { useIntl } from 'react-intl';
import { Avatar, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import styles from './ProfileImage.styl';
import { FormatMessageFunc } from '../../../types';
import FormattedMessage from '../../../localization/FormatMessage';
import { useDisableRefetchOnFocus } from '../../../hooks/useDisableRefetchOnFocus';
import './CropModal.global.styl';

interface ProfileImageProps {
    src?: string;
    shape: 'round' | 'rect';
    placeholder?: React.ReactNode;
    onUpload: (file: RcFile) => void;
    onRemove?: () => void;
    title: string;
    fullImage?: boolean;
}
const EditAvatar: React.FunctionComponent<ProfileImageProps> = (props) => {
    const { src, shape = 'round', placeholder, title, fullImage, onUpload, onRemove } = props;
    const formatMessage: FormatMessageFunc = useIntl().formatMessage;

    const handleUpload: (file: RcFile, _: RcFile[]) => boolean = (file) => {
        onUpload(file);
        return false;
    };
    const showPlaceholder = placeholder && !src;

    useDisableRefetchOnFocus();

    return (
        <div className={styles.profile_image_wrapper}>
            {showPlaceholder ? (
                placeholder
            ) : (
                <Avatar
                    className={fullImage ? styles.full_profile_image : ''}
                    shape={shape === 'round' ? 'circle' : 'square'}
                    size={120}
                    src={src}
                />
            )}
            <div className={shape === 'round' ? styles.edit_avatar_round : styles.edit_avatar_rect}>
                <ImgCrop
                    cropShape={shape === 'round' ? 'round' : 'rect'}
                    modalOk={formatMessage({ id: 'App.Upload' })}
                    modalCancel={formatMessage({ id: 'App.Cancel' })}
                    modalTitle={title}
                    aspect={shape === 'round' ? 1 : undefined}
                    modalClassName={['styled-modal', 'image-crop-modal'].join(' ')}
                    modalWidth={550}
                    modalProps={{
                        closable: false,
                        cancelButtonProps: { type: 'primary', ghost: true },
                    }}
                >
                    <Upload
                        showUploadList={false}
                        multiple={false}
                        beforeUpload={handleUpload}
                        accept="image/png,image/jpeg,image/bmp,image/gif"
                    >
                        <div className={styles.edit_avatar_button}>
                            <FormattedMessage id="App.Edit" />
                        </div>
                    </Upload>
                </ImgCrop>
            </div>
            {src && onRemove && (
                <div className={styles.remove_button}>
                    <span onClick={() => onRemove()}>
                        <FormattedMessage id="App.Remove" />
                    </span>
                </div>
            )}
        </div>
    );
};

export default EditAvatar;
