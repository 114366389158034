import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeUtils } from 'tds-common-fe';
import * as landingUtils from '../../utils/landingUtils';
import routeURLs from './urls';
import { useCurrentUserID } from '../../hooks/useCurrentUser';

const RootRedirection: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const userID = useCurrentUserID();

    useLayoutEffect(() => {
        // Restore the landing path
        const landingPath = landingUtils.restoreLandingPath();
        const redirectionPath =
            landingPath && landingPath !== location.pathname + location.search ? landingPath : routeURLs.DASHBOARD;
        const { appUserID } = routeUtils.parseQuery<{ appUserID: string }>(redirectionPath);
        if (appUserID && userID && userID.toString() !== appUserID) {
            const switchUserPath = routeUtils.makeQueryPath(routeURLs.EAGLE_ID_SWITCH_ACCOUNT, {
                product: 'workspace',
                userID,
                redirect_uri: location.href,
            });
            window.open(switchUserPath, '_self');
        } else {
            navigate(redirectionPath, { replace: true });
        }
    }, [navigate, userID]);

    return null;
};

export default RootRedirection;
