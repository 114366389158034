import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { formatDateTimeDefault } from '../../../../../utils/dateUtils';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import styles from './UserText.styl';

export const UserText: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) =>
    log.content?.deletedAt ? (
        <div className={styles.deleted}>
            <FormattedMessage
                id="Proceq.LogbookTextDeleted"
                values={{
                    date: formatDateTimeDefault(log.content.deletedAt),
                }}
            />
        </div>
    ) : (
        <div>
            <b>
                <FormattedMessage id="Proceq.LogbookTextAdded" />
            </b>
            <div>{log.content?.comment}</div>
        </div>
    );

export const icon = (log: Log) => (log.content?.deletedAt ? 'log_deleted' : 'log');

export default {
    element: UserText,
    icon,
};
