import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import { FormatIDs } from '../../../../../types';

const ADD_REMOVE_SPOT_MAP: Record<string, { text: FormatIDs; color: string }> = {
    addNewSpot: {
        text: 'App.Logbook.PI8000.AddNewSpot',
        color: 'blue',
    },
    removeSpot: {
        text: 'App.Logbook.PI8000.RemoveSpot',
        color: 'red',
    },
};

const AddRemoveSpot: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    return (
        <div>
            <FormattedMessage id={ADD_REMOVE_SPOT_MAP[log.type].text} values={{ name: log.content.name }} />
        </div>
    );
};

const color = (log: Log) => ADD_REMOVE_SPOT_MAP[log.type].color;

export default {
    element: AddRemoveSpot,
    color,
};
