import React, { useCallback, useState } from 'react';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import { Spin } from 'antd';
import { exportMeasurements, getAttachment } from '../../../../api/utilsService';
import { ProductCode } from '../../../../types/proceq';
import analytics from '../../../../analytics/firebaseAnalytics';
import { useMaxFileDownloadSize } from '../../../../hooks/useConfig';

export const DownloadAttachment: React.FunctionComponent<{
    product: ProductCode;
    id: string;
    mID: string;
    fID: string;
    zippedFileSize?: number;
}> = (props) => {
    const { product, id, mID, fID, zippedFileSize } = props;
    const [isLoading, setIsLoading] = useState(false);
    const maxFileDownloadSize = useMaxFileDownloadSize();

    const handleDownload = useCallback(async () => {
        setIsLoading(true);
        try {
            // if it is bigger than max download size, use long running job
            if (zippedFileSize && maxFileDownloadSize > 0 && zippedFileSize > maxFileDownloadSize) {
                const response = await exportMeasurements({
                    product,
                    mIDs: [mID],
                    format: 'attachments',
                    fileType: 'measurement',
                    exportAsURL: true,
                    attachmentIDs: [id],
                });
                if (!(response.data instanceof Blob)) {
                    window.open(response.data.url, '_blank');
                }
            } else {
                const data = await getAttachment({ product, mID, fID });
                saveAs(data, `${id}.zip`);
            }
        } finally {
            setIsLoading(false);
        }
        analytics.logDownloadAttachment(product);
    }, [product, zippedFileSize, maxFileDownloadSize, mID, fID, id]);

    return (
        <a
            onClick={isLoading ? undefined : handleDownload}
            style={isLoading ? { cursor: 'default', opacity: 0.5, pointerEvents: 'none' } : {}}
        >
            <FormattedMessage id="App.Download" />
            {isLoading && (
                <Spin
                    size="small"
                    style={{
                        marginLeft: '0.25rem',
                        marginTop: -6,
                        marginBottom: -6,
                    }}
                />
            )}
        </a>
    );
};

export default DownloadAttachment;
