import React, { useRef } from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input/Input';
import styles from './AuthInput.styl';

interface AuthInputProps extends InputProps {
    authInputType?: 'password' | 'default';
    inputs?: React.MutableRefObject<HTMLInputElement[]>;
}

const AuthInput = React.forwardRef<any, AuthInputProps>((props, ref) => {
    const { authInputType, inputs } = props;
    const inputProps: AuthInputProps = {
        onKeyDown: (event) => {
            if (event.key.toLowerCase() === 'enter' && inputs?.current) {
                const nextIndex = inputIndex.current + 1;
                if (nextIndex < inputs.current.length) {
                    inputs.current[nextIndex].focus();
                    event.preventDefault();
                }
            }
        },
        ...props,
    };

    const inputIndex = useRef(-1);

    delete inputProps.authInputType;
    delete inputProps.inputs;

    const setupRef = (inputRef: any) => {
        ref = inputRef;
        if (!inputs?.current) {
            return;
        }
        // Set the index value automatically
        if (inputIndex.current < 0) {
            inputIndex.current = inputs.current.length;
        }
        inputs.current[inputIndex.current] = ref as any;
    };

    if (authInputType === 'password') {
        return <Input.Password ref={setupRef} className={styles.input} {...inputProps} />;
    }
    return <Input ref={setupRef} className={styles.input} type="text" {...inputProps} />;
});

export default AuthInput;
