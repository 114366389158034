import { useMemo } from 'react';
import { ProductCode } from '../../../types/proceq';
import { useCreateMeasurementsData } from '../../../hooks/useProductData';
import { CustomCurveFileType } from '../../../types/measurement';

interface UseCreateMeasurementDataParams {
    fileType: string;
    createMeasurementIDs: string[] | undefined;
    page: number;
    product: ProductCode | undefined;
}

const useCreateMeasurementDataSource = (params: UseCreateMeasurementDataParams) => {
    const { product, page, fileType, createMeasurementIDs } = params;
    const createMeasurementsData = useCreateMeasurementsData();
    const showCreatedMeasurements = product && page === 1 && fileType === CustomCurveFileType.dgscc;

    return useMemo(() => {
        if (!createMeasurementIDs || !showCreatedMeasurements) {
            return [];
        }
        return createMeasurementIDs
            .filter((id) => createMeasurementsData[id])
            .map((id) => {
                return { ...createMeasurementsData[id], key: id, isCreate: true };
            });
    }, [createMeasurementIDs, showCreatedMeasurements, createMeasurementsData]);
};

export default useCreateMeasurementDataSource;
