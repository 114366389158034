import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import FormattedMessage from '../../../../../localization/FormatMessage';

const GridInfo: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const isMetric = log.content.unit?.toLowerCase() === 'metric';
    return (
        <div>
            <FormattedMessage id="App.Logbook.PI8000.Grid.Rows" values={{ value: log.content.rows }} />
            <br />
            <FormattedMessage id="App.Logbook.PI8000.Grid.Columns" values={{ value: log.content.columns }} />
            <br />
            <FormattedMessage
                id="App.Logbook.PI8000.Grid.CellWidth"
                values={{
                    value: log.content.cellWidth,
                    unit: convertAndDisplay('PIT_IE.Grid.CellWidthHeight', scanType ?? ScanType.AScan, isMetric).unit,
                }}
            />
            <br />
            <FormattedMessage
                id="App.Logbook.PI8000.Grid.CellHeight"
                values={{
                    value: log.content.cellHeight,
                    unit: convertAndDisplay('PIT_IE.Grid.CellWidthHeight', scanType ?? ScanType.AScan, isMetric).unit,
                }}
            />
        </div>
    );
};

export default {
    element: GridInfo,
};
