import { Button } from 'antd';
import React from 'react';
import StyledModal from '../shared/StyledModal';
import FormattedMessage from '../../localization/FormatMessage';

interface ImportFileExistsProps {
    onClose: () => void;
    customCurveNameConflicts?: string[];
}

const ImportFileExists: React.FunctionComponent<ImportFileExistsProps> = (props) => {
    const { customCurveNameConflicts, onClose } = props;
    const count = customCurveNameConflicts?.length ?? 1;
    return (
        <StyledModal
            title={<FormattedMessage id="Import.Error.FileNameExists" values={{ count }} />}
            open={!!customCurveNameConflicts}
            onCancel={onClose}
            destroyOnClose
            footer={
                <Button key="footerButtons" type="primary" onClick={onClose}>
                    <FormattedMessage id="App.OK" />
                </Button>
            }
        >
            <FormattedMessage id="Import.Error.FileNameExists.List" values={{ count }} />
            <ul style={{ overflow: 'auto', maxHeight: '150px' }}>
                {customCurveNameConflicts?.map((name, index) => <li key={index}>{name}</li>)}
            </ul>
            <FormattedMessage id="Import.Error.FileNameExists.RenameFile" values={{ count }} />
        </StyledModal>
    );
};

export default ImportFileExists;
