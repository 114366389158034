import React from 'react';
import styles from './DataView.styl';
import TooltipText from '../shared/TooltipText';

interface FolderNameProps {
    icon?: React.ReactNode;
    name: string | React.ReactNode;
}

const FolderName: React.FunctionComponent<FolderNameProps> = (props) => {
    const { icon, name } = props;
    return (
        <div className={styles.menu_title_content}>
            {icon}
            <TooltipText text={name} />
        </div>
    );
};

export default FolderName;
