import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormatIDs } from '../../../types';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './AuthPasswordLabel.styl';

interface AuthPasswordLabelProps {
    label?: FormatIDs;
}

const AuthPasswordLabel = React.memo<AuthPasswordLabelProps>((props) => {
    const { label } = props;

    const Content = (
        <ul>
            {[1, 2, 3, 4].map((val) => (
                <li key={val}>
                    <FormattedMessage id={`Password.Constraints.${val}` as FormatIDs} />
                </li>
            ))}
        </ul>
    );

    return (
        <span>
            {label && <FormattedMessage id={label} />}{' '}
            <Tooltip title={Content} placement="right" overlayClassName={styles.tooltip}>
                <InfoCircleOutlined />
            </Tooltip>
        </span>
    );
});

export default AuthPasswordLabel;
