import config from '../config';

const types = {
    postPageHeight: '',
};
type EventType = keyof typeof types;

export const iFramePageListener = (event: MessageEvent, setFormHeight: (height: number) => void) => {
    if (event.origin !== config.EAGLE_ID_DOMAIN) {
        return;
    }
    const { type, data }: { type: EventType; data: any } = event.data;

    if (type === 'postPageHeight') {
        if (typeof setFormHeight === 'function' && !isNaN(data)) {
            setFormHeight(data);
        }
    }
};
