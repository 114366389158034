import React from 'react';
import { get } from 'lodash';
import styles from '../../DataViewers.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import SectionTable from '../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.PUNDIT.Settings.TimeSlice" />
    </>
);

export const TimeSlice: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    isMetric,
    showTitle,
}) => {
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.PUNDIT.TimeSlice.Depth',
            unitId: 'PUNDIT.CSV.Depth',
            value: get(data, 'settings.0.content.process.depth'),
        },
        {
            label: 'App.HTML.PUNDIT.TimeSlice.Thickness',
            unitId: 'PUNDIT.CSV.Thickness',
            value: get(data, 'settings.0.content.process.thickness'),
        },
    ];

    const presets = getConvertedSectionTableData(presetsRaw, scanType, isMetric);

    return (
        <SectionTable
            showTitle={showTitle}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={presets}
        />
    );
};

export default TimeSlice;
