import React from 'react';
import { ButtonType } from 'antd/lib/button';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './ButtonExport.styl';
import { ProductCode } from '../../types/proceq';
import { classNames } from '../../utils/styleUtils';
import { useMeasurementExportContext } from './MeasurementExportProvider';
import AnalyticsButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { MeasurementPage } from '../../analytics/analyticsConstants';

interface DownloadProps {
    product: ProductCode;
    fileType?: string;
    mIDs: string[];
    className?: string;
    type?: ButtonType;
    analyticsPage: MeasurementPage;
    setSelectedKeys?: (keys: string[]) => void;
}

export const ButtonExportBluetooth: React.FunctionComponent<DownloadProps> = (props) => {
    const { product, fileType, mIDs, className, type, analyticsPage, setSelectedKeys } = props;
    const { setExportConfig } = useMeasurementExportContext();

    const disabled = !product || !mIDs.length;
    const handleClick = () => {
        setExportConfig({ mIDs, product, fileType, showModal: true, setSelectedKeys });
        analytics.logViewExportOptions(analyticsPage, product);
    };

    return (
        <AnalyticsButton
            type={type || 'primary'}
            className={classNames(styles.button_export, className)}
            onClick={handleClick}
            disabled={disabled}
        >
            <FormattedMessage id="App.Export" />
        </AnalyticsButton>
    );
};

export default ButtonExportBluetooth;
