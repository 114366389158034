import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { routeUtils } from 'tds-common-fe';
import * as authService from '../../api/authService';
import { GetOAuthTokenResponse, OAuthToken } from '../../api/authService';
import config from '../../config';
import { checkEagleIDState, getRedirectURI, redirectEagleIDLogin } from '../../utils/authUtils';
import Spinner from '../shared/Spinner';
import routeURLs from '../Routes/urls';
import { useCurrentUserID } from '../../hooks/useCurrentUser';
import { useFormatMessage } from '../../localization/useFormatMessage';
import { logDeviceType } from '../../api/analyticsEventService';
import { getSessionItem } from '../../utils/storageUtils';

const AuthReceiver: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formatMessage = useFormatMessage();

    const userID = useCurrentUserID();

    const {
        code,
        lang,
        state = null,
        error,
    } = routeUtils.parseQuery<{ code: string; lang: string; state: string; error: string }>(location.search);

    const isFirstTime = useRef(true);

    const logout = useCallback(() => {
        const path = routeURLs.LOGOUT;
        navigate(path);
    }, [navigate]);

    const handleGetOAuthToken = useCallback(
        (code: string) => {
            const clientID = config.EAGLE_ID_CLIENT_ID || '';
            const redirectURI = getRedirectURI(lang);

            const sessionStorageCodeVerifier = getSessionItem('codeVerifier');
            const codeVerifier = sessionStorageCodeVerifier ? { code_verifier: sessionStorageCodeVerifier } : {};

            return authService.getOAuthToken({
                code,
                client_id: clientID,
                redirect_uri: redirectURI,
                grant_type: 'authorization_code',
                ...codeVerifier,
            });
        },
        [lang]
    );

    const handleVerifyOAuthToken = useCallback(
        (data: { response: GetOAuthTokenResponse; token: OAuthToken }) => {
            return authService.validateToken(data, dispatch).then(() => {
                logDeviceType('login');
                navigate('/');
            });
        },
        [navigate, dispatch]
    );

    const handleUserID = useCallback(
        async (userID: number, code: string | undefined) => {
            // userID and code present at the same time, this is due to back action triggered by the user
            if (code) {
                try {
                    // Check if the user changed
                    const response = await handleGetOAuthToken(code);
                    if (response.token.sub !== String(userID)) {
                        // If user changed, we will update the credentials
                        await handleVerifyOAuthToken(response);
                    } else {
                        // If user remains the same, we can use the App
                        navigate('/');
                    }
                } catch {
                    logout();
                }
            } else {
                navigate('/');
            }
        },
        [handleGetOAuthToken, navigate, handleVerifyOAuthToken, logout]
    );

    useEffect(() => {
        // We only want to run this 1 time
        if (!isFirstTime.current) return;

        if (userID) {
            handleUserID(userID, code);
            return;
        }

        const stateValid = checkEagleIDState(state);

        if (code && stateValid) {
            handleGetOAuthToken(code).then(handleVerifyOAuthToken).catch(logout);
        } else if (error || (state && !stateValid)) {
            // Perform logout if there's error (from Eagle ID) or state not valid (copy paste from another tab)
            logout();
        } else {
            redirectEagleIDLogin(error !== undefined);
        }
    }, [userID, code, state, handleGetOAuthToken, error, logout, handleVerifyOAuthToken, handleUserID]);

    useEffect(() => {
        isFirstTime.current = false;
    }, []);

    return (
        <Spinner style={{ maxHeight: 'none' }} tip={formatMessage({ id: 'Auth.LoggingIn' })}>
            <div style={{ width: '100vw' }} />
        </Spinner>
    );
};

export default AuthReceiver;
