import React from 'react';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import styles from './BackButton.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import { ReactComponent as IconArrow } from '../../../images/iconArrowLeft.svg';
import { FormatIDs } from '../../../types';
import { classNames } from '../../../utils/styleUtils';

interface BackButtonProps {
    onClick: () => void;
    buttonText?: FormatIDs;
    extraClass?: string;
}

const BackButton: React.FunctionComponent<BackButtonProps> = ({ onClick, buttonText, extraClass }) => (
    <AnalyticsButton onClick={onClick} type="link" className={classNames(styles.back_button, extraClass)}>
        <IconArrow />
        <span>
            <FormattedMessage id={buttonText ?? 'App.Back'} />
        </span>
    </AnalyticsButton>
);

export default BackButton;
