import { FormatMessageFunc } from '../../../../types';
import { getResolutionForScale } from '../utils/conversionsHelper';

interface TooltipFormatterArgs {
    formatMessage: FormatMessageFunc;
    unit: string | null;
    skipNegativeNumber?: boolean;
    skipResolutionConversion?: boolean;
}
export function tooltipFormatter({
    formatMessage,
    unit,
    skipNegativeNumber,
    skipResolutionConversion,
}: TooltipFormatterArgs) {
    function unitFormatter(this: Highcharts.TooltipFormatterContextObject) {
        if (isNaN(this.y) || this.y === 0 || (skipNegativeNumber && this.y < 0)) {
            const noConversionText = formatMessage({ id: 'Proceq.ExportFieldNoConversion' });
            return `<b>${this.x}</b><br/><span>${noConversionText}</span>`;
        }
        const resolutionText = skipResolutionConversion ? String(this.y) : getResolutionForScale(unit, String(this.y));
        return `<b>${this.x}</b><br/><span>${resolutionText} ${unit}</span>`;
    }
    return unitFormatter;
}

export function labelFormatter(
    formatMessage: FormatMessageFunc,
    unit: string | null,
    skipResolutionConversion?: boolean
) {
    function unitFormatter(this: any) {
        if (isNaN(this.total)) {
            const noConversionText = formatMessage({ id: 'Proceq.ExportFieldNoConversion' });
            return `<span>${noConversionText}</span>`;
        }
        if (this.total <= 0) {
            return '';
        }
        const resolutionText = skipResolutionConversion
            ? String(this.total)
            : getResolutionForScale(unit, String(this.total));
        return `<span>${resolutionText}</span>`;
    }
    return unitFormatter;
}
