import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';

enum CurveTypes {
    convex = 'Convex',
    concave = 'Concave',
}

const CurveTypesFormatId: Record<CurveTypes, FormatIDs> = {
    [CurveTypes.convex]: 'App.Logbook.FDL.CSCApplied.Thickness.Convex',
    [CurveTypes.concave]: 'App.Logbook.FDL.CSCApplied.Thickness.Concave',
};

const getConvertedValue = (unitID: string, unitType: string, value: number, scanType?: ScanType) => {
    const { convertFunction, unit } = convertAndDisplay(unitID, scanType ?? ScanType.Indication, unitType);
    return `${convertFunction(value)} ${unit}`;
};

const CSCApplied: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const { content } = log;
    return (
        <>
            <b>
                <FormattedMessage id="App.Logbook.FDL.CSCApplied" />
            </b>
            {content?.cscInfo?.externalRadius !== undefined && (
                <>
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.CSCApplied.ExternalRadius"
                        values={{
                            value: getConvertedValue(
                                'FDL.CSCApplied.Radius',
                                content?.cscInfo?.unit,
                                content?.cscInfo?.externalRadius,
                                scanType
                            ),
                        }}
                    />
                </>
            )}
            {content?.cscInfo?.internalRadius !== undefined && (
                <>
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.CSCApplied.InternalRadius"
                        values={{
                            value: getConvertedValue(
                                'FDL.CSCApplied.Radius',
                                content?.cscInfo?.unit,
                                content?.cscInfo?.internalRadius,
                                scanType
                            ),
                        }}
                    />
                </>
            )}
            {content?.cscInfo?.thickness && (
                <>
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.CSCApplied.Thickness"
                        values={{
                            value: getConvertedValue(
                                'FDL.Severity.Thickness',
                                content?.cscInfo?.unit,
                                content?.cscInfo?.thickness,
                                scanType
                            ),
                        }}
                    />
                </>
            )}
            {content?.cscInfo?.curveType && (
                <>
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.CSCApplied.CurveType"
                        values={{
                            value: Object.values(CurveTypes).includes(content.cscInfo.curveType) ? (
                                <FormattedMessage id={CurveTypesFormatId[content.cscInfo.curveType as CurveTypes]} />
                            ) : (
                                content.cscInfo.curveType
                            ),
                        }}
                    />
                </>
            )}
        </>
    );
};

export default {
    element: CSCApplied,
    color: () => 'green',
};
