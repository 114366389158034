import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataViewFolders from './DataViewFolder';
import DataTableMeasurement from './DataTableMeasurement';
import DataTableCustomMaterial from './DataTableCustomMaterial';
import { DataViewProvider, useDataViewContext } from './DataViewProvider';
import DataViewModal from './DataViewModal';
import AppLayout from '../shared/AppLayout';
import { getDataViewRoute } from '../Routes/urls';
import { productMap } from '../shared/AppIcon';
import {
    BluetoothFileType,
    DataType,
    DEFAULT_DATA_TYPE,
    productDataCategoryRoutes,
    SystemFolderID,
    ViewType,
} from '../../types/measurement';
import { ProductCode } from '../../types/proceq';
import useRefWithChange from '../../hooks/useRefWithChange';
import NonEditableModal from './NonEditableModal';
import ImportFileExists from '../MeasurementImport/ImportFileExists';
import { useProceqAppConfig } from '../../queries/utilsQueries';
import { SPATIAL_DATA_FILE_TYPE } from '../../types/userFiles';
import SpatialDataTable from './SpatialData/SpatialDataTable';
import { UserFilesProvider } from './SpatialData/UserFilesProvider';
import { MeasurementProvider } from './MeasurementContext/MeasurementProvider';

const DataView: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const {
        productName = '',
        viewType,
        folderID = '',
    } = useParams<{ productName: string; viewType: ViewType; folderID: string }>();
    const productCode = productMap[productName]?.productCode ?? (productName as ProductCode);

    const locationDataViewState = useLocation();
    const [customCurveNameConflicts, setCustomCurveNameConflicts] = useState<string[] | undefined>(
        locationDataViewState.state?.customCurveNameConflicts
    );

    const stateFolderIDRef = useRefWithChange(folderID);

    const { setProduct, setViewType, setActiveFolder, dataType, setDataType } = useDataViewContext();

    const isMeasurement = dataType !== BluetoothFileType.CustomMaterial;

    useProceqAppConfig(productCode);

    useEffect(() => {
        setActiveFolder(folderID);
    }, [folderID, setActiveFolder]);

    useEffect(() => {
        setProduct(productCode, stateFolderIDRef.current);
    }, [productCode, setProduct, stateFolderIDRef]);

    useEffect(() => {
        if (viewType === ViewType.Archived) {
            setViewType(ViewType.Archived);
        } else {
            setViewType(ViewType.Active);
        }
    }, [viewType, setViewType]);

    useEffect(() => {
        if (productDataCategoryRoutes.some((category) => category === folderID)) {
            setDataType(folderID as DataType);
        } else {
            setDataType(DEFAULT_DATA_TYPE);
            const route = getDataViewRoute({
                productName,
                viewType,
                folderID: folderID || SystemFolderID.All,
            });
            if (location.pathname !== route) {
                navigate(route);
            }
        }
    }, [folderID, navigate, productName, setDataType, viewType]);

    return (
        <AppLayout>
            <DataViewFolders />
            {dataType === SPATIAL_DATA_FILE_TYPE ? (
                <UserFilesProvider>
                    <SpatialDataTable />
                </UserFilesProvider>
            ) : (
                <MeasurementProvider>
                    {isMeasurement ? <DataTableMeasurement /> : <DataTableCustomMaterial />}
                    <ImportFileExists
                        onClose={() => setCustomCurveNameConflicts(undefined)}
                        customCurveNameConflicts={customCurveNameConflicts}
                    />
                    <DataViewModal />
                    <NonEditableModal />
                </MeasurementProvider>
            )}
        </AppLayout>
    );
};

const DataViewWithProvider: React.FunctionComponent = () => {
    return (
        <DataViewProvider>
            <DataView />
        </DataViewProvider>
    );
};

export default DataViewWithProvider;
