import { get } from 'lodash';
import React from 'react';
import { HighchartsChart, Chart, XAxis, YAxis, ColumnSeries, Tooltip, HighchartsProvider } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import { useIntl } from 'react-intl';
import Statistics, { title as statsTitle } from './GLM/Statistics';
import { GLMSettingsContent, GLMMeasurementContent } from '../../../../types/measurement';
import { getMinMaxValue } from '../utils/conversionsHelper';
import FormattedMessage from '../../../../localization/FormatMessage';
import styles from './EquotipReadings.styl';
import { getYAxisStyleProps, labelStyleConfig, plotOptions, tooltipStyleProps } from './HighchartsConfigs';
import colorsLib from '../../../../styles/colors.json';
import { EquotipFamilyRendererProps } from './ReadingComponents';
import { labelFormatter, tooltipFormatter } from './HighchartsUtils';
import { roundToNDigit } from '../utils/conversionRates';

enum EUnit {
    'GU', // 0
    '%', // 1
}

const HAZE_UNIT = 'HU';

type GLMReadingsProps = EquotipFamilyRendererProps & { showHaze?: boolean };

const GLMReadings: React.FunctionComponent<GLMReadingsProps> = (props) => {
    const { data: measurementData, isStandalone, showHaze } = props;

    const formatMessage = useIntl().formatMessage;

    const readings = get(measurementData, 'readings.0.content') as GLMMeasurementContent;
    const settings = get(measurementData, 'settings.0.content') as GLMSettingsContent;

    if (!measurementData || !readings || !settings) {
        return <FormattedMessage id="Proceq.GraphNoDataAvailable" />;
    }

    const getValueColor = (value: number, hi: number, lo: number, isText?: boolean) => {
        if (value > hi || value < lo) return colorsLib.red700;
        return isText ? 'currentColor' : colorsLib.teal400;
    };

    const getValueParam = (
        isScanMode: boolean,
        bin: number
    ): { key: keyof GLMMeasurementContent['series'][number]; value: string } => {
        if (!isScanMode) {
            switch (bin) {
                case 1:
                    return { key: 'valueA0', value: '20°' };
                case 2:
                    return { key: 'valueA1', value: '60°' };
                case 3:
                    return { key: 'valueHaze', value: 'Haze' };
            }
        } else {
            switch (bin) {
                case 1:
                    return { key: 'valueActual0', value: '20°' };
                case 2:
                    return { key: 'valueActual1', value: '60°' };
                case 3:
                    return { key: 'valueHaze', value: 'Haze' };
            }
        }
        return { key: 'valueA0', value: '20°' };
    };
    const graphHTML: JSX.Element[] = [];
    const isScanMode = settings.measureMode === 2;
    const binList = settings.angleBinList;
    binList
        .filter((bin: number) => {
            if (bin === 3) {
                if (!showHaze) return false;
            }
            return true;
        })
        .forEach((bin: number) => {
            const valueParam = getValueParam(isScanMode, bin).key;
            const unit = valueParam === 'valueHaze' ? HAZE_UNIT : EUnit[settings.unit]; // TODO: map id to value
            const thresholdHiValue = settings.limitsItems[bin].high;
            const thresholdLoValue = settings.limitsItems[bin].low;
            const yAxisStyleProps = getYAxisStyleProps({ thresholdLoValue, thresholdHiValue });

            const categories = readings.series.map((readingSeries) => String(readingSeries.index));
            const colors = readings.series.map((readingSeries) => {
                const value = readingSeries[valueParam];
                return getValueColor(value, thresholdHiValue, thresholdLoValue);
            });
            const data = readings.series.map((readingSeries) => {
                let reading = readingSeries[valueParam];
                if (bin === 3 && reading < 0) {
                    reading = 0;
                }
                return +roundToNDigit(reading, 1);
            });
            const maxValueFound = data.reduce(
                (max: number, current: number) => (current > max ? current : max),
                thresholdHiValue
            );
            const minValueFound = data.reduce(
                (min: number, current: number) => (current < min ? current : min),
                thresholdLoValue
            );
            const { maxValue, minValue } = getMinMaxValue(minValueFound, maxValueFound);
            if (readings.series.length > 0) {
                graphHTML.push(
                    <div key={bin} style={{ width: '100%' }}>
                        <div className={styles.category_properties}>
                            {formatMessage({ id: getValueParam(isScanMode, bin).value })}
                        </div>
                        <div style={{ margin: '0 auto' }}>
                            <HighchartsChart className={styles.highcharts_instance} plotOptions={plotOptions}>
                                <Chart
                                    backgroundColor="transparent"
                                    scrollablePlotArea={{ minWidth: 26 * readings.series.length, scrollPositionX: 1 }}
                                    panning={{ enabled: true, type: 'x' }}
                                />
                                <Tooltip
                                    formatter={tooltipFormatter({
                                        formatMessage,
                                        unit,
                                        skipNegativeNumber: true,
                                        skipResolutionConversion: true,
                                    })}
                                    {...tooltipStyleProps}
                                    shared
                                />
                                <XAxis categories={categories} title={labelStyleConfig} labels={labelStyleConfig} />
                                <YAxis
                                    id="number"
                                    min={minValue}
                                    max={maxValue}
                                    title={labelStyleConfig}
                                    labels={labelStyleConfig}
                                    {...yAxisStyleProps}
                                    stackLabels={{
                                        style: {
                                            color: 'currentColor',
                                            textOutline: 'none',
                                        },
                                        enabled: true,
                                        verticalAlign: 'top',
                                        formatter: labelFormatter(formatMessage, unit, true),
                                    }}
                                    allowDecimals={false}
                                >
                                    <YAxis.Title>{unit}</YAxis.Title>
                                    <ColumnSeries
                                        id="installation"
                                        name="Installation"
                                        data={data}
                                        colors={colors}
                                        borderWidth={0}
                                        animation={!isStandalone}
                                    />
                                </YAxis>
                            </HighchartsChart>
                        </div>
                    </div>
                );
            }
        });

    return (
        <div>
            <div className={styles.fdl_graph_wrapper}>
                <div className={styles.category_properties}>
                    <FormattedMessage id="App.Measurements" />
                </div>
                <div className={styles.graph}>{graphHTML}</div>
                <div className={styles.glm_readings_table}>
                    <div className={styles.category_properties}>{statsTitle}</div>
                    <Statistics data={measurementData.measurement} settings={settings} showHaze={showHaze} />
                </div>
            </div>
        </div>
    );
};

export const GLMReadingsWithState: React.FunctionComponent<GLMReadingsProps> = (props) => {
    return (
        <HighchartsProvider Highcharts={Highcharts}>
            <GLMReadings {...props} />
        </HighchartsProvider>
    );
};

export default GLMReadingsWithState;
