import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { getLabelFromGlobalDataKey } from '../../../RegisteredInfo/helper';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { FormatIDs } from '../../../../../types';

export const SurfaceCondition: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    const formatMessage = useFormatMessage();

    const oldValue =
        log.content.oldSurfaceConditionId !== undefined
            ? formatMessage({
                  id:
                      (getLabelFromGlobalDataKey(
                          'surfaceconditionschmidt',
                          log.content.oldSurfaceConditionId
                      ) as FormatIDs) ?? 'Proceq.Unknown',
              })
            : undefined;

    const newValue =
        log.content.updatedSurfaceConditionId !== undefined
            ? formatMessage({
                  id:
                      (getLabelFromGlobalDataKey(
                          'surfaceconditionschmidt',
                          log.content.updatedSurfaceConditionId
                      ) as FormatIDs) ?? 'Proceq.Unknown',
              })
            : undefined;

    return (
        <span>
            {log.content.oldSurfaceConditionId ? (
                <FormattedMessage
                    id="Proceq.LogbookPropertySurfaceConditionChangedFromTo"
                    values={{
                        oldValue,
                        newValue,
                    }}
                />
            ) : (
                <FormattedMessage
                    id="Proceq.LogbookPropertySurfaceConditionChangedTo"
                    values={{
                        newValue,
                    }}
                />
            )}
        </span>
    );
};

export default {
    element: SurfaceCondition,
};
