import getGPRLogElement, { EventLogElement } from '../GPR/getGPRLogElement';
import elementAddMeasuringMode from './AddMeasuringMode';
import elementIndication from './Indication';
import elementSnapshots from './Snapshots';
import elementChangeKeyFrame from './ChangeKeyFrame';
import elementMarker from './Marker';
import elementCreation from './Creation';
import elementSetting from './Setting';
import elementCurve from './Curve';
import elementEncoderCalibration from './EncoderCalibration';
import elementCSCApplied from './CSCApplied';
import elementSeverity from './Severity';

enum FDLLogType {
    AddMeasuringMode = 'AddMeasuringMode',
    Indication = 'Indication',
    Snapshots = 'Snapshots',
    ChangeKeyFrame = 'ChangeKeyFrame',
    Marker = 'Marker',
    Creation = 'Creation',
    Setting = 'Setting',
    Curve = 'Curve',
    EncoderCalibration = 'EncoderCalibration',
    CSCApplied = 'CscApplied',
    Severity = 'Severity',
}

const getFDLLogElement = (logType: string | FDLLogType): EventLogElement => {
    switch (logType) {
        case FDLLogType.AddMeasuringMode:
            return elementAddMeasuringMode;
        case FDLLogType.Indication:
            return elementIndication;
        case FDLLogType.Snapshots:
            return elementSnapshots;
        case FDLLogType.ChangeKeyFrame:
            return elementChangeKeyFrame;
        case FDLLogType.Marker:
            return elementMarker;
        case FDLLogType.Creation:
            return elementCreation;
        case FDLLogType.Setting:
            return elementSetting;
        case FDLLogType.Curve:
            return elementCurve;
        case FDLLogType.EncoderCalibration:
            return elementEncoderCalibration;
        case FDLLogType.CSCApplied:
            return elementCSCApplied;
        case FDLLogType.Severity:
            return elementSeverity;
        default:
            return getGPRLogElement(logType);
    }
};

export default getFDLLogElement;
