import React from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import styles from '../../DataViewers.styl';
import { PunditDisplayedVelocity, MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import SectionTable from '../../shared/SectionTable';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';
import { FormatIDs } from '../../../../../types';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.PUNDIT.Settings.Display" />
    </>
);

const DISPLAYED_VELOCITY_TEXT: Record<PunditDisplayedVelocity, FormatIDs> = {
    [PunditDisplayedVelocity.ESTIMATE_P_WAVE]: 'App.HTML.PUNDIT.Display.DisplayedVelocity.EstimatePWave',
    [PunditDisplayedVelocity.S_WAVE]: 'App.HTML.PUNDIT.Display.DisplayedVelocity.SWave',
};

export const Display: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    isMetric,
    showTitle,
}) => {
    const formatMessage = useFormatMessage();
    const displayVelocityWave = data.settings[0].content?.display?.displayVelocityWave;
    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.PUNDIT.Display.ColorPalette',
            value: get(data, 'settings.0.content.display.colorScheme'),
        },
        {
            label: 'App.HTML.PUNDIT.Display.DisplayedVelocity',
            value: Object.values(PunditDisplayedVelocity).includes(displayVelocityWave)
                ? formatMessage({ id: DISPLAYED_VELOCITY_TEXT[displayVelocityWave as PunditDisplayedVelocity] })
                : displayVelocityWave,
        },
        {
            label: 'App.HTML.PUNDIT.Display.AspectRatio',
            value: get(data, 'settings.0.content.display.isLockingAspectRatio')
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
        },
        {
            label: 'App.HTML.PUNDIT.Display.GridLines',
            value: get(data, 'settings.0.content.display.isShowingGridLines')
                ? formatMessage({ id: 'Proceq.On' })
                : formatMessage({ id: 'Proceq.Off' }),
        },
        {
            label: 'App.HTML.PUNDIT.Display.Units',
            value: get(data, 'settings.0.content.display.unit'),
        },
        {
            label: 'App.HTML.PUNDIT.Display.LineViewXZoom',
            value: get(data, 'settings.0.content.lineViewLayoutSettings.zoomLevelXAxis'),
        },
        {
            label: 'App.HTML.PUNDIT.Display.LineViewYZoom',
            value: get(data, 'settings.0.content.lineViewLayoutSettings.zoomLevelYAxis'),
        },
        {
            label: 'App.HTML.PUNDIT.Display.LineViewXOffset',
            unitId: 'PUNDIT.CSV.X axis content offset',
            value: get(data, 'settings.0.content.lineViewLayoutSettings.contentOffsetX'),
        },
        {
            label: 'App.HTML.PUNDIT.Display.LineViewYOffset',
            unitId: 'PUNDIT.CSV.Y axis content offset',
            value: get(data, 'settings.0.content.lineViewLayoutSettings.contentOffsetY'),
        },
    ];
    const presets = getConvertedSectionTableData(presetsRaw, scanType, isMetric);

    return (
        <SectionTable
            showTitle={showTitle}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={presets}
        />
    );
};

export default Display;
