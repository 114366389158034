import { routeUtils } from 'tds-common-fe/src/lib/index';
import { receivedAppsStatus, receivedPlanTiers } from '../actions/config';
import { LicenseProduct, LicenseTier, UserLicenseInfo } from '../types/license';
import { thunkGet, thunkPost } from './apiService';
import { getApiURL } from './ApiURL';
import { NewsResponse } from '../types/news';
import { APIResponse } from '../types/api';
import { ProductCode } from '../types/proceq';
import { handleError } from './error';

type ActiveAppsResponse = APIResponse<
    {
        product: string;
        active: boolean;
        url?: string;
        eligibleFreeTrial?: boolean;
    }[]
>;

export const getActiveApps = async () => {
    const url = getApiURL('APPS_STATUS');
    const { response, dispatch } = await thunkGet<ActiveAppsResponse>(url);

    if (Array.isArray(response.data)) {
        dispatch(receivedAppsStatus({ productsStatus: response.data }));
    }

    return response;
};

type CMSNewsResponse = APIResponse<{
    getNewsListing: {
        edges: { node: NewsResponse }[];
    };
}>;

export const getCMSNews = async () => {
    const url = routeUtils.makeQueryPath(getApiURL('APPS_CMS_NEWS'), { size: 3 });
    const { response } = await thunkGet<CMSNewsResponse | null>(url);
    return response;
};

type LicenseResponse = APIResponse<LicenseTier[]>;

export const getLicenseTier = async (product: LicenseProduct) => {
    const url = getApiURL('LICENSE_TIER');

    const { response, dispatch } = await thunkGet<LicenseResponse>(url, {
        params: {
            product,
        },
    });

    if (response.data) {
        dispatch(
            receivedPlanTiers({
                product,
                tiers: response.data,
            })
        );
    }
};

type ActivateLicenseResponse = APIResponse<UserLicenseInfo>;

export const activateFreeTrial = async (product: ProductCode | LicenseProduct, errorHandler = handleError) => {
    const url = routeUtils.makeQueryPath(getApiURL('LICENSE_ACTIVATE_TRIAL'), { product: product.toLowerCase() });
    await thunkPost<ActivateLicenseResponse>(url, {
        errorHandler,
        params: {
            product,
        },
    });
};

export const activateLicense = async (product: ProductCode, key: string) => {
    const url = routeUtils.makeQueryPath(getApiURL('LICENSE_ACTIVATE'), { product: product.toLowerCase() });
    await thunkPost<ActivateLicenseResponse>(url, {
        params: {
            product,
            key,
        },
    });
};
