import React from 'react';
import { ReactComponent as IconInfo } from '../../../../../images/iconAbout.svg';
import styles from '../GPR/ObjectMarker.styl';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { classNames } from '../../../../../utils/styleUtils';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { ScanType } from '../../../../../types/proceq';

const iconInfo = <IconInfo className={styles.info_icon} />;

export const TextWithKeyValue: React.FunctionComponent<
    GPRLogbookEntryProps & {
        highlighted?: boolean;
        toggleHighlight?: () => void;
    }
> = ({ log, highlighted, toggleHighlight, scanType }) => {
    const isPileIntegrity = scanType === ScanType.AScan;
    return (
        <div
            className={classNames(
                styles.message_container,
                styles.highlightable,
                'highlightable', // For HTML export
                highlighted && styles.highlighted
            )}
            onClick={toggleHighlight}
            role="button"
            tabIndex={0}
        >
            <b>
                <FormattedMessage
                    id={
                        isPileIntegrity
                            ? 'App.Logbook.PI8000.TextWithKeyValuesPileIntegrity'
                            : 'App.Logbook.PI8000.TextWithKeyValues'
                    }
                />
            </b>
            <br />
            {isPileIntegrity ? (
                <>
                    <FormattedMessage id="App.Logbook.PI8000.Pile" />
                    {`: ${log.content.pileName}`}
                    <br />
                    <FormattedMessage id="App.Logbook.PI8000.Position" />
                    {`: ${log.content.positionName}`}
                </>
            ) : (
                <>
                    <FormattedMessage id="App.HTML.PI8000.Spots.Spot" />
                    {`: ${log.content.spotName}`}
                    <br />
                    <FormattedMessage id="App.Logbook.PI8000.Signal" />
                    {`: ${log.content.signal}`}
                </>
            )}
            {iconInfo}
        </div>
    );
};

export default {
    element: TextWithKeyValue,
};
