import { useMemo } from 'react';
import { routeUtils } from 'tds-common-fe';
import { useParams, useNavigate } from 'react-router-dom';
import { ProductCode, SharedURLParams } from '../../types/proceq';
import { getLabelFromGlobalDataKey } from '../DataView/RegisteredInfo/helper';
import { productCodeToIconMap } from '../shared/AppIcon';

export default function useMeasurementImportParams() {
    const query = routeUtils.parseQuery<SharedURLParams>(location.search);
    const { k, d, id, source, t } = query;
    const nQuery = Number(query.n);
    const n = !isNaN(nQuery) ? nQuery : 0;

    const { product = '' } = useParams<{ product: string }>();
    const productCode = product.toUpperCase() as ProductCode;

    const appName = useMemo(() => getLabelFromGlobalDataKey('availproductsfull', productCode), [productCode]);
    const productName = productCodeToIconMap[productCode];

    const navigate = useNavigate();

    const handleGotoHomepage = () => {
        navigate('/');
    };

    return {
        k,
        d,
        t,
        n,
        id,
        source,
        productCode,
        appName,
        productName,
        handleGotoHomepage,
    };
}
