import React from 'react';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './ButtonUpload.styl';
import { CUSTOM_CURVE_INPUT_INFO, CustomCurveFormFieldNames, TRANSDUCER_FIELDS } from '../../../types/customCurve';

interface CustomCurveLabelProps {
    fieldName: CustomCurveFormFieldNames;
    includeUnits?: boolean;
    includeAsterisk?: boolean;
    prefixTransducerFields?: boolean;
}

const CustomCurveLabel: React.FunctionComponent<CustomCurveLabelProps> = (props) => {
    const { fieldName, includeAsterisk, includeUnits, prefixTransducerFields } = props;
    const curveInfo = CUSTOM_CURVE_INPUT_INFO[fieldName];
    return (
        <>
            {prefixTransducerFields && TRANSDUCER_FIELDS.includes(fieldName) && (
                <>
                    <FormattedMessage id="DataView.Upload.Inputs.Transducer" />{' '}
                </>
            )}
            <FormattedMessage id={curveInfo.label} />
            {includeUnits && curveInfo.units && ` (${curveInfo.units})`}
            {includeAsterisk && <span className={styles.mandatory_asterisk_field}>*</span>}
        </>
    );
};

export default CustomCurveLabel;
