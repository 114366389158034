import React, { useMemo } from 'react';
import { get } from 'lodash';
import LogEvent, { renderUserInitials } from '../LogEvent/LogEvent';
import FormattedMessage from '../../../../localization/FormatMessage';
import { ProductCode, ProductModel, ScanType } from '../../../../types/proceq';
import { Log } from '../../../../types/logs';
import { renderLogbookDate } from '../../../../utils/dateUtils';
import useLogEventsSeparator from './useLogEventsSeparator';
import useProceqUsers from '../../../../hooks/useProceqUsers';
import { useProbeTypeID } from '../../../../hooks/useProductData';
import { useMeasurementViewerContext } from '../../../StandaloneMeasurementView/MeasurementViewerContext';
import { Probe } from '../LogEvent/shared/Probe';
import { MeasurementSettings, ProbeTypeID } from '../../../../types/measurement';
import getSchmidtPrimaryScaleUnit from '../utils/getSchmidtPrimaryScaleUnit';

interface EquotipLogbookProps {
    isVerificationMode?: boolean;
    product: ProductCode;
    productModel: ProductModel;
    logs: Log[];
    scanType: ScanType;
    isMetric: boolean;
    highlight: Set<string>;
    toggleHighlight: (id: string, isHighlighted: boolean) => void;
    measurementID: string;
    measurementSettings?: MeasurementSettings;
}

const logsSorter = (a: Log, b: Log) => {
    if (a.type === 'creation') {
        return -1;
    }
    if (b.type === 'creation') {
        return 1;
    }
    return a.clientCreated - b.clientCreated || a.content.measurementIndex - b.content.measurementIndex;
};

export const EquotipLogbook: React.FunctionComponent<EquotipLogbookProps> = (props) => {
    const {
        isVerificationMode,
        product,
        productModel,
        logs,
        isMetric,
        scanType,
        highlight,
        toggleHighlight,
        measurementID,
        measurementSettings,
    } = props;
    const { users } = useProceqUsers();

    const primaryScaleId = useMemo(
        () =>
            product === ProductCode.SCHMIDT
                ? getSchmidtPrimaryScaleUnit(productModel)
                : get(logs && logs.find((log) => log.type === 'creation'), 'content.measurementInfo.primaryScaleId'),
        [logs, product, productModel]
    );
    const probeTypeID = useProbeTypeID(measurementID);
    const equotipScale = primaryScaleId || (probeTypeID === ProbeTypeID.EquotipUCI ? 25 : 2);
    const schmidtScale = primaryScaleId;

    const { probeLog, otherLogs } = useLogEventsSeparator(logs, logsSorter);

    const { isStandalone } = useMeasurementViewerContext();

    return (
        <div id="logbookModal">
            {isStandalone && (
                <div className="title-wrapper">
                    <h1>
                        <FormattedMessage id="Logbook" />
                    </h1>
                </div>
            )}
            <div id="chat-body" className="chat-body-custom-scroll">
                {logs === null && <FormattedMessage id="Proceq.LogbookNoEntries" />}
                {!!probeLog && <Probe log={probeLog} product={product} productModel={productModel} />}
                <ul>
                    {logs &&
                        otherLogs.map((log, index) => {
                            const isHighlighted = highlight.has(log.id);
                            return (
                                <LogEvent
                                    key={log.id}
                                    log={log}
                                    product={product}
                                    productModel={productModel}
                                    user={renderUserInitials(users[log.uID])}
                                    highlighted={isHighlighted}
                                    toggleHighlight={() => toggleHighlight(log.id, isHighlighted)}
                                    showDate={
                                        index === 0 ||
                                        renderLogbookDate(log.clientCreated) !==
                                            renderLogbookDate(otherLogs[index - 1].clientCreated)
                                    }
                                    date={log.clientCreated}
                                    isMetric={isMetric}
                                    scanType={scanType}
                                    isVerificationMode={isVerificationMode}
                                    primaryEquotipScaleId={equotipScale}
                                    primarySchmidtScaleUnit={schmidtScale}
                                    primaryVerificationScaleId={product === 'EQUOTIP' ? equotipScale : schmidtScale}
                                    measurementSettings={measurementSettings}
                                />
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

export default EquotipLogbook;
