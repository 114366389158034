import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';

export const TestBlock: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log, product }) => {
    const oldValue = log.content.oldTestBlockTitle;
    const newValue = log.content.updatedTestBlockTitle;
    return (
        <span>
            {log.content.oldTestBlockTitle ? (
                <FormattedMessage
                    id={
                        product === 'EQUOTIP'
                            ? 'Proceq.LogbookPropertyTestBlockChangedFromTo'
                            : 'Proceq.LogbookPropertyCalibrationAnvilChangedFromTo'
                    }
                    values={{ oldValue, newValue }}
                />
            ) : (
                <FormattedMessage
                    id={
                        product === 'EQUOTIP'
                            ? 'Proceq.LogbookPropertyTestBlockChangedTo'
                            : 'Proceq.LogbookPropertyCalibrationAnvilChangedTo'
                    }
                    values={{ newValue }}
                />
            )}
        </span>
    );
};

export default {
    element: TestBlock,
};
