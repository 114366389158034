import { DateTimeString, ProductModel, UUID } from './proceq';

export interface ContractInfo {
    bp: string;
    businessUnit: string;
    contactEmail: string;
    created: DateTimeString;
    creator: number;
    dongleSN: string;
    dueDate: string;
    expirationDate: string;
    featureList: FeatureInfo[];
    features: string;
    hardwareID: string;
    id: UUID;
    invoiceNo: string;
    isCareRedeemed: number;
    isDemo: number;
    isInternal: number;
    language: string;
    licenseCount: 0;
    mASN: string;
    payment: 0;
    paymentTerm: 0;
    probeSN: string;
    product: ProductModel;
    remarks: string;
    tier: string;
    tierName: string;
    type: number;
    updated: DateTimeString;
}

interface FeatureInfo {
    code: string;
    name: string;
    type: string;
}

export const FeatureCode = {
    COMMON: {
        HTML: 'COMMON_0003',
        SHARE_URL: 'COMMON_0002',
        CSV_EXPORT: 'COMMON_0007',
    },
    GPR: {
        SEGY: 'GPR_0005',
    },
    GPR_PALM: {
        SEGY: 'GPR_PALM_0005',
    },
    GPR_GP8100: {
        SEGY: 'GPR_GP8100_0005',
    },
    GPR_SOIL: {
        SEGY: 'GPR_SOIL_0005',
        DXF: 'GPR_SOIL_0012',
        KML: 'GPR_SOIL_0014',
        SHP: 'GPR_SOIL_0015',
    },
    GS9000: {
        SEGY: 'GS9000_0005',
        DXF: 'GS9000_0012',
        KML: 'GS9000_0014',
        SHP: 'GS9000_0015',
    },
    PUNDIT: {
        RAW: 'PUNDIT_0004',
    },
    PUNDIT_PD8050: {
        RAW: 'PUNDIT_PD8050_0002',
    },
    FDL: {
        RAW: 'FDL_0009',
    },
    PROFOMETER: {
        DXF: 'PM8000_0010',
    },
};
