import React, { useCallback, useState } from 'react';
import { Spin } from 'antd';
import JSZip from 'jszip';
import { find } from 'lodash';
import { getFile } from '../../api/utilsService';
import styles from './Attachment.styl';
import FormattedMessage from '../../localization/FormatMessage';
import { classNames } from '../../utils/styleUtils';
import useCheckInViewPort, { nullCallback } from '../../hooks/useCheckInviewPort';
import AuthButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { SnapshotAction } from '../../analytics/analyticsConstants';
import { ProductCode } from '../../types/proceq';
import { useMeasurementViewerContext } from '../StandaloneMeasurementView/MeasurementViewerContext';
import imagePlaceholderSrc from '../../images/imagePlaceholder.svg';
import StyledModal from '../shared/StyledModal';

interface PhotoProps {
    mID: string;
    aID: string;
    attachmentName?: string;
    getScrollParent?: () => HTMLElement | null;
    analyticsCategory: SnapshotAction;
    analyticsProduct: ProductCode;
    analyticsType: string;
}

export const Photo: React.FunctionComponent<PhotoProps> = (props) => {
    const { mID, aID, attachmentName, analyticsCategory, analyticsProduct, analyticsType } = props;
    const { isStandalone, isHTMLExport } = useMeasurementViewerContext();
    const getScrollParent = isStandalone ? nullCallback : props.getScrollParent;

    const [loading, setLoading] = useState(true);
    const [image, setImage] = useState(() => {
        if (!isHTMLExport) return '';
        return `media/${aID}.jpg`;
    });
    const [visible, setVisible] = useState(false);
    const [imageWidth, setImageWidth] = useState(0);
    const [filename, setFilename] = useState('');

    const fetchData = useCallback(() => {
        if (isHTMLExport) {
            setLoading(false);
            return;
        }

        getFile({ mID, aID })
            .then((arrayBuffer) => JSZip.loadAsync(arrayBuffer))
            .then((zip) => {
                const image = find(zip.files, (_file, fileName: string) => /\.(jpg|png)$/.test(fileName));

                if (image) {
                    image.async('blob').then((blob) => {
                        const imageURL = window.URL.createObjectURL(blob);
                        const img = new Image();
                        img.onload = function onload() {
                            // include padding from modal body
                            setImageWidth(img.width + 48);
                        };
                        img.src = imageURL;
                        setImage(imageURL);
                        setFilename(image.name || attachmentName || '');
                    });
                }
            })

            .finally(() => {
                setLoading(false);
            });
    }, [aID, attachmentName, mID, isHTMLExport]);

    const { ref } = useCheckInViewPort(fetchData, getScrollParent);

    return (
        <div
            className={classNames(
                styles.attachment_container,
                styles.image_container,
                image ? '' : styles.default_cursor
            )}
            ref={ref}
        >
            {loading ? (
                <Spin className={styles.loading} />
            ) : (
                <>
                    <a
                        className={image ? styles.image_container : styles.image_placeholder_container}
                        href={isHTMLExport ? image : undefined}
                        onClick={!isHTMLExport && image ? () => setVisible(true) : undefined}
                    >
                        <img className={styles.image} src={image || imagePlaceholderSrc} alt="thumbnail" />
                    </a>
                    {!isHTMLExport && (
                        <StyledModal
                            open={visible}
                            closable={false}
                            footer={null}
                            onOk={() => setVisible(false)}
                            onCancel={() => setVisible(false)}
                            width={imageWidth}
                            styles={{ body: { overflow: 'auto' } }}
                        >
                            <img
                                className={styles.full_image}
                                src={image}
                                alt="full size"
                                onClick={() => setVisible(false)}
                            />
                        </StyledModal>
                    )}
                    {!isHTMLExport && image && (
                        <a
                            href={image}
                            download={filename}
                            className={styles.photo_download}
                            onClick={() =>
                                analytics.logDownloadSnapshot(analyticsCategory, analyticsProduct, analyticsType)
                            }
                        >
                            <AuthButton>
                                <FormattedMessage id="App.Download" />
                            </AuthButton>
                        </a>
                    )}
                </>
            )}
        </div>
    );
};

export default Photo;
