import React from 'react';
import { LineSeries } from 'react-jsx-highcharts';
import { useMemo } from 'react';
import { doExtraInfoColumn } from '../utils/legacyEquotipChartHelper';
import { getResolutionForScale } from '../utils/conversionsHelper';
import {
    getAllParsedDataNeeded,
    getPairValueForProduct,
    getSettingsParametersForProduct,
} from '../utils/legacyCustomMaterialsChartHelper';
import { ProductCode } from '../../../../types/proceq';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { CustomMaterialItem } from '../../../../types/customMaterial';
import { chartColors } from './HighchartsConfigs';
import styles from './EquotipReadings.styl';
import HighchartsLineSeries from '../../../shared/Charts/HighchartsLineSeries';

interface CustomMaterialReadingProps {
    record: CustomMaterialItem;
    product: ProductCode;
}

export const CustomMaterialReading: React.FunctionComponent<CustomMaterialReadingProps> = ({ record, product }) => {
    const formatMessage = useFormatMessage();

    const allParsedDataNeeded = getAllParsedDataNeeded(record, product, formatMessage);
    const sourceUnit = allParsedDataNeeded.SETTINGS.SOURCEUNIT?.VALUE;
    const targetUnit = allParsedDataNeeded.SETTINGS.TARGETUNIT?.VALUE;

    const curves = record.content.curves;

    const originalCurves = useMemo(() => {
        return (
            curves.original?.map(([source, target]) => [
                parseFloat(getResolutionForScale(sourceUnit, String(source))),
                parseFloat(getResolutionForScale(targetUnit, String(target))),
            ]) ?? []
        );
    }, [curves.original, sourceUnit, targetUnit]);

    const transformedCurves = useMemo(() => {
        return (
            curves.transformed?.map(([source, target]) => [
                parseFloat(getResolutionForScale(sourceUnit, String(source))),
                parseFloat(getResolutionForScale(targetUnit, String(target))),
            ]) ?? []
        );
    }, [curves.transformed, sourceUnit, targetUnit]);

    const pairValueHTML = doExtraInfoColumn(
        getPairValueForProduct(product),
        'Proceq.ExportFieldValuePair',
        allParsedDataNeeded
    );
    const settingsHTML = doExtraInfoColumn(
        getSettingsParametersForProduct(product),
        'Proceq.ExportFieldSettings',
        allParsedDataNeeded
    );

    return (
        <>
            {curves && curves.original && (
                <div className={styles.custom_material_graph_wrapper}>
                    <HighchartsLineSeries
                        sourceUnit={sourceUnit ?? ''}
                        targetUnit={String(targetUnit)}
                        xAxisTitle={sourceUnit}
                        yAxisTitle={targetUnit}
                    >
                        {curves.original.length > 0 && (
                            <LineSeries
                                name={formatMessage({
                                    id:
                                        product === 'SCHMIDT'
                                            ? 'Proceq.FieldWebCustomCurve'
                                            : 'Proceq.FieldWebOriginalCurve',
                                })}
                                data={originalCurves}
                                color={product === 'SCHMIDT' ? chartColors[0] : chartColors[1]}
                            />
                        )}
                        {curves.transformed.length > 0 && (
                            <LineSeries
                                name={formatMessage({
                                    id: 'Proceq.FieldWebCustomCurve',
                                })}
                                data={transformedCurves}
                                color={chartColors[0]}
                            />
                        )}
                    </HighchartsLineSeries>
                </div>
            )}
            <div className={styles.extraInfo_wrapper}>
                {pairValueHTML}
                {settingsHTML}
            </div>
        </>
    );
};

export default CustomMaterialReading;
