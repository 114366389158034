import React from 'react';
import { Spin } from 'antd';
import { SpinProps } from 'antd/lib/spin';

const Spinner: React.FunctionComponent<SpinProps> = (props) => (
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Spin {...props} size={props.size || 'large'} />
    </div>
);

export default Spinner;
