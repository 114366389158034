import { ProbeBasicInfo, ProbeContractInfo } from '../types/probe';
import actionTypes, { PayloadAction } from './actionTypes';

export interface ReceivedProbeListPayload {
    probeList: ProbeBasicInfo[];
}

export const receivedProbeList = (payload: ReceivedProbeListPayload): PayloadAction<ReceivedProbeListPayload> => {
    return {
        type: actionTypes.RECEIVED_PROBE_LIST,
        payload,
    };
};

export interface ReceivedProbeInfoPayload {
    probeSN: string;
    probeInfo: ProbeContractInfo;
}
export const receivedProbeInfo = (payload: ReceivedProbeInfoPayload): PayloadAction<ReceivedProbeInfoPayload> => {
    return {
        type: actionTypes.RECEIVED_PROBE_INFO,
        payload,
    };
};
