import React, { Fragment, useMemo } from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DerivedProps } from '../../SharedTypes';
import styles from '../../DataViewers.styl';
import Markers from '../shared/Markers';
import { MeasurementFullData, MeasurementReading } from '../../../../../types/measurement';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { FormatIDs } from '../../../../../types';

const tableTitle = (pileName: string, positionIndex: number) => {
    const orderDesc = String.fromCharCode(65 + positionIndex);
    return <FormattedMessage id="App.HTML.PI8000.Piles.PositionWithPileName" values={{ pileName, orderDesc }} />;
};

const positionLengthCalculator = (depth: { top: number; bottom: number }, velocity: number, samplingRate: number) => {
    const distance = depth.bottom - depth.top;
    if (!samplingRate || !velocity) {
        return '';
    }

    return ((distance / samplingRate) * velocity) / 2;
};

const aScanIsIncludedMaps = (readings: MeasurementReading[]) => {
    return readings.reduce((accumulator: { [key: string]: string }, reading) => {
        if (reading.type === 'AScan') {
            const content = reading.content;
            accumulator[content.aScanID] = content.isIncluded;
        }
        return accumulator;
    }, {});
};

const calculateTotalImpacts = (isIncludedMaps: { [x: string]: any }, aScanIDs: string[]) => {
    const impacts = aScanIDs.length;
    const excludedCount = aScanIDs.reduce((count, aScanID) => {
        return !isIncludedMaps[aScanID] ? count + 1 : count;
    }, 0);

    if (excludedCount > 0) {
        return (
            <FormattedMessage
                id="App.HTML.PI8000.Piles.Position.ImpactWithExcludedFromAverage"
                values={{ excludedCount, impacts }}
            />
        );
    }

    return impacts;
};

const PilePositions: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    product,
    isMetric,
    convert,
}) => {
    const piles = data.measurement.content.piles;
    const velocity = data.settings[0].content.processing.velocity;
    const samplingRate = data.settings[0].content.processing.samplingRate;
    const isIncludedMaps = aScanIsIncludedMaps(data.readings);
    const derivedProps: DerivedProps = {
        product,
        scanType,
        isMetric,
        convert,
    };

    const pilePositions: any[] = useMemo(() => {
        const temp: any[] = [];
        piles.forEach((pile: { positions: any[]; name: string }) => {
            pile.positions.forEach((position, index) => {
                const title = tableTitle(pile.name, index);
                const positionID = position.id;
                const overview = [
                    {
                        field: 'App.HTML.PI8000.Piles.Position.Status',
                        value: position.status,
                        key: 'status',
                    },
                    {
                        field: 'App.HTML.PI8000.Piles.Position.TotalImpacts',
                        value: calculateTotalImpacts(isIncludedMaps, position.aScanIDs),
                        key: 'totalImpact',
                    },
                    {
                        field: 'App.HTML.PI8000.Piles.Position.CalculatedLength',
                        unitId: 'PIT_IE.CSV.CalculatedLength',
                        value: positionLengthCalculator(position.depth, velocity, samplingRate),
                        key: 'calculatedLength',
                    },
                ].map((overviewData) =>
                    overviewData.unitId
                        ? {
                              ...overviewData,
                              value: convert(overviewData.value, overviewData.unitId),
                              field: (
                                  <ConvertedUnits
                                      id={overviewData.field as FormatIDs}
                                      unitId={overviewData.unitId}
                                      scanType={scanType}
                                      isMetric={isMetric}
                                  />
                              ),
                          }
                        : {
                              ...overviewData,
                              field: <FormattedMessage id={overviewData.field} />,
                          }
                );
                temp.push({ title, overview, positionID });
            });
        });
        return temp;
    }, [convert, isIncludedMaps, isMetric, piles, samplingRate, scanType, velocity]);

    return (
        <>
            {pilePositions.map(({ title, overview, positionID }) => (
                <Fragment key={positionID}>
                    <Table
                        title={() => <span className={styles.sub_header}>{title}</span>}
                        className={styles.table}
                        dataSource={overview}
                        pagination={false}
                        showHeader={false}
                        columns={[
                            {
                                dataIndex: 'field',
                                key: 'filed',
                                className: styles.column,
                            },
                            {
                                dataIndex: 'value',
                                key: 'value',
                            },
                        ]}
                        size="small"
                    />
                    <Markers data={data} positionID={positionID} {...derivedProps} showTitle />
                    <br />
                </Fragment>
            ))}
        </>
    );
};

export default PilePositions;
