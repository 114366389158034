import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { RootState } from '../reducers';
import { ProductData, SingleMeasurementState } from '../reducers/measurement';
import { ProductCode } from '../types/proceq';
import { EquotipProbeInfo } from '../types/probe';
import { htmlViewExportFolder } from '../types/measurement';

export const useProductData = (product?: ProductCode): ProductData => {
    const productData: ProductData | undefined = useSelector(
        (state: RootState) => state.measurement.products[product ?? '']
    );
    return useMemo(() => ({ ...productData }), [productData]);
};

export const useMeasurements = () => {
    const { measurements } = useSelector((state: RootState) => state.measurement);
    return measurements;
};

export const useCustomMaterials = () => {
    const { customMaterials } = useSelector((state: RootState) => state.measurement);
    return customMaterials;
};

export const useMeasurement = (measurementID: string): SingleMeasurementState => {
    const measurement: SingleMeasurementState | undefined = useSelector(
        (state: RootState) => state.measurement.measurements[measurementID]
    );
    return useMemo(() => ({ ...measurement }), [measurement]);
};

export const useRecentMeasurements = () => {
    const { recentMeasurementIDs } = useSelector((state: RootState) => state.measurement);
    return recentMeasurementIDs;
};

export const useProbeTypeID = (measurementID: string): number | undefined => {
    const measurement: SingleMeasurementState | undefined = useSelector(
        (state: RootState) => state.measurement.measurements[measurementID]
    );
    const probeInfo: EquotipProbeInfo | undefined = measurement?.measurement?.probeinfo as any;
    return probeInfo?.typeId;
};

export const useHTMLViewExportIDs = (product: ProductCode) => {
    return useSelector(
        (state: RootState) => state.measurement.products[product].measurementIDs?.[htmlViewExportFolder]
    );
};

export const useImportMeasurementsData = () => {
    const { importMeasurementsData } = useSelector((state: RootState) => state.measurement);
    return importMeasurementsData;
};

export const useCreateMeasurementsData = () => {
    const { createMeasurementsData } = useSelector((state: RootState) => state.measurement);
    return createMeasurementsData;
};

export const useImportMeasurementsInfo = () => {
    const { importInfo } = useSelector((state: RootState) => state.measurement);
    return importInfo;
};

export const useProductFolders = (product?: ProductCode) => {
    const archivedFolders = useSelector(
        (state: RootState) => state.measurement.products[product ?? '']?.archivedData?.folders
    );
    const folders = useSelector((state: RootState) => state.measurement.products[product ?? '']?.folders);
    return { archivedFolders, folders };
};
