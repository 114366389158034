import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

const CalibrateSpotVelocity: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    return (
        <div>
            <FormattedMessage id="App.Logbook.PI8000.CalibrateSpotVelocity" values={{ name: log.content.spotName }} />
        </div>
    );
};

export default {
    element: CalibrateSpotVelocity,
};
