import { useEffect, useRef, useState } from 'react';

export const checkInViewPort = (element: HTMLDivElement | null, offset: number) => {
    if (!element) {
        return false;
    }
    const top = element.getBoundingClientRect().top;
    const isInViewPort = top + offset >= 0 && top - offset <= window.innerHeight;
    return isInViewPort;
};

export const findScrollParent = (node: HTMLElement | null) => {
    let parent = node?.parentElement || null;
    while (parent) {
        if (parent.scrollHeight > parent.clientHeight) {
            return parent;
        }
        parent = parent.parentElement;
    }
    return parent;
};

export const nullCallback = () => null;

const useCheckInViewPort = (
    callback: () => void,
    getScrollParent: (() => HTMLElement | null) | undefined,
    offset: number = 0
) => {
    const ref = useRef<HTMLDivElement>(null);

    const [onHold, setOnHold] = useState(true);

    useEffect(() => {
        if (onHold) {
            // setTimeout to wait for DOM elements being ready
            setTimeout(() => setOnHold(false));
            return () => {};
        }

        const scrollParent = getScrollParent ? getScrollParent() : findScrollParent(ref.current);
        if (scrollParent) {
            const detection = () => {
                if (checkInViewPort(ref.current, offset)) {
                    callback();
                    clear();
                }
            };

            const clear = () => scrollParent.removeEventListener('scroll', detection);
            scrollParent.addEventListener('scroll', detection);
            // Execute detection once to check if the element already inside viewport
            detection();
            return clear;
        }

        // Execute callback if not able to find scroll parent.
        callback();
        return () => {};
    }, [callback, getScrollParent, offset, onHold]);

    return { ref };
};

export default useCheckInViewPort;
