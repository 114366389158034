import React, { useMemo } from 'react';
import MenuList from '../shared/MenuList';
import styles from './DataView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import { IconCustomCurve } from './FolderIcons';
import { CustomCurveFileType } from '../../types/measurement';
import { useDataViewContext } from './DataViewProvider';
import { useProductData } from '../../hooks/useProductData';

interface CustomCurveMenuListProps {
    activeItem?: string;
    onSelect?: (key: string) => void;
}

const CustomCurveMenuList: React.FunctionComponent<CustomCurveMenuListProps> = (props) => {
    const { activeItem, onSelect } = props;
    const { product } = useDataViewContext();
    const { folders } = useProductData(product);

    const dgsccFolderCount = useMemo(() => {
        if (!folders) return 0;
        const dgsccFolderID = Object.keys(folders).find((id) => folders[id].type === 'dgs_default');
        if (dgsccFolderID) {
            return folders[dgsccFolderID].itemCount;
        }
        return 0;
    }, [folders]);

    const customCurveMenu = [
        {
            id: CustomCurveFileType.dgscc,
            title: (
                <div className={styles.menu_title_content}>
                    <IconCustomCurve />
                    <span>
                        <FormattedMessage id="DataView.Folder.CustomDGSCurve" />
                    </span>
                </div>
            ),
            count: dgsccFolderCount,
        },
    ];

    return <MenuList menuItems={customCurveMenu} activeItem={activeItem} onSelect={onSelect} />;
};

export default CustomCurveMenuList;
