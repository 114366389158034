import React, { useEffect } from 'react';
import { Routes as ReactDomRoutes, Route, useLocation } from 'react-router-dom';
import AuthReceiver from '../Auth/AuthReceiver';
import AuthLogout from '../Auth/AuthLogout';
import routeURLs from './urls';
import AppRoute from './AppRoutes';
import PermissionDenied from '../PermissionDenied';
import StandaloneMeasurementView from '../StandaloneMeasurementView/StandaloneMeasurementView';
import StandaloneMeasurementListing from '../StandaloneMeasurementView/StandaloneMeasurementListing';
import StandaloneMeasurementRoot from '../StandaloneMeasurementView/Root';
import ImportRedirection from '../MeasurementImport/ImportRedirection';
import HTMLViewer from '../DataView/HTMLViewer/HTMLViewer';
import ToolInsights from '../DataView/Tools/ToolInsights';
import UriRedirect from '../UriRedirect';
import analytics from '../../analytics/firebaseAnalytics';

const Routes: React.FunctionComponent = () => {
    const location = useLocation();
    useEffect(() => {
        analytics.setCurrentScreen(location.pathname + location.search);
    }, [location.pathname, location.search]);

    return (
        <ReactDomRoutes>
            <Route path={routeURLs.AUTH} element={<AuthReceiver />} />
            <Route path={routeURLs.LOGOUT} element={<AuthLogout />} />
            <Route path={routeURLs.PERMISSION_DENIED} element={<PermissionDenied />} />
            <Route path={routeURLs.STANDALONE_TOOL} element={<ToolInsights />} />
            <Route path={routeURLs.STANDALONE_MEASUREMENT} element={<StandaloneMeasurementRoot />} />
            <Route path={routeURLs.STANDALONE_MEASUREMENT_LISTING} element={<StandaloneMeasurementListing />} />
            <Route path={routeURLs.STANDALONE_MEASUREMENT_DETAIL} element={<StandaloneMeasurementView />} />
            <Route path={routeURLs.SHARED_URL} element={<ImportRedirection />} />
            <Route path={routeURLs.EXPORT_HTML} element={<HTMLViewer />} />
            <Route path={routeURLs.URI_REDIRECT} element={<UriRedirect />} />
            <Route path="/*" element={<AppRoute />} />
        </ReactDomRoutes>
    );
};

export default Routes;
