import Slider, { Settings } from 'react-slick';
import React from 'react';
import styles from './ImageSlider.styl';
import { ReactComponent as IconArrowDown } from '../../images/iconArrowDown.svg';
import { ProductCode } from '../../types/proceq';
import analytics from '../../analytics/firebaseAnalytics';
import { ImageSliderAction, ImageSliderCategory } from '../../analytics/analyticsConstants';

// due to bug in lib https://github.com/akiran/react-slick/issues/1195
const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }: any) => <span {...props}>{children}</span>;

interface ImageSliderProps extends Settings {
    product?: ProductCode;
    analyticsCategory: ImageSliderCategory;
}

const ImageSlider: React.FunctionComponent<React.PropsWithChildren<ImageSliderProps>> = ({
    product,
    analyticsCategory,
    children,
    ...props
}) => {
    return (
        <Slider
            className={styles.slider}
            speed={500}
            infinite={false}
            lazyLoad="ondemand"
            nextArrow={
                <SlickButtonFix>
                    <IconArrowDown
                        onClick={() =>
                            analytics.logImageSliderAction({
                                product,
                                category: analyticsCategory,
                                action: ImageSliderAction.leftButton,
                            })
                        }
                    />
                </SlickButtonFix>
            }
            prevArrow={
                <SlickButtonFix>
                    <IconArrowDown
                        onClick={() =>
                            analytics.logImageSliderAction({
                                product,
                                category: analyticsCategory,
                                action: ImageSliderAction.rightButton,
                            })
                        }
                    />
                </SlickButtonFix>
            }
            {...(props as any)}
        >
            {children}
        </Slider>
    );
};

export default ImageSlider;
