import React from 'react';
import styles from './AppWrapper.styl';

interface AppWrapperProps {
    className?: string;
    style?: React.CSSProperties;
}

const AppWrapper: React.FunctionComponent<React.PropsWithChildren<AppWrapperProps>> = (props) => {
    const { className, style } = props;

    return (
        <div className={`${styles.app_wrapper} ${className ?? ''}`} style={style}>
            {props.children}
        </div>
    );
};

export default AppWrapper;
