import { usePrevious } from 'tds-common-fe';
import { useImportMeasurementsInfo, useProductData } from '../../../hooks/useProductData';

export default function useImportMeasurementCount() {
    const info = useImportMeasurementsInfo();
    const { product } = info;

    const productData = useProductData(product);

    const importCount = productData.importIDs?.length || 0;
    const prevImportCount = usePrevious(importCount) || 0;

    return { importCount, prevImportCount };
}
