import * as storageUtils from '../utils/storageUtils';

class AuthInfo {
    token: string | undefined;
    alwaysInjectToken: boolean = false;

    constructor() {
        this.reloadTokenFromSession();
    }

    reloadTokenFromSession = () => {
        this.token = storageUtils.getSessionItem('token') || undefined;
    };

    updateInfo = (token: string, alwaysInjectToken: boolean = false) => {
        this.token = token;
        this.alwaysInjectToken = alwaysInjectToken;

        // Save to persist
        storageUtils.saveSessionItem('token', token);
    };

    clearInfo = () => {
        this.token = undefined;
        this.alwaysInjectToken = false;

        // Remove from persist
        storageUtils.removeSessionItem('token');
    };
}

const authInfo = new AuthInfo();

export default authInfo;
