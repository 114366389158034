import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';

export const Snapshots: React.FunctionComponent = () => (
    <div>
        <b>
            <FormattedMessage id="Proceq.LogbookPropertySnapshotExported" />
        </b>
    </div>
);

export default {
    element: Snapshots,
};
