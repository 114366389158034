import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import { ScanType } from '../../../../../types/proceq';
import styles from './AddNewImpact.styl';
import { FormatIDs } from '../../../../../types';

const ADD_REMOVE_IMPACT_MAP: Record<string, { text: FormatIDs; color: string }> = {
    addNewImpact: {
        text: 'App.LogbookPropertyAddRemoveImpact.Recorded',
        color: 'blue',
    },
    removeImpact: {
        text: 'App.LogbookPropertyAddRemoveImpact.Removed',
        color: 'red',
    },
};

const getImpactStrength = (strength: number): FormatIDs => {
    switch (strength) {
        case 0:
            return 'App.Logbook.PI8000.ImpactStrength.Optimal';
        case -1:
            return 'App.Logbook.PI8000.ImpactStrength.Weak';
        case 1:
            return 'App.Logbook.PI8000.ImpactStrength.Strong';
        default:
            return 'App.Logbook.PI8000.ImpactStrength.Unknown';
    }
};

export const AddRemoveImpact: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    return (
        <div>
            <FormattedMessage
                id="App.LogbookPropertyAddRemoveImpact"
                values={{
                    impactID: log.content.impactID,
                    addedRemoved: <FormattedMessage id={ADD_REMOVE_IMPACT_MAP[log.type].text} />,
                }}
            />
            <br />
            {scanType === ScanType.ImpactEchoSpot && (
                <ul className={styles.list_container}>
                    <li>
                        <FormattedMessage id="App.HTML.PI8000.Spots.Spot" />
                        {`: ${log.content.positionName}`}
                    </li>
                </ul>
            )}
            {scanType === ScanType.ImpactEchoGrid && (
                <ul className={styles.list_container}>
                    <li>
                        <FormattedMessage id="App.Logbook.PI8000.PositionName" />
                        {`: ${log.content.positionName}`}
                    </li>
                </ul>
            )}
            {scanType === ScanType.AScan && (
                <ul className={styles.list_container}>
                    {log.content.impactStrength !== undefined && (
                        <li>
                            <FormattedMessage id="App.Logbook.PI8000.ImpactStrength" />
                            {': '}
                            <FormattedMessage id={getImpactStrength(log.content.impactStrength)} />
                        </li>
                    )}
                    <li>
                        <FormattedMessage id="App.Logbook.PI8000.PositionName" />
                        {`: ${log.content.positionName}`}
                    </li>
                    <li>
                        <FormattedMessage id="App.Logbook.PI8000.PileName" />
                        {`: ${log.content.pileName}`}
                    </li>
                </ul>
            )}
        </div>
    );
};

const color = (log: Log) => ADD_REMOVE_IMPACT_MAP[log.type].color;

export default {
    element: AddRemoveImpact,
    color,
};
