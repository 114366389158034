import React, { useCallback, useState } from 'react';
import * as probeService from '../../api/probeService';
import { useProbeInfoReloadLimiter } from '../../hooks/useLimiter';
import { getProbeName, useProbe } from '../../hooks/useProbes';
import styles from './ProbeCard.styl';
import { ProbeImage } from './ProbeImage';

interface ProbeCardProps {
    serialNumber: string;
    onClick?: () => void;
}

const ProbeCard: React.FunctionComponent<ProbeCardProps> = (props) => {
    const { serialNumber, onClick } = props;
    const [isLoading, setIsLoading] = useState(false);

    const probeInfo = useProbe(serialNumber);
    const { m_log_id: mLogID } = probeInfo ?? {};
    const probeName = getProbeName(probeInfo?.pModel, probeInfo?.name);

    const shouldReload = useProbeInfoReloadLimiter(serialNumber);

    const handleGetProbeInfo = useCallback(async () => {
        if (mLogID && shouldReload) {
            try {
                setIsLoading(true);
                await probeService.getProbeInfo({ probeSN: serialNumber, mLogID });
            } finally {
                setIsLoading(false);
            }
        }

        if (onClick) {
            onClick();
        }
    }, [mLogID, onClick, serialNumber, shouldReload]);

    return (
        <div
            className={styles.card_wrapper}
            onClick={isLoading ? undefined : handleGetProbeInfo}
            style={{
                cursor: onClick ? 'pointer' : undefined,
            }}
        >
            <ProbeImage isLoading={isLoading} name={probeName} pModel={probeInfo?.pModel} />
            <div className={styles.name}>{probeName}</div>
            <div className={styles.serial_number}>{serialNumber}</div>
        </div>
    );
};

export default ProbeCard;
