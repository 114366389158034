import React from 'react';
import { FormInstance, Rule } from 'antd/lib/form';
import {
    CustomCurveFormFieldNames,
    CustomCurveProbeInfo,
    SmoothenedCurveTransducerType,
    TransducerType,
} from '../../../../types/customCurve';

interface CheckInputValueParams {
    decimals: number;
    errorMessage: React.ReactNode;
    checkPositive?: boolean;
    acceptZero?: boolean;
}

const checkInputValue = (value: number | null, params: CheckInputValueParams) => {
    const { decimals, errorMessage, checkPositive, acceptZero } = params;
    if (value === null) {
        return Promise.reject(errorMessage);
    }
    if (checkPositive) {
        if (acceptZero) {
            if (value < 0) {
                return Promise.reject(errorMessage);
            }
        } else if (value <= 0) {
            return Promise.reject(errorMessage);
        }
    }
    const digitsAfterDecimals = value.toString().split('.')[1];
    return digitsAfterDecimals && digitsAfterDecimals.length > decimals
        ? Promise.reject(errorMessage)
        : Promise.resolve();
};

export const getDecimalPlaceValidator =
    (params: CheckInputValueParams): Rule =>
    () => ({
        validator(rule: any, value: number | null) {
            return checkInputValue(value, params);
        },
    });

export const getGreaterThanValidator =
    (fieldKey: string, params: CheckInputValueParams): Rule =>
    (ruleForm: any) => ({
        validator(rule: any, value: number | null) {
            const { errorMessage } = params;
            if (value === null) {
                return Promise.reject(errorMessage);
            }
            const form = ruleForm as FormInstance;
            const smallerValue = form.getFieldValue(fieldKey);
            if (!(smallerValue === null || smallerValue === undefined) && smallerValue >= value) {
                return Promise.reject(errorMessage);
            }
            return checkInputValue(value, params);
        },
    });

export const getCreateMeasurementProbeInfo = (
    requiredFields: CustomCurveFormFieldNames[],
    form: FormInstance<CustomCurveFormFieldNames>,
    isSmoothenedCurveInfo?: boolean
): CustomCurveProbeInfo => {
    return requiredFields.reduce((previousValue, currentValue) => {
        const fieldValue =
            isSmoothenedCurveInfo && currentValue === CustomCurveFormFieldNames.transducerType
                ? SmoothenedCurveTransducerType[form.getFieldValue(currentValue) as TransducerType]
                : form.getFieldValue(currentValue);
        return { ...previousValue, [currentValue]: fieldValue };
    }, {}) as CustomCurveProbeInfo;
};
