import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';

enum CoverEnabledType {
    maxCoverEnabled = 'maxCoverEnabled',
    minCoverEnabled = 'minCoverEnabled',
}

const COVER_ENABLED_TEXT: Record<CoverEnabledType, FormatIDs> = {
    [CoverEnabledType.minCoverEnabled]: 'App.HTML.Profometer.Logbook.CoverEnabled.Minimum',
    [CoverEnabledType.maxCoverEnabled]: 'App.HTML.Profometer.Logbook.CoverEnabled.Maximum',
};

const CoverEnabled: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id={COVER_ENABLED_TEXT[log.type as CoverEnabledType]}
            values={{ enabled: <FormattedMessage id={log.content.enabled ? 'Proceq.On' : 'Proceq.Off'} /> }}
        />
    );
};

export default {
    element: CoverEnabled,
};
