import React, { useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { ButtonType } from 'antd/lib/button';
import FormattedMessage from '../../localization/FormatMessage';
import styles from './ButtonExport.styl';
import { ProductCode } from '../../types/proceq';
import { classNames } from '../../utils/styleUtils';
import AnalyticsButton from '../AnalyticsComponents/Button';
import analytics from '../../analytics/firebaseAnalytics';
import { ExportAction, MeasurementPage } from '../../analytics/analyticsConstants';
import { exportMeasurements } from '../../api/utilsService';
import { logExportUserAction } from '../../api/analyticsEventService';
import useMeasurementImportParams from '../MeasurementImport/useMeasurementImportParams';

interface DownloadProps {
    product: ProductCode;
    mIDs: string[];
    className?: string;
    type?: ButtonType;
    analyticsPage: MeasurementPage;
}

export const ButtonExportHTML: React.FunctionComponent<DownloadProps> = (props) => {
    const { product, mIDs, className, type, analyticsPage } = props;
    const [isLoading, setIsLoading] = useState(false);
    const disabled = !product || !mIDs.length || isLoading;
    const { k, d } = useMeasurementImportParams();

    const handleExport = useCallback(async () => {
        try {
            const format: string = 'HTML';
            setIsLoading(true);
            const response = await exportMeasurements({
                product,
                mIDs,
                format,
                fileType: 'measurement',
                exportAsURL: true,
                k,
                d,
            });
            analytics.logExportMeasurement(analyticsPage, product, format, mIDs.length, ExportAction.export);
            logExportUserAction(format, mIDs.length);

            if (response.data && !(response.data instanceof Blob)) {
                window.open(response.data.url, '_blank');
            }
        } finally {
            setIsLoading(false);
        }
    }, [product, mIDs, k, d, analyticsPage]);

    return (
        <AnalyticsButton
            type={type || 'primary'}
            className={classNames(styles.button_export, className)}
            onClick={handleExport}
            disabled={disabled}
        >
            <FormattedMessage id="App.Export" />
            {isLoading && <LoadingOutlined className={styles.icon} />}
        </AnalyticsButton>
    );
};

export default ButtonExportHTML;
