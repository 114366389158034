import React from 'react';
import { ButtonType } from 'antd/es/button';
import styles from './MeasurementImport.styl';
import { FormatIDs } from '../../types';
import FormattedMessage from '../../localization/FormatMessage';
import Button from '../AnalyticsComponents/Button';

type Action = {
    title: FormatIDs;
    action: () => void;
    type?: ButtonType;
    ghost?: boolean;
    fillWidth?: boolean;
    loading?: boolean;
};

type FooterAction = {
    title: React.ReactNode;
    action: () => void;
    linkText: FormatIDs;
};

interface ImportMessageProps {
    Icon?: React.FunctionComponent<any>;
    title?: React.ReactNode;
    message?: React.ReactNode;
    primaryAction?: Action;
    secondaryAction?: Action;
    footerAction?: FooterAction;
}

const ImportMessage: React.FunctionComponent<ImportMessageProps> = (props) => {
    const { Icon, title, message, primaryAction, secondaryAction, footerAction } = props;

    return (
        <div className={styles.block_container}>
            {Icon && <Icon style={{ fontSize: 80 }} />}
            <div className={styles.block_title}>{title}</div>
            {message}
            {primaryAction && (
                <Button
                    className={styles.button}
                    type={primaryAction.type ?? 'primary'}
                    ghost={primaryAction.ghost}
                    onClick={primaryAction.action}
                    block={primaryAction.fillWidth ?? true}
                    loading={primaryAction.loading ?? false}
                >
                    <FormattedMessage id={primaryAction.title} />
                </Button>
            )}
            {secondaryAction && (
                <Button
                    className={styles.button}
                    type={secondaryAction.type ?? 'link'}
                    onClick={secondaryAction.action}
                    block={secondaryAction.fillWidth ?? true}
                    loading={secondaryAction.loading ?? false}
                >
                    <FormattedMessage id={secondaryAction.title} />
                </Button>
            )}
            {footerAction && (
                <div className={styles.caption}>
                    {footerAction.title}
                    <a className={styles.footer_link} onClick={footerAction.action}>
                        <FormattedMessage id={footerAction.linkText} />
                    </a>
                </div>
            )}
        </div>
    );
};

export default ImportMessage;
