import React from 'react';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import Audio from '../../../../Attachments/Audio';
import styles from '../GPR/UserText.styl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';
import { ScanType } from '../../../../../types/proceq';

enum MarkerType {
    Audio = 'Audio',
    Text = 'Text',
}

enum MarkerStatusType {
    added = 'added',
    deleted = 'deleted',
    edited = 'edited',
}

const MARKER_TEXT: Record<MarkerType, { added: FormatIDs; deleted: FormatIDs; edited?: FormatIDs }> = {
    [MarkerType.Audio]: {
        added: 'Proceq.LogbookMarkerAudioAdded',
        deleted: 'Proceq.LogbookMarkerAudioDeletedWithoutDate',
    },
    [MarkerType.Text]: {
        added: 'Proceq.LogbookMarkerTextAdded',
        deleted: 'Proceq.LogbookMarkerTextDeletedWithoutDate',
        edited: 'Proceq.LogbookMarkerTextEdited',
    },
};

export const Marker: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const { markerType, markerLabel: marker, markerContent, statusType, attachmentName } = log.content;
    const position = +log.content.position;

    const message = (
        <>
            {scanType !== ScanType.BScan && (
                <FormattedMessage id="App.Logbook.FDL.Indication.Position" values={{ position }} />
            )}
            <FormattedMessage
                id={MARKER_TEXT[markerType as MarkerType]?.[statusType as MarkerStatusType] ?? ' '}
                values={{ marker }}
            />
        </>
    );

    return (
        <div>
            {statusType === 'deleted' ? (
                <div className={styles.deleted}>{message}</div>
            ) : (
                <>
                    <div>
                        <b>{message}</b>
                    </div>
                    {markerType === 'Text' && markerContent}
                    {markerType === 'Audio' && (
                        <Audio mID={log.mID} aID={markerContent} attachmentName={attachmentName} />
                    )}
                </>
            )}
        </div>
    );
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');

export default {
    element: Marker,
    color,
};
