import { Table } from 'antd';
import React, { useMemo } from 'react';
import compareVersions from 'compare-versions';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MeasurementFullData, PunditImpactSpot } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import styles from '../../DataViewers.styl';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { FormatIDs } from '../../../../../types';
import { ScanType } from '../../../../../types/proceq';

export const title = <FormattedMessage id="App.HTML.PI8000.Piles.Summary" />;

interface SpotsColumnType {
    title: FormatIDs;
    unitId?: string;
    dataIndex: string;
}

const columnsRaw = (scanType: ScanType): SpotsColumnType[] => [
    {
        title: scanType === ScanType.ImpactEchoGrid ? 'App.HTML.PUNDIT.GridCell' : 'App.HTML.PI8000.Spots.Spot',
        dataIndex: 'name',
    },
    {
        title: 'App.HTML.PI8000.Spots.Impacts',
        dataIndex: 'impacts',
    },
    {
        title: 'App.HTML.PI8000.Spots.BestImpact',
        dataIndex: 'bestImpact',
    },
    {
        title: 'App.HTML.PI8000.Spots.PeakLocation',
        dataIndex: 'peakLocation',
        unitId: 'PIT_IE.Spots.Peak Location',
    },
    {
        title: 'App.HTML.PI8000.Spots.Thickness',
        dataIndex: 'thickness',
        unitId: 'PIT_IE.Spots.Thickness',
    },
];

const calculateThickness = (factor: number, velocity?: number, fftPeak?: number): string | number => {
    if (!velocity || !fftPeak) return '-';
    const pWaveVelocity = velocity * factor;
    return pWaveVelocity / (fftPeak * 2);
};

const SpotsSummary: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, convert, scanType, isMetric } = props;
    const velocity = data.settings[0].content.processing.velocity;

    // use factor 1 if app version >= 2.1.1
    const version = data.measurement.content?.info?.version ?? '0';
    const thicknessFactor = compareVersions.compare(version, '2.1.1', '>=') ? 1 : 0.96;

    const summaries = useMemo(() => {
        const spots: PunditImpactSpot[][] = data.measurement.content.spots ?? [];
        return spots.flat().map((spot) => {
            if (spot.bestScanID) {
                return {
                    name: spot.name,
                    impacts: spot.aScanIDs.length,
                    bestImpact: spot.aScanIDs.indexOf(spot.bestScanID) + 1,
                    peakLocation: spot.fftPeak,
                    thickness: calculateThickness(thicknessFactor, velocity, spot.fftPeak),
                    key: spot.name,
                };
            }
            return {
                name: spot.name,
                impacts: spot.aScanIDs.length,
                bestImpact: '-',
                peakLocation: '-',
                thickness: '-',
                key: spot.name,
            };
        });
    }, [data.measurement.content.spots, thicknessFactor, velocity]);

    const columns = useMemo(() => {
        return columnsRaw(scanType).map((columnConfig) => ({
            render: (text: string | number) => (columnConfig.unitId ? convert(text, columnConfig.unitId) : text),
            ...columnConfig,
            key: columnConfig.title,
            title: columnConfig.unitId ? (
                <ConvertedUnits
                    id={columnConfig.title}
                    unitId={columnConfig.unitId}
                    scanType={scanType}
                    isMetric={isMetric}
                />
            ) : (
                <FormattedMessage id={columnConfig.title} />
            ),
        }));
    }, [convert, isMetric, scanType]);

    return (
        <Table
            title={() => <span className={styles.sub_header}>{title}</span>}
            className={styles.table}
            dataSource={summaries}
            pagination={false}
            bordered
            columns={columns}
            size="small"
        />
    );
};

export default SpotsSummary;
