import { get } from 'lodash';
import { MeasurementFullData } from '../../../../types/measurement';
import { FormatMessageFunc } from '../../../../types';

export const renderFolderName = (measurement: MeasurementFullData, formatMessage: FormatMessageFunc): string => {
    if (get(measurement, 'measurement.isDemoFile') === true) {
        return formatMessage({ id: 'DataView.Folder.Demo' });
    }
    if (get(measurement, 'folder.type') === 'default') {
        return formatMessage({ id: 'DataView.Folder.All' });
    }
    if (get(measurement, 'folder.name')) {
        return get(measurement, 'folder.name');
    }
    return formatMessage({ id: 'DataView.Folder.All' });
};
