export function stringEnumToArray<T>(enumValue: { [s: string]: T }): T[] {
    return Object.values(enumValue);
}

export function numberEnumToArray<T>(enumValue: { [s: string]: T }): number[] {
    const keys = Object.keys(enumValue);
    const values: number[] = [];
    for (const key of keys) {
        const value = enumValue[key];
        if (typeof value === 'number') {
            values.push(value);
        }
    }
    return values;
}

export const deepFreeze = (object: { [key: string]: any }) => {
    const keys = Object.getOwnPropertyNames(object);
    for (const key of keys) {
        const value = object[key];
        if (value && typeof value === 'object' && !Object.isFrozen(value)) {
            deepFreeze(value);
        }
    }
    return Object.freeze(object);
};

export const ellipsesText = (fullText: string) => {
    if (fullText.length > 100) {
        return `${fullText.substr(0, 60)}...${fullText.substr(fullText.length - 40, fullText.length)}`;
    }
    return fullText;
};

export const isNameASCIIPrintable = (name: string) => name.match(/^[\x20-\x7F]+$/i);
