import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import ConvertedUnits from '../../shared/ConvertedUnits';

export const ObjectMarker: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, product, isMetric, scanType }) => {
    const { oldStart, oldEnd, newStart, newEnd } = log.content;

    if (isMetric === undefined || scanType === undefined) {
        return null;
    }

    return (
        <ConvertedUnits
            id="Proceq.LogbookChangeCropArea"
            unitId={`${product}.Logbook Panel.Focus area`}
            scanType={scanType}
            isMetric={isMetric}
            values={[oldStart, oldEnd, newStart, newEnd]}
        />
    );
};

export default {
    element: ObjectMarker,
};
