import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'antd';
import MenuList from '../shared/MenuList';
import { useDataViewContext } from './DataViewProvider';
import styles from './DataView.styl';
import FormattedMessage from '../../localization/FormatMessage';
import * as icons from './FolderIcons';
import { useProductData } from '../../hooks/useProductData';
import { ProductCode, productsWithSpatialData, productsWithVerificationData } from '../../types/proceq';
import {
    ArchivedSystemFolderTypes,
    CustomCurveFileType,
    SystemFolderID,
    SystemFolderType,
    SystemFolderTypes,
    ViewType,
} from '../../types/measurement';
import { getDataViewRoute } from '../Routes/urls';
import BluetoothMenuList, { bluetoothFolderTypes } from './BluetoothMenuList';
import { ReactComponent as PlusIcon } from '../../images/iconPlus.svg';
import CreateRenameModal, { CreateRenameModalAction } from './FolderManagement/CreateRenameModal';
import analytics from '../../analytics/firebaseAnalytics';
import { FolderManagementCategory } from '../../analytics/analyticsConstants';
import { getMeasurementFolders } from '../../api/folderService';
import SortFolder, { SORT_OPTION_MAP, SortOption } from './FolderManagement/SortFolder';
import CustomCurveMenuList from './CustomCurveMenuList';
import FolderName from './FolderName';
import { useProductContext } from './ProductContextProvider';
import { ProductFeature } from './ProductContextProvider';
import BackButton from '../shared/Buttons/BackButton';
import SpatialDataFolder from './SpatialDataFolder';

export interface FolderItemProps {
    icon: React.FunctionComponent;
    title: string | React.ReactNode;
}

const staticFolders: { [key: string]: FolderItemProps } = {
    [SystemFolderType.All]: {
        icon: icons.IconFile,
        title: <FormattedMessage id="DataView.Folder.All" />,
    },
    [SystemFolderType.Import]: {
        icon: icons.IconImport,
        title: <FormattedMessage id="DataView.Folder.Import" />,
    },
    [SystemFolderType.Flagged]: {
        icon: icons.IconFlag,
        title: <FormattedMessage id="DataView.Folder.Flag" />,
    },
    [SystemFolderType.Demo]: {
        icon: icons.IconFolder,
        title: <FormattedMessage id="DataView.Folder.Demo" />,
    },
    [SystemFolderType.Archived]: {
        icon: icons.IconArchive,
        title: <FormattedMessage id="DataView.Folder.Archived" />,
    },
    [SystemFolderType.Trash]: {
        icon: icons.IconTrash,
        title: <FormattedMessage id="DataView.Folder.Trash" />,
    },
};

const DataViewFolders: React.FunctionComponent = () => {
    const { product, activeFolder, setActiveFolder, viewType, dataType, withUnsynced } = useDataViewContext();
    const { isFeatureEnabled } = useProductContext();
    const navigate = useNavigate();
    const { productName = '' } = useParams<{ productName: string }>();

    const productData = useProductData(product);

    const {
        folderIDs: activeFolderIDs = [],
        folders: activeFolders = {},
        userFolderIDs: activeUserFolderIDs = [],
        archivedData = {},
    } = productData;
    const {
        folderIDs: archivedFolderIDs = [],
        folders: archivedFolders = {},
        userFolderIDs: archivedUserFolderIDs = [],
    } = archivedData;
    const [addNewFolder, setAddNewFolder] = useState(false);
    const [sortOption, setSortOption] = useState(SortOption.dateCreated);
    const isArchiveViewType = viewType === ViewType.Archived;
    const isArchivedAllowed = isFeatureEnabled(ProductFeature.ARCHIVE_MEASUREMENTS);
    const folderIDs = isArchiveViewType ? archivedFolderIDs : activeFolderIDs;
    const folders = isArchiveViewType ? archivedFolders : activeFolders;
    const userFolderIDs = isArchiveViewType ? archivedUserFolderIDs : activeUserFolderIDs;

    const isSpatialDataEnabled = isFeatureEnabled(ProductFeature.SPATIAL_DATA);

    useEffect(() => {
        if (product) {
            getMeasurementFolders({ product, withUnsynced, archived: isArchiveViewType });
        }
    }, [isArchiveViewType, isArchivedAllowed, product, withUnsynced]);

    const systemFolderItems = useMemo(
        () =>
            (isArchiveViewType ? ArchivedSystemFolderTypes : SystemFolderTypes)
                .filter((type) => (type === SystemFolderType.Archived ? isArchivedAllowed : true))
                .map((type) => {
                    const id =
                        type === SystemFolderType.Archived
                            ? SystemFolderType.Archived
                            : folderIDs.find((id) => folders[id].type === type);
                    if (
                        type === SystemFolderType.Default ||
                        type === SystemFolderType.VerificationDefault ||
                        type === SystemFolderType.DGSDefault
                    ) {
                        return null;
                    }
                    const folder = id && folders[id] ? folders[id] : { id: type, itemCount: undefined };
                    const staticFolder = staticFolders[type];
                    const Icon = staticFolder?.icon;
                    const title = staticFolder?.title;

                    return {
                        id: String(folder.id),
                        title: <FolderName name={title} icon={<Icon />} />,
                        count: folder.itemCount !== undefined ? Number(folder.itemCount) : undefined,
                    };
                }),
        [folderIDs, folders, isArchiveViewType, isArchivedAllowed]
    );

    const userFolderItems = useMemo(() => {
        return [...userFolderIDs]
            .sort((a, b) => SORT_OPTION_MAP[sortOption].sort(folders[a], folders[b]))
            .map((id) => {
                return {
                    id: String(id),
                    title: <FolderName name={folders[id].name} icon={<icons.IconFolder />} />,
                    name: folders[id].name,
                    count: Number(folders[id].itemCount),
                    deletable: folders[id].deletable,
                    restorable: folders[id].restorable,
                };
            });
    }, [folders, sortOption, userFolderIDs]);

    const handleSetFileType = useCallback(
        (value: string) => {
            const route = getDataViewRoute({ productName, viewType, folderID: value });
            navigate(route);
            setActiveFolder(SystemFolderID.All);
        },
        [navigate, productName, setActiveFolder, viewType]
    );

    const isMeasurementFileType = !([...bluetoothFolderTypes, CustomCurveFileType.dgscc] as string[]).includes(
        dataType
    );

    const handleSetActiveFolder = useCallback(
        (value: string) => {
            let route;
            if (value === SystemFolderID.Archived) {
                route = getDataViewRoute({
                    productName,
                    viewType: ViewType.Archived,
                    folderID: SystemFolderID.All,
                });
            } else {
                route = getDataViewRoute({ productName, viewType, folderID: value });
                setActiveFolder(value);
            }
            navigate(route);
        },
        [navigate, productName, setActiveFolder, viewType]
    );

    const customCurveFolder =
        !isArchiveViewType && product === ProductCode.FDL ? (
            <>
                <CustomCurveMenuList activeItem={dataType} onSelect={handleSetFileType} />
                <hr className={styles.divider} />
            </>
        ) : null;

    const bluetoothProductFolders =
        !isArchiveViewType && product && productsWithVerificationData.has(product) ? (
            <>
                <BluetoothMenuList activeItem={dataType} onSelect={handleSetFileType} />
                <hr className={styles.divider} />
            </>
        ) : null;

    const spatialDataFolder =
        isSpatialDataEnabled && !isArchiveViewType && product && productsWithSpatialData.has(product) ? (
            <>
                <SpatialDataFolder activeItem={dataType} onSelect={handleSetFileType} />
                <hr className={styles.divider} />
            </>
        ) : null;

    const archiveModeHeader = isArchiveViewType ? (
        <div className={styles.archive_container}>
            <BackButton
                buttonText="DataView.Folder.Archived.Back"
                extraClass={styles.archive_mode_back}
                onClick={() => {
                    const route = getDataViewRoute({
                        productName,
                        viewType: ViewType.Active,
                        folderID: SystemFolderID.All,
                    });
                    navigate(route);
                    setActiveFolder(SystemFolderID.All);
                }}
            />
            <h3 className={styles.archive_mode_header}>
                <FormattedMessage id="DataView.Folder.Archived" />
            </h3>
        </div>
    ) : null;

    return (
        <div className={isArchiveViewType ? styles.archive_menu_container : styles.menu_container}>
            {archiveModeHeader}
            <div className={styles.menu_column}>
                {spatialDataFolder}
                {customCurveFolder}
                {bluetoothProductFolders}
                <h3 className={isArchiveViewType ? styles.archive_system_folder_title : styles.folder_title}>
                    <FormattedMessage id="App.SystemFolder" />
                </h3>
                <MenuList
                    menuItems={systemFolderItems}
                    activeItem={isMeasurementFileType ? activeFolder : ''}
                    onSelect={handleSetActiveFolder}
                />
                <hr className={styles.divider} />
                {userFolderItems.length > 0 && (
                    <div className={styles.folder_sort_container}>
                        <h3 className={styles.folder_title}>
                            <FormattedMessage id="App.MyFolders" />
                        </h3>
                        <SortFolder selectedSortOption={sortOption} onChange={setSortOption} />
                    </div>
                )}
                <MenuList
                    menuItems={userFolderItems}
                    activeItem={isMeasurementFileType ? activeFolder : ''}
                    onSelect={handleSetActiveFolder}
                    isUserFolder
                    isArchiveViewType={isArchiveViewType}
                />
            </div>
            {!isArchiveViewType && (
                <div className={styles.add_folder}>
                    <Button
                        type="text"
                        onClick={() => {
                            setAddNewFolder(true);
                            analytics.logFolderManagement(FolderManagementCategory.sidebarNewFolder, product ?? '');
                        }}
                    >
                        <PlusIcon />
                        <FormattedMessage id="App.NewFolder" />
                    </Button>
                </div>
            )}
            <CreateRenameModal
                action={CreateRenameModalAction.newFolder}
                visible={addNewFolder}
                onCancel={() => setAddNewFolder(false)}
            />
        </div>
    );
};

export default DataViewFolders;
