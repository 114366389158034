import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import styles from './Header.styl';
import { ReactComponent as EagleWorkspaceLogo } from '../../../images/eagleWorkspace.svg';
import ProfileMenu from './ProfileMenu';
import routeURLs from '../../Routes/urls';
import analytics from '../../../analytics/firebaseAnalytics';
import NotificationButton from '../../Notification/NotificationButton';
import config from '../../../config';
import InsightsMenu, { InsightsMenuProps } from './InsightsMenu';
import DevFeatureConfig from './DevFeatureConfig';
import { useBackendVersionQuery } from '../../../queries/utilsQueries';

interface HeaderProps extends InsightsMenuProps {
    hasLogo?: boolean;
    hasActions?: boolean;
    background?: CSSProperties['background'];
    removeLinks?: boolean;
    showInsightsMenu?: boolean;
    isSidePanel?: boolean;
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
    const {
        hasLogo = false,
        hasActions = false,
        background,
        removeLinks = false,
        showInsightsMenu,
        endSession,
        shareSession,
        isAppstreamReady = false,
        isStandaloneTool = false,
        isSidePanel = false,
        relaunchSession,
    } = props;
    const showTag = config.APP_ENV !== 'production';
    const { data: backendVersion } = useBackendVersionQuery();

    return (
        <div className={isSidePanel && showTag ? styles.side_panel_header : styles.header} style={{ background }}>
            <div className={styles.logo_container}>
                {hasLogo && (
                    <>
                        {removeLinks ? (
                            <div
                                className={styles.logo}
                                onClick={() => {
                                    analytics.logClickHeaderLogo();
                                    window.open(routeURLs.DASHBOARD, 'workspace');
                                }}
                            >
                                <EagleWorkspaceLogo />
                            </div>
                        ) : (
                            <Link
                                className={styles.logo}
                                to={routeURLs.DASHBOARD}
                                onClick={() => analytics.logClickHeaderLogo()}
                            >
                                <EagleWorkspaceLogo />
                            </Link>
                        )}

                        {showTag && (
                            <DevFeatureConfig>
                                <div className={styles.tag}>
                                    {config.APP_ENV?.toUpperCase()} FE {config.APP_VERSION}
                                    {backendVersion && `${isSidePanel ? '\n' : ' '}BE ${backendVersion}`}
                                </div>
                            </DevFeatureConfig>
                        )}
                    </>
                )}
            </div>
            {hasActions && (
                <div className={styles.actions}>
                    <NotificationButton />
                    <ProfileMenu />
                </div>
            )}
            {showInsightsMenu && endSession && (
                <InsightsMenu
                    endSession={endSession}
                    isAppstreamReady={isAppstreamReady}
                    shareSession={shareSession}
                    isStandaloneTool={isStandaloneTool}
                    relaunchSession={relaunchSession}
                />
            )}
        </div>
    );
};

export default Header;
