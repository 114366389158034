import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const Angles: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const { newValue, oldValue } = log.content;
    return <FormattedMessage id="App.Logbook.GLM.GeometriesChanged" values={{ oldValue, newValue }} />;
};

export const color = () => 'blue';

export default {
    element: Angles,
    color,
};
