import React, { useCallback } from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import Photo from '../../../../Attachments/Photo';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';
import { ScanType } from '../../../../../types/proceq';
import styles from './AddNewImpact.styl';

export const Snapshot: React.FC<GPRLogbookEntryProps> = ({ log, product, scanType }) => {
    const getAScanItems = useCallback(() => {
        if (log.content.pileAndPositions) {
            return log.content.pileAndPositions.map((position: { [key in string]: string[] }) => {
                const [key, value] = Object.entries(position)[0];
                return (
                    <li key={key}>
                        {key}
                        {': '}
                        {value.join(', ')}
                    </li>
                );
            });
        }
        return (
            <>
                <li>
                    <FormattedMessage id="App.Logbook.PI8000.Pile" />
                    {': '}
                    {log.content.pileName}
                </li>
                <li>
                    <FormattedMessage id="App.Logbook.PI8000.PositionName" />
                    {': '}
                    {log.content.positionName}
                </li>
                <li>
                    <FormattedMessage id="App.Logbook.PI8000.HighlightedSignal" />
                    {': '}
                    {log.content.highlightedSignal}
                </li>
            </>
        );
    }, [log.content]);

    const getItems = useCallback(() => {
        switch (scanType) {
            case ScanType.ImpactEchoGrid:
                return (
                    <ul className={styles.list_container}>
                        <li>
                            <FormattedMessage
                                id={
                                    log.content.isGridMode
                                        ? 'App.Logbook.PI8000.SelectedCell'
                                        : 'App.HTML.FDL.MeasurementPresets.CorrosionGrid.Cells'
                                }
                            />
                            {': '}
                            {log.content.name}
                        </li>
                        <li>
                            <FormattedMessage id="App.Logbook.PI8000.SelectedSignal" />
                            {': '}
                            {log.content.selectedSignal}
                        </li>
                    </ul>
                );
            case ScanType.ImpactEchoSpot:
                return (
                    <ul className={styles.list_container}>
                        <li>
                            <FormattedMessage id="App.HTML.PI8000.Spots.Spot" />
                            {': '}
                            {log.content.name}
                        </li>
                        <li>
                            <FormattedMessage id="App.Logbook.PI8000.SelectedSignal" />
                            {': '}
                            {log.content.selectedSignal}
                        </li>
                    </ul>
                );
            case ScanType.AScan:
                return <ul className={styles.list_container}>{getAScanItems()}</ul>;
            default:
                return null;
        }
    }, [getAScanItems, log.content, scanType]);

    return (
        <div>
            <b>
                <FormattedMessage
                    id={
                        log.content.isExport
                            ? 'Proceq.LogbookPropertySnapshotExported'
                            : 'Proceq.LogbookPropertySnapshotSaved'
                    }
                />
            </b>
            {getItems()}
            <Photo
                mID={log.mID}
                aID={log.content.attachmentId}
                attachmentName={log.content.attachmentName}
                analyticsCategory={SnapshotAction.logbook}
                analyticsProduct={product}
                analyticsType={log.type}
            />
        </div>
    );
};

export default {
    element: Snapshot,
};
