import { AnyAction, Reducer } from 'redux';
import actionTypes, { PayloadAction } from '../actions/actionTypes';
import { ReceivedContractsPayload } from '../actions/contract';
import { ContractInfo } from '../types/contract';
import { normalizer } from '../utils/genericNormalizer';

export interface ContractState {
    contracts: {
        [key: string]: ContractInfo | null;
    };
}

export const initialState: ContractState = {
    contracts: {},
};

const contract: Reducer<ContractState, AnyAction> = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RECEIVED_CONTRACTS: {
            const { payload } = action as PayloadAction<ReceivedContractsPayload>;
            const { contracts } = payload;

            const { entities } = normalizer(contracts, 'id');

            state.contracts = {
                ...state.contracts,
                ...entities,
            };
            return state;
        }

        default:
            return state;
    }
};

export default contract;
