import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../shared/Header';
import colors from '../../../styles/colors.json';
import styles from '../DataView.styl';
import useMeasurementImportParams from '../../MeasurementImport/useMeasurementImportParams';
import { proceqURL } from '../../../api/RegionalAPI';
import * as measurementService from '../../../api/measurementService';
import { APIError } from '../../../api/error';
import FormattedMessage from '../../../localization/FormatMessage';
import FullMeasurementViewer from '../../StandaloneMeasurementView/FullMeasurementViewer';
import { FormatIDs } from '../../../types';
import ImportMessage from '../../MeasurementImport/ImportMessage';
import { ReactComponent as IconExpire } from '../../../images/iconExpire.svg';
import { ReactComponent as IconNotFound } from '../../../images/iconNotFound.svg';
import HTMLViewerTable from './HTMLViewerTable';
import Spinner from '../../shared/Spinner';
import authInfo from '../../../api/AuthInfo';
import { cleanUpCredentials } from '../../../utils/authUtils';
import { CHINA_DOMAINS } from '../../../constants';
import ProductContextProvider from '../ProductContextProvider';

enum HTMLViewState {
    loading,
    valid,
    invalidURL,
    expiredURL,
}

export const htmlStateData: {
    [key in HTMLViewState]?: {
        icon: React.FunctionComponent<any>;
        message: FormatIDs;
    };
} = {
    [HTMLViewState.invalidURL]: {
        icon: IconNotFound,
        message: 'Export.Invalid.Message',
    },
    [HTMLViewState.expiredURL]: {
        icon: IconExpire,
        message: 'Export.Expired.Message',
    },
};

const HTMLViewer: React.FunctionComponent = () => {
    const { k, d, n, id, productCode, handleGotoHomepage } = useMeasurementImportParams();

    const [checkingState, setCheckingState] = useState<HTMLViewState>(
        k && d && n ? HTMLViewState.loading : HTMLViewState.invalidURL
    );
    const [countryCode, setCountryCode] = useState<string | undefined>();

    const shouldFetch = checkingState === HTMLViewState.loading;
    const dispatch = useDispatch();

    useEffect(() => {
        if (!k || !d || !shouldFetch) {
            return;
        }

        // Initialise the regional URL
        proceqURL.init({ regionalDomain: d });
        if (CHINA_DOMAINS.has(d)) {
            setCountryCode('CN');
        }

        cleanUpCredentials(dispatch)
            .then(() => measurementService.getExportMeasurementList(k, productCode))
            .then((response) => {
                authInfo.updateInfo(`Bearer ${response.token!}`);
                setCheckingState(HTMLViewState.valid);
            })
            .catch((error: APIError) => {
                // eslint-disable-next-line no-console
                console.error(error);
                // For expired URL, we should different message
                if (error.response?.data?.code === 'ExpiredURL') {
                    setCheckingState(HTMLViewState.expiredURL);
                } else {
                    setCheckingState(HTMLViewState.invalidURL);
                }
            });
    }, [k, d, productCode, shouldFetch, dispatch]);

    const getStateElement = () => {
        switch (checkingState) {
            case HTMLViewState.loading:
                return (
                    <div>
                        <Spinner />
                    </div>
                );
            case HTMLViewState.valid:
                return id ? (
                    <FullMeasurementViewer measurementID={id} isHTMLView countryCode={countryCode} />
                ) : (
                    <HTMLViewerTable productCode={productCode} />
                );
            case HTMLViewState.expiredURL:
            case HTMLViewState.invalidURL:
            default: {
                const stateValue = htmlStateData[checkingState];
                return (
                    <div className={styles.center}>
                        <ImportMessage
                            Icon={stateValue!.icon}
                            message={<FormattedMessage id={stateValue!.message} />}
                            primaryAction={{
                                title: 'Import.GotoHomepage',
                                action: handleGotoHomepage,
                                type: 'default',
                                fillWidth: false,
                            }}
                        />
                    </div>
                );
            }
        }
    };

    return (
        <ProductContextProvider>
            <div className={styles.container_html_viewer}>
                <Header hasLogo hasActions={false} background={colors.gray200} />
                <div className={styles.container_body_html_viewer}>{getStateElement()}</div>
            </div>
        </ProductContextProvider>
    );
};

export default HTMLViewer;
