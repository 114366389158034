import { getApiURL } from './ApiURL';
import { thunkPost } from './apiService';
import config from '../config';
import { ExportFormat, PDFExtendedFileType } from './utilsService';

enum LogEventType {
    deviceType = 'DEVICE_TYPE',
    userAction = 'USER_ACTION',
}

const triggerName: {
    [key: string]: {
        single: string;
        multiple: string;
        extendedFileTypes?: {
            [key: string]: string;
        };
    };
} = {
    [ExportFormat.HTML]: {
        single: 'export_HTML_single_from_explorer',
        multiple: 'export_HTML_multiple_from_explorer',
    },
    [ExportFormat.SEGY]: {
        single: 'export_SEGY_single_from_explorer',
        multiple: 'export_SEGY_multiple_from_explorer',
    },
    [ExportFormat.KML]: {
        single: 'export_KML_single_from_explorer',
        multiple: 'export_KML_multiple_from_explorer',
    },
    [ExportFormat.DXF]: {
        single: 'export_DXF_single_from_explorer',
        multiple: 'export_DXF_multiple_from_explorer',
    },
    [ExportFormat.PDF]: {
        single: 'export_PDF_single_from_explorer',
        multiple: 'export_PDF_multiple_from_explorer',
        extendedFileTypes: {
            [PDFExtendedFileType.Uniformity]: 'export_PDF_Uniformity',
            [PDFExtendedFileType.China]: 'export_PDF_JGJ',
            [PDFExtendedFileType.Europe]: 'export_PDF_EN_13791',
        },
    },
    [ExportFormat.CSV]: {
        single: 'export_CSV_single_from_explorer',
        multiple: 'export_CSV_multiple_from_explorer',
    },
    [ExportFormat.RAW]: {
        single: 'export_raw_single_from_explorer',
        multiple: 'export_raw_multiple_from_explorer',
    },
};

const getValueFromUserAgent = (regex: RegExp) => {
    const match = navigator.userAgent.match(regex);
    return match ? match[1] : '';
};

const logContent = {
    osVersion: getValueFromUserAgent(new RegExp(`${navigator.appCodeName}/\\S+ \\((.*?)\\)`)), // osVersion takes value in first brackets
    deviceModel: getValueFromUserAgent(/.*\(.*\) (.*)/), // device model gets value after brackets
    deviceID: '',
    product: 'workspace',
    platform: navigator.platform,
    appVersion: config.APP_VERSION,
    vendor: navigator.vendor,
    userAgent: navigator.userAgent,
};

const logEvent = async (logType: LogEventType, triggerEvent: string) => {
    const url = getApiURL('LOG_EVENT');

    const event = {
        content: logContent,
        type: logType,
        clientTS: Date.now(),
        trigger: triggerEvent,
    };

    await thunkPost<Response>(url, {
        injectToken: true,
        params: {
            events: [event],
        },
    });
};

export const logExportUserAction = async (format: string, fileCount: number, fileType?: string) => {
    // some formats do not need to be logged eg json
    if (triggerName[format]) {
        if (fileType && triggerName[format].extendedFileTypes?.[fileType]) {
            logEvent(LogEventType.userAction, triggerName[format].extendedFileTypes![fileType]);
        } else {
            logEvent(LogEventType.userAction, triggerName[format][fileCount > 1 ? 'multiple' : 'single']);
        }
    }
};

export const logShareUserAction = async (format: string, fileCount: number) => {
    const triggerName = fileCount > 1 ? 'share_URL_multiple_from_explorer' : 'share_URL_single_from_explorer';
    logEvent(LogEventType.userAction, triggerName);
};

export const logDeviceType = async (triggerEvent: string) => {
    logEvent(LogEventType.deviceType, triggerEvent);
};
