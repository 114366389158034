import React from 'react';
import { Log } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { ContractExpirationDate, VerifiedDate } from '../../utils/LogDate';
import { getProbeName } from '../../../../../hooks/useProbes';
import { ProductCode, ProductModel, productModelToFamilyMap } from '../../../../../types/proceq';
import ProbeCommonContent from '../ProbeCommonContent';

export const EquotipProbeContent: React.FunctionComponent<{
    content: Log['content'];
    productModel: ProductModel;
}> = (props) => {
    const { content, productModel } = props;
    const title = getProbeName(productModel, content.probeModel);
    const product = productModelToFamilyMap[productModel];

    return (
        <>
            <div>
                <b>{title}</b>
            </div>
            <div>
                <FormattedMessage id="Proceq.LogbookPropertySerialNumberShort" />
                {': '}
                {content.probeSerialNo}
            </div>
            <ProbeCommonContent content={content} />
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyBluetoothModuleShort" />
                {': '}
                {content.btmSN}
            </div>
            {product !== ProductCode.PROFOMETER && (
                <div>
                    <FormattedMessage id="Proceq.LogbookPropertyVerified" />
                    {': '}
                    <VerifiedDate date={content.verifiedOn} />
                </div>
            )}
            {!!content.contractType && (
                <div>
                    <FormattedMessage id="Proceq.LogbookContractType" />
                    {`: ${content.contractType}`}
                </div>
            )}
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyContractValidUntil" />
                {': '}
                <ContractExpirationDate date={content.contractExpirationDate} />
            </div>
        </>
    );
};
