import React, { useCallback, useEffect, useState } from 'react';
import Inspector from 'react-json-inspector';
import 'react-json-inspector/json-inspector.css';
import './JSONViewer.global.styl';
import { Tabs, Input } from 'antd';
import { getMeasurementFullData } from '../../../../api/measurementService';
import Spinner from '../../../shared/Spinner';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import { useCustomMaterials } from '../../../../hooks/useProductData';

export const JSONTreeView: React.FunctionComponent<{ data: Object }> = (props) => {
    const { data } = props;
    const formatMessage = useFormatMessage();
    return (
        <Tabs defaultActiveKey="1" className="json-viewer-wrapper">
            <Tabs.TabPane tab="JSON Navigator" key="1">
                <Inspector
                    data={data}
                    id={null}
                    filterOptions={{ ignoreCase: true }}
                    search={(props: { onChange: any }) => (
                        <Input
                            onChange={(e: any) => props.onChange(e.target.value)}
                            placeholder={formatMessage({ id: 'DataView.JSON.Filter.Placeholder' })}
                        />
                    )}
                    validateQuery={() => true}
                />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Raw JSON Output" key="2">
                <Input.TextArea readOnly value={JSON.stringify(data, null, 4)} autoSize style={{ maxHeight: '100%' }} />
            </Tabs.TabPane>
        </Tabs>
    );
};

export const MeasurementJSONView: React.FunctionComponent<{ measurementID: string }> = (props) => {
    const { measurementID } = props;

    const [isLoading, setIsLoading] = useState(false);

    const [output, setOutput] = useState<Object>({});

    const handleFetchMeasurementFullData = useCallback(async () => {
        setIsLoading(true);
        try {
            const data = await getMeasurementFullData({ measurementID });
            if (data) {
                setOutput(data);
            }
        } finally {
            setIsLoading(false);
        }
    }, [measurementID]);

    useEffect(() => {
        handleFetchMeasurementFullData();
    }, [handleFetchMeasurementFullData]);

    if (isLoading) {
        return <Spinner size="default" />;
    }
    return <JSONTreeView data={output} />;
};

export const CustomMaterialJSONView: React.FunctionComponent<{ customMaterialID: string }> = (props) => {
    const { customMaterialID } = props;
    const customMaterials = useCustomMaterials();
    const data = customMaterials?.[customMaterialID] ?? {};
    return <JSONTreeView data={data} />;
};
