import getGPRLogElement, { EventLogElement } from '../GPR/getGPRLogElement';
import elementCreation from './Creation';
import elementConvertUnit from './ConvertUnit';

enum PunditLogType {
    Creation = 'Creation',
    ConvertUnit = 'ConvertUnit',
}

const getPunditLogElement = (logType: string | PunditLogType): EventLogElement => {
    switch (logType) {
        case PunditLogType.Creation:
            return elementCreation;
        case PunditLogType.ConvertUnit:
            return elementConvertUnit;
        default:
            return getGPRLogElement(logType);
    }
};

export default getPunditLogElement;
