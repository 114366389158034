import { Rule } from 'antd/lib/form';

export const getMaxLengthValidator =
    (maxLength: number, errorMessage: React.ReactNode): Rule =>
    () => ({
        validator(rule: any, value: string) {
            if (!value) {
                return Promise.resolve();
            }

            if (value.length <= maxLength) {
                return Promise.resolve();
            }

            return Promise.reject(errorMessage);
        },
    });

export const getTncValidator = (rejectMessage: React.ReactNode) => ({
    validator(rule: any, value?: boolean) {
        return value === true ? Promise.resolve() : Promise.reject(rejectMessage);
    },
});
