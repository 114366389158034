import dayjs from 'dayjs';
import { intlProxy } from '../localization/IntlProxy';

let utcOffsetHTMLExport: number | undefined;

export const setUTCOffset = (offset: number) => {
    utcOffsetHTMLExport = offset;
};

export function renderLogbookDate(date: number | string) {
    if (utcOffsetHTMLExport !== undefined) {
        return dayjs(date).utcOffset(utcOffsetHTMLExport).format('ll');
    }
    return dayjs(date).format('ll');
}

export function renderLogbookTime(date: number) {
    if (utcOffsetHTMLExport !== undefined) {
        return dayjs(date).utcOffset(utcOffsetHTMLExport).format('LT');
    }
    return dayjs(date).format('LT');
}

function getSeconds(timeInMilliSeconds: number, endOfDay: boolean = false, fromDate: Date = new Date()): number {
    const date = endOfDay ? dayjs(fromDate).endOf('day') : dayjs(fromDate);
    return (date.valueOf() - timeInMilliSeconds) / 1000;
}

export function isTimeWithinDay(
    timeInMilliSeconds: number,
    days: number = 1,
    endOfDay: boolean = false,
    fromDate: Date = new Date()
): boolean {
    const seconds = Math.floor(getSeconds(timeInMilliSeconds, endOfDay, fromDate));
    const interval = Math.floor(seconds / 86400);
    return interval < days;
}

export function formatTime(milliSeconds: number, mask?: string) {
    return dayjs(milliSeconds).format(mask);
}

export function formatDateTimeDefault(milliSeconds: number) {
    return formatTime(milliSeconds, 'lll');
}

export function formatTimeDefault(milliSeconds: number) {
    return formatTime(milliSeconds, 'll');
}

export const formatDate = (date: Date | number) => {
    return dayjs(date).format('ll');
};

// Time in milliseconds to localeDateString converter with literal string or Today string
export function formatTimeWithToday(milliseconds: number) {
    if (isTimeWithinDay(milliseconds, 1, true)) {
        return intlProxy.formatMessage({ id: 'Date.Today' });
    }
    if (isTimeWithinDay(milliseconds, 2, true)) {
        return intlProxy.formatMessage({ id: 'Date.Yesterday' });
    }
    return formatTimeDefault(milliseconds);
}
