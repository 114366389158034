export const simpleID = (length: number) => {
    return new Array(length)
        .fill('0')
        .join('')
        .replace(/[0]/gi, () =>
            // eslint-disable-next-line no-bitwise
            (crypto.getRandomValues(new Uint8Array(1))[0] & 0xf).toString(16)
        );
};

const unitByte = 1000;
const byteSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export const convertBytes = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const i = Math.floor(Math.log(bytes) / Math.log(unitByte));
    return `${parseFloat((bytes / unitByte ** i).toFixed(1))} ${byteSizes[i]}`;
};
