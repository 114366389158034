import React from 'react';
import { LicenseFeature } from '../../../types/license';
import PlanFeature from './PlanFeature';
import FormattedMessage from '../../../localization/FormatMessage';

interface PlanFeatureListProps {
    columnCount: number;
    features: LicenseFeature[];
    className?: string;
    includePreviousTier?: string;
}

const PlanFeatureList: React.FunctionComponent<PlanFeatureListProps> = (props) => {
    const { columnCount, features, className, includePreviousTier } = props;

    return (
        <div style={{ columnCount }} className={className}>
            {includePreviousTier && (
                <p style={{ marginBottom: '10px' }}>
                    <b>
                        <FormattedMessage id="License.Tier.Includes" values={{ tierName: includePreviousTier }} />
                    </b>
                </p>
            )}
            {features.map((feature) => (
                <PlanFeature content={feature.description} key={feature.code} />
            ))}
        </div>
    );
};

export default PlanFeatureList;
