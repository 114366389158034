import React from 'react';
import { useIntl } from 'react-intl';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import { FDLSetupFile } from '../../../../../types/measurement';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs, FormatMessageFunc } from '../../../../../types';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import SectionTable from '../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

export const getSetupFile = (
    setupFile: FDLSetupFile,
    unit: string,
    formatMessage: FormatMessageFunc,
    isFullList?: boolean
) => {
    const getDigitalFilterValue = (val: string | number) => {
        switch (val) {
            case '-1':
            case -1:
                return 'Auto';
            case '0':
            case 0:
                return 'No filter';
            case '1':
            case 1:
                return '0.2 - 1.2';
            case '2':
            case 2:
                return '0.2 - 4';
            case '3':
            case 3:
                return '0.2 - 10';
            case '4':
            case 4:
                return '1.5 - 8.5';
            case '5':
            case 5:
                return '2 - 20';
            case '6':
            case 6:
                return '5 - 15';
            case '7':
            case 7:
                return '8 - 20';
        }
        return 'No filter';
    };

    const getAnalogFilterValue = (val: string | number) => {
        switch (val) {
            case '-1':
            case -1:
                return 'Auto';
            case '0':
            case 0:
                return '0.2 - 16.1';
            case '1':
            case 1:
                return '0.94 - 17.3';
            case '2':
            case 2:
                return '1.98 - 20';
            case '3':
            case 3:
                return 'No filter';
        }
        return 'No filter';
    };

    const dataSourceRaw: RawSectionTableData[] = [
        {
            label: 'App.Logbook.FDL.Item.measuringMode',
            value: formatMessage({ id: `App.Logbook.FDL.Item.measuringMode.${setupFile.measuringMode}` as FormatIDs }),
        },
        {
            label: 'App.Logbook.FDL.Item.probeAngle',
            value: setupFile.probeAngle,
            unitId: 'FDL.probeAngle',
        },
        {
            label: 'App.Logbook.FDL.Item.frequency',
            value: setupFile.frequency,
            unitId: 'FDL.frequency',
        },
        {
            label: 'App.Logbook.FDL.Item.energy',
            value: setupFile.voltage,
            unitId: 'FDL.energy',
        },
        {
            label: 'App.Logbook.FDL.Item.damping',
            value: setupFile.damping,
            unitId: 'FDL.damping',
        },
        {
            label: 'App.Logbook.FDL.Item.pulseWidth',
            value: formatMessage({ id: `App.Logbook.FDL.Item.pulseWidth.${setupFile.pulseWidth.mode}` as FormatIDs }),
        },
        {
            label: 'App.HTML.FDL.MeasurementPresets.PRF',
            value: formatMessage({ id: `App.Logbook.FDL.Item.prf.${setupFile.prf.mode}` as FormatIDs }),
        },
        {
            label: 'App.Logbook.FDL.Item.prf',
            value: setupFile.prf.value,
            unitId: 'FDL.prf',
        },
        {
            label: 'App.Logbook.FDL.Item.digitalFilter',
            value:
                setupFile.filter.option === undefined
                    ? getDigitalFilterValue('0')
                    : getDigitalFilterValue(setupFile.filter.option),
            unitId: 'FDL.filter',
            escape: true,
        },
        {
            label: 'App.Logbook.FDL.Item.analogueFilter',
            value:
                setupFile.analogueFilter === undefined
                    ? getAnalogFilterValue('-1')
                    : getAnalogFilterValue(setupFile.analogueFilter),
            unitId: 'FDL.filter',
            escape: true,
        },
        {
            label: 'App.Logbook.FDL.Item.spatialAveraging',
            value:
                Number(setupFile.spatialAveraging).toString() === '1'
                    ? 'None'
                    : Number(setupFile.spatialAveraging).toString(),
        },
    ];

    if (isFullList) {
        dataSourceRaw.unshift({
            label: 'App.HTML.FDL.MeasurementPresets.SetupFile',
            value: setupFile.name,
        });
    }

    const valueConversion = (row: RawSectionTableData): string | number | React.ReactNode => {
        let value: string | number | undefined = row.value;
        if (row.unitId) {
            const { convertFunction } = convertAndDisplay(row.unitId, ScanType.Indication, unit);
            if (row.unitId === 'FDL.frequency') {
                value = Math.round(Number(row.value) * 100) / 100;
            } else if (!row.escape) {
                value = convertFunction(Number(row.value));
            }
        }
        return value;
    };

    return getConvertedSectionTableData(dataSourceRaw, ScanType.Indication, unit, valueConversion);
};

export const SetupFile: React.FunctionComponent<{ setupFile: FDLSetupFile } & FDLMeasurementPresetsProps> = (props) => {
    const { setupFile, number, showTitle, unit } = props;
    const formatMessage = useIntl().formatMessage;

    const title = (
        <>
            <FormattedMessage id="Proceq.ExportFieldSettings" />
            {' ­­» '}
            <FormattedMessage id="App.HTML.FDL.MeasurementPresets.SetupFile" />
            {number && ` ­­» ${number}`}
        </>
    );

    const dataSource = getSetupFile(setupFile, unit, formatMessage, true);

    return <SectionTable showTitle={showTitle} title={title} dataSource={dataSource} />;
};

export default SetupFile;
