import React, { createContext } from 'react';
import { noop } from 'lodash';

export interface MeasurementModalContextState {
    showRenameMeasurement: boolean;
    setShowRenameMeasurement: React.Dispatch<React.SetStateAction<boolean>>;

    showCreateFolder: boolean;
    setShowCreateFolder: React.Dispatch<React.SetStateAction<boolean>>;

    showMoveMeasurement: boolean;
    setShowMoveMeasurement: React.Dispatch<React.SetStateAction<boolean>>;

    showShareMeasurement: boolean;
    setShowShareMeasurement: React.Dispatch<React.SetStateAction<boolean>>;

    showDeleteConfirmationModal: boolean;
    setShowDeleteConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;

    measurementName: string | undefined;
    setMeasurementName: React.Dispatch<React.SetStateAction<string | undefined>>;

    measurementID: string | undefined;
    setMeasurementID: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const MeasurementModalContext = createContext<MeasurementModalContextState>({
    showRenameMeasurement: false,
    setShowRenameMeasurement: noop,

    showCreateFolder: false,
    setShowCreateFolder: noop,

    showMoveMeasurement: false,
    setShowMoveMeasurement: noop,

    showShareMeasurement: false,
    setShowShareMeasurement: noop,

    showDeleteConfirmationModal: false,
    setShowDeleteConfirmationModal: noop,

    measurementName: undefined,
    setMeasurementName: noop,

    measurementID: undefined,
    setMeasurementID: noop,
});
