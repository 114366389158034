import React from 'react';
import { get } from 'lodash';
import { Table } from 'antd';

import styles from '../../DataViewers.styl';
import { ScanType } from '../../../../../types/proceq';
import { DerivedProps } from '../../SharedTypes';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { FormatIDs } from '../../../../../types';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MeasurementFullData } from '../../../../../types/measurement';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { nullCallback } from '../../../../../hooks/useCheckInviewPort';

export const title = (
    <>
        <FormattedMessage id="Proceq.Settings" />
        {' ­­» '}
        <FormattedMessage id="Proceq.Settings.ImageProcessing" />
    </>
);

export const ImageProcessing: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    product,
    isMetric,
    convert,
    showTitle,
}) => {
    interface MatrixDataRaw {
        title: FormatIDs;
        unitId?: string;
        migratedView: string | React.ReactNode;
        nonMigratedView: string | React.ReactNode;
        timeSliceView: string | React.ReactNode;
        dontConvert?: boolean;
    }
    const matrixRaw: MatrixDataRaw[] = [
        {
            title: 'Proceq.LogbookSettingsDielectricConstant',
            unitId: 'GPR.CSV.Dielectric Constant',
            migratedView: get(data, 'settings.0.content.settings.process.dielectricConstant'),
            nonMigratedView: <span>&nbsp;</span>,
            timeSliceView: <span>&nbsp;</span>,
            dontConvert: true,
        },
        {
            title: ' ',
            migratedView: (
                <span className={styles.table_header}>
                    <FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing.MigratedView" />
                </span>
            ),
            nonMigratedView: (
                <span className={styles.table_header}>
                    <FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing.NonMigratedView" />
                </span>
            ),
            timeSliceView: (
                <span className={styles.table_header}>
                    <FormattedMessage id="App.HTML.GPR.Settings.ImageProcessing.TSV/3D" />
                </span>
            ),
        },
        {
            title: 'App.HTML.GPR.Settings.ImageProcessing.AutoGain',
            migratedView: get(data, 'settings.0.content.settings.migratedLineViewSettings.isAutoGainOn') ? (
                <FormattedMessage id="Proceq.On" />
            ) : (
                <FormattedMessage id="Proceq.Off" />
            ),
            nonMigratedView: get(data, 'settings.0.content.settings.nonMigratedLineViewSettings.isAutoGainOn') ? (
                <FormattedMessage id="Proceq.On" />
            ) : (
                <FormattedMessage id="Proceq.Off" />
            ),
            timeSliceView: get(data, 'settings.0.content.settings.timeSliceViewSettings.isAutoGainOn') ? (
                <FormattedMessage id="Proceq.On" />
            ) : (
                <FormattedMessage id="Proceq.Off" />
            ),
        },
        {
            title: 'App.HTML.GPR.Settings.ImageProcessing.LinearGain',
            unitId: `${product}.CSV.Linear Gain`,
            migratedView: get(data, 'settings.0.content.settings.migratedLineViewSettings.gain'),
            nonMigratedView: get(data, 'settings.0.content.settings.nonMigratedLineViewSettings.gain'),
            timeSliceView: get(data, 'settings.0.content.settings.timeSliceViewSettings.gain'),
        },
        {
            title: 'App.HTML.GPR.Settings.ImageProcessing.TimeGain',
            unitId: `${product}.CSV.Time Gain Compensation`,
            migratedView: get(data, 'settings.0.content.settings.migratedLineViewSettings.timeGainCompensation'),
            nonMigratedView: get(data, 'settings.0.content.settings.nonMigratedLineViewSettings.timeGainCompensation'),
            timeSliceView: get(data, 'settings.0.content.settings.timeSliceViewSettings.timeGainCompensation'),
        },
    ];

    const matrix = matrixRaw.map((row) => {
        const rows: {
            key: string;
            title: JSX.Element;
            migratedView: React.ReactNode;
            nonMigratedView: React.ReactNode;
            timeSliceView?: React.ReactNode;
        } = {
            key: row.title,
            title: row.unitId ? (
                <ConvertedUnits id={row.title} unitId={row.unitId} scanType={scanType} isMetric={isMetric} />
            ) : (
                <FormattedMessage id={row.title} />
            ),
            migratedView:
                row.unitId && isDataStringOrNumber(row.migratedView)
                    ? convert(row.migratedView, row.unitId)
                    : row.migratedView,
            nonMigratedView:
                !row.dontConvert && row.unitId && isDataStringOrNumber(row.nonMigratedView)
                    ? convert(row.nonMigratedView, row.unitId)
                    : row.nonMigratedView,
            timeSliceView: undefined,
        };

        if (scanType === ScanType.AreaScan) {
            rows.timeSliceView =
                !row.dontConvert && row.unitId && isDataStringOrNumber(row.timeSliceView)
                    ? convert(row.timeSliceView, row.unitId)
                    : row.timeSliceView;
        }

        return rows;
    });

    return (
        <Table
            title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : undefined}
            className={styles.table}
            components={{
                header: {
                    row: nullCallback, // hide header row
                },
            }}
            rowKey="key"
            columns={[
                {
                    dataIndex: 'title',
                },
                {
                    dataIndex: 'migratedView',
                },
                {
                    dataIndex: 'nonMigratedView',
                },
                {
                    dataIndex: 'timeSliceView',
                },
            ]}
            dataSource={matrix}
            pagination={false}
            size="small"
            locale={tableLocaleSetting}
        />
    );
};

export default ImageProcessing;

function isDataStringOrNumber(data: any): data is string | number {
    return typeof data === 'string' || typeof data === 'number';
}
