import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';

const MeasurementRebarChanged: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id={
                log.content.event?.include
                    ? 'App.HTML.Profometer.Logbook.MeasurementRebarChanged.Included'
                    : 'App.HTML.Profometer.Logbook.MeasurementRebarChanged.Excluded'
            }
            values={{ measurementId: log.content.measurementId }}
        />
    );
};

export default {
    element: MeasurementRebarChanged,
};
