import { isMaliciousFile } from 'tds-common-fe/src/lib/utils/fileUtils';
import { App, Upload } from 'antd';
import React from 'react';
import { RcFile } from 'antd/lib/upload';
import styles from './UploadDragger.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import { ReactComponent as UploadIcon } from '../../../images/iconUpload.svg';

const { Dragger } = Upload;

export interface UploadDraggerProps {
    supportedFormats: string[];
    onDrop: (file: RcFile, fileExt: string) => void;
    extraSubtitle?: React.ReactNode;
}

const UploadDragger: React.FunctionComponent<UploadDraggerProps> = (props) => {
    const { supportedFormats, onDrop, extraSubtitle } = props;
    const formatMessage = useFormatMessage();
    const supportedFormatsString = `.${supportedFormats.join(', .')}`;
    const { message } = App.useApp();
    return (
        <Dragger
            beforeUpload={(file, fileList) => {
                if (file.uid === fileList[fileList.length - 1].uid) {
                    fileList.forEach((file) => {
                        const fileExt = file.name.split('.').pop();
                        if (fileExt && supportedFormats.includes(fileExt) && !isMaliciousFile(file)) {
                            onDrop(file, fileExt);
                        } else {
                            message.error(formatMessage({ id: 'DataView.Upload.Error' }, { fileName: file.name }));
                        }
                    });
                    const hasMaliciousFile = fileList.some((file) => isMaliciousFile(file));
                    if (hasMaliciousFile) {
                        message.error(formatMessage({ id: 'DataView.Upload.Error.MaliciousFile' }), 5);
                    }
                }
                return false;
            }}
            multiple={false}
            accept={supportedFormatsString}
            showUploadList={false}
            className={styles.dragger_main}
        >
            <div className={styles.dragger_container}>
                <UploadIcon className={styles.icon} />
                <p>
                    <FormattedMessage
                        id="DataView.Upload.DragFileHere"
                        values={{
                            browse: (
                                <span className={styles.browse_text}>
                                    <FormattedMessage id="DataView.Upload.Browse" />
                                </span>
                            ),
                        }}
                    />
                </p>
                <p className={styles.caption}>
                    <FormattedMessage id="DataView.Upload.SupportedFormat" />
                    {supportedFormatsString}
                </p>
                {extraSubtitle}
            </div>
        </Dragger>
    );
};

export default UploadDragger;
