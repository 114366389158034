import React from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { Rule } from 'antd/lib/form';
import FormattedMessage from '../../../localization/FormatMessage';
import styles from './ButtonUpload.styl';
import './UploadCustomCurve.global.styl';
import { useFormatMessage } from '../../../localization/useFormatMessage';
import CustomCurveLabel from './CustomCurveLabel';
import { getDecimalPlaceValidator, getGreaterThanValidator } from './utils/curveInputUtils';
import { classNames } from '../../../utils/styleUtils';
import {
    CUSTOM_CURVE_INPUT_INFO,
    CustomCurveFormFieldNames,
    TRANSDUCER_TYPE_MAP,
    TransducerType,
} from '../../../types/customCurve';

interface CustomCurveInputsProps {
    transducerType: TransducerType;
    setTransducerType: (transducerType: TransducerType) => void;
}

interface FormItemProps {
    name: CustomCurveFormFieldNames;
    rules: Rule[];
    dependencies?: string[];
}

const FormItem: React.FunctionComponent<React.PropsWithChildren<FormItemProps>> = (props) => {
    const { name, rules, dependencies } = props;
    return (
        <Form.Item
            dependencies={dependencies}
            name={name}
            label={<CustomCurveLabel fieldName={name} includeAsterisk includeUnits />}
            rules={rules}
        >
            {props.children}
        </Form.Item>
    );
};

const CustomCurveInputs: React.FunctionComponent<CustomCurveInputsProps> = (props) => {
    const { transducerType, setTransducerType } = props;
    const commonRules = [{ required: true, message: '' }];
    const effectiveDiameterWidthLengthRules = [
        ...commonRules,
        getDecimalPlaceValidator({
            decimals: 1,
            errorMessage: <FormattedMessage id="DataView.Upload.Error.Float" />,
            acceptZero: false,
            checkPositive: true,
        }),
    ];
    const formatMessage = useFormatMessage();
    return (
        <>
            <div id="customCurveInputs" className={styles.custom_curve_input_container}>
                <Row className={styles.custom_curve_input_row}>
                    <Col>
                        <FormItem
                            name={CustomCurveFormFieldNames.minDistance}
                            rules={[
                                ...commonRules,
                                getDecimalPlaceValidator({
                                    decimals: 1,
                                    errorMessage: <FormattedMessage id="DataView.Upload.Error.FloatZero" />,
                                    acceptZero: true,
                                    checkPositive: true,
                                }),
                            ]}
                        >
                            <InputNumber
                                autoComplete="off"
                                controls={false}
                                placeholder={formatMessage({
                                    id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.minDistance].label,
                                })}
                            />
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem
                            name={CustomCurveFormFieldNames.maxDistance}
                            dependencies={[CustomCurveFormFieldNames.minDistance]}
                            rules={[
                                ...commonRules,
                                getGreaterThanValidator(CustomCurveFormFieldNames.minDistance, {
                                    decimals: 1,
                                    errorMessage: (
                                        <FormattedMessage id="DataView.Upload.Error.GreaterThanMinDistance" />
                                    ),
                                }),
                            ]}
                        >
                            <InputNumber
                                autoComplete="off"
                                controls={false}
                                placeholder={formatMessage({
                                    id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.maxDistance].label,
                                })}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row className={styles.custom_curve_input_row}>
                    <Col>
                        <FormItem
                            name={CustomCurveFormFieldNames.minGain}
                            rules={[
                                ...commonRules,
                                getDecimalPlaceValidator({
                                    decimals: 0,
                                    errorMessage: <FormattedMessage id="DataView.Upload.Error.IntegerZero" />,
                                    acceptZero: true,
                                    checkPositive: true,
                                }),
                            ]}
                        >
                            <InputNumber
                                autoComplete="off"
                                controls={false}
                                placeholder={formatMessage({
                                    id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.minGain].label,
                                })}
                            />
                        </FormItem>
                    </Col>
                    <Col>
                        <FormItem
                            name={CustomCurveFormFieldNames.maxGain}
                            dependencies={[CustomCurveFormFieldNames.minGain]}
                            rules={[
                                ...commonRules,
                                getGreaterThanValidator(CustomCurveFormFieldNames.minGain, {
                                    decimals: 0,
                                    errorMessage: <FormattedMessage id="DataView.Upload.Error.GreaterThanMinGain" />,
                                }),
                            ]}
                        >
                            <InputNumber
                                autoComplete="off"
                                controls={false}
                                placeholder={formatMessage({
                                    id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.maxGain].label,
                                })}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <FormattedMessage id="DataView.Upload.Inputs.Transducer" />
                </Row>
                <Row className={classNames(styles.custom_curve_input_row, styles.transducer_row)}>
                    <Col>
                        <FormItem name={CustomCurveFormFieldNames.transducerType} rules={commonRules}>
                            <Select
                                options={Object.values(TransducerType).map((key) => ({
                                    key,
                                    label: formatMessage({ id: TRANSDUCER_TYPE_MAP[key as TransducerType] }),
                                    value: key,
                                }))}
                                onSelect={(val: TransducerType) => setTransducerType(val)}
                            />
                        </FormItem>
                    </Col>
                    {transducerType === TransducerType.CIRCULAR_CRYSTAL && (
                        <Col>
                            <FormItem
                                name={CustomCurveFormFieldNames.effectiveDiameter}
                                rules={effectiveDiameterWidthLengthRules}
                            >
                                <InputNumber
                                    autoComplete="off"
                                    controls={false}
                                    placeholder={formatMessage({
                                        id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.effectiveDiameter].label,
                                    })}
                                />
                            </FormItem>
                        </Col>
                    )}
                    {transducerType === TransducerType.RECTANGULAR_CRYSTAL && (
                        <Col className={styles.custom_curve_rectangular}>
                            <FormItem
                                name={CustomCurveFormFieldNames.effectiveWidth}
                                rules={effectiveDiameterWidthLengthRules}
                            >
                                <InputNumber
                                    autoComplete="off"
                                    controls={false}
                                    placeholder={formatMessage({
                                        id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.effectiveWidth].label,
                                    })}
                                />
                            </FormItem>
                            <FormItem
                                name={CustomCurveFormFieldNames.effectiveLength}
                                rules={effectiveDiameterWidthLengthRules}
                            >
                                <InputNumber
                                    autoComplete="off"
                                    controls={false}
                                    placeholder={formatMessage({
                                        id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.effectiveLength].label,
                                    })}
                                />
                            </FormItem>
                        </Col>
                    )}
                </Row>
                <Row className={styles.custom_curve_input_row}>
                    <Col>
                        <FormItem
                            name={CustomCurveFormFieldNames.deltaVk}
                            rules={[
                                ...commonRules,
                                getDecimalPlaceValidator({
                                    decimals: 1,
                                    errorMessage: <FormattedMessage id="DataView.Upload.Error.MaxOneDecimals" />,
                                }),
                            ]}
                        >
                            <InputNumber
                                autoComplete="off"
                                controls={false}
                                placeholder={formatMessage({
                                    id: CUSTOM_CURVE_INPUT_INFO[CustomCurveFormFieldNames.deltaVk].label,
                                })}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </div>
            <div className={styles.required_information}>
                <span className={styles.mandatory_asterisk_color}>*</span>
                <FormattedMessage id="DataView.Upload.Inputs.RequiredInformation" />
            </div>
        </>
    );
};

export default CustomCurveInputs;
