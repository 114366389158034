import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';

export const CarbonationDepth: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id="Proceq.LogbookPropertyCarbonationDepthChangedFromTo"
            values={{
                oldValue: log.content.oldCarbonationDepth,
                newValue: log.content.updatedCarbonationDepth,
            }}
        />
    );
};

export default {
    element: CarbonationDepth,
};
