import { get, isFinite } from 'lodash';
import React, { useCallback } from 'react';
import User, { title as UserTitle } from '../Common/User';
import MeasurementPresets, { title as MeasurementPresetsTitle } from './shared/MeasurementPresets';
import ImageProcessing, { title as ImageProcessingTitle } from './GPR/ImageProcessing';
import Objects, { title as ObjectsTitle } from './GPR/Objects';
import Markers, { title as MarkersTitle } from './shared/Markers';
import ScanDistance, { title as scanDistanceTitle } from './GPR/ScanDistance';
import { convertAndDisplay } from '../utils/conversionRates';
import { DerivedProps } from '../SharedTypes';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import SavedSnapshots from './SavedSnapshots';
import { useMeasurementViewerContext } from '../../../StandaloneMeasurementView/MeasurementViewerContext';
import { ProductCode } from '../../../../types/proceq';

const GPRReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data } = props;

    const scanType = get(data, 'measurement.type');
    const isMetric = get(data, 'settings.0.content.settings.display.unit') === 'Metric';

    const { isStandalone } = useMeasurementViewerContext();

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data || !scanType) {
        return null;
    }

    const derivedProps: DerivedProps = {
        product,
        convert: convertor,
        scanType,
        isMetric,
        showTitle: isStandalone,
    };

    return (
        <ReadingContainer>
            <ReadingPanel key="user" header={UserTitle}>
                <User data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="measurement" header={MeasurementPresetsTitle}>
                <MeasurementPresets data={data} {...derivedProps} />
            </ReadingPanel>

            <SavedSnapshots data={data} {...derivedProps} />

            {product !== ProductCode.GPR && (
                <ReadingPanel key="imageProcessing" header={ImageProcessingTitle}>
                    <ImageProcessing data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            {data.objects && (
                <>
                    <ReadingPanel key="objects" header={ObjectsTitle}>
                        <Objects data={data} {...derivedProps} />
                    </ReadingPanel>

                    <ReadingPanel key="markers" header={MarkersTitle}>
                        <Markers data={data} {...derivedProps} />
                    </ReadingPanel>
                </>
            )}

            <ReadingPanel key="scanDistance" header={scanDistanceTitle(derivedProps)}>
                <ScanDistance data={data} {...derivedProps} />
            </ReadingPanel>
        </ReadingContainer>
    );
};

export default GPRReadings;
