import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MeasurementFullData } from '../../../../../types/measurement';
import type { DerivedProps } from '../../SharedTypes';
import styles from '../../DataViewers.styl';
import { ScanType } from '../../../../../types/proceq';
import SectionTable from '../../shared/SectionTable';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.PI8000.Settings.FilteringPresets" />
    </>
);

export const getFilteringPresets = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    isFullList: boolean = false
) => {
    if (!data) {
        return [];
    }
    const isHighPassFilterOn = data.settings[0].content.processing.isHighPassFilterOn;
    const isLowPassFilterOn = data.settings[0].content.processing.isLowPassFilterOn;
    const isPivotFilterOn = data.settings[0].content.processing.isPivotFilterOn;

    const presetsRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.PI8000.Settings.TimeGain',
            unitId: 'PIT_IE.Settings Panel.Time Gain',
            value: data.settings[0].content.processing.timeGainSlope,
            filterOn: true,
            enable: isFullList && scanType === ScanType.AScan,
        },
        {
            label: 'App.HTML.PI8000.Settings.HighPassFilter',
            unitId: 'PIT_IE.Settings Panel.High Pass Filter',
            value: data.settings[0].content.processing.highPassFilter,
            filterOn: isHighPassFilterOn,
            enable: isFullList,
        },
        {
            label: 'App.HTML.PI8000.Settings.LowPassFilter',
            unitId: 'PIT_IE.Settings Panel.Low Pass Filter',
            value: data.settings[0].content.processing.lowPassFilter,
            filterOn: isLowPassFilterOn,
            enable: isFullList,
        },
        {
            label: 'App.HTML.PI8000.Settings.Pivot',
            unitId: 'PIT_IE.Settings Panel.Pivot Filter',
            value: data.settings[0].content.processing.pivot,
            filterOn: isPivotFilterOn,
            enable: isFullList && scanType === ScanType.AScan,
        },
    ];

    const valueConversion = (row: RawSectionTableData): string | number | React.ReactNode => {
        return row.filterOn && row.unitId ? convert(row.value, row.unitId) : <FormattedMessage id="Proceq.Off" />;
    };

    return getConvertedSectionTableData(presetsRaw, scanType, isMetric, valueConversion);
};

export const FilteringPresets: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data, scanType, isMetric, convert, showTitle } = props;
    const presets = getFilteringPresets(data, scanType, isMetric, convert, true);

    return (
        <SectionTable
            showTitle={showTitle}
            className={[styles.table, 'table-measurement-presets'].join(' ')}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={presets}
        />
    );
};

export default FilteringPresets;
