export const formatUUID = (value: string) => {
    const validCharsRegex = /[0-9a-f]/i;
    const digits = value
        .split('')
        .filter((d) => validCharsRegex.test(d))
        .join('');

    let pos = 0;
    const array = [8, 4, 4, 4, 12].map((amount) => {
        const fragment = digits.slice(pos, pos + amount);
        pos += amount;
        return fragment;
    });
    return array.filter(Boolean).join('-');
};
