import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useFormCheckErrors } from 'tds-common-fe';
import styles from './AuthButton.styl';
import formStyles from './Form.styl';
import FormattedMessage from '../../../localization/FormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import { FormatIDs } from '../../../types';

interface AuthButtonProps {
    title: FormatIDs;
    form: FormInstance;
    loading?: boolean;
    className?: string;
    buttonClassName?: string;
    requiredFields?: string[];
    size?: SizeType;
}

const AuthButton: React.FunctionComponent<React.PropsWithChildren<AuthButtonProps>> = (props) => {
    const { title, form, className, buttonClassName, children, requiredFields, size } = props;
    const loading = props.loading ?? false;

    const { checkErrors } = useFormCheckErrors(form, requiredFields);

    return (
        <div className={[styles.container, className ?? ''].join(' ')}>
            <div className={styles.left_container}>{children}</div>
            <AnalyticsButton
                className={[formStyles.form_submit_button, buttonClassName].filter(Boolean).join(' ')}
                type="primary"
                htmlType="submit"
                disabled={checkErrors()}
                loading={loading}
                size={size ?? 'large'}
            >
                <FormattedMessage id={title} />
            </AnalyticsButton>
        </div>
    );
};

export default AuthButton;
