import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../reducers';

const PREVENT_RELOAD_IN = 1000 * 60;

interface UserPreventMeasurementReloadArguments {
    measurementID: string;
    timestampType: TimestampType;
    callback: () => void;
}

export enum TimestampType {
    Full = 'full',
    Attachments = 'attachments',
    Logs = 'logs',
}

const timestampIsExpired = (timestamp: number | undefined) => !timestamp || Date.now() - timestamp > PREVENT_RELOAD_IN;

export const useMeasurementReloadLimiter = (args: UserPreventMeasurementReloadArguments) => {
    const { measurementID, timestampType, callback } = args;
    const timestamp = useSelector((state: RootState) => {
        const fetchedTimestamp = state.measurement.measurements[measurementID]?.fetchedTimestamp;
        if (!fetchedTimestamp) {
            return undefined;
        }
        return fetchedTimestamp[timestampType] ?? fetchedTimestamp.full;
    });

    useEffect(() => {
        if (timestampIsExpired(timestamp)) {
            callback();
        }
    }, [timestamp, callback]);
};

export const useProbeInfoReloadLimiter = (probeSN: string) => {
    const timestamp = useSelector((state: RootState) => state.probe.probes[probeSN]?.fetchedTimestamp);
    return timestampIsExpired(timestamp);
};
