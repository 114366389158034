import { useQuery } from '@tanstack/react-query';
import { GetNotificationsParams } from '../api/notificationService';
import * as notificationServer from '../api/notificationService';
import { ReactQueryKeys } from './queryKeys';

export const useNotifications = (params: GetNotificationsParams, enabled?: boolean) =>
    useQuery({
        queryKey: ReactQueryKeys.notificationsFilter(params),
        queryFn: () => notificationServer.getNotifications(params),
        enabled,
        refetchOnWindowFocus: false,
    });
