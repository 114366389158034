import React from 'react';
import { useIntl } from 'react-intl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { getScaleInfoOfScaleID, getSchmidtScaleIdOfFromFactorAndUnitID } from '../../utils/conversionsHelper';
import { ROCK_SCHMIDT_PROBE } from '../../../../../types/measurement';

export const TargetScale: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log, productModel }) => {
    const formatMessage = useIntl().formatMessage;
    const isRockSchmidt = productModel === ROCK_SCHMIDT_PROBE;

    const oldID = getSchmidtScaleIdOfFromFactorAndUnitID(log.content.oldUnitId, log.content.oldFormFactorId);
    const newID = getSchmidtScaleIdOfFromFactorAndUnitID(log.content.updatedUnitId, log.content.updatedFormFactorId);

    const oldValue = getScaleInfoOfScaleID(formatMessage, oldID, productModel, isRockSchmidt).scaleName;
    const newValue = getScaleInfoOfScaleID(formatMessage, newID, productModel, isRockSchmidt).scaleName;

    return (
        <span>
            <FormattedMessage
                id="Proceq.LogbookPropertyTargetScaleChangedFromTo"
                values={{
                    oldValue,
                    newValue,
                }}
            />
        </span>
    );
};

export default {
    element: TargetScale,
};
