import React, { useState } from 'react';
import FormattedMessage from '../../../localization/FormatMessage';
import AnalyticsButton from '../../AnalyticsComponents/Button';
import LaunchAppModal from './LaunchAppModal';
import { ProductCode } from '../../../types/proceq';
import analytics from '../../../analytics/firebaseAnalytics';
import MeasurementActionMenu from '../MeasurementActionMenu';
import { MeasurementListItem, SystemFolderID } from '../../../types/measurement';
import { ReactComponent as SyncIcon } from '../../../images/iconRefresh.svg';
import Tooltip from '../../shared/Tooltip';
import styles from '../ButtonShare.styl';
import { useDataViewContext } from '../DataViewProvider';

interface ButtonSyncProps {
    product: ProductCode;
    isRecentFiles?: boolean;
    buttonStyles?: React.CSSProperties;
    measurement: MeasurementListItem;
    fileType?: string;
    setSelectedKeys?: React.Dispatch<React.SetStateAction<string[]>>;
}

const UnsyncedButtons: React.FunctionComponent<ButtonSyncProps> = ({
    product,
    buttonStyles,
    isRecentFiles,
    measurement,
    fileType,
    setSelectedKeys,
}) => {
    const [openLaunchApp, setOpenLaunchApp] = useState(false);
    const showMoreButton = fileType !== undefined && !isRecentFiles;
    const { activeFolder } = useDataViewContext();
    const text = <FormattedMessage id="LaunchApp.Sync" />;
    return (
        <>
            <AnalyticsButton
                onClick={() => {
                    setOpenLaunchApp(true);
                    analytics.logSyncMeasurement(product);
                }}
                type="link"
                style={{ padding: 0, ...buttonStyles }}
            >
                {isRecentFiles ? (
                    text
                ) : (
                    <div className={styles.delete_icon_only}>
                        <Tooltip title={text}>
                            <SyncIcon />
                        </Tooltip>
                    </div>
                )}
            </AnalyticsButton>
            {activeFolder !== SystemFolderID.Trashed && <div />}
            {showMoreButton && (
                <MeasurementActionMenu
                    measurement={measurement}
                    fileType={fileType!}
                    setSelectedKeys={setSelectedKeys}
                />
            )}
            <LaunchAppModal
                measurementID={measurement.id}
                open={openLaunchApp}
                product={product}
                onClose={() => {
                    setOpenLaunchApp(false);
                    analytics.logLaunchApp(product, 'close');
                }}
            />
        </>
    );
};

export default UnsyncedButtons;
