import React from 'react';
import { CustomCurveFileType, MeasurementListItem, SystemFolderID, ViewType } from '../../../types/measurement';
import { CustomMaterialItem } from '../../../types/customMaterial';
import analytics from '../../../analytics/firebaseAnalytics';
import { MeasurementMenuCategory } from '../../../analytics/analyticsConstants';
import { useMeasurement } from '../../../hooks/useProductData';
import { useSelectedContractFeatures } from '../../../hooks/useContract';
import { useDataViewContext } from '../DataViewProvider';
import useInvalidMeasurements from '../../../hooks/useInvalidMeasurements';
import { useMeasurementModalContext } from '../MeasurementModals/MeasurementModalProvider';
import EditButton from '../../shared/Buttons/EditButton';
import { useMeasurementContext } from '../MeasurementContext/MeasurementProvider';

interface MeasurementEditButtonProps {
    measurement: MeasurementListItem | CustomMaterialItem;
    hideButton?: boolean;
}

const MeasurementEditButton: React.FunctionComponent<MeasurementEditButtonProps> = ({ measurement, hideButton }) => {
    const { setShowRenameMeasurement, setMeasurementID, setMeasurementName } = useMeasurementModalContext();
    const measurementID = measurement.id;
    const measurementTest = useMeasurement(measurementID);
    const { getInvalidMeasurementIDs, demoMeasurementIDs } = useSelectedContractFeatures(
        { [measurementID]: measurementTest },
        [measurementID]
    );
    const { nonEditableMeasurements, nonEditableExcludeDemo } = useInvalidMeasurements({
        invalidMeasurementIDs: getInvalidMeasurementIDs(),
        demoMeasurementIDs,
        measurementID,
    });

    const { product, activeFolder, viewType } = useDataViewContext();
    const { setNonEditableMeasurements, measurementListParams } = useMeasurementContext();
    const { fileType } = measurementListParams;

    if (hideButton || activeFolder === SystemFolderID.Trashed || viewType === ViewType.Archived) return null;
    return (
        <EditButton
            onClick={(e) => {
                e.stopPropagation();
                analytics.logMeasurementMenu(MeasurementMenuCategory.rename);
                if (nonEditableExcludeDemo && fileType !== CustomCurveFileType.dgscc) {
                    setNonEditableMeasurements(nonEditableMeasurements);
                    analytics.logNonEditableMeasurement(MeasurementMenuCategory.rename, product ?? '');
                } else {
                    setShowRenameMeasurement(true);
                    setMeasurementName(measurement.name);
                    setMeasurementID(measurementID);
                }
            }}
        />
    );
};

export default MeasurementEditButton;
