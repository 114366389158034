import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import Photo from '../../../../Attachments/Photo';
import { SnapshotAction } from '../../../../../analytics/analyticsConstants';

export const ChangeKeyFrame: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, product }) => {
    const position = +log.content.position;

    return (
        <div>
            <b>
                <FormattedMessage id="App.Logbook.FDL.Keyframe.Changed" values={{ position }} />
            </b>
            <br />
            <Photo
                mID={log.mID}
                aID={log.content.attachmentId}
                attachmentName={log.content.attachmentName}
                analyticsCategory={SnapshotAction.logbook}
                analyticsProduct={product}
                analyticsType={log.type}
            />
        </div>
    );
};

export const color = () => 'green';

export default {
    element: ChangeKeyFrame,
    color,
};
