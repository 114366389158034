import React from 'react';
import { get, isEqual } from 'lodash';
import File from './File';
import IndicationTable from './IndicationTable';
import CorrosionGrid from './CorrosionGrid';
import KeyFrameSnapshot from './KeyFrameSnapshot';
import SetupFile, { getSetupFile } from './SetupFile';
import { DerivedProps } from '../../SharedTypes';
import { MeasurementFullData } from '../../../../../types/measurement';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { ScanType } from '../../../../../types/proceq';
import { FormatMessageFunc } from '../../../../../types';
import Baseline from './Baseline';

export type FDLMeasurementPresetsProps = {
    number?: number;
    showTitle?: boolean;
    unit: string;
};

export const title = <FormattedMessage id={'Proceq.Settings'} />;

const getLastReading = (data?: MeasurementFullData) => {
    const scanMode: string = get(data, 'measurement.content.info.scanMode');
    const isCorrosionGrid = scanMode === 'Corrosion Map';
    const readings =
        data?.readings
            ?.filter((reading) => !!reading.content?.indicationPosition?.indicationPosition)
            ?.slice()
            .sort(
                (a, b) =>
                    a.content.indicationPosition.indicationPosition - b.content.indicationPosition.indicationPosition
            ) ?? [];
    const currentLine = get(data, 'settings.0.content.indicationStatus.currentLine');
    const lastReading = currentLine
        ? readings.find((r) => {
              return isCorrosionGrid
                  ? isEqual(r.content.indicationPosition?.gridPosition, currentLine.gridPosition)
                  : r.content.indicationPosition.indicationPosition === currentLine.indicationPosition;
          })
        : undefined;
    return { scanMode, isCorrosionGrid, readings, lastReading };
};

export const getMeasurementPresetsForSnapshotReading = (
    data: MeasurementFullData | undefined,
    scanType: ScanType,
    isMetric: boolean,
    convert: (value: string | number, unitId: string) => string | number,
    formatMessage: FormatMessageFunc
) => {
    const { lastReading } = getLastReading(data);
    const unit = get(lastReading, 'content.indicationSetupFile.unit');
    if (!data || !lastReading) {
        return [];
    }
    return getSetupFile(lastReading.content.indicationSetupFile, unit, formatMessage);
};

export const MeasurementPresets: React.FunctionComponent<
    { data: MeasurementFullData; showTitle?: boolean } & DerivedProps
> = (props) => {
    const { data, showTitle = false, convert, scanType } = props;

    if (!data.readings) {
        return <File {...props} showTitle={showTitle} />;
    }

    const { isCorrosionGrid, readings, lastReading } = getLastReading(data);

    if (readings.length < 1) {
        return <File {...props} showTitle={showTitle} />;
    }

    const unit = get(lastReading, 'content.indicationSetupFile.unit');

    const derivedProps = {
        unit,
        showTitle,
        convert,
        scanType,
    };

    if (isCorrosionGrid) {
        // only need to use first reading as info is repeated
        const firstReading = readings.length > 0 ? readings[0] : undefined;
        return (
            <>
                <File {...props} showTitle={showTitle} />
                <br />
                <CorrosionGrid {...props} {...derivedProps} />
                <br />
                {firstReading && (
                    <>
                        <SetupFile
                            setupFile={firstReading.content.indicationSetupFile}
                            showTitle={showTitle}
                            unit={unit}
                        />
                        <br />
                        <Baseline scanType={scanType} setupFile={firstReading.content?.indicationSetupFile} />
                        <br />
                    </>
                )}
            </>
        );
    }

    return (
        <>
            <File {...props} showTitle={showTitle} />
            {data.measurement.type !== ScanType.BScan && (
                <>
                    <br />
                    <IndicationTable readings={readings} {...derivedProps} />
                    <br />
                    {readings.map((reading) => (
                        <KeyFrameSnapshot
                            key={reading.id}
                            reading={reading}
                            log={data.logs.find(
                                (log) =>
                                    log.content.attachmentId &&
                                    log.content.attachmentId ===
                                        reading.content.indicationSettings.info.lastSnapshotAttachmentID
                            )}
                            {...derivedProps}
                        />
                    ))}
                </>
            )}
        </>
    );
};

export default MeasurementPresets;
