import { useEffect, useRef } from 'react';

// Same as `useRef`, but it will automatically update the ref value when the actual value change
export default function useRefWithChange<T>(value: T) {
    const valueRef = useRef(value);

    useEffect(() => {
        valueRef.current = value;
    }, [value]);

    return valueRef;
}
