import React from 'react';
import { has, get, intersection } from 'lodash';
import { Table, TableColumnType } from 'antd';
import { useIntl } from 'react-intl';
import type { FDLMeasurementPresetsProps } from './MeasurementPresets';
import FormattedMessage from '../../../../../localization/FormatMessage';
import ConvertedUnits from '../../shared/ConvertedUnits';
import { MeasurementReading } from '../../../../../types/measurement';
import { ScanType } from '../../../../../types/proceq';
import { convertAndDisplay } from '../../utils/conversionRates';
import styles from '../../DataViewers.styl';
import { tableLocaleSetting } from '../../../../shared/MyEmpty';
import { INFINITY_SYMBOL, isInfinity } from '../../utils/conversionsHelper';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.FDL.MeasurementPresets.Indication" />
    </>
);

const parameters = [
    'gate1SoundPathDistance',
    'gate1DepthToReflector',
    'gate1SurfaceDistance',
    'gate1CurrentAmplitude',
    'gate1MaximumAmplitude',
    'gate2SoundPathDistance',
    'gate2DepthToReflector',
    'gate2SurfaceDistance',
    'gate2CurrentAmplitude',
    'gate2MaximumAmplitude',
    'gate1AmplitudeToCurve',
    'gate2AmplitudeToCurve',
    'gate1DBtoCurve',
    'gate2DBtoCurve',
    'gate1Ers',
    'gate2Ers',
    'gate1DB',
    'gate2DB',
    'awsA',
    'awsB',
    'awsC',
    'awsD',
    'echoToEcho',
    'echoToEchoSoundPath',
    'gate1SurfaceDistanceIndexPointCorrection',
    'gate2SurfaceDistanceIndexPointCorrection',
    'equivalentReflectorSize',
    'overshoot',
];

export const IndicationTable: React.FunctionComponent<
    { readings: MeasurementReading[] } & FDLMeasurementPresetsProps
> = (props) => {
    const { readings, showTitle, unit } = props;
    const formatMessage = useIntl().formatMessage;

    const parametersToShow = [].concat(
        ...readings.map((reading) => reading.content.indicationSetupFile.toolBarOptions)
    );

    const parameterColumns: TableColumnType<MeasurementReading>[] = intersection(parameters, parametersToShow).map(
        (dataIndex) => ({
            // dataIndex is gate1SoundPathDistance, gate2CurrentAmplitude, etc.
            title: formatMessage({ id: `App.HTML.FDL.MeasurementPresets.${dataIndex}` }),
            render: (_, row) => {
                const unitToUse = row.content.indicationSetupFile.unit;
                const { convertFunction, unit: displayUnit } = convertAndDisplay(
                    `FDL.IndicationTable.${dataIndex}`,
                    ScanType.Indication,
                    unitToUse
                );
                let fullDataIndex = `content.indicationSettings.toolBarOptions.${dataIndex}`;
                if (unitToUse === 'µs') {
                    const timeIndex = `${fullDataIndex}InTimeUnit`;
                    if (has(row, timeIndex)) {
                        fullDataIndex = timeIndex;
                    }
                }
                const value = get(row, fullDataIndex);
                return value
                    ? isInfinity(value)
                        ? INFINITY_SYMBOL
                        : `${convertFunction(value)} ${displayUnit}`
                    : 'N/A';
            },
        })
    );

    const columns: TableColumnType<MeasurementReading>[] = [
        {
            dataIndex: ['content', 'indicationPosition', 'indicationPosition'],
            title: 'ID',
            render: (value: number) => value + 1,
        },
        {
            dataIndex: ['content', 'indicationSettings', 'info', 'type'],
            title: <FormattedMessage id="App.Logbook.FDL.Item.type" />,
            render: (text: string) => formatMessage({ id: `App.Logbook.FDL.Item.type.${text}` }),
        },
        {
            dataIndex: ['content', 'indicationSettings', 'info', 'description'],
            title: <FormattedMessage id="App.Logbook.FDL.Item.description" />,
        },
        {
            dataIndex: ['content', 'indicationSetupFile', 'gain'],
            title: (
                <ConvertedUnits
                    id="App.HTML.FDL.MeasurementPresets.KeyFrameSnapshot.GlobalGain"
                    unitId="FDL.KeyFrameSnapshot.GlobalGain"
                    scanType={ScanType.Indication}
                    isMetric={unit}
                />
            ),
            render: (text: number) => {
                const { convertFunction } = convertAndDisplay(
                    'FDL.IndicationTable.GlobalGain',
                    ScanType.Indication,
                    unit
                );
                return convertFunction(text);
            },
        },
        ...parameterColumns,
    ];

    return (
        <Table
            rowKey="id"
            title={showTitle ? () => <span className={styles.sub_header}>{title}</span> : undefined}
            className={styles.table}
            columns={columns}
            dataSource={readings}
            pagination={false}
            size="small"
            locale={tableLocaleSetting}
        />
    );
};

export default IndicationTable;
