import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const ImpactInAverage: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const content = log.content;
    const title = content.isExcluded
        ? 'App.Logbook.PI8000.ImpactInAverageExcluded'
        : 'App.Logbook.PI8000.ImpactInAverageIncluded';
    return (
        <div>
            <b>
                <FormattedMessage id={title} values={{ impactID: content.impactID }} />
            </b>
            <br />
            <FormattedMessage id="App.Logbook.PI8000.Position" />
            {`: ${content.positionName}`}
            <br />
            <FormattedMessage id="App.Logbook.PI8000.Pile" />
            {`: ${content.pileName}`}
        </div>
    );
};

export default {
    element: ImpactInAverage,
};
