import { routeUtils } from 'tds-common-fe/src/lib/index';
import { handleError } from '../api/error';
import { getAppleMapToken, getAppleMapTokenHTMLExport } from '../api/utilsService';
import { LanguageOptions } from '../localization';
import { intlProxy } from '../localization/IntlProxy';
import { SharedURLParams } from '../types/proceq';

export enum MapKitInitStatus {
    initialized,
    pending,
    initializing,
    error,
}

let mapKitInitStatus = MapKitInitStatus.pending;
let mapKitCallback: ((status: MapKitInitStatus) => void) | undefined;

export function getMapKitInitStatus() {
    return mapKitInitStatus;
}

export function initMapKit(
    callback: (status: MapKitInitStatus) => void,
    onError?: (error: Error) => void,
    isHTMLView?: boolean,
    isHTMLExport?: boolean
) {
    mapKitCallback = callback;

    if (
        mapKitInitStatus === MapKitInitStatus.initialized ||
        mapKitInitStatus === MapKitInitStatus.initializing ||
        !mapkit
    ) {
        callback(mapKitInitStatus);
        mapKitCallback = undefined;
        return;
    }

    const getMapToken = () => {
        if (isHTMLView) {
            const { k, d } = routeUtils.parseQuery<SharedURLParams>(location.search);
            if (k && d) {
                return getAppleMapTokenHTMLExport(k, d);
            }
        }
        return getAppleMapToken();
    };

    mapKitInitStatus = MapKitInitStatus.initializing;

    mapkit.removeEventListener('error', handleMapKitOnError);
    mapkit.addEventListener('configuration-change', handleMapKitConfigsChanged);
    mapkit.addEventListener('error', handleMapKitOnError);

    mapkit.init({
        language: intlProxy.locale,
        authorizationCallback: async (done) => {
            try {
                if (window.mapToken) {
                    // Token coming from backend for HTML Export
                    done(window.mapToken);
                    return;
                }

                // map token optional for standalone viewer EC-777
                if (!isHTMLExport) {
                    const { data } = await getMapToken();
                    if (data.token) {
                        done(data.token);
                    }
                }
            } catch (error: any) {
                if (onError) {
                    onError(error);
                } else {
                    handleError(error, intlProxy.formatMessage({ id: 'Mapkit.Failed.Init' }));
                }
            }
        },
    });
}

const handleMapKitConfigsChanged = (event: any) => {
    if (event.status === 'Initialized') {
        mapKitInitStatus = MapKitInitStatus.initialized;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        mapKitCallback && mapKitCallback(mapKitInitStatus);
        mapKitCallback = undefined;
    } else {
        mapKitInitStatus = MapKitInitStatus.pending;
    }
    mapkit.removeEventListener('configuration-change', handleMapKitConfigsChanged);
};

const handleMapKitOnError = (event: any) => {
    mapKitInitStatus = MapKitInitStatus.error;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    mapKitCallback && mapKitCallback(mapKitInitStatus);
    mapKitCallback = undefined;
    // eslint-disable-next-line no-console
    console.error(event);
    // Insert log for puppeteer logging
    // eslint-disable-next-line no-console
    console.log(event);
    mapkit.removeEventListener('error', handleMapKitOnError);
};

export const updateMapKitLanguage = (language: LanguageOptions) => {
    if (mapkit) {
        mapkit.language = language;
    }
};
