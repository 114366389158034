/* eslint-disable */

import AWS, { AWSError } from 'aws-sdk';
import S3, { ManagedUpload } from 'aws-sdk/clients/s3';
import { getAppstreamDetails } from '../../../api/appstreamService';

interface s3Props extends Omit<AWS.S3, 'upload' | 'headObject'> {
    upload(
        params: Omit<S3.Types.PutObjectRequest, 'Bucket'>,
        options?: ManagedUpload.ManagedUploadOptions,
        callback?: (err: Error, data: ManagedUpload.SendData) => void
    ): ManagedUpload;

    headObject(
        params: Omit<S3.Types.HeadObjectRequest, 'Bucket'>,
        callback?: (err: AWSError, data: S3.Types.HeadObjectOutput) => void
    ): any;
}

interface CommonProps {
    s3: s3Props;
    filePath: string;
    maxAttempts?: number;
    attempt?: number;
    appstreamErrorHandler: () => void;
}

interface UploadProps extends CommonProps {
    file: File;
    onUploadProgress: (progress: number) => void;
}

interface DownloadHandlerProps extends Omit<UploadProps, 'filePath' | 'onUploadProgress'> {
    eagleId: number;
    frontendBucket: string;
    appstreamErrorHandler: () => void;
}

interface SignedUrlProps extends CommonProps {
    file: string;
}

export function s3UploadFile({
    s3,
    file,
    filePath,
    maxAttempts = 2,
    attempt = 1,
    appstreamErrorHandler,
    onUploadProgress,
}: UploadProps): Promise<any> {
    return new Promise((resolve, reject) => {
        s3.upload(
            {
                Key: filePath || '',
                Body: file,
            },
            {},
            function (err, data) {
                if (err) {
                    return reject(err);
                }

                return resolve(data);
            }
        ).on('httpUploadProgress', function (progress) {
            const uploaded = (progress.loaded * 100) / progress.total;
            onUploadProgress(uploaded);
            console.log(uploaded);
        });
    }).catch((err) => {
        if (attempt < maxAttempts) {
            return getAppstreamDetails(appstreamErrorHandler).then((res) => {
                if (res) {
                    s3UploadFile({
                        s3: res.s3,
                        file,
                        filePath,
                        maxAttempts,
                        attempt: attempt + 1,
                        appstreamErrorHandler,
                        onUploadProgress,
                    });
                }
            });
        }
        throw err;
    });
}

function checkFileExists({
    s3,
    filePath,
    maxAttempts = 2,
    attempt = 1,
    appstreamErrorHandler,
}: CommonProps): Promise<any> {
    return new Promise((resolve, reject) => {
        s3.headObject(
            {
                Key: filePath,
            },
            function (err, data) {
                if (err) {
                    return reject(err);
                }

                return resolve(data);
            }
        );
    }).catch((err) => {
        if (attempt < maxAttempts) {
            return getAppstreamDetails(appstreamErrorHandler).then((res) => {
                if (res) {
                    checkFileExists({ s3: res.s3, filePath, maxAttempts, attempt: attempt + 1, appstreamErrorHandler });
                }
            });
        }
        throw err;
    });
}

export async function downloadHandler({
    s3,
    file,
    eagleId,
    frontendBucket,
    appstreamErrorHandler,
}: DownloadHandlerProps) {
    let filePath = `${eagleId}/${file}`;
    const fileName = ((file as unknown) as string).split('/').reverse()[0];
    const cleanFile = ((file as unknown) as string).replace('s3://', '').replace(`${frontendBucket}/`, '');

    if (cleanFile.substr(0, eagleId.toString().length + 1) === `${eagleId}/`) {
        filePath = cleanFile;
    }

    const existsResult = await checkFileExists({ s3, filePath, appstreamErrorHandler });
    console.debug(existsResult);

    const url = await getSignedUrl({ s3, file: fileName, filePath, appstreamErrorHandler });
    console.log(url);
    const element = document.createElement('a');
    console.log(element);
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
}

function getSignedUrl({
    s3,
    file,
    filePath,
    maxAttempts = 2,
    attempt = 1,
    appstreamErrorHandler,
}: SignedUrlProps): Promise<any> {
    return new Promise((resolve, reject) => {
        s3.getSignedUrl(
            'getObject',
            {
                Key: filePath,
                Expires: 300,
                ResponseContentDisposition: `attachment; filename="${file}"`,
            },
            function (err, url) {
                if (err) {
                    return reject(err);
                }
                return resolve(url);
            }
        );
    }).catch((err) => {
        if (attempt < maxAttempts) {
            return getAppstreamDetails(appstreamErrorHandler).then((res) => {
                if (res) {
                    getSignedUrl({
                        s3: res.s3,
                        file,
                        filePath,
                        maxAttempts,
                        attempt: attempt + 1,
                        appstreamErrorHandler,
                    });
                }
            });
        }
        throw err;
    });
}
