import React, { useEffect } from 'react';
import DashboardContainer from './DashboardContainer';
import RecentDataBlock from './RecentDataBlock';
import AppLayout from '../shared/AppLayout';
import ProbeListBlock from './ProbeListBlock';
import ContentBlocks from './ContentBlock';
import { useProductContext } from '../DataView/ProductContextProvider';

const Dashboard: React.FunctionComponent = () => {
    const { setProduct } = useProductContext();

    useEffect(() => {
        setProduct(undefined);
    }, [setProduct]);

    const mainColumn = (
        <>
            <RecentDataBlock />
            <ProbeListBlock />
        </>
    );
    const sideColumn = <ContentBlocks />;
    return (
        <AppLayout>
            <DashboardContainer mainColumn={mainColumn} sideColumn={sideColumn} />
        </AppLayout>
    );
};

export default Dashboard;
