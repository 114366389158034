import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';

const Favourite: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id={
            log.content?.status
                ? 'Proceq.LogbookPropertyMeasurementFlagged'
                : 'Proceq.LogbookPropertyMeasurementUnflagged'
        }
    />
);

export default {
    element: Favourite,
};
