import React from 'react';
import { FormatIDs } from '../../../../../types';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';

const RENAME_PILE_SPOT: Record<string, { typeText: FormatIDs; oldNameAttribute: string; newNameAttribute: string }> = {
    renamePile: {
        typeText: 'App.Logbook.PI8000.Pile',
        oldNameAttribute: 'oldPileName',
        newNameAttribute: 'newPileName',
    },
    renameImpactEcho: {
        typeText: 'App.HTML.PI8000.Spots.Spot',
        oldNameAttribute: 'oldName',
        newNameAttribute: 'newName',
    },
};

const RenamePileSpot: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const logInfo = RENAME_PILE_SPOT[log.type];
    return (
        <div>
            <FormattedMessage
                id="App.Logbook.PI8000.PileSpotRename"
                values={{
                    type: <FormattedMessage id={logInfo.typeText} />,
                    oldName: log.content[logInfo.oldNameAttribute],
                    newName: log.content[logInfo.newNameAttribute],
                }}
            />
        </div>
    );
};

export default {
    element: RenamePileSpot,
};
