import React from 'react';
import { Card } from 'antd';
import { ReadingPanel } from '../ReadingComponents';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import useMeasurementImages, { MeasurementImageType } from '../../../../../hooks/useMeasurementImages';
import styles from './Snapshots.styl';
import Spinner from '../../../../shared/Spinner';
import imagePlaceholderSrc from '../../../../../images/imagePlaceholder.svg';
import { ReactComponent as StarIcon } from '../../../../../images/star.svg';
import SnapshotInformation from '../SnapshotsViewer/SnapshotInformation';
import ImageSlider from '../../../../shared/ImageSlider';
import FormattedMessage from '../../../../../localization/FormatMessage';
import Button from '../../../../AnalyticsComponents/Button';
import SnapshotModal from './SnapshotModal';
import { useMeasurementViewerContext } from '../../../../StandaloneMeasurementView/MeasurementViewerContext';
import ExportImages from './ExportImages';
import {
    ImageSliderAction,
    ImageSliderCategory,
    ShortlistImageForReportCategory,
} from '../../../../../analytics/analyticsConstants';
import analytics from '../../../../../analytics/firebaseAnalytics';
import { ProductFeature, useProductContext } from '../../../ProductContextProvider';

const Snapshots: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({ data, product }) => {
    const { onLazyLoad, entryIDs, entries } = useMeasurementImages({
        attachments: data.attachments,
        logs: data.logs,
        measurementImageType: MeasurementImageType.snapshots,
    });

    const { isFeatureEnabled } = useProductContext();
    const isDocxExportEnabled = isFeatureEnabled(ProductFeature.PROFOMETER_DOCX);

    const { isHTMLExport, isHTMLView } = useMeasurementViewerContext();
    const allowShortlistForReport = isDocxExportEnabled && !isHTMLView && !isHTMLExport;
    const [modalAttachmentID, setModalAttachmentID] = React.useState<string | undefined>();

    if (!entryIDs.length) {
        return null;
    }

    const snapshotTitle = (
        <div className={styles.shortlist_button_container}>
            <FormattedMessage id="Proceq.DashboardSnapshots" />
            {allowShortlistForReport && (
                <Button
                    type="link"
                    className={styles.shortlist_button}
                    onClick={() => {
                        setModalAttachmentID(entryIDs[0]);
                        analytics.logShortlistImageForReport(ShortlistImageForReportCategory.sectionHeader, product);
                    }}
                >
                    <StarIcon />
                    <FormattedMessage id="Snapshots.ShortlistForReport" />
                </Button>
            )}
        </div>
    );

    return (
        <>
            <ReadingPanel key="snapshots" header={<FormattedMessage id="Proceq.DashboardSnapshots" />}>
                <Card size="small" title={snapshotTitle}>
                    {isHTMLExport ? (
                        <ExportImages entryIDs={entryIDs} entries={entries} imagesPerRow={2} />
                    ) : (
                        <ImageSlider
                            slidesToShow={2}
                            slidesToScroll={2}
                            onLazyLoad={onLazyLoad}
                            adaptiveHeight
                            product={product}
                            analyticsCategory={ImageSliderCategory.snapshots}
                        >
                            {entryIDs.map((aID) => {
                                const imageURL = entries[aID]?.imageURL;
                                const isLoading = imageURL === undefined;
                                return (
                                    <div className={styles.slide} key={aID}>
                                        <div
                                            className={styles.image_wrapper}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setModalAttachmentID(aID);
                                                analytics.logImageSliderAction({
                                                    product,
                                                    category: ImageSliderCategory.snapshots,
                                                    action: ImageSliderAction.clickImage,
                                                });
                                            }}
                                        >
                                            {isLoading ? (
                                                <Spinner size="default" />
                                            ) : (
                                                <>
                                                    <img src={imageURL || imagePlaceholderSrc} alt="" />
                                                    <div className={styles.snapshot_information_container}>
                                                        <SnapshotInformation
                                                            entry={entries[aID]}
                                                            product={product}
                                                            allowDownload
                                                            containerClassName={styles.snapshot_information}
                                                            useDownloadIcon
                                                            shortlistForReport={
                                                                allowShortlistForReport
                                                                    ? () => {
                                                                          analytics.logShortlistImageForReport(
                                                                              ShortlistImageForReportCategory.snapshotInfo,
                                                                              product
                                                                          );
                                                                      }
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </ImageSlider>
                    )}
                </Card>
            </ReadingPanel>
            <SnapshotModal
                attachmentID={modalAttachmentID}
                setAttachmentID={setModalAttachmentID}
                entries={entries}
                entryIDs={entryIDs}
                onLazyLoad={onLazyLoad}
                allowShortlistForReport={allowShortlistForReport}
                product={product}
            />
        </>
    );
};

export default Snapshots;
