import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { NotificationRecord } from '../../types/notification';
import { useNotificationItemData } from './useNotifcationData';
import styles from './Notification.styl';
import AppIcon, { ProductName } from '../shared/AppIcon';
import IconButton from '../shared/Buttons/IconButton';
import * as notificationService from '../../api/notificationService';
import { getToolRoute } from '../Routes/urls';

interface NotificationItemProps {
    notification: NotificationRecord;
}

const NotificationItem = React.memo<NotificationItemProps>((props) => {
    const { notification } = props;
    const navigate = useNavigate();
    const { read, date, redirectURL, title, productName, messages, messageURL, type } =
        useNotificationItemData(notification);
    const { mutate: markAsRead } = useMutation({
        mutationFn: ({ id }: { id: string }) => notificationService.markAsRead(id),
    });

    const handleClick = () => {
        if (!read) {
            markAsRead({ id: notification.id });
        }
        // redirection included only GPR insights
        if (productName && [ProductName.GPRInsights].includes(productName) && type === 1) {
            navigate(getToolRoute(productName));
        }
        if (redirectURL) {
            window.open(redirectURL, '_blank');
        }
    };

    const messageSpans = messages.reduce((spans, message, index) => {
        if (message) {
            spans.push(<span key={index}>{message}</span>);
        }
        if (index === 0 && messageURL) {
            spans.push(
                <span key="messageURL" className={styles.highlight}>
                    {messageURL}
                </span>
            );
        }
        return spans;
    }, [] as JSX.Element[]);

    return (
        <IconButton className={styles.item_container} overlay onClick={handleClick}>
            <div className={read ? styles.read_notification : styles.unread_notification} />
            <AppIcon product={productName} size={40} />
            <div className={styles.item_content}>
                <div className={styles.item_header}>
                    {title}
                    <span>{date}</span>
                </div>
                <div className={styles.item_message}>{messageSpans}</div>
            </div>
        </IconButton>
    );
});

export default NotificationItem;
