import React from 'react';
import { Table } from 'antd';
import FormattedMessage from '../../../../../localization/FormatMessage';
import styles from '../../DataViewers.styl';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';

export const title = <FormattedMessage id="App.HTML.PI8000.Piles.Summary" />;

const PilesSummary: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = (props) => {
    const { data } = props;
    const piles = data.measurement.content.piles;
    const summaries = piles.map((pile: { name: string; expectedLength: string; positions: string }) => {
        return {
            pileName: pile.name,
            expectedLength: pile.expectedLength || '-',
            positions: pile.positions.length,
            key: pile.name,
        };
    });
    return (
        <Table
            title={() => <span className={styles.sub_header}>{title}</span>}
            className={styles.table}
            dataSource={summaries}
            pagination={false}
            columns={[
                {
                    title: <FormattedMessage id="App.HTML.PI8000.Piles.Summary.Pile" />,
                    dataIndex: 'pileName',
                    key: 'pileName',
                },
                {
                    title: <FormattedMessage id="App.HTML.PI8000.Piles.Summary.Positions" />,
                    dataIndex: 'positions',
                    key: 'positions',
                },
                {
                    title: <FormattedMessage id="App.HTML.PI8000.Piles.Summary.ExpectedLength" />,
                    dataIndex: 'expectedLength',
                    key: 'expectedLength',
                },
            ]}
            size="small"
        />
    );
};

export default PilesSummary;
