import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import User, { title as titleUser } from '../Common/User';
import MeasurementPresets, { title as titleMeasurementPresets } from './FDL/MeasurementPresets';
import Baseline, { title as titleBaseline } from './FDL/Baseline';
import { DerivedProps } from '../SharedTypes';
import { ProductModel, ScanType } from '../../../../types/proceq';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import { convertAndDisplay } from '../utils/conversionRates';
import Severity, { title as titleSeverity } from './FDL/Severity';
import SavedSnapshots from './SavedSnapshots';
import { BScanPresentationMode, FlawDetectorComponentType } from '../../../../types/measurement';

const FDLReading: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data, isStandalone } = props;

    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = (data?.measurement?.type as ScanType) ?? ScanType.Indication;
    const isMetric = get(data, 'settings.0.content.settings.display.unit') === 'Metric';

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data) {
        return null;
    }

    const derivedProps: DerivedProps & { productModel: ProductModel } = {
        product,
        productModel,
        scanType,
        isMetric: false,
        showTitle: isStandalone,
        convert: convertor,
    };

    const bScanMeasurementScanMode =
        data.settings[0]?.content.indicationStatus?.measurementSetupFile?.bScanMeasurementScanMode;

    return (
        <ReadingContainer>
            <ReadingPanel key="user" header={titleUser}>
                <User data={data} {...derivedProps} />
            </ReadingPanel>
            <ReadingPanel key="measurementPresets" header={titleMeasurementPresets}>
                <MeasurementPresets data={data} {...derivedProps} showTitle />
            </ReadingPanel>
            {scanType === ScanType.BScan &&
                data.settings[0]?.content?.indicationStatus?.measurementSetupFile?.componentType ===
                    FlawDetectorComponentType.curvedSurface && (
                    <ReadingPanel key="settingsBaseline" header={titleBaseline}>
                        <Baseline
                            setupFile={data.settings[0]?.content.indicationStatus?.measurementSetupFile}
                            scanType={scanType}
                        />
                    </ReadingPanel>
                )}
            {scanType === ScanType.BScan && bScanMeasurementScanMode === BScanPresentationMode.thicknessProfile && (
                <ReadingPanel key="severity" header={titleSeverity}>
                    <Severity data={data} {...derivedProps} />
                </ReadingPanel>
            )}
            <SavedSnapshots data={data} {...derivedProps} showPropertiesTable={false} />
        </ReadingContainer>
    );
};

export default FDLReading;
