import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { EquotipLogbookEntryProps, ScaleLog } from '../../../../../types/logs';
import { getScaleInfoOfScaleID } from '../../utils/conversionsHelper';

export const Scale: React.FunctionComponent<EquotipLogbookEntryProps> = (props) => {
    const { productModel } = props;
    const log = props.log as unknown as ScaleLog;
    const formatMessage = useFormatMessage();

    const oldScale = getScaleInfoOfScaleID(formatMessage, log.content.oldScaleId, productModel).scaleUnits;
    const newScale = getScaleInfoOfScaleID(formatMessage, log.content.updatedScaleId, productModel).scaleUnits;
    return (
        <span>
            <FormattedMessage id="Proceq.LogbookPropertySecondaryScaleChangedFromTo" values={{ oldScale, newScale }} />
        </span>
    );
};
export default {
    element: Scale,
};
