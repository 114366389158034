import * as storageUtils from '../utils/storageUtils';
import deLocalizedMessage from './de';
import enLocalizedMessage from './en';
import esLocalizedMessage from './es';
import frLocalizedMessage from './fr';
import itLocalizedMessage from './it';
import jaLocalizedMessage from './ja';
import koLocalizedMessage from './ko';
import ptLocalizedMessage from './pt';
import ruLocalizedMessage from './ru';
import zhLocalizedMessage from './zh';
import config from '../config';
import { ShortLangKey } from '../types/proceq';

export type LocalizedMessage = typeof enLocalizedMessage;

export type LanguageOptions = 'en' | 'zh' | 'ja' | 'ko' | 'de' | 'fr' | 'it' | 'pt' | 'ru' | 'es';

const releasedLanguages: LanguageOptions[] = ['en'];
export const exportReleasedLanguages: LanguageOptions[] = ['en', 'zh', 'ja', 'ko', 'de', 'fr', 'it', 'pt', 'ru', 'es'];

export const messagesLib: { [key in LanguageOptions]: { [key: string]: string } } = {
    en: enLocalizedMessage,
    zh: zhLocalizedMessage,
    ja: jaLocalizedMessage,
    ko: koLocalizedMessage,
    de: deLocalizedMessage,
    fr: frLocalizedMessage,
    it: itLocalizedMessage,
    pt: ptLocalizedMessage,
    ru: ruLocalizedMessage,
    es: esLocalizedMessage,
};

export const languageKeys =
    config.APP_ENV === 'dev' ? (Object.keys(messagesLib) as LanguageOptions[]) : releasedLanguages;

export const getDefaultLanguage = (): LanguageOptions => {
    const navLanguage = navigator.language.split(/[-_]/)[0]; // language without region code
    const storageLanguage = storageUtils.getLocalItem('language');
    const language = storageLanguage ?? navLanguage;
    return languageKeys.find((key) => key === language) ?? 'en';
};

export const getHTMLExportLang = (lang: ShortLangKey): LanguageOptions => {
    // turkish currently not supported
    if (lang === 'tr') {
        return 'en';
    }
    if (lang === 'cn') {
        return 'zh';
    }
    if (lang === 'jp') {
        return 'ja';
    }
    return lang;
};
