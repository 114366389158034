import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';

export const Favourite: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) =>
    log.content.status ? (
        <FormattedMessage id="Proceq.LogbookPropertySeriesFlagged" />
    ) : (
        <FormattedMessage id="Proceq.LogbookPropertySeriesUnflagged" />
    );

export default {
    element: Favourite,
};
