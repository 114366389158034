import { ProceqUser } from '../types/profile';
import actionTypes, { PayloadAction } from './actionTypes';

export interface ReceivedProceqUserPayload {
    userList: ProceqUser[];
}

export const receivedProceqUsers = (payload: ReceivedProceqUserPayload): PayloadAction<ReceivedProceqUserPayload> => {
    return {
        type: actionTypes.RECEIVED_PROCEQ_USERS,
        payload,
    };
};
