import React from 'react';
import styles from './PropertiesTable.styl';

interface PropertiesTableProps {
    title: React.ReactNode;
    properties?: { key: string; label: React.ReactNode; value: React.ReactNode }[];
}

const PropertiesTable: React.FunctionComponent<PropertiesTableProps> = (props) => {
    const { title, properties } = props;
    return (
        <div className={styles.extraInfo_container}>
            <div className={styles.category_properties}>{title}</div>
            {properties && (
                <div className={styles.properties_wrapper}>
                    {properties.map(({ key, label, value }) => (
                        <div key={key} className={styles.property_container}>
                            <div className={styles.property_title}>{label}</div>
                            <div className={styles.property_value}>{value}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PropertiesTable;
