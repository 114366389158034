import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const RestoreFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.LogbookEntryRestored"
        values={{
            folder: log.content.folder,
        }}
    />
);

export const color = () => 'grey';

export default {
    element: RestoreFile,
    color,
};
