import elementCarbonationDepth from './CarbonationDepth';
import elementCorrectionFactor from './CorrectionFactor';
import elementSurfaceCorrection from './SurfaceCorrection';
import elementConcreteAge from './ConcreteAge';
import elementCreation from './Creation';
import elementTargetScale from './TargetScale';
import elementConversionCurve from './ConversionCurve';
import elementSurfaceCondition from './SurfaceCondition';
import getEquotipLogElement, { EquotipEventLogElement } from '../Equotip/getEquotipLogElement';

enum SchmidtLogType {
    CarbonationDepth = 'CarbonationDepth',
    CorrectionFactor = 'CorrectionFactor',
    SurfaceCorrection = 'SurfaceCorrection',
    ConcreteAge = 'ConcreteAge',
    Creation = 'Creation',
    TargetScale = 'TargetScale',
    ConversionCurve = 'ConversionCurve',
    SurfaceCondition = 'SurfaceCondition',
}

const getSchmidtLogElement = (logType: string | SchmidtLogType): EquotipEventLogElement => {
    switch (logType) {
        case SchmidtLogType.CarbonationDepth:
            return elementCarbonationDepth;
        case SchmidtLogType.CorrectionFactor:
            return elementCorrectionFactor;
        case SchmidtLogType.SurfaceCorrection:
            return elementSurfaceCorrection;
        case SchmidtLogType.ConcreteAge:
            return elementConcreteAge;
        case SchmidtLogType.Creation:
            return elementCreation;
        case SchmidtLogType.TargetScale:
            return elementTargetScale;
        case SchmidtLogType.ConversionCurve:
            return elementConversionCurve;
        case SchmidtLogType.SurfaceCondition:
            return elementSurfaceCondition;
        default:
            return getEquotipLogElement(logType);
    }
};

export default getSchmidtLogElement;
