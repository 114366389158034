import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';

export const CorrectionFactor: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id="Proceq.LogbookPropertyCorrectionFactorFromTo"
            values={{
                oldValue: log.content.oldCorrectionFactor.toFixed(1),
                newValue: log.content.updatedCorrectionFactor.toFixed(1),
            }}
        />
    );
};

export default {
    element: CorrectionFactor,
};
