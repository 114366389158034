import React from 'react';
import { Collapse, CollapsePanelProps } from 'antd';
import { ProductCode } from '../../../../types/proceq';
import { SingleMeasurementState } from '../../../../reducers/measurement';
import ExpandButton from '../../../shared/Buttons/ExpandButton';
import { useMeasurementViewerContext } from '../../../StandaloneMeasurementView/MeasurementViewerContext';

export interface GPRFamilyReadingsProps {
    product: ProductCode;
    data?: SingleMeasurementState;
    isStandalone?: boolean;
}

export interface EquotipFamilyRendererProps {
    product: ProductCode;
    data?: SingleMeasurementState;
    isStandalone?: boolean;
    isVerificationMode?: boolean;
}

export const ReadingContainer: React.FunctionComponent<React.PropsWithChildren> = (props) => {
    const { isStandalone } = useMeasurementViewerContext();
    const { children } = props;

    return isStandalone ? (
        <div className="data-view-html">{children}</div>
    ) : (
        <Collapse
            expandIconPosition="right"
            expandIcon={(props) => (
                <div style={{ top: 0 }}>
                    <ExpandButton isActive={!!props.isActive} />
                </div>
            )}
            className="data-view-collapse"
        >
            {children}
        </Collapse>
    );
};

export const ReadingPanel: React.FunctionComponent<CollapsePanelProps> = (props) => {
    const { isStandalone } = useMeasurementViewerContext();
    return isStandalone ? <div className="data-view-panel-html">{props.children}</div> : <Collapse.Panel {...props} />;
};
