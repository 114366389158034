import React from 'react';

import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { getMaterialNameOfMaterialID } from '../../utils/conversionsHelper';

export const Material: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    const formatMessage = useFormatMessage();

    const fromValue = log.content.oldCustomMaterialName
        ? log.content.oldCustomMaterialName
        : getMaterialNameOfMaterialID(null, formatMessage, log.content.oldMaterialId);

    const toValue = log.content.updatedCustomMaterialName
        ? log.content.updatedCustomMaterialName
        : getMaterialNameOfMaterialID(null, formatMessage, log.content.updatedMaterialId);

    return <span>{formatMessage({ id: 'Proceq.LogbookPropertyMaterialChangedFromTo' }, { fromValue, toValue })}</span>;
};

export default {
    element: Material,
};
