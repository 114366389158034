import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../reducers';
import AppleStaticMap from './AppleStaticMap';
import ChinaStaticMap from './ChinaStaticMap';
import { useMeasurementViewerContext } from '../../../../StandaloneMeasurementView/MeasurementViewerContext';

export interface StaticMapProps {
    width?: number;
    height?: number;
    coordinates: {
        lat: number;
        lng: number;
    };
}

const StaticMap: React.FunctionComponent<StaticMapProps> = (props) => {
    const { isHTMLExport, isHTMLView, countryCode } = useMeasurementViewerContext();
    const userProfile = useSelector((state: RootState) => state.profile.userProfile.countryCode);
    const isChinaRegion = ((isHTMLExport || isHTMLView) && countryCode === 'CN') || userProfile === 'CN';
    return isChinaRegion ? <ChinaStaticMap {...props} /> : <AppleStaticMap {...props} />;
};

export default StaticMap;
