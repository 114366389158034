import React from 'react';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';

const getRebarChangedFormatID = (log: Log) => {
    if (log.content.lineIndex) {
        return log.content.event.adjustDiameter
            ? 'App.HTML.Profometer.Logbook.RebarChanged.Line.DiameterAdjusted'
            : log.content.event.resetDiameter
              ? 'App.HTML.Profometer.Logbook.RebarChanged.Line.Reset'
              : log.content.event.include
                ? 'App.HTML.Profometer.Logbook.RebarChanged.Line.Included'
                : 'App.HTML.Profometer.Logbook.RebarChanged.Line.Excluded';
    }
    return log.content.event.adjustDiameter
        ? 'App.HTML.Profometer.Logbook.RebarChanged.DiameterAdjusted'
        : log.content.event.resetDiameter
          ? 'App.HTML.Profometer.Logbook.RebarChanged.Reset'
          : log.content.event.include
            ? 'App.HTML.Profometer.Logbook.RebarChanged.Included'
            : 'App.HTML.Profometer.Logbook.RebarChanged.Excluded';
};

const RebarChanged: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id={getRebarChangedFormatID(log)}
            values={{ lineIndex: log.content.lineIndex, value: log.content.value, diameter: log.content.diameter }}
        />
    );
};

export default {
    element: RebarChanged,
};
