import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import getConcreteAgeSchmidt from '../../utils/getConcreteAgeSchmidt';

export const ConcreteAge: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log }) => {
    return (
        <FormattedMessage
            id="Proceq.LogbookPropertyConcreteAgeChangedFromTo"
            values={{
                oldValue: getConcreteAgeSchmidt(log.content.oldAge),
                newValue: getConcreteAgeSchmidt(log.content.updatedAge),
            }}
        />
    );
};

export default {
    element: ConcreteAge,
};
