import { thunkGet } from './apiService';
import { getApiURL } from './ApiURL';
import * as contractActions from '../actions/contract';
import { ContractInfo } from '../types/contract';

export const getContracts = async (contractIDs: string[]) => {
    type Response = ContractInfo[];

    const { response, dispatch } = await thunkGet<Response>(getApiURL('CONTRACTS'), {
        injectToken: true,
        params: {
            ids: contractIDs,
        },
    });

    dispatch(contractActions.receivedContracts({ contracts: response }));

    return response;
};
