import React from 'react';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import elementGenericLogEvent from '../GenericLogEvent';
import elementChangeCropArea from './ChangeCropArea';
import elementChangeFile from './ChangeFile';
import elementChangeFileUnit from './ChangeFileUnit';
import elementCloseFile from './CloseFile';
import elementCreation from './Creation';
import elementImportFile from './ImportFile';
import elementMarkerAudio from './MarkerAudio';
import elementMarkerText from './MarkerText';
import elementMoveFile from './MoveFile';
import elementObjectMarker from './ObjectMarker';
import elementProbe from '../shared/Probe';
import elementRenameFile from './RenameFile';
import elementRestoreFile from './RestoreFile';
import elementResumeScan from './ResumeScan';
import elementSaveSnapshot from './SaveSnapshot';
import elementSnapshot from './Snapshot';
import elementTrashFile from './TrashFile';
import elementUpdateStatus from './UpdateStatus';
import elementUserAudio from './UserAudio';
import elementUserPhoto from './UserPhoto';
import elementUserText from './UserText';
import elementUtilityLine from './UtilityLine';
import elementFavourite from './Favourite';
import elementArchive from './ArchiveFile';

enum GPRLogType {
    ChangeCropArea = 'ChangeCropArea',
    ChangeFile = 'ChangeFile',
    ChangeFileUnit = 'ChangeFileUnit',
    CloseFile = 'CloseFile',
    Creation = 'Creation',
    ImportFile = 'ImportFile',
    MarkerAudio = 'MarkerAudio',
    MarkerText = 'MarkerText',
    MoveFile = 'MoveFile',
    ObjectMarker = 'ObjectMarker',
    Probe = 'Probe',
    RenameFile = 'RenameFile',
    RestoreFile = 'RestoreFile',
    ResumeScan = 'ResumeScan',
    SaveSnapshot = 'SaveSnapshot',
    Snapshot = 'Snapshot',
    TrashFile = 'TrashFile',
    UpdateStatus = 'UpdateStatus',
    UserAudio = 'UserAudio',
    UserPhoto = 'UserPhoto',
    UserText = 'UserText',
    UtilityLine = 'UtilityLine',
    Favourite = 'Favourite',
    Archive = 'Archive',
}

export interface EventLogElement {
    element: React.FunctionComponent<
        GPRLogbookEntryProps & {
            highlighted?: boolean;
            toggleHighlight?: () => void;
        }
    >;
    icon?: (log: Log) => string;
    color?: (log: Log) => string;
}

const getGPRLogElement = (logType: string | GPRLogType): EventLogElement => {
    switch (logType) {
        case GPRLogType.ChangeCropArea:
            return elementChangeCropArea;
        case GPRLogType.ChangeFile:
            return elementChangeFile;
        case GPRLogType.ChangeFileUnit:
            return elementChangeFileUnit;
        case GPRLogType.CloseFile:
            return elementCloseFile;
        case GPRLogType.Creation:
            return elementCreation;
        case GPRLogType.ImportFile:
            return elementImportFile;
        case GPRLogType.MarkerAudio:
            return elementMarkerAudio;
        case GPRLogType.MarkerText:
            return elementMarkerText;
        case GPRLogType.MoveFile:
            return elementMoveFile;
        case GPRLogType.ObjectMarker:
            return elementObjectMarker;
        case GPRLogType.Probe:
            return elementProbe;
        case GPRLogType.RenameFile:
            return elementRenameFile;
        case GPRLogType.RestoreFile:
            return elementRestoreFile;
        case GPRLogType.ResumeScan:
            return elementResumeScan;
        case GPRLogType.SaveSnapshot:
            return elementSaveSnapshot;
        case GPRLogType.Snapshot:
            return elementSnapshot;
        case GPRLogType.TrashFile:
            return elementTrashFile;
        case GPRLogType.UpdateStatus:
            return elementUpdateStatus;
        case GPRLogType.UserAudio:
            return elementUserAudio;
        case GPRLogType.UserPhoto:
            return elementUserPhoto;
        case GPRLogType.UserText:
            return elementUserText;
        case GPRLogType.UtilityLine:
            return elementUtilityLine;
        case GPRLogType.Favourite:
            return elementFavourite;
        case GPRLogType.Archive:
            return elementArchive;
        default:
            return elementGenericLogEvent;
    }
};

export default getGPRLogElement;
