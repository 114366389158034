import React from 'react';
import { Card } from 'antd';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import useMeasurementImages, { MeasurementImageType } from '../../../../../hooks/useMeasurementImages';
import { ReadingPanel } from '../ReadingComponents';
import FormattedMessage from '../../../../../localization/FormatMessage';
import ImageSlider from '../../../../shared/ImageSlider';
import styles from './Snapshots.styl';
import Spinner from '../../../../shared/Spinner';
import imagePlaceholderSrc from '../../../../../images/imagePlaceholder.svg';
import { useMeasurementViewerContext } from '../../../../StandaloneMeasurementView/MeasurementViewerContext';
import ExportImages from './ExportImages';
import { ImageSliderCategory } from '../../../../../analytics/analyticsConstants';

const SupportingImages: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({ data, product }) => {
    const { onLazyLoad, entryIDs, entries } = useMeasurementImages({
        attachments: data.attachments,
        logs: data.logs,
        measurementImageType: MeasurementImageType.others,
    });
    const { isHTMLExport } = useMeasurementViewerContext();

    if (!entryIDs.length) {
        return null;
    }

    return (
        <ReadingPanel key="snapshots" header={<FormattedMessage id="App.SupportingImages" />}>
            <Card size="small" title={<FormattedMessage id="App.SupportingImages" />}>
                {isHTMLExport ? (
                    <ExportImages entryIDs={entryIDs} entries={entries} imagesPerRow={3} />
                ) : (
                    <ImageSlider
                        slidesToShow={3}
                        slidesToScroll={3}
                        onLazyLoad={onLazyLoad}
                        adaptiveHeight
                        product={product}
                        analyticsCategory={ImageSliderCategory.supportingImages}
                    >
                        {entryIDs.map((aID) => {
                            const imageURL = entries[aID]?.imageURL;
                            const isLoading = imageURL === undefined;
                            return (
                                <div className={styles.slide} key={aID}>
                                    <div className={styles.image_wrapper} style={{ maxHeight: 280 }}>
                                        {isLoading ? (
                                            <Spinner size="default" />
                                        ) : (
                                            <img src={imageURL || imagePlaceholderSrc} alt="" />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </ImageSlider>
                )}
            </Card>
        </ReadingPanel>
    );
};

export default SupportingImages;
