import type { MeasurementObjectWithInfo } from '../GPR/Objects';
import { MeasurementFullData } from '../../../../../types/measurement';
import { MarkersColumnRaw } from '../shared/Markers';

const buildMarkerPositionCalculator = (data: MeasurementFullData) => {
    const velocity = data.settings[0].content.processing.velocity;
    const samplingRate = data.settings[0].content.processing.samplingRate;
    const piles = data.measurement.content.piles;

    const positionDepths: { [id: string]: any } = {};
    piles.forEach((pile: { positions: any[] }) => {
        pile.positions.forEach(({ id, depth }) => {
            positionDepths[id] = depth;
        });
    });

    return (location: number, positionID: string) => {
        if (!samplingRate || !velocity) {
            return '';
        }

        const sampleTop = positionDepths[positionID].top;
        const positionInSamples = location - sampleTop;
        return ((positionInSamples / samplingRate) * velocity) / 2;
    };
};

export const getMarkersTableData = (data: MeasurementFullData, positionID: string | undefined) => {
    const markers = positionID
        ? data.objects.filter((object) => object.content.positionID === positionID)
        : data.objects;

    const calculateMarkerPosition = buildMarkerPositionCalculator(data);

    const sortedLogs = data.logs.slice().sort((a, b) => b.clientCreated - a.clientCreated);

    const markerInfo: MeasurementObjectWithInfo[] = markers
        .filter((object) => object.type === 'piTextMarker')
        .sort((a, b) => {
            return a.content.number - b.content.number;
        })
        .map((object) => {
            const reading = data.readings.find((r) => r.id === object.rID);
            const log = sortedLogs.find((l) => +l.content.sequence === +object.content.number);

            return {
                ...object,
                readingContent: reading ? reading.content : {},
                logContent: log ? log.content : {},
                markerPosition: calculateMarkerPosition(object.content.location, object.content.positionID),
            };
        });

    const columnsRaw: MarkersColumnRaw[] = [
        {
            title: 'App.HTML.PI8000.Markers.MarkerName',
            render: (text: string, record: MeasurementObjectWithInfo) => `M${record.content.number}`,
        },
        {
            title: 'App.HTML.PI8000.Markers.Position',
            dataIndex: 'markerPosition',
            unitId: 'PIT_IE.CSV.Marker distance X',
        },
        {
            title: 'App.HTML.PI8000.Markers.Comments',
            dataIndex: ['content', 'text'],
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return record.content.text ? record.content.text : '-';
            },
        },
    ];
    return { markers: markerInfo, columnsRaw };
};
