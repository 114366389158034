import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import SavedSnapshots from './SavedSnapshots';
import UserComponent, { title as UserTitle } from '../Common/User';
import MeasurementPresets, { title as MeasurementPresetsTitle } from './shared/MeasurementPresets';
import TimeSlice, { title as TimeSliceTitle } from './Pundit/TimeSlice';
import ImageProcessing, { title as ImageProcessingTitle } from './Pundit/ImageProcessing';
import Display, { title as DisplayTitle } from './Pundit/Display';
import Objects, { title as ObjectsTitle } from './Pundit/Objects';
import VelocityObjects, { title as VelocityObjectsTitle } from './Pundit/VelocityObjects';
import Markers, { title as MarkersTitle } from './shared/Markers';
import Grid, { title as GridTitle } from './Pundit/Grid';
import { DerivedProps } from '../SharedTypes';
import { convertAndDisplay } from '../utils/conversionRates';
import { GPRFamilyReadingsProps, ReadingContainer, ReadingPanel } from './ReadingComponents';
import { ProductModel, ScanType } from '../../../../types/proceq';

const PunditReadings: React.FunctionComponent<GPRFamilyReadingsProps> = (props) => {
    const { product, data, isStandalone } = props;
    const productModel = get(data, 'measurement.productModel')?.toUpperCase() as ProductModel;
    const scanType = get(data, 'measurement.type');
    const isMetric = get(data, 'settings.0.content.display.unit') === 'Metric';

    const convertor: DerivedProps['convert'] = useCallback(
        (value, unitId) =>
            isFinite(Number(value))
                ? convertAndDisplay(unitId, scanType, isMetric).convertFunction(Number(value)) ?? ''
                : '-',
        [isMetric, scanType]
    );

    if (!data) {
        return null;
    }

    const derivedProps: DerivedProps & {
        productModel: ProductModel;
    } = {
        product,
        productModel,
        scanType,
        isMetric,
        convert: convertor,
        showTitle: isStandalone,
    };

    return (
        <ReadingContainer>
            <ReadingPanel key="user" header={UserTitle}>
                <UserComponent data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="measurement" header={MeasurementPresetsTitle}>
                <MeasurementPresets data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="imageProcessing" header={ImageProcessingTitle}>
                <ImageProcessing data={data} {...derivedProps} />
            </ReadingPanel>

            <ReadingPanel key="display" header={DisplayTitle}>
                <Display data={data} {...derivedProps} />
            </ReadingPanel>

            {scanType === ScanType.StripeScan && (
                <ReadingPanel key="timeSlice" header={TimeSliceTitle}>
                    <TimeSlice data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            {scanType === ScanType.GridScan && (
                <ReadingPanel key="gridSettings" header={GridTitle}>
                    <Grid data={data} {...derivedProps} />
                </ReadingPanel>
            )}

            <SavedSnapshots data={data} {...derivedProps} />

            {data.objects && (
                <>
                    <ReadingPanel key="objects" header={ObjectsTitle}>
                        <Objects data={data} {...derivedProps} />
                    </ReadingPanel>
                    <ReadingPanel key="velocityObjects" header={VelocityObjectsTitle}>
                        <VelocityObjects data={data} {...derivedProps} />
                    </ReadingPanel>
                    <ReadingPanel key="markers" header={MarkersTitle}>
                        <Markers data={data} {...derivedProps} />
                    </ReadingPanel>
                </>
            )}
        </ReadingContainer>
    );
};

export default PunditReadings;
