import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import styles from '../Readings/PropertiesTable/PropertiesTable.styl';
import { InfoForDataMeasurements, ValueSet } from './legacyCustomMaterialsChartHelper';

export const doExtraInfoColumn = (
    valueKeys: string[],
    title: string,
    infoForDataMeasurements: InfoForDataMeasurements
) => {
    if (valueKeys.length === 0) return null;
    return (
        <div className={styles.extraInfo_container}>
            <div className={styles.category_properties}>
                <FormattedMessage id={title} />
            </div>
            <div className={styles.properties_wrapper}>
                {valueKeys.map((valueKey) => {
                    const valueFound = get(infoForDataMeasurements, valueKey, null) as ValueSet;
                    if (valueFound != null && valueFound.VALUE !== undefined && valueFound.VALUE != null) {
                        return (
                            <div key={valueKey} className={styles.property_container}>
                                <div className={styles.property_title}>
                                    {<FormattedMessage id={valueFound.TITLE} />}
                                </div>
                                <div className={styles.property_value}>{valueFound.VALUE}</div>
                            </div>
                        );
                    }

                    return null;
                }, this)}
            </div>
        </div>
    );
};
