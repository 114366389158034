import React, { useMemo } from 'react';
import { isFunction, upperFirst } from 'lodash';
import dayjs from 'dayjs';
import { ReactComponent as IconLogText } from '../../../../images/iconLogText.svg';
import { ReactComponent as IconLogDot } from '../../../../images/iconLogDot.svg';
import elementGenericLogEvent from './GenericLogEvent';
import LogEventInfo from './LogEventInfo';
import { ProductCode, ProductModel } from '../../../../types/proceq';
import { EquotipLogbookEntryProps, GPRLogbookEntryProps, Log } from '../../../../types/logs';
import { classNames } from '../../../../utils/styleUtils';
import { renderLogbookTime } from '../../../../utils/dateUtils';
import { ProceqUser } from '../../../../types/profile';
import getGPRLogElement, { EventLogElement } from './GPR/getGPRLogElement';
import getEquotipElement, { EquotipEventLogElement } from './Equotip/getEquotipLogElement';
import getPunditLogElement from './Pundit/getPunditLogElement';
import getSchmidtLogElement from './Schmidt/getSchmidtLogElement';
import getGLMLogElement from './GLM/getGLMLogElement';
import getFDLLogElement from './FDL/getFDLLogElement';
import styles from './LogEvent.styl';
import getPunditImpactLogElement from './PunditImpact/getPunditImpactLogElement';
import getProfometerLogElement from './Profometer/getProfometerLogElement';
import { MeasurementSettings } from '../../../../types/measurement';

export const renderUserInitials = (user?: ProceqUser) => {
    if (user) {
        const initials = [user.firstName, user.lastName]
            .map((name) => name.slice(0, 1).toUpperCase())
            .filter(Boolean)
            .join('');

        if (initials.length > 0) {
            return initials;
        }
    }

    return '';
};

type GPRProps = Pick<GPRLogbookEntryProps, 'product' | 'productModel' | 'isMetric' | 'version' | 'scanType'>;
type EquotipProps = Pick<
    EquotipLogbookEntryProps,
    'isVerificationMode' | 'primaryEquotipScaleId' | 'primarySchmidtScaleUnit' | 'primaryVerificationScaleId'
>;

type LogEventProps = {
    log: Log;
    product: ProductCode;
    productModel: ProductModel;
    showDate: boolean;
    date: number;
    user: string;
    highlighted?: boolean;
    toggleHighlight?: () => void;
    probeLog?: Log;
    measurementSettings?: MeasurementSettings;
} & Partial<GPRProps> &
    Partial<EquotipProps>;

export const LogEvent: React.FunctionComponent<LogEventProps> = (props) => {
    const {
        log,
        user,
        product,
        productModel,
        showDate,
        date,
        isMetric,
        version,
        scanType,
        highlighted,
        toggleHighlight,
        isVerificationMode,
        primaryEquotipScaleId,
        primarySchmidtScaleUnit,
        primaryVerificationScaleId,
        probeLog,
        measurementSettings,
    } = props;

    const element = useMemo(() => mapModule(product, log), [product, log]);

    const Element = element.element;
    const color = isFunction(element.color) ? element.color(log) : 'blue';
    const icon = isFunction(element.icon) ? element.icon(log) || 'info' : undefined;

    const hasExtraInfo =
        log.type === 'textWithKeyValues' || (log.type === 'objectMarker' && log.content.status !== 'deleted');

    const hasMA8000 = (product === ProductCode.GPR_SOIL && probeLog?.content.ma8000SerialNumber) ?? undefined;

    return (
        <li className={classNames(styles.message, 'message', log.type === 'fileLogEvent' && 'messageWithAttachment')}>
            {showDate && <div className={styles.date_container}>{dayjs(date).format('D MMM YYYY')}</div>}
            <div className={styles.content_container}>
                <div>
                    <div>
                        <time className={styles.time} dateTime={date.toString()}>
                            {renderLogbookTime(date)}
                        </time>
                        <span className={styles.user}>{user}</span>
                    </div>
                    {Element && (
                        <Element
                            product={product}
                            productModel={productModel}
                            log={log}
                            isMetric={isMetric}
                            version={version ?? ''}
                            scanType={scanType}
                            highlighted={highlighted}
                            toggleHighlight={toggleHighlight}
                            isVerificationMode={isVerificationMode}
                            primaryEquotipScaleId={primaryEquotipScaleId}
                            primarySchmidtScaleUnit={primarySchmidtScaleUnit}
                            primaryVerificationScaleId={primaryVerificationScaleId}
                            hasMA8000={hasMA8000}
                            measurementSettings={measurementSettings}
                        />
                    )}
                </div>

                <div className={classNames(styles.icon, icon && styles.icon_with_logo)}>
                    {icon ? (
                        <IconLogText
                            className={classNames(icon === 'log' ? styles.icon_log : styles.icon_log_delete)}
                        />
                    ) : (
                        <IconLogDot className={classNames(styles[color])} />
                    )}
                </div>
            </div>
            {hasExtraInfo && (
                <div
                    className={classNames(
                        'log_event', // for printing
                        styles.log_event,
                        // Use css to hide the DOM. This is to support printing and html export.
                        // THe DOM must always be rendered
                        highlighted && styles.highlighted
                    )}
                >
                    <LogEventInfo
                        product={product}
                        productModel={productModel}
                        log={log}
                        isMetric={isMetric}
                        scanType={scanType}
                        version={version ?? ''}
                    />
                </div>
            )}
        </li>
    );
};

export default LogEvent;

const mapModule = (product: ProductCode | undefined, log: Log): EventLogElement | EquotipEventLogElement => {
    const logType = upperFirst(log.type);
    switch (product) {
        case 'GPR':
        case 'GPR_SOIL':
        case 'GPR_MOUNTED':
            return getGPRLogElement(logType);
        case 'FDL':
            return getFDLLogElement(logType);
        case 'PUNDIT':
            return getPunditLogElement(logType);
        case 'GLM':
            return getGLMLogElement(logType);
        case 'SCHMIDT':
            return getSchmidtLogElement(logType);
        case 'EQUOTIP':
            return getEquotipElement(logType);
        case 'PROFOMETER':
            return getProfometerLogElement(logType);
        case 'PIT_IE':
            return getPunditImpactLogElement(logType);
        default:
            return elementGenericLogEvent;
    }
};
