import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FDL_METRIC_UNITS } from '../../../../../types/measurement';

const Severity: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    const { content } = log;
    const thicknessConvertor = convertAndDisplay(
        'FDL.Severity.Thickness',
        scanType ?? ScanType.BScan,
        FDL_METRIC_UNITS.has(content.severityInfo?.unit ?? 'metric')
    );
    const { unit: thicknessUnit, convertFunction, roundNumber } = thicknessConvertor;

    return (
        <div>
            <b>
                <FormattedMessage id="App.Logbook.FDL.Severity" />
            </b>
            <br />
            <FormattedMessage id="App.Logbook.FDL.Severity.SliderRange" values={{ value: '' }} />
            <br />
            <FormattedMessage
                id="App.Logbook.FDL.Severity.Green"
                values={{
                    value: `${convertFunction(content.severityInfo?.greenStart ?? 0)}-${convertFunction(
                        content.severityInfo?.greenEnd ?? 0
                    )} ${thicknessUnit}`,
                }}
            />
            <br />
            <FormattedMessage
                id="App.Logbook.FDL.Severity.Yellow"
                values={{
                    value: `${convertFunction(content.severityInfo?.yellowStart ?? 0)}-${convertFunction(
                        content.severityInfo?.yellowEnd ?? 0
                    )} ${thicknessUnit}`,
                }}
            />
            <br />
            <FormattedMessage
                id="App.Logbook.FDL.Severity.Red"
                values={{
                    value: `${convertFunction(content.severityInfo?.redStart ?? 0)}-${convertFunction(
                        content.severityInfo?.redEnd ?? 0
                    )} ${thicknessUnit}`,
                }}
            />
            <br />
            <span>
                {content.severityInfo?.redTitle}: {roundNumber(content.severityInfo?.redPercent ?? '-')}%
            </span>
            <br />
            <span>
                {content.severityInfo?.yellowTitle}: {roundNumber(content.severityInfo?.yellowPercent ?? '-')}%
            </span>
            <br />
            <span>
                {content.severityInfo?.greenTitle}: {roundNumber(content.severityInfo?.greenPercent ?? '-')}%
            </span>
        </div>
    );
};

export default {
    element: Severity,
    color: () => 'green',
};
