import { noop } from 'lodash';
import React, { createContext } from 'react';
import { DataViewMode, DEFAULT_PAGE_SIZE, NonEditableMeasurements, ViewType } from '../../../types/measurement';
import { MeasurementListParams } from '../../../queries/measurementQueries';

export interface ViewModeConfig {
    viewMode?: DataViewMode;
    measurementID?: string;
    customMaterialID?: string;
}

export interface MeasurementContextState {
    fetchMeasurementList: (changePage?: boolean) => Promise<void>;
    fetchCustomMaterialList: (changePage?: boolean) => Promise<void>;
    isFetching: boolean;

    modalViewerConfig: ViewModeConfig;
    setModalViewerConfig: (config: ViewModeConfig) => void;
    openMeasurementInNewTab: (id: string) => void;

    resetConfig: () => void;

    showHaze: boolean;
    setShowHaze: (value: ((prevState: boolean) => boolean) | boolean) => void;

    nonEditableMeasurements: undefined | NonEditableMeasurements;
    setNonEditableMeasurements: (value: undefined | NonEditableMeasurements) => void;

    selectedKeys: string[];
    setSelectedKeys: React.Dispatch<React.SetStateAction<string[]>>;

    measurementListParams: MeasurementListParams;
    setMeasurementListParams: React.Dispatch<React.SetStateAction<MeasurementListParams>>;
}

export const MeasurementContext = createContext<MeasurementContextState>({
    fetchMeasurementList: async () => {},
    fetchCustomMaterialList: async () => {},
    isFetching: false,

    modalViewerConfig: {},
    setModalViewerConfig: noop,
    openMeasurementInNewTab: noop,

    resetConfig: noop,

    showHaze: true,
    setShowHaze: noop,

    nonEditableMeasurements: undefined,
    setNonEditableMeasurements: noop,

    selectedKeys: [],
    setSelectedKeys: noop,

    measurementListParams: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        fileType: '',
        searchText: '',
        withUnsynced: false,
        viewType: ViewType.Active,
        filterConfig: {},
    },
    setMeasurementListParams: noop,
});
