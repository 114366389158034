import React, { useEffect, useRef } from 'react';
import useMapKitStatus from '../../../../../hooks/useMapKitStatus';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { MapKitInitStatus } from '../../../../../utils/mapUtils';
import { useMeasurementViewerContext } from '../../../../StandaloneMeasurementView/MeasurementViewerContext';
import { StaticMapProps } from './index';

const AppleStaticMap: React.FunctionComponent<StaticMapProps> = ({ width, height = 200, coordinates }) => {
    const mapInstance = useRef<mapkit.Map>();
    const emptyLocation = coordinates.lat === 0 && coordinates.lng === 0;
    const { isHTMLExport, setViewerReady } = useMeasurementViewerContext();
    const mapkitStatus = useMapKitStatus();
    const mapkitInitialized = mapkitStatus === MapKitInitStatus.initialized;
    const mapkitInitFailed = mapkitStatus === MapKitInitStatus.error;

    useEffect(() => {
        if (mapkitInitialized && container.current) {
            mapInstance.current = new mapkit.Map(container.current);
        }
    }, [mapkitInitialized]);

    useEffect(() => {
        // map token optional for standalone viewer EC-777
        if (isHTMLExport && !window.mapToken) {
            setViewerReady();
        }
    }, [isHTMLExport, mapkitStatus, setViewerReady]);

    useEffect(() => {
        const myMap = mapInstance.current;
        if (!mapkitInitialized || !myMap) {
            return () => {};
        }

        const complete = 'complete' as any;
        myMap.addEventListener(complete, setViewerReady);
        return () => {
            myMap.removeEventListener(complete, setViewerReady);
        };
    }, [mapkitInitialized, setViewerReady]);

    useEffect(() => {
        if (mapkitInitFailed) {
            window.viewerError = new Error('Failed to initialize Apple Map');
        }
    }, [mapkitInitFailed]);

    useEffect(() => {
        if (
            mapkitInitialized &&
            mapInstance.current &&
            !emptyLocation &&
            !isNaN(coordinates.lng) &&
            !isNaN(coordinates.lat)
        ) {
            const site = new mapkit.Coordinate(coordinates.lat, coordinates.lng);
            const span = new mapkit.CoordinateSpan(0.005, 0.005);
            const siteAnnotation = new mapkit.MarkerAnnotation(site);
            mapInstance.current.addAnnotation(siteAnnotation);
            mapInstance.current.region = new mapkit.CoordinateRegion(site, span);
        }
    }, [coordinates.lat, coordinates.lng, emptyLocation, mapkitInitialized]);

    const container = useRef<HTMLDivElement>(null);

    if (emptyLocation) {
        return null;
    }

    return (
        <div
            ref={container}
            style={{
                width: width ?? '100%',
                height,
                maxWidth: '100%',
                pointerEvents: 'none',
            }}
        >
            {mapkitInitFailed && (
                <span style={{ color: 'red' }}>
                    <FormattedMessage id="Mapkit.Failed.Init" />
                </span>
            )}
        </div>
    );
};

export default AppleStaticMap;
