import { useSelector } from 'react-redux';
import { getLabelFromGlobalDataKey } from '../components/DataView/RegisteredInfo/helper';
import { modelImageMap } from '../components/Probes/ProbeImage';
import { RootState } from '../reducers';
import { ProbeInfoState } from '../reducers/probe';
import { ProductModel } from '../types/proceq';

const useProbes = () => {
    const { probeIDs, probes } = useSelector((state: RootState) => state.probe);
    return { probeIDs, probes };
};

export const useProbe = (probeSN?: string) => {
    const probeInfo: ProbeInfoState | undefined = useSelector((state: RootState) => state.probe.probes[probeSN ?? '']);
    return probeInfo;
};

export const useProbeImage = (probeSN?: string, productModel?: string) => {
    const pModel: string | undefined = useSelector((state: RootState) =>
        state.probe.probes[probeSN ?? '']?.pModel?.toUpperCase()
    );
    const myModel = pModel ?? productModel;
    return myModel ? modelImageMap[myModel] : undefined;
};

const SCHMIDT_NAME_MAP: Record<string, string> = {
    'Original Schmidt Live L': 'OS8000 L',
    'Original Schmidt Live N': 'OS8000 N',
    'Silver Schmidt Live L': 'OS8200 L',
    'Silver Schmidt Live N': 'OS8200 N',
    'Rock Schmidt Live L': 'RS8000 L',
    'Rock Schmidt Live N': 'RS8000 N',
};

export const getProbeName = (productModel?: ProductModel, name?: string) => {
    const tempName = name ?? '';

    // only schmidt probes cannot be identified by product model only
    if (productModel?.toUpperCase() === ProductModel.SCHMIDT || productModel?.toUpperCase() === ProductModel.SSL) {
        return SCHMIDT_NAME_MAP[tempName] ?? tempName;
    }

    return (
        (productModel ? getLabelFromGlobalDataKey('latestavailproductsfull', productModel?.toUpperCase()) : null) ??
        tempName
    );
};

export default useProbes;
