import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const AddMeasuringMode: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="App.Logbook.FDL.MeasuringMode"
        values={{
            mode: log.content.mode,
        }}
    />
);

export default {
    element: AddMeasuringMode,
};
