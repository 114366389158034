import React, { useCallback } from 'react';
import { get, isFinite } from 'lodash';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import ConvertedUnits from '../shared/ConvertedUnits';
import { ReactComponent as IconBackwall } from '../../../../images/iconLogbookBackwall.svg';
import { ReactComponent as IconNonMetallic } from '../../../../images/iconLogbookNonMetallic.svg';
import { ReactComponent as IconRebar } from '../../../../images/iconLogbookRebar.svg';
import { ReactComponent as IconVoid } from '../../../../images/iconLogbookVoid.svg';
import { ReactComponent as IconLiveWire } from '../../../../images/iconLogbookLiveWire.svg';
import { ReactComponent as IconUserDefined } from '../../../../images/iconLogbookUserDefined.svg';
import { ReactComponent as IconPulseVelocity } from '../../../../images/iconLogbookPulseVelocity.svg';
import { ReactComponent as IconTendonDucts } from '../../../../images/iconLogbookTendonDucts.svg';
import { GPRLogbookEntryProps } from '../../../../types/logs';
import { convertAndDisplay } from '../utils/conversionRates';
import { FormatIDs } from '../../../../types';
import { tableLocaleSetting } from '../../../shared/MyEmpty';
import { ProductCode, ScanType } from '../../../../types/proceq';
import { TagObjectType } from '../../../../types/measurement';

export const iconMap: {
    [key: string]: { icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>; text: FormatIDs };
} = {
    [TagObjectType.Backwall]: { icon: IconBackwall, text: 'App.HTML.GPR.Objects.Backwall' },
    [TagObjectType.NonMetallic]: { icon: IconNonMetallic, text: 'App.HTML.GPR.Objects.Non Metallic' },
    [TagObjectType.Rebar]: { icon: IconRebar, text: 'App.HTML.GPR.Objects.Rebar' },
    [TagObjectType.Void]: { icon: IconVoid, text: 'App.HTML.GPR.Objects.Void' },
    [TagObjectType.LiveWire]: { icon: IconLiveWire, text: 'App.HTML.GPR.Objects.Live Wire' },
    [TagObjectType.UserDefined]: { icon: IconUserDefined, text: 'App.HTML.GPR.Objects.User Defined' },
    [TagObjectType.Velocity]: { icon: IconPulseVelocity, text: 'App.HTML.GPR.Objects.Velocity' },
    [TagObjectType.PulseVelocity]: { icon: IconPulseVelocity, text: 'App.HTML.GPR.Objects.Velocity' },
    [TagObjectType.TendonDuctVoid]: { icon: IconTendonDucts, text: 'App.HTML.PUNDIT.Objects.TendonDuctVoid' },
};

const PI_ITEMS: Partial<
    Record<ScanType, { metric: { [key: string]: FormatIDs }; imperial: { [key: string]: FormatIDs } }>
> = {
    [ScanType.AScan]: {
        metric: {
            'Velocity (m/s)': 'Proceq.LogbookSettingsVelocityMetric',
            'Top position (ms)': 'Proceq.LogbookSettingsTopPosition',
            'Bottom Position (ms)': 'Proceq.LogbookSettingsBottomPosition',
            'Calculated length (m)': 'Proceq.LogbookSettingsCalculatedLengthMetric',
        },
        imperial: {
            'Velocity (ft/s)': 'Proceq.LogbookSettingsVelocityImperial',
            'Top position (ms)': 'Proceq.LogbookSettingsTopPosition',
            'Bottom Position (ms)': 'Proceq.LogbookSettingsBottomPosition',
            'Calculated length (ft)': 'Proceq.LogbookSettingsCalculatedLengthImperial',
        },
    },
    [ScanType.ImpactEchoSpot]: {
        metric: {
            'Velocity (m/s)': 'Proceq.LogbookSettingsVelocityMetric',
            'Frequency (Hz)': 'Proceq.LogbookSettingsFrequency',
            'Calculated thickness (mm)': 'Proceq.LogbookSettingsCalculatedThicknessMetric',
        },
        imperial: {
            'Velocity (ft/s)': 'Proceq.LogbookSettingsVelocityImperial',
            'Frequency (Hz)': 'Proceq.LogbookSettingsFrequency',
            'Calculated thickness (in)': 'Proceq.LogbookSettingsCalculatedThicknessImperial',
        },
    },
    [ScanType.ImpactEchoGrid]: {
        metric: {
            'Velocity (m/s)': 'Proceq.LogbookSettingsVelocityMetric',
            'Frequency (Hz)': 'Proceq.LogbookSettingsFrequency',
            'Calculated thickness (mm)': 'Proceq.LogbookSettingsCalculatedThicknessMetric',
        },
        imperial: {
            'Velocity (ft/s)': 'Proceq.LogbookSettingsVelocityImperial',
            'Frequency (Hz)': 'Proceq.LogbookSettingsFrequency',
            'Calculated thickness (in)': 'Proceq.LogbookSettingsCalculatedThicknessImperial',
        },
    },
};

export const LogEventInfo = ({ log, product, isMetric, scanType }: GPRLogbookEntryProps) => {
    const getItemsPI = useCallback(() => {
        const isMetric = log.content.keyValueItems[0]['Velocity (m/s)'] ? 'metric' : 'imperial';
        if (scanType && PI_ITEMS[scanType]) {
            return Object.entries(PI_ITEMS[scanType]![isMetric]).map(([key, value], index) => {
                return { id: value, dataIndex: log.content.keyValueItems[index][key] };
            });
        }
        return [];
    }, [log.content.keyValueItems, scanType]);

    if (isMetric === undefined || scanType === undefined) {
        return null;
    }
    interface IRow {
        id: FormatIDs;
        icon?: string;
        unitId?: string;
        dataIndex?: string | string[];
    }

    const commonSet: IRow[] =
        log.content.type !== TagObjectType.Velocity
            ? [
                  {
                      id: 'Proceq.LogbookSettingsCalculatedDepth',
                      unitId: `${product}.Logbook Panel.Calculated Depth`,
                      dataIndex: get(log.content, 'locationY'),
                  },
                  {
                      id: 'Proceq.LogbookSettingsSetDepth',
                      unitId: `${product}.Logbook Panel.Set Depth`,
                      dataIndex: get(log.content, 'calibratedDepthString'),
                  },
                  {
                      id: 'Proceq.LogbookSettingsSetSize',
                      unitId: `${product}.Logbook Panel.Set Size`,
                      dataIndex: get(log.content, 'sizeCentimeter'),
                  },
              ]
            : [];

    const gprSet: IRow[] =
        product === ProductCode.GPR
            ? [
                  {
                      id: 'Proceq.LogbookSettingsDielectricConstant',
                      unitId: 'GPR.Logbook Panel.Dielectric Constant',
                      dataIndex: get(log.content, 'settings.Dielectric Constant (ℇᵣ)'),
                  },
                  {
                      id: 'Proceq.LogbookSettingsLinearGain',
                      unitId: 'GPR.Logbook Panel.Linear Gain',
                      dataIndex: get(log.content, 'settings.Linear Gain (dB)'),
                  },
              ]
            : [];

    const punditSet: IRow[] =
        product === ProductCode.PUNDIT
            ? [
                  {
                      id: 'Proceq.LogbookSettingsPulseVelocity',
                      unitId: 'PUNDIT.Settings Panel.Pulse Velocity',
                      dataIndex: get(log.content, 'settings.Pulse Velocity'),
                  },
                  {
                      id: 'Proceq.LogbookSettingsAnalogGain',
                      unitId: 'PUNDIT.Settings Panel.Analogue Gain',
                      dataIndex: get(log.content, 'settings.Analogue Gain'),
                  },
                  {
                      id: 'App.HTML.PUNDIT.MeasurementPresets.AnalogTGC',
                      unitId: `${product}.Logbook Panel.Time Gain Compensation`,
                      dataIndex: get(log.content, 'settings.Time Gain Compensation'),
                  },
                  {
                      id: 'App.HTML.PUNDIT.Tag.TransmissionTime',
                      unitId: 'PUNDIT.GridScan.TransmissionTime',
                      dataIndex: get(log.content, 'transmissionTime'),
                  },
              ]
            : [];

    const punditVelocitySet: IRow[] =
        product === ProductCode.PUNDIT && log.content.type === TagObjectType.Velocity
            ? [
                  {
                      id: 'App.HTML.PUNDIT.TransmissionTime',
                      unitId: 'PUNDIT.GridScan.TransmissionTime',
                      dataIndex: log.content.transmissionTime,
                  },
                  {
                      id: 'App.HTML.PUNDIT.Display.LocalDepth',
                      unitId: 'PUNDIT.Logbook Panel.Set Depth',
                      dataIndex: log.content.settings['Local Depth'],
                  },
              ]
            : [];

    const pitIESet: IRow[] = product === ProductCode.PIT_IE ? [...getItemsPI()] : [];

    const rows: IRow[] =
        product === ProductCode.PIT_IE
            ? pitIESet
            : [
                  {
                      id: 'Proceq.LogbookSettingsType',
                      icon: get(log.content, 'type'),
                  },
                  {
                      id: 'Proceq.LogbookSettingsDistance',
                      unitId: `${product}.Logbook Panel.Tag Object Distance`,
                      dataIndex: get(log.content, 'locationX'),
                  },
                  ...gprSet,
                  ...punditSet,
                  ...(product !== ProductCode.PUNDIT
                      ? [
                            {
                                id: 'Proceq.LogbookSettingsTimeGainCompensation',
                                unitId: `${product}.Logbook Panel.Time Gain Compensation`,
                                dataIndex: get(log.content, 'settings.Time Gain Compensation'),
                            } as IRow,
                        ]
                      : []),
                  ...commonSet,
                  ...punditVelocitySet,
                  {
                      id: 'Proceq.LogbookSettingsComment',
                      dataIndex: get(log.content, 'comment'),
                  },
                  {
                      id: 'Proceq.LogbookSettingsName',
                      dataIndex: get(log.content, 'settings.name'),
                  },
              ];

    return (
        <Table
            rowKey="id"
            dataSource={rows}
            pagination={false}
            showHeader={false}
            size="small"
            className="to_toggle"
            locale={tableLocaleSetting}
        >
            <Table.Column
                key="label"
                render={(text, record: IRow) =>
                    record.unitId ? (
                        <ConvertedUnits
                            id={record.id}
                            unitId={record.unitId}
                            isMetric={isMetric}
                            scanType={scanType}
                            values={[]}
                        />
                    ) : (
                        <FormattedMessage id={record.id} />
                    )
                }
                width={230}
            />
            <Table.Column
                key="value"
                render={(text, record: IRow) => {
                    if (record.icon) {
                        if (iconMap[record.icon]) {
                            const Icon = iconMap[record.icon].icon;
                            return (
                                <span>
                                    <Icon style={{ height: 34, marginRight: '0.5rem', verticalAlign: 'middle' }} />
                                    <FormattedMessage id={iconMap[record.icon].text} />
                                </span>
                            );
                        }
                        return <span>{record.icon}</span>;
                    }
                    if (record.unitId) {
                        return isFinite(Number(record.dataIndex))
                            ? convertAndDisplay(record.unitId, scanType, isMetric).convertFunction(
                                  Number(record.dataIndex)
                              )
                            : '-';
                    }
                    return record.dataIndex;
                }}
            />
        </Table>
    );
};

export default LogEventInfo;
