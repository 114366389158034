import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const ChangeFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.ChangedFromTo"
        values={{
            changed: log.content.settingName,
            from: log.content.old,
            to: log.content.new,
        }}
    />
);

export default {
    element: ChangeFile,
};
