import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { getStandardNameOfStandardID } from '../../utils/conversionsHelper';

export const Standard: React.FunctionComponent<EquotipLogbookEntryProps> = ({ log, product }) => {
    const formatMessage = useFormatMessage();
    const oldStandard = getStandardNameOfStandardID(null, formatMessage, log.content.oldStandardId, product);
    const newStandard = getStandardNameOfStandardID(null, formatMessage, log.content.updatedStandardId, product);
    return (
        <span>
            <FormattedMessage id="Proceq.LogbookPropertyStandardChangedFromTo" values={{ oldStandard, newStandard }} />
        </span>
    );
};

export default {
    element: Standard,
};
