import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import AppLayout from '../../shared/AppLayout';
import { useAppsStatus } from '../../../hooks/useConfig';
import styles from './ToolFrame.styl';
import { ProductCode } from '../../../types/proceq';
import { useProductContext } from '../ProductContextProvider';
import { productMap, ProductName } from '../../shared/AppIcon';
import FreeTrialModal, { LicenseActionState } from '../../License/FreeTrialModal';
import * as appService from '../../../api/appService';
import { getActiveApps } from '../../../api/appService';
import { getAppRoute } from '../../Routes/urls';
import UpgradeModal from '../../License/UpgradeModal';
import { useUserLicense } from '../../../hooks/useCurrentUser';
import { useLicenseList } from '../../../queries/userQueries';
import { ProductCodeToLicenseProduct } from '../../../types/license';

const ToolFrame: React.FunctionComponent = () => {
    const { appsStatus } = useAppsStatus();
    const navigate = useNavigate();
    const { productName = ProductName.GPRInsights } = useParams<{ productName: string }>();
    const productInfo = productMap[productName];
    const productCode = productInfo?.productCode ?? (productName as ProductCode);
    const productAppStatus = appsStatus[productCode];
    const [isActiveAppsUpdated, setIsActiveAppsUpdated] = useState(false);
    const [showActivateFreeTrialModal, setShowActivateFreeTrialModal] = useState(false);
    const { setProduct } = useProductContext();
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);
    const { license } = useUserLicense();
    const { isSuccess: isSuccessLicenses } = useLicenseList();

    useEffect(() => {
        const getInfo = async () => {
            await Promise.all([
                getActiveApps(),
                // tier info needed to show upgrade choices if user has no license and not eligible for free trial
                productCode === ProductCode.GPR_INSIGHTS && ProductCodeToLicenseProduct[productCode]
                    ? appService.getLicenseTier(ProductCodeToLicenseProduct[productCode]!)
                    : Promise.resolve(),
            ]);
            setIsActiveAppsUpdated(true);
        };
        getInfo();
    }, [productCode]);

    useEffect(() => {
        setProduct(productCode);
    }, [setProduct, productCode]);

    useEffect(() => {
        if (isActiveAppsUpdated && productAppStatus && isSuccessLicenses) {
            if (productAppStatus.active) {
                window.name = 'workspace';
                window.open(getAppRoute(ProductName.GPRInsights), 'insights');
                navigate('/');
            } else if (productAppStatus.eligibleFreeTrial) {
                setShowActivateFreeTrialModal(true);
            } else {
                setShowUpgradeModal(true);
            }
        }
    }, [navigate, isActiveAppsUpdated, isSuccessLicenses, productAppStatus]);

    return (
        <AppLayout>
            {!isActiveAppsUpdated && (
                <div className={styles.container_body}>
                    <Spin />
                </div>
            )}
            <FreeTrialModal
                open={showActivateFreeTrialModal}
                productCode={productCode}
                licenseActionState={LicenseActionState.activateFreeTrial}
                showFreeLicense
                onClose={() => {
                    setShowActivateFreeTrialModal(false);
                    navigate('/');
                }}
            />
            <UpgradeModal
                license={license[productCode]}
                product={productCode}
                onCancel={() => setShowUpgradeModal(false)}
                navigateToDashboardOnClose
                open={showUpgradeModal}
            />
        </AppLayout>
    );
};

export default memo(ToolFrame);
