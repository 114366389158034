import React from 'react';
import { FormInstance, Rule } from 'antd/lib/form';
import { passwordRegex, escapeRegexExpression } from './regex';

export const getConfirmPasswordValidator =
    <T extends string>(fieldKey: T, errorMessage: React.ReactNode): Rule =>
    (ruleForm: any) => ({
        validator(rule: any, value: string) {
            const form = ruleForm as FormInstance;
            if (!value || form.getFieldValue(fieldKey) === value) {
                return Promise.resolve();
            }
            return Promise.reject(errorMessage);
        },
    });

export const getStrongPasswordValidator =
    <T extends string>(nameFields: T[], errorMessage: React.ReactNode, getFormValues: boolean = true): Rule =>
    (ruleForm: any) => ({
        validator(rule: any, value: string) {
            const form = ruleForm as FormInstance;
            if (!value) {
                return Promise.resolve();
            }

            if (!passwordRegex.test(value)) {
                return Promise.reject(errorMessage);
            }

            const startFieldRegexStr = '^(';
            const endFieldRegexStr = '.)*$';

            // Do not allowed the password to contain the same field value. Usually the name
            let fieldRegexStr = nameFields.reduce((regexStr, field) => {
                let fieldValue: string = getFormValues ? form.getFieldValue(field) : field;
                if (!fieldValue) {
                    return regexStr;
                }
                // Get the first part if the value is an email format
                fieldValue = escapeRegexExpression(fieldValue.split('@', 2)[0]);

                regexStr += `(?!${fieldValue})`;
                return regexStr;
            }, startFieldRegexStr);
            fieldRegexStr += endFieldRegexStr;

            const fieldRegex = new RegExp(fieldRegexStr, 'i');

            if (fieldRegexStr.length <= startFieldRegexStr.length + endFieldRegexStr.length || fieldRegex.test(value)) {
                return Promise.resolve();
            }
            return Promise.reject(errorMessage);
        },
    });
