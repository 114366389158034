import React, { useCallback, useState } from 'react';
import { uniq } from 'lodash';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { TableCurrentDataSource } from 'antd/lib/table/interface';
import { formatDateTimeDefault } from '../../../../utils/dateUtils';
import DownloadAttachment from './DownloadAttachment';
import { Attachment } from '../../../../types/measurement';
import FormattedMessage from '../../../../localization/FormatMessage';
import { getMeasurementAttachments } from '../../../../api/measurementService';
import { ProductCode } from '../../../../types/proceq';
import { useMeasurement } from '../../../../hooks/useProductData';
import Spinner from '../../../shared/Spinner';
import TableWrapper from '../../../TableWrapper';
import { TimestampType, useMeasurementReloadLimiter } from '../../../../hooks/useLimiter';
import styles from './Attachments.styl';
import { convertBytes } from '../../../../utils/mathUtils';

const generateFilter = (all: Attachment[], key: 'type' | 'rType') => ({
    filters: uniq(all.map((a) => a[key])).map((t) => ({ text: t, value: t })),
    onFilter: (v: React.Key | boolean, r: Attachment) => r[key] === v,
});
const generateSorter = (key: keyof Attachment) => (a: Attachment, b: Attachment) => Number(a[key]) - Number(b[key]);

type AttachmentsProps = {
    product?: ProductCode;
    measurementID: string;
    onFilter: (filteredIDs: string[]) => void;
};

export const Attachments: React.FunctionComponent<AttachmentsProps> = (props) => {
    const { product, measurementID, onFilter } = props;
    const [loading, setLoading] = useState(false);

    const measurement = useMeasurement(measurementID);

    const attachments = measurement?.attachments;

    const handleFetchAttachments = useCallback(async () => {
        if (product) {
            setLoading(true);
            try {
                await getMeasurementAttachments({ product, measurementID });
            } finally {
                setLoading(false);
            }
        }
    }, [measurementID, product]);

    useMeasurementReloadLimiter({
        measurementID,
        timestampType: TimestampType.Attachments,
        callback: handleFetchAttachments,
    });

    const columnsRaw: ColumnsType<Attachment> = [
        {
            title: <FormattedMessage id="App.ID" />,
            fixed: 'left',
            width: 320,
            dataIndex: 'id',
        },
        {
            title: <FormattedMessage id="App.AttachmentSize" />,
            render: convertBytes,
            width: 120,
            dataIndex: 'zippedFileSize',
            sorter: generateSorter('zippedFileSize'),
        },
        {
            title: <FormattedMessage id="App.Type" />,
            width: 150,
            dataIndex: 'type',
            ...generateFilter(attachments || [], 'type'),
        },
        {
            title: <FormattedMessage id="App.AttachmentAttachedTo" />,
            width: 120,
            dataIndex: 'rType',
            ...generateFilter(attachments || [], 'rType'),
        },
        {
            title: <FormattedMessage id="App.Created" />,
            render: formatDateTimeDefault,
            width: 180,
            dataIndex: 'created',
            sorter: generateSorter('created'),
        },
        {
            title: <FormattedMessage id="App.Modified" />,
            render: formatDateTimeDefault,
            width: 180,
            dataIndex: 'updated',
            sorter: generateSorter('updated'),
        },
        {
            title: <FormattedMessage id="App.Actions" />,
            fixed: 'right',
            width: 120,
            render: (_: Attachment, record: Attachment) =>
                product ? (
                    <DownloadAttachment
                        id={record.id}
                        product={product}
                        mID={record.mID}
                        fID={record.fID}
                        zippedFileSize={record.zippedFileSize}
                    />
                ) : null,
        },
    ];

    const onChange = (_: any, __: any, ___: any, extra: TableCurrentDataSource<Attachment>) => {
        onFilter(extra.currentDataSource.map((item) => item.id));
    };

    if (loading) {
        return <Spinner size="default" />;
    }

    return (
        <TableWrapper>
            {() => (
                <Table
                    className={styles.attachments_table}
                    rowKey="id"
                    dataSource={attachments || []}
                    columns={columnsRaw}
                    pagination={false}
                    onChange={onChange}
                    scroll={{ y: window.innerHeight - 352 }} // 352 is sum of height of occupied upper and lower portions out of the table.
                />
            )}
        </TableWrapper>
    );
};

export default Attachments;
