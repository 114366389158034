import React from 'react';
import StaticMap from '../StaticMap';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import {
    getMaterialNameOfMaterialID,
    getResolutionForScale,
    getScaleInfoOfScaleID,
    getStandardNameOfStandardID,
} from '../../utils/conversionsHelper';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useProbeTypeID } from '../../../../../hooks/useProductData';
import { ProbeTypeID } from '../../../../../types/measurement';

export const Creation: React.FunctionComponent<EquotipLogbookEntryProps> = (props) => {
    const { log, product, productModel, isVerificationMode, primaryEquotipScaleId } = props;
    const formatMessage = useFormatMessage();

    let { primaryVerificationScaleId } = props;

    const formatLimitValue = (scale: number | undefined, value: string) =>
        `${getResolutionForScale(
            getScaleInfoOfScaleID(formatMessage, scale, productModel).scaleUnits,
            value,
            'limit'
        )} ${getScaleInfoOfScaleID(formatMessage, scale, productModel).scaleUnits}`;

    const {
        folderName,
        materialID,
        maxLimitValue,
        minLimitValue,
        secondaryScaleId,
        seriesName,
        standardID,
        triggerLoadId,
        testBlockTitle,
    } = log.content.measurementInfo;

    const probeTypeID = useProbeTypeID(log.mID);

    const renderMap = () => {
        const { latitude, longitude } = log.content;

        if (!longitude && !latitude) {
            return null;
        }
        return <StaticMap coordinates={{ lat: +latitude, lng: +longitude }} />;
    };

    let primaryVerificationScaleName = '';
    if (isVerificationMode) {
        switch (probeTypeID) {
            case ProbeTypeID.EquotipD:
            default:
                primaryVerificationScaleId = 2;
                primaryVerificationScaleName = 'Leeb D';
                break;
            case ProbeTypeID.EquotipUCI:
                primaryVerificationScaleId = 25;
                primaryVerificationScaleName = 'UCI Vickers';
                break;
        }

        return (
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyCreatedVerification" values={{ seriesName }} />
                {triggerLoadId && (
                    <div>
                        <FormattedMessage id="Proceq.LogBookTestLoad" />
                        {`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
                    </div>
                )}
                <div>
                    <FormattedMessage id="Proceq.ExportFieldStandard" />
                    {': '}
                    {getStandardNameOfStandardID(null, formatMessage, standardID, product)}
                </div>
                {testBlockTitle && (
                    <div>
                        <div>
                            <FormattedMessage id="Proceq.LogBookTestBlock" />
                            {': '}
                            {testBlockTitle}
                        </div>
                    </div>
                )}
                <div>
                    <FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
                    {': '}
                    {primaryVerificationScaleName}
                </div>
                {minLimitValue && (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldLowerLimit" />
                        {': '}
                        {formatLimitValue(primaryVerificationScaleId, minLimitValue.primaryValue)}
                    </div>
                )}
                {maxLimitValue && (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldUpperLimit" />
                        {': '}
                        {formatLimitValue(primaryVerificationScaleId, maxLimitValue.primaryValue)}
                    </div>
                )}
                <br />
                {renderMap()}
            </div>
        );
    }

    return (
        <div>
            <FormattedMessage
                id="Proceq.LogbookPropertyCreatedFileInFolder"
                values={{
                    fileName: seriesName,
                    folderName,
                }}
            />
            {triggerLoadId && (
                <div>
                    <FormattedMessage id="Proceq.LogBookTestLoad" />
                    {`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
                </div>
            )}
            <div>
                <div>
                    <FormattedMessage id="Proceq.ExportFieldMaterial" />
                    {': '}
                    {getMaterialNameOfMaterialID(null, formatMessage, materialID)}
                </div>
                <div>
                    <FormattedMessage id="Proceq.ExportFieldStandard" />
                    {': '}
                    {getStandardNameOfStandardID(null, formatMessage, standardID, product)}
                </div>
                <div>
                    <FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
                    {': '}
                    {getScaleInfoOfScaleID(formatMessage, primaryEquotipScaleId, productModel).scaleName}
                </div>
                <div>
                    <FormattedMessage id="Proceq.ExportFieldSecondaryScale" />
                    {': '}
                    {getScaleInfoOfScaleID(formatMessage, secondaryScaleId, productModel).scaleName}
                </div>

                {minLimitValue && (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldLowerLimit" />
                        {': '}
                        {formatLimitValue(primaryEquotipScaleId, minLimitValue.primaryValue)}
                        {' / '}
                        {minLimitValue.secondaryValue ? (
                            formatLimitValue(minLimitValue.secondaryScaleId, minLimitValue.secondaryValue)
                        ) : (
                            <span>
                                <FormattedMessage id="Proceq.LogbookNoConversionValue" />{' '}
                                {
                                    getScaleInfoOfScaleID(formatMessage, minLimitValue.secondaryScaleId, productModel)
                                        .scaleUnits
                                }
                            </span>
                        )}
                    </div>
                )}
                {maxLimitValue && (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldUpperLimit" />
                        {': '}
                        {formatLimitValue(primaryEquotipScaleId, maxLimitValue.primaryValue)}
                        {' / '}
                        {maxLimitValue.secondaryValue ? (
                            formatLimitValue(maxLimitValue.secondaryScaleId, maxLimitValue.secondaryValue)
                        ) : (
                            <span>
                                <FormattedMessage id="Proceq.LogbookNoConversionValue" />{' '}
                                {
                                    getScaleInfoOfScaleID(formatMessage, maxLimitValue.secondaryScaleId, productModel)
                                        .scaleUnits
                                }
                            </span>
                        )}
                    </div>
                )}
            </div>
            <br />
            {renderMap()}
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
