import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { formatDateTimeDefault } from '../../../../../utils/dateUtils';

export const MarkerChange: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <div>
        <b>
            <FormattedMessage id="App.LogbookPropertyMarkerChange" />
        </b>
        <br />
        <FormattedMessage id="App.Logbook.PI8000.PileName" />
        {`: ${log.content.pileName}`}
        <br />
        <FormattedMessage id="App.Logbook.PI8000.PositionName" />
        {`: ${log.content.positionName}`}
        <br />
        <FormattedMessage id="App.Logbook.PI8000.MarkerName" />
        {`: ${log.content.markerName}`}
        <br />
        <FormattedMessage id="App.Logbook.PI8000.Event" />
        {`: ${log.content.event}`}
        <br />
        <FormattedMessage id="App.Logbook.PI8000.DeletedDate" />
        {`: ${log.content.event === 'deleted' ? formatDateTimeDefault(log.content.deletedAt) : '-'}`}
    </div>
);

export default {
    element: MarkerChange,
};
