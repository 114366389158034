import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { useFormatMessage } from '../../../../../localization/useFormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import {
    getResolutionForScale,
    getScaleInfoOfScaleID,
    getSchmidtScaleIdOfFromFactorAndUnitID,
} from '../../utils/conversionsHelper';
import { ROCK_SCHMIDT_PROBE } from '../../../../../types/measurement';
import { ProductCode } from '../../../../../types/proceq';

export const Limit: React.FunctionComponent<EquotipLogbookEntryProps> = (props) => {
    const {
        log,
        product,
        productModel,
        isVerificationMode,
        primaryEquotipScaleId,
        primarySchmidtScaleUnit,
        primaryVerificationScaleId,
    } = props;

    const formatMessage = useFormatMessage();

    let primaryUnits =
        primaryEquotipScaleId !== undefined
            ? getScaleInfoOfScaleID(formatMessage, primaryEquotipScaleId, productModel).scaleUnits
            : undefined;
    const secondaryUnits = getScaleInfoOfScaleID(
        formatMessage,
        log.content.oldLimitValue.secondaryScaleId,
        productModel
    ).scaleUnits;
    const newSecondaryUnits = getScaleInfoOfScaleID(
        formatMessage,
        log.content.updatedLimitValue.secondaryScaleId,
        productModel
    ).scaleUnits;
    let fromValue = '';
    let toValue = '';
    if (isVerificationMode) {
        primaryUnits =
            product === ProductCode.SCHMIDT
                ? primarySchmidtScaleUnit
                : primaryVerificationScaleId !== undefined
                  ? getScaleInfoOfScaleID(formatMessage, primaryVerificationScaleId, productModel).scaleUnits
                  : undefined;
        fromValue = `${getResolutionForScale(
            primaryUnits,
            log.content.oldLimitValue.primaryValue,
            'limit'
        )} ${primaryUnits}`;
        toValue = `${getResolutionForScale(
            primaryUnits,
            log.content.updatedLimitValue.primaryValue,
            'limit'
        )} ${primaryUnits}`;
    } else {
        switch (product) {
            case 'EQUOTIP':
                fromValue = `${getResolutionForScale(
                    primaryUnits,
                    log.content.oldLimitValue.primaryValue,
                    'limit'
                )} ${primaryUnits} / ${getResolutionForScale(
                    secondaryUnits,
                    log.content.oldLimitValue.secondaryValue,
                    'limit'
                )} ${secondaryUnits}`;
                toValue = `${getResolutionForScale(
                    primaryUnits,
                    log.content.updatedLimitValue.primaryValue,
                    'limit'
                )} ${primaryUnits} / ${getResolutionForScale(
                    newSecondaryUnits,
                    log.content.updatedLimitValue.secondaryValue,
                    'limit'
                )} ${newSecondaryUnits}`;

                break;
            case 'SCHMIDT':
                {
                    const oldScaleId = getSchmidtScaleIdOfFromFactorAndUnitID(
                        log.content.oldLimitValue.unitId,
                        log.content.oldLimitValue.formFactorId
                    );
                    const updatedScaleId = getSchmidtScaleIdOfFromFactorAndUnitID(
                        log.content.updatedLimitValue.unitId,
                        log.content.updatedLimitValue.formFactorId
                    );

                    const isRockSchmidt = productModel === ROCK_SCHMIDT_PROBE;
                    const fromScaleInfo = getScaleInfoOfScaleID(formatMessage, oldScaleId, productModel, isRockSchmidt);
                    const toScaleInfo = getScaleInfoOfScaleID(
                        formatMessage,
                        updatedScaleId,
                        productModel,
                        isRockSchmidt
                    );

                    fromValue = `${getResolutionForScale(
                        primarySchmidtScaleUnit,
                        log.content.oldLimitValue.primaryValue,
                        'limit'
                    )} ${primarySchmidtScaleUnit} / ${getResolutionForScale(
                        fromScaleInfo.scaleUnits,
                        log.content.oldLimitValue.secondaryValue,
                        'limit'
                    )} ${fromScaleInfo.scaleName}`;

                    toValue = `${getResolutionForScale(
                        primarySchmidtScaleUnit,
                        log.content.updatedLimitValue.primaryValue,
                        'limit'
                    )} ${primarySchmidtScaleUnit} / ${getResolutionForScale(
                        toScaleInfo.scaleUnits,
                        log.content.updatedLimitValue.secondaryValue,
                        'limit'
                    )} ${toScaleInfo.scaleName}`;
                }
                break;
            default:
                break;
        }
    }

    if (log.content.isLimitMax) {
        return (
            <span>
                <FormattedMessage
                    id="Proceq.LogbookPropertyMaxLimitChangedFromTo"
                    values={{
                        fromValue,
                        toValue,
                    }}
                />
            </span>
        );
    }
    return (
        <span>
            <FormattedMessage
                id="Proceq.LogbookPropertyMinLimitChangedFromTo"
                values={{
                    fromValue,
                    toValue,
                }}
            />
        </span>
    );
};

export default {
    element: Limit,
};
