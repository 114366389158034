import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const TrashFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.LogbookEntryTrashed"
        values={{
            folder: log.content.folder,
        }}
    />
);

export const color = () => 'grey';

export default {
    element: TrashFile,
    color,
};
