import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { getGridFromNumber } from '../../utils/getGridFromNumber';

const getCellInfo = (content: any) => {
    if (content?.startDirection === 'X') {
        return `${getGridFromNumber(content?.cellPosition?.x ?? 0)}${
            content?.cellPosition?.y !== undefined ? content.cellPosition.y + 1 : 0
        }`;
    }
    return `${getGridFromNumber(content?.cellPosition?.y ?? 0)}${
        content?.cellPosition?.x !== undefined ? content.cellPosition.x + 1 : 0
    }`;
};

const LineScanDeleted: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="App.HTML.Profometer.Logbook.LineScanDeleted"
        values={{
            scanInfo: log.type === 'cellDeleted' ? getCellInfo(log.content) : log.content.sequence,
        }}
    />
);

export default {
    element: LineScanDeleted,
};
