import React from 'react';
import { EquotipLogbookEntryProps, Log } from '../../../../../types/logs';
import elementGenericLogEvent from '../GenericLogEvent';
import elementCreation from './Creation';
import elementProbe from '../shared/Probe';
import elementFavourite from './Favourite';
import elementInitialInfo from './InitialInfo';
import elementResult from './Result';
import elementLimit from './Limit';
import elementScale from './Scale';
import elementMaterial from './Material';
import elementStandard from './Standard';
import elementMeasurement from './Measurement';
import elementTestBlock from './TestBlock';
import elementTestLoad from './TestLoad';
import getGPRLogElement from '../GPR/getGPRLogElement';

enum EquotipLogType {
    Creation = 'Creation',
    Probe = 'Probe',
    TrashFile = 'TrashFile',
    Favourite = 'Favourite',
    RenameFile = 'RenameFile',
    UserAudio = 'UserAudio',
    InitialInfo = 'InitialInfo',
    Result = 'Result',
    UserPhoto = 'UserPhoto',
    Limit = 'Limit',
    Scale = 'Scale',
    UserText = 'UserText',
    Material = 'Material',
    Standard = 'Standard',
    Measurement = 'Measurement',
    TestBlock = 'TestBlock',
    MoveFile = 'MoveFile',
    TestLoad = 'TestLoad',
    ImportFile = 'ImportFile',
    ChangeFile = 'ChangeFile',
    ChangeFileUnit = 'ChangeFileUnit',
    RestoreFile = 'RestoreFile',
    Archive = 'Archive',
}

export interface EquotipEventLogElement {
    element: React.FunctionComponent<
        EquotipLogbookEntryProps & {
            author?: string;
        }
    >;
    icon?: (log: Log) => string;
    color?: (log: Log) => string;
}

const getEquotipLogElement = (logType: string | EquotipLogType): EquotipEventLogElement => {
    switch (logType) {
        case EquotipLogType.Creation:
            return elementCreation;
        case EquotipLogType.Probe:
            return elementProbe;
        case EquotipLogType.Favourite:
            return elementFavourite;
        case EquotipLogType.InitialInfo:
            return elementInitialInfo;
        case EquotipLogType.Result:
            return elementResult;
        case EquotipLogType.Limit:
            return elementLimit;
        case EquotipLogType.Scale:
            return elementScale;
        case EquotipLogType.Material:
            return elementMaterial;
        case EquotipLogType.Standard:
            return elementStandard;
        case EquotipLogType.Measurement:
            return elementMeasurement;
        case EquotipLogType.TestBlock:
            return elementTestBlock;
        case EquotipLogType.TestLoad:
            return elementTestLoad;
        case EquotipLogType.TrashFile:
        case EquotipLogType.MoveFile:
        case EquotipLogType.RenameFile:
        case EquotipLogType.ImportFile:
        case EquotipLogType.ChangeFile:
        case EquotipLogType.ChangeFileUnit:
        case EquotipLogType.RestoreFile:
        case EquotipLogType.UserAudio:
        case EquotipLogType.UserPhoto:
        case EquotipLogType.UserText:
            return getGPRLogElement(logType);
        default:
            return elementGenericLogEvent;
    }
};

export default getEquotipLogElement;
