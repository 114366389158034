import React from 'react';
import { useIntl } from 'react-intl';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { EquotipLogbookEntryProps } from '../../../../../types/logs';
import { getLabelFromGlobalDataKey } from '../../../RegisteredInfo/helper';
import {
    getResolutionForScale,
    getScaleInfoOfScaleID,
    getSchmidtScaleIdOfFromFactorAndUnitID,
    getStandardNameOfStandardID,
} from '../../utils/conversionsHelper';
import StaticMap from '../StaticMap';
import { ROCK_SCHMIDT_PROBE } from '../../../../../types/measurement';

export const Creation: React.FunctionComponent<EquotipLogbookEntryProps> = ({
    log,
    product,
    productModel,
    isVerificationMode,
    primarySchmidtScaleUnit,
}) => {
    const formatMessage = useIntl().formatMessage;
    const isRockSchmidt = productModel === ROCK_SCHMIDT_PROBE;
    const formatPrimaryLimitValue = (value: string) =>
        `${getResolutionForScale(primarySchmidtScaleUnit, value, 'limit', false, product)} ${primarySchmidtScaleUnit}`;
    const formatSecondaryLimitValue = (scale: number | undefined, value: string) =>
        `${getResolutionForScale(
            getScaleInfoOfScaleID(formatMessage, scale, productModel, isRockSchmidt).scaleUnits,
            value,
            'limit',
            false,
            product
        )} ${getScaleInfoOfScaleID(formatMessage, scale, productModel, isRockSchmidt).scaleName}`;

    const {
        // btSerialNumber,
        folderName,
        maxLimitValue,
        minLimitValue,
        seriesName,
        standardID,
        triggerLoadId,

        // schmidt
        unitId,
        formFactorId,
        conversionCurveId,
        correctionFactor,
        testBlockTitle,
    } = log.content.measurementInfo;

    const renderMap = () => {
        const { latitude, longitude } = log.content;

        if (!longitude && !latitude) {
            return null;
        }
        return (
            <>
                <br />
                <StaticMap coordinates={{ lat: +latitude, lng: +longitude }} />
            </>
        );
    };

    if (isVerificationMode) {
        return (
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyCreatedVerification" values={{ seriesName }} />
                {triggerLoadId && (
                    <div>
                        <FormattedMessage id="Proceq.LogBookTestLoad" />
                        {`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
                    </div>
                )}
                <div>
                    <FormattedMessage id="Proceq.ExportFieldStandard" />
                    {': '}
                    {getStandardNameOfStandardID(null, formatMessage, standardID, product)}
                </div>
                {testBlockTitle && (
                    <div>
                        {product === 'EQUOTIP' ? (
                            <div>
                                <FormattedMessage id="Proceq.LogBookTestBlock" />
                                {': '}
                                {testBlockTitle}
                            </div>
                        ) : (
                            <div>
                                <FormattedMessage id="Proceq.LogBookCalibrationAnvil" />
                                {': '}
                                {testBlockTitle}
                            </div>
                        )}
                    </div>
                )}
                <div>
                    <FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
                    {': '}
                    {primarySchmidtScaleUnit}
                </div>
                {minLimitValue && (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldLowerLimit" />
                        {': '}
                        {formatPrimaryLimitValue(minLimitValue.primaryValue)}
                    </div>
                )}
                {maxLimitValue && (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldUpperLimit" />
                        {': '}
                        {formatPrimaryLimitValue(maxLimitValue.primaryValue)}
                    </div>
                )}
                {renderMap()}
            </div>
        );
    }

    const targetScaleId = getSchmidtScaleIdOfFromFactorAndUnitID(unitId, formFactorId);

    return (
        <div>
            <FormattedMessage
                id="Proceq.LogbookPropertyCreatedFileInFolder"
                values={{ fileName: seriesName, folderName }}
            />
            {triggerLoadId && (
                <div>
                    <FormattedMessage id="Proceq.LogBookTestLoad" />
                    {`: HV${triggerLoadId} (${triggerLoadId}kgf)`}
                </div>
            )}
            <div>
                <FormattedMessage id="Proceq.ExportFieldStandard" />
                {': '}
                {getStandardNameOfStandardID(null, formatMessage, standardID, product)}
                {conversionCurveId !== undefined ? (
                    <div>
                        <FormattedMessage id="Proceq.ExportFieldConversionCurve" />
                        {': '}
                        {formatMessage({
                            id: getLabelFromGlobalDataKey('convcurveschmidt', conversionCurveId) ?? conversionCurveId,
                        })}
                    </div>
                ) : (
                    <br />
                )}
                {productModel === 'SSL' && correctionFactor !== undefined && (
                    <>
                        <FormattedMessage id="Proceq.LogbookPropertyCorrectionFactor" />
                        {': '}
                        {correctionFactor}
                        <br />
                    </>
                )}
                <FormattedMessage id="Proceq.ExportFieldPrimaryScale" />
                {': '}
                {primarySchmidtScaleUnit}
                <br />
                <FormattedMessage id="Proceq.ExportFieldTargetScale" />
                {': '}
                {getScaleInfoOfScaleID(formatMessage, targetScaleId, productModel, isRockSchmidt).scaleName}
                <br />
                <FormattedMessage id="Proceq.ExportFieldLowerLimit" />
                {': '}
                {formatPrimaryLimitValue(minLimitValue.primaryValue)}
                {' / '}
                {formatSecondaryLimitValue(targetScaleId, minLimitValue.secondaryValue)}
                <br />
                <FormattedMessage id="Proceq.ExportFieldUpperLimit" />
                {': '}
                {formatPrimaryLimitValue(maxLimitValue.primaryValue)}
                {' / '}
                {formatSecondaryLimitValue(targetScaleId, maxLimitValue.secondaryValue)}
                <br />
            </div>
            {renderMap()}
        </div>
    );
};

export const color = () => 'green';

export default {
    element: Creation,
    color,
};
