import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import profile, { ProfileState } from './profile';
import user, { UserState } from './user';
import probe, { ProbeState } from './probe';
import measurement, { MeasurementState } from './measurement';
import config, { ConfigState } from './config';
import actionTypes from '../actions/actionTypes';
import contract, { ContractState } from './contract';
import notification, { NotificationState } from './notification';

const profilePersistConfig = {
    key: 'profile',
    storage,
    whitelist: ['userProfile', 'regionInfo'],
};

export interface RootState {
    profile: ProfileState;
    measurement: MeasurementState;
    probe: ProbeState;
    user: UserState;
    config: ConfigState;
    contract: ContractState;
    notification: NotificationState;
}

const rootReducer = combineReducers({
    profile: persistReducer(profilePersistConfig, profile),
    measurement,
    probe,
    user,
    config,
    contract,
    notification,
});

const myRootReducer: typeof rootReducer = (state, action) => {
    // Clean everything in store to default state
    if (action.type === actionTypes.CLEAN_UP) {
        state = undefined;
    }
    return rootReducer(state, action);
};

export default myRootReducer;
