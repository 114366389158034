import React from 'react';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { FormatIDs } from '../../../../../types';

const LOG_TYPE_NAME: Record<string, FormatIDs> = {
    ambientTempChanged: 'App.HTML.Profometer.Logbook.ConcreteTemperature',
    potentialShiftChanged: 'App.HTML.Profometer.Logbook.PotentialShift.NoUnits',
};

const SettingsChanged: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="App.HTML.Profometer.Logbook.SettingsChanged"
        values={{
            oldValue: log.content.oldValue,
            newValue: log.content.newValue,
            settings: <FormattedMessage id={LOG_TYPE_NAME[log.type] ?? ' '} />,
        }}
    />
);

export default {
    element: SettingsChanged,
};
