import type { AliasToken } from 'antd/es/theme/interface';
import { ThemeConfig } from 'antd';
import colors from './colors.json';
import fonts from './fonts.json';

// Returns the box shadow string with 20% opacity
const getBoxShadow = (color: string) => `0 0 0 2px ${color}32`;

const SEED_TOKEN: Partial<AliasToken> = {
    colorTextLightSolid: colors.black,
    colorBgBase: colors.dark_hover,
    colorTextBase: colors.white,
    colorPrimary: colors.primary,
    colorInfo: colors.primary,
    colorLink: colors.primary,
    colorBgElevated: colors.gray100,
    controlItemBgHover: colors.dark_hover,
    controlItemBgActive: colors.dark_hover,
    controlItemBgActiveHover: colors.dark_hover,
    colorBgMask: colors.dark_mask,
    fontFamily: fonts.fontFamily,
    controlHeight: 40,
    controlInteractiveSize: 16,
    borderRadius: 2,
};

export const THEME_CONFIG: ThemeConfig = {
    components: {
        Layout: { colorBgLayout: 'white', headerBg: 'white', siderBg: 'white' },
        Collapse: { headerPadding: 0, marginSM: 0 },
        Select: {
            optionSelectedBg: colors.primary,
            optionSelectedColor: colors.black,
        },
        Button: {
            primaryShadow: 'none',
            borderColorDisabled: colors.grey,
            primaryColor: colors.black,
            colorTextDisabled: colors.grey,
            colorBgContainerDisabled: 'transparent',
            fontWeight: 500,
            controlHeight: 40,
        },
        Table: {
            rowSelectedBg: colors.dark_hover,
            rowSelectedHoverBg: colors.gray300,
            rowExpandedBg: colors.gray100,
            colorBgContainer: colors.dark_background,
            bodySortBg: colors.dark_hover,
            headerSortActiveBg: colors.dark_hover,
            rowHoverBg: colors.dark_hover,
        },
        Tooltip: {
            colorBgSpotlight: colors.gray300,
        },
        Modal: {
            contentBg: colors.gray200,
            footerBg: colors.gray200,
            headerBg: colors.gray200,
        },
        Input: {
            colorBorder: colors.gray300,
            activeBg: colors.dark_background,
            hoverBg: colors.dark_background,
            colorBgContainer: colors.dark_background,
            activeShadow: getBoxShadow(colors.primary),
            errorActiveShadow: getBoxShadow(colors.red600),
        },
        InputNumber: {
            colorBorder: colors.gray300,
            activeBg: colors.dark_background,
            colorBgContainer: colors.dark_background,
            hoverBg: colors.dark_background,
            activeShadow: getBoxShadow(colors.primary),
            errorActiveShadow: getBoxShadow(colors.red600),
        },
        Checkbox: {
            colorBgContainer: colors.dark_background,
        },
        Card: {
            headerBg: colors.gray100,
            colorBgContainer: colors.dark_background,
            colorBorderSecondary: colors.gray300,
        },
    },
    token: SEED_TOKEN,
};
