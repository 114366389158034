import React, { Fragment } from 'react';
import { upperFirst } from 'lodash';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { FormatIDs } from '../../../../../types';

export const GenericLogEvent: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => {
    const logEventType = upperFirst(log.type);
    const content = log.content;
    return (
        <div>
            <b>
                <FormattedMessage id={`App.LogbookProperty${logEventType}` as FormatIDs} />
            </b>
            <br />
            {Object.keys(content).map((key, index) => {
                const formattedMessageID = `App.Logbook.PI8000.${upperFirst(key)}`;
                let value = content[key];
                if (Array.isArray(value)) {
                    value = `[${value}]`;
                }
                return (
                    <Fragment key={index}>
                        {index > 0 && <br />}
                        <FormattedMessage id={formattedMessageID as FormatIDs} />
                        {`: ${value}`}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default {
    element: GenericLogEvent,
};
