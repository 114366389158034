import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Sidebar.styl';
import { ReactComponent as HomeIcon } from '../../images/homeIcon.svg';
import routeURLs, { getAppRoute, getDataViewRoute, getToolRoute } from '../Routes/urls';
import AppIcon, { productCodeToIconMap, ProductName } from '../shared/AppIcon';
import { classNames } from '../../utils/styleUtils';
import { useAppsStatus } from '../../hooks/useConfig';
import analytics from '../../analytics/firebaseAnalytics';
import { ProductCode } from '../../types/proceq';
import { useProductContext } from '../DataView/ProductContextProvider';
import { getActiveApps } from '../../api/appService';

interface SidebarProps {
    visible?: boolean;
}

const Sidebar: React.FunctionComponent<SidebarProps> = (props) => {
    const { visible = true } = props;
    const navigate = useNavigate();
    const { pathname } = location;
    const { product: currentProduct } = useProductContext();

    const { appsList, appsStatus } = useAppsStatus();

    const iconList = useMemo(() => {
        const navigateToInsights = async () => {
            await getActiveApps();
            const productAppStatus = appsStatus[ProductCode.GPR_INSIGHTS];
            window.name = 'workspace';
            if (productAppStatus && productAppStatus.url) {
                window.open(getAppRoute(ProductName.GPRInsights), 'insights');
            } else {
                navigate(getToolRoute(ProductName.GPRInsights));
            }
        };
        return appsList.map((productCode) => {
            const productName = productCodeToIconMap[productCode] || productCode;
            if (productName === ProductName.Inspect) {
                return (
                    <a
                        href={appsStatus.INSPECT?.url}
                        target="_blank"
                        rel="noreferrer"
                        key={productName}
                        onClick={() => analytics.logSidebarSwitchProduct(productName)}
                    >
                        <div className={classNames(styles.icon_wrapper)}>
                            <AppIcon product={ProductName.Inspect} />
                        </div>
                    </a>
                );
            }
            if (productName) {
                const appIcon = (
                    <div
                        className={classNames(
                            styles.icon_wrapper,
                            currentProduct === productCode && styles.icon_wrapper_active
                        )}
                    >
                        <AppIcon
                            product={productName}
                            disabled={!appsStatus[productCode]?.active && !appsStatus[productCode]?.eligibleFreeTrial}
                        />
                    </div>
                );
                if (productCode === ProductCode.GPR_INSIGHTS) {
                    return (
                        <div
                            key={productName}
                            className={styles.insights_container}
                            onClick={() => {
                                analytics.logSidebarSwitchProduct(productCode);
                                navigateToInsights();
                            }}
                        >
                            {appIcon}
                        </div>
                    );
                }
                return (
                    <Link
                        to={getDataViewRoute({ productName })}
                        key={productName}
                        onClick={() => analytics.logSidebarSwitchProduct(productCode)}
                    >
                        {appIcon}
                    </Link>
                );
            }
            return null;
        });
    }, [appsList, appsStatus, currentProduct, navigate]);

    return (
        <div className={visible ? styles.sidebar : styles.sidebar_hidden}>
            <Link to={routeURLs.DASHBOARD}>
                <div
                    className={classNames(
                        styles.icon_wrapper,
                        pathname === routeURLs.DASHBOARD && styles.icon_wrapper_active
                    )}
                >
                    <HomeIcon />
                </div>
            </Link>
            {iconList}
        </div>
    );
};

export default Sidebar;
