import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const RenameFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.LogbookEntryRenamed"
        values={{
            from: log.content.old,
            to: log.content.new,
        }}
    />
);

export default {
    element: RenameFile,
};
