import { ScanType } from '../../../../../types/proceq';

const getSequenceNumber = (
    scanType: ScanType,
    startDirection?: string,
    scanPosition?: {
        dimension: string;
        sequence: number;
    },
    areaScanParameters?: any
) => {
    if (scanType === ScanType.LineScan || !(startDirection && scanPosition && areaScanParameters)) return 1;
    if (startDirection === scanPosition.dimension) return scanPosition.sequence;
    const additionalLines =
        scanPosition.dimension === 'X'
            ? areaScanParameters.numberOfVerticalLines
            : areaScanParameters.numberOfHorizontalLines;
    return additionalLines + scanPosition.sequence;
};

export default getSequenceNumber;
