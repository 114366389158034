import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { classNames } from '../../utils/styleUtils';
import './TableWrapper.global.styl';

interface TableWrapperProps {
    className?: string;
    children: (height: number | undefined, width: number | undefined) => React.ReactNode;
}

const TableWrapper: React.FunctionComponent<TableWrapperProps> = (props) => {
    const { className, children } = props;
    const {
        height: tableContainerHeight,
        width: tableContainerWidth,
        ref: tableContainer,
    } = useResizeDetector<HTMLDivElement>();

    return (
        <div className={classNames('app-table-wrapper', className)} ref={tableContainer}>
            {children(tableContainerHeight, tableContainerWidth)}
        </div>
    );
};

export default TableWrapper;
