import React from 'react';
import PropertiesTable from '../../DataViewers/Readings/PropertiesTable';
import FormattedMessage from '../../../../localization/FormatMessage';
import CustomCurveLabel from '../CustomCurveLabel';
import {
    CIRCULAR_REQUIRED_FIELDS,
    CustomCurveFormFieldNames,
    CustomCurveProbeInfo,
    RECTANGULAR_REQUIRED_FIELDS,
    TRANSDUCER_TYPE_MAP,
    TransducerType,
} from '../../../../types/customCurve';
import { useFormatMessage } from '../../../../localization/useFormatMessage';

interface CurveReadingsProps {
    probeInfo: CustomCurveProbeInfo;
}

const CurveReadings: React.FunctionComponent<CurveReadingsProps> = ({ probeInfo }) => {
    const formatMessage = useFormatMessage();
    const requiredFields =
        probeInfo.transducerType === TransducerType.CIRCULAR_CRYSTAL
            ? CIRCULAR_REQUIRED_FIELDS
            : RECTANGULAR_REQUIRED_FIELDS;
    const data = requiredFields.map((field) => ({
        key: field,
        label: <CustomCurveLabel prefixTransducerFields fieldName={field} includeUnits />,
        value:
            field === CustomCurveFormFieldNames.transducerType
                ? formatMessage({ id: TRANSDUCER_TYPE_MAP[probeInfo[field] as TransducerType] })
                : probeInfo[field],
    }));

    return (
        <PropertiesTable title={<FormattedMessage id="DataView.Upload.Preview.CurveProbeInfo" />} properties={data} />
    );
};

export default CurveReadings;
