import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';
import { ConvertedUnits } from '../../shared/ConvertedUnits';
import { ScanType } from '../../../../../types/proceq';

export const CloseFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ product, log, scanType, isMetric }) => {
    if (isMetric === undefined || scanType === undefined) {
        return null;
    }

    return (
        <div>
            <FormattedMessage id="Proceq.LogbookEntryMeasurementEnd" />
            <br />
            {scanType === ScanType.GridScan ? (
                <FormattedMessage id="Proceq.LogbookEntryNumberOfScans" values={{ value: log.content.numberOfScan }} />
            ) : (
                <ConvertedUnits
                    id="Proceq.LogbookEntryMeasurementEndTotalLength"
                    unitId={`${product}.Logbook Panel.Measurement ended. Total length`}
                    scanType={scanType}
                    isMetric={isMetric}
                    values={[log.content.distance]}
                />
            )}
            <br />
            {scanType === 'Area Scan' && (
                <FormattedMessage
                    id="Proceq.LogbookEntryMeasurementEndNoOfLines"
                    values={{
                        count: log.content.numberOfScan,
                    }}
                />
            )}
        </div>
    );
};

export default {
    element: CloseFile,
};
