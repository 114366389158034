import React, { useMemo } from 'react';
import { ProbeImage } from './ProbeImage';
import styles from './ProbeDetails.styl';
import { useProbe } from '../../hooks/useProbes';
import { EquotipProbeContent } from '../DataView/DataViewers/LogEvent/Equotip/EquotipProbeContent';
import { GPRProbeContent } from '../DataView/DataViewers/LogEvent/GPR/GPRProbeContent';
import { GLMProbeContent } from '../DataView/DataViewers/LogEvent/GLM/GLMProbeContent';
import { bluetoothProductFamilySet, ProductModel, productModelToFamilyMap } from '../../types/proceq';

interface ProbeDetailsProps {
    probeID: string;
}

const ProbeDetails: React.FunctionComponent<ProbeDetailsProps> = (props) => {
    const { probeID } = props;
    const probeInfo = useProbe(probeID);
    const productModel = probeInfo?.pModel.toUpperCase() as ProductModel;

    const probeContent = useMemo(() => {
        if (!probeInfo || !productModel) {
            return null;
        }

        if (bluetoothProductFamilySet.has(productModelToFamilyMap[productModel])) {
            if (productModel === 'GLM') {
                return <GLMProbeContent content={probeInfo} productModel={productModel} />;
            }
            return <EquotipProbeContent content={probeInfo} productModel={productModel} />;
        }
        return <GPRProbeContent content={probeInfo} productModel={productModel} />;
    }, [productModel, probeInfo]);

    if (!probeInfo) {
        return null;
    }

    return (
        <div className={styles.probe_details}>
            <ProbeImage name={probeInfo.name} pModel={probeInfo.pModel} width={112} height={112} />
            <div className={styles.text_content}>{probeContent}</div>
        </div>
    );
};

export default ProbeDetails;
