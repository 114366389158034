import { keyBy } from 'lodash';

export type NormalizerReturnType<T> = { entities: { [key: string]: T }; entityIds: string[] };

export const normalizer: <T extends {}>(data: T[], key?: keyof T | 'id') => NormalizerReturnType<T> = (
    data,
    key = 'id'
) => {
    const entities = keyBy(data, key);
    const entityIds = (data ?? []).map((obj: any) => obj[key].toString() || []);
    return { entities, entityIds };
};
