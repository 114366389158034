import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../DataViewers.styl';
import SectionTable from '../../shared/SectionTable';
import { MeasurementFullData } from '../../../../../types/measurement';
import { DerivedProps } from '../../SharedTypes';
import { getConvertedSectionTableData, RawSectionTableData } from '../../../../../utils/tableDataUtils';

export const title = (
    <>
        <FormattedMessage id="Proceq.ExportFieldSettings" />
        {' ­­» '}
        <FormattedMessage id="App.HTML.PI8000.Settings.GridInformation" />
    </>
);

const GridInfo: React.FunctionComponent<{ data: MeasurementFullData } & DerivedProps> = ({
    data,
    scanType,
    isMetric,
    showTitle,
}) => {
    const gridRaw: RawSectionTableData[] = [
        {
            label: 'App.HTML.PI8000.Settings.GridInformation.Rows',
            value: data.measurement.content.info?.gridInfo?.rows,
        },
        {
            label: 'App.HTML.PI8000.Settings.GridInformation.Columns',
            value: data.measurement.content.info?.gridInfo?.columns,
        },
        {
            label: 'App.HTML.PI8000.Settings.GridInformation.CellWidth',
            value: data.measurement.content.info?.gridInfo?.cellWidth,
            unitId: 'PIT_IE.Grid.CellWidthHeight',
        },
        {
            label: 'App.HTML.PI8000.Settings.GridInformation.CellHeight',
            value: data.measurement.content.info?.gridInfo?.cellHeight,
            unitId: 'PIT_IE.Grid.CellWidthHeight',
        },
    ];

    const grid = getConvertedSectionTableData(gridRaw, scanType, isMetric);

    return (
        <SectionTable
            showTitle={showTitle}
            title={<span className={styles.sub_header}>{title}</span>}
            dataSource={grid}
        />
    );
};

export default GridInfo;
