function isMacWithTouch(): boolean {
    return !!navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /Macintosh/.test(navigator.userAgent);
}

export function isIPad(): boolean {
    // In Safari, it is defaulted to use desktop web, thus need to check for mac with touch
    return /iPad/.test(navigator.userAgent) || isMacWithTouch();
}

export function isIPhone(): boolean {
    return /iPhone/.test(navigator.userAgent);
}

export const isTablet = () => {
    return (
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            navigator.userAgent.toLowerCase()
        ) || isMacWithTouch()
    );
};
