import React from 'react';

export interface Handler {
    action?: () => void;
}

interface ImportMeasurementContextType {
    viewFilesHandler: Handler;
    setViewFilesHandler: (handler: Handler) => void;
}

const ImportMeasurementContext = React.createContext<ImportMeasurementContextType>({
    viewFilesHandler: {},
    setViewFilesHandler: () => {},
});

export default ImportMeasurementContext;
