import getGPRLogElement, { EventLogElement } from '../GPR/getGPRLogElement';
import elementCreation from './Creation';
import elementSettingsEnabled from './SettingsEnabled';
import elementRebarChanged from './RebarChanged';
import elementCoverEnabled from './CoverEnabled';
import elementCoverChanged from './CoverChanged';
import elementMeasurementRebarChanged from './MeasurementRebarChanged';
import elementZeroingCompleted from './ZeroingCompleted';
import elementSettingsChanged from './SettingsChanged';
import elementLineScanDeleted from './LineScanDeleted';

enum ProfometerLogType {
    Creation = 'Creation',
    NrcEnabled = 'NrcEnabled',
    RebarChanged = 'RebarChanged',
    MaxCoverEnabled = 'MaxCoverEnabled',
    MinCoverEnabled = 'MinCoverEnabled',
    MaxCoverChanged = 'MaxCoverChanged',
    MinCoverChanged = 'MinCoverChanged',
    MeasurementRebarChanged = 'MeasurementRebarChanged',
    ZeroingCompleted = 'ZeroingCompleted',
    AmbientTempChanged = 'AmbientTempChanged',
    AutoPotentialShiftEnabled = 'AutoPotentialShiftEnabled',
    PotentialShiftChanged = 'PotentialShiftChanged',
    CellDeleted = 'CellDeleted',
    LineDeleted = 'LineDeleted',
}

const getPunditLogElement = (logType: string | ProfometerLogType): EventLogElement => {
    switch (logType) {
        case ProfometerLogType.Creation:
            return elementCreation;
        case ProfometerLogType.AutoPotentialShiftEnabled:
        case ProfometerLogType.NrcEnabled:
            return elementSettingsEnabled;
        case ProfometerLogType.RebarChanged:
            return elementRebarChanged;
        case ProfometerLogType.MaxCoverEnabled:
        case ProfometerLogType.MinCoverEnabled:
            return elementCoverEnabled;
        case ProfometerLogType.MaxCoverChanged:
        case ProfometerLogType.MinCoverChanged:
            return elementCoverChanged;
        case ProfometerLogType.MeasurementRebarChanged:
            return elementMeasurementRebarChanged;
        case ProfometerLogType.ZeroingCompleted:
            return elementZeroingCompleted;
        case ProfometerLogType.AmbientTempChanged:
        case ProfometerLogType.PotentialShiftChanged:
            return elementSettingsChanged;
        case ProfometerLogType.LineDeleted:
        case ProfometerLogType.CellDeleted:
            return elementLineScanDeleted;
        default:
            return getGPRLogElement(logType);
    }
};

export default getPunditLogElement;
