import type { MeasurementObjectWithInfo } from './Objects';
import { MeasurementFullData } from '../../../../../types/measurement';
import { ProductCode } from '../../../../../types/proceq';
import { renderComment } from '../GPR/getMarkersData';
import { MarkersColumnRaw } from '../shared/Markers';

export const getMarkersTableData = (data: MeasurementFullData, product: ProductCode) => {
    // we only want to use the most recent log entry
    const sortedLogs = data.logs.slice().sort((a, b) => b.clientCreated - a.clientCreated);

    const markers: MeasurementObjectWithInfo[] = data.objects
        .filter((object) => object.type === 'marker')
        .map((object) => {
            const reading = data.readings.find((r) => r.id === object.rID);

            const log = sortedLogs.find((l) => +l.content.sequence === +object.content.number);

            return {
                ...object,
                readingContent: reading ? reading.content : {},
                logContent: log ? log.content : {},
            };
        })
        .sort((a, b) => {
            // this is actually the last to be sorted, applies only when type is sorted
            if (a.content.type === b.content.type) {
                return a.content.number - b.content.number;
            }

            // then sort by tag type
            return a.content.type === 'Text' ? -1 : 1;
        });

    const columnsRaw: MarkersColumnRaw[] = [
        {
            title: 'App.HTML.GPR.Markers.MarkerNumber',
            render: (text: string, record: MeasurementObjectWithInfo) =>
                `${record.content.type === 'Text' ? 'M' : 'V'}${record.content.number}`,
        },
        {
            title: 'App.HTML.GPR.Objects.DistanceX',
            unitId: `${product}.CSV.Marker distance X`,
            dataIndex: ['content', 'distanceMeter'],
        },
        {
            title: 'App.HTML.GPR.Objects.DistanceY',
            unitId: `${product}.CSV.Marker distance Y`,
        },
        {
            title: 'Proceq.LogbookSettingsComment',
            dataIndex: ['content', 'content'],
            render: (text: string, record: MeasurementObjectWithInfo) => {
                return renderComment(text, record, data);
            },
        },
    ];
    return { markers, columnsRaw };
};
