import { flatten } from 'lodash';
import { GLOBAL_REGISTERED_INFO, GlobalInfoKey } from './data';

function getGlobalRegister(key: GlobalInfoKey): { [key: string]: string | undefined } {
    return GLOBAL_REGISTERED_INFO[key] ?? {};
}

export function getLabelFromGlobalDataKey(key: GlobalInfoKey, data: string | number | undefined): string | null {
    return getGlobalRegister(key)[String(data)] ?? null;
}

export function getTextValue(keys: GlobalInfoKey[]): { text: string; value: any }[] {
    return flatten(
        keys.map((key) =>
            Object.entries(getGlobalRegister(key)).map(([value, text]) => ({ value, text: text as string }))
        )
    );
}
