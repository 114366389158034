import React, { useEffect } from 'react';
import { IntlFormatters, useIntl, MessageDescriptor } from 'react-intl';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { getDefaultLanguage, LocalizedMessage } from './index';

export interface ProxyMessageDescriptor extends MessageDescriptor {
    id: keyof LocalizedMessage;
}

interface IntlProxyObject {
    locale: string;
    formatMessage: (
        params: ProxyMessageDescriptor,
        values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
    ) => string;
    formatMessageSource?: IntlFormatters['formatMessage'];
}

export const intlProxy: IntlProxyObject = {
    locale: getDefaultLanguage(),
    formatMessage: (params, values) => {
        if (intlProxy.formatMessageSource) {
            return intlProxy.formatMessageSource(params, values);
        }
        return '';
    },
    formatMessageSource: undefined,
};

const IntlProxy: React.FunctionComponent = () => {
    const { formatMessage, locale } = useIntl();

    useEffect(() => {
        intlProxy.locale = locale;
    }, [locale]);

    useEffect(() => {
        intlProxy.formatMessageSource = formatMessage;
        return () => {
            intlProxy.formatMessageSource = undefined;
        };
    }, [formatMessage]);

    return null;
};

export default IntlProxy;
