import React from 'react';
import styles from './UserText.styl';
import { ScanType } from '../../../../../types/proceq';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { formatDateTimeDefault } from '../../../../../utils/dateUtils';
import { FormatIDs } from '../../../../../types';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';

const getTitle = (log: Log, scanType: ScanType): FormatIDs => {
    const isArea = scanType === 'Area Scan';
    switch (log.content.status) {
        case 'added':
            return isArea ? 'Proceq.LogbookMarkerTextAddedAtLine' : 'Proceq.LogbookMarkerTextAdded';
        case 'edited':
            return isArea ? 'Proceq.LogbookMarkerTextEditedAtLine' : 'Proceq.LogbookMarkerTextEdited';
        case 'deleted':
            return isArea ? 'Proceq.LogbookMarkerTextDeletedAtLine' : 'Proceq.LogbookMarkerTextDeleted';
        default:
            return ' ';
    }
};

export const MarkerText: React.FunctionComponent<GPRLogbookEntryProps> = ({ log, scanType }) => {
    if (scanType === undefined) {
        return null;
    }

    const titleID = getTitle(log, scanType);
    return (
        <div>
            {log.content.status === 'deleted' ? (
                <div className={styles.deleted}>
                    <FormattedMessage
                        id={titleID}
                        values={{
                            marker: log.content.sequence,
                            lineId: log.content.lineId,
                            date: formatDateTimeDefault(log.clientCreated),
                        }}
                    />
                </div>
            ) : (
                <b>
                    <FormattedMessage
                        id={titleID}
                        values={{
                            marker: log.content.sequence,
                            lineId: log.content.lineId,
                        }}
                    />
                </b>
            )}

            {log.content.status === 'edited' && (
                <>
                    <br />
                    {log.content.content}
                </>
            )}
        </div>
    );
};

export const color = (log: Log) => (log.content.status === 'deleted' ? 'red' : 'green');

export default {
    element: MarkerText,
    color,
};
