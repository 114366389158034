import { NavigatorYAxisOptions } from 'highcharts';
import { TooltipProps } from 'react-jsx-highcharts';
import colors from '../../../../styles/colors.json';

export const tooltipStyleProps: TooltipProps = {
    backgroundColor: colors.gray300,
    borderWidth: 0,
    padding: 12,
    style: {
        color: 'currentColor',
        fontSize: '14px',
        lineHeight: '24px',
        borderRadius: 4,
    },
};

export const labelStyleConfig = { style: { color: 'currentColor' } };

export const plotOptions: Highcharts.PlotOptions = {
    column: {
        colorByPoint: true,
        stacking: 'normal',
    },
};

export const getYAxisStyleProps = (args: {
    thresholdLoValue: number | null;
    thresholdHiValue: number | null;
}): NavigatorYAxisOptions => {
    const { thresholdLoValue, thresholdHiValue } = args;

    if (thresholdLoValue !== null && thresholdHiValue !== null) {
        return {
            gridLineColor: 'rgba(209, 209, 209, 0.5)',
            plotBands: [
                {
                    from: thresholdLoValue,
                    to: thresholdHiValue,
                    color: 'rgba(255,255,255, 0.1)',
                },
            ],
            plotLines: [
                {
                    color: 'rgba(209, 209, 209, 0.5)',
                    dashStyle: 'ShortDash',
                    value: thresholdLoValue,
                    width: 2,
                },
                {
                    color: 'rgba(209, 209, 209, 0.5)',
                    dashStyle: 'ShortDash',
                    value: thresholdHiValue,
                    width: 2,
                },
            ],
        };
    }
    return {};
};

export const chartColors = [
    colors.purple600,
    colors.blue600,
    colors.teal600,
    colors.magenta700,
    colors.red700,
    colors.yellow700,
    colors.green700,
    colors.blue800,
];
