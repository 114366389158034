import React from 'react';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { GPRLogbookEntryProps } from '../../../../../types/logs';

export const MoveFile: React.FunctionComponent<GPRLogbookEntryProps> = ({ log }) => (
    <FormattedMessage
        id="Proceq.LogbookEntryMoved"
        values={{
            from: log.content.source,
            to: log.content.destination,
        }}
    />
);

export default {
    element: MoveFile,
};
