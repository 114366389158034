import React from 'react';
import FormattedMessage from '../../../../localization/FormatMessage';
import { Log } from '../../../../types/logs';

const ProbeCommonContent: React.FunctionComponent<{ content: Log['content'] }> = ({ content }) => (
    <>
        {content.hardwareVersion && (
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyHardwareVersion" />
                {':'} {content.hardwareVersion}
            </div>
        )}
        {content.firmwareVersion && (
            <div>
                <FormattedMessage id="Proceq.LogbookPropertyFirmwareVersion" />
                {':'} {content.firmwareVersion}
            </div>
        )}
    </>
);

export default ProbeCommonContent;
