import React from 'react';
import { get, has } from 'lodash';
import { Chart, ColumnSeries, HighchartsChart, HighchartsProvider, Tooltip, XAxis, YAxis } from 'react-jsx-highcharts';
import Highcharts from 'highcharts';
import colorsLib from '../../../../styles/colors.json';
import {
    getMinMaxValue,
    getResolutionForScale,
    getScaleInfoOfScaleID,
    getSchmidtCompositeSurfaceId,
    getSchmidtScaleIdOfFromFactorAndUnitID,
} from '../utils/conversionsHelper';
import styles from './EquotipReadings.styl';
import { FormatIDs } from '../../../../types';
import { EquotipSeries, ROCK_SCHMIDT_PROBE } from '../../../../types/measurement';
import { useFormatMessage } from '../../../../localization/useFormatMessage';
import FormattedMessage from '../../../../localization/FormatMessage';
import { getLabelFromGlobalDataKey } from '../../RegisteredInfo/helper';
import { getYAxisStyleProps, labelStyleConfig, plotOptions, tooltipStyleProps } from './HighchartsConfigs';
import { EquotipFamilyRendererProps } from './ReadingComponents';
import { ProductCode, ProductModel } from '../../../../types/proceq';
import { labelFormatter, tooltipFormatter } from './HighchartsUtils';
import PropertiesTable from './PropertiesTable';
import getConcreteAgeSchmidt from '../utils/getConcreteAgeSchmidt';

enum ColorType {
    NoConversion = 'noConversion',
    Excluded = 'excluded',
    OverThreshold = 'overThreshold',
    Normal = 'normal',
}

const chartBarColorMap: { [key in ColorType]: string } = {
    [ColorType.NoConversion]: colorsLib.gray300,
    [ColorType.Excluded]: colorsLib.gray500,
    [ColorType.OverThreshold]: colorsLib.red700,
    [ColorType.Normal]: colorsLib.teal400,
};

const LABEL_FORMATID_MAP: Record<string, FormatIDs> = {
    count: 'Proceq.ExportFieldReadings',
    stdev: 'Proceq.ExportFieldStandardDeviation',
    range: 'Proceq.ExportFieldRange',
    relSpan: 'Proceq.ExportFieldRelativeSpan',
    max: 'Proceq.ExportFieldMaximum',
    min: 'Proceq.ExportFieldMinimum',
    avg: 'Proceq.ExportFieldAverage',
    mode: 'Proceq.ExportFieldMode',
    median: 'Proceq.ExportFieldMedian',
};

const statisticsColumns = (product: ProductCode, standardID?: number) => {
    if (product === ProductCode.EQUOTIP) {
        return ['avg', 'count', 'stdev', 'min', 'max', 'range', 'relSpan'];
    }
    if (product === ProductCode.SCHMIDT && standardID === 9) {
        return ['avg', 'count', 'min', 'max', 'median', 'mode'];
    }
    return ['avg', 'count', 'min', 'max'];
};

export const EquotipReadings: React.FunctionComponent<EquotipFamilyRendererProps> = (props) => {
    const { product, data: measurementData, isStandalone, isVerificationMode } = props;
    const formatMessage = useFormatMessage();
    const readings = get(measurementData, 'readings.0.content');
    const settings = get(measurementData, 'settings.0.content');
    const productModel = get(measurementData, 'measurement.productModel')?.toUpperCase() as ProductModel;

    if (!measurementData || !settings || !readings) {
        return <FormattedMessage id="Proceq.GraphNoDataAvailable" />;
    }

    const measurement = measurementData?.measurement;
    const settingsData = isVerificationMode ? settings.primary : settings.secondary;

    const unit =
        product === ProductCode.EQUOTIP
            ? getScaleInfoOfScaleID(formatMessage, settingsData.scaleId, productModel).scaleUnits
            : getScaleInfoOfScaleID(
                  formatMessage,
                  getSchmidtScaleIdOfFromFactorAndUnitID(settingsData.scaleId, settingsData.formFactorId),
                  productModel
              ).scaleUnits;

    const thresholdLoValue = +getResolutionForScale(null, settingsData.limits.low, undefined, false, product);
    const thresholdHiValue = +getResolutionForScale(null, settingsData.limits.high, undefined, false, product);
    const yAxisStyleProps = getYAxisStyleProps({ thresholdLoValue, thresholdHiValue });

    const data = readings.series.map((readingSeries: EquotipSeries) =>
        readingSeries.isNoConversion ? 0 : readingSeries.secondaryValue
    );
    const standardId = settings.standardId;
    const maxValueFound = data.reduce(
        (max: number, current: number) => (current > max ? current : max),
        thresholdHiValue
    );
    const minValueFound = data.reduce(
        (min: number, current: number) => (current < min ? current : min),
        thresholdLoValue
    );
    const { maxValue, minValue } = getMinMaxValue(minValueFound, maxValueFound);

    const categories =
        product === ProductCode.SCHMIDT
            ? Array.from({ length: (readings?.series ?? []).length }, (_, i) => (i + 1).toString())
            : readings.series.map((readingSeries: EquotipSeries) => readingSeries.index);
    const colorTypes: ColorType[] = readings.series.map((readingSeries: EquotipSeries) => {
        const value = readingSeries.secondaryValue;
        if (readingSeries.isNoConversion) {
            return ColorType.NoConversion;
        }
        if (readingSeries.excluded || readingSeries.isOutlier) {
            return ColorType.Excluded;
        }
        if (value > thresholdHiValue || value < thresholdLoValue) {
            return ColorType.OverThreshold;
        }
        return ColorType.Normal;
    });

    let graphHTML = null;

    if (readings.series.length > 0) {
        graphHTML = (
            <div style={{ margin: '0 auto' }}>
                <HighchartsChart className={styles.highcharts_instance} plotOptions={plotOptions}>
                    <Chart backgroundColor="transparent" />

                    <Tooltip formatter={tooltipFormatter({ formatMessage, unit })} {...tooltipStyleProps} shared />

                    <XAxis categories={categories} title={labelStyleConfig} labels={labelStyleConfig} />

                    <YAxis
                        id="number"
                        min={minValue}
                        max={maxValue}
                        title={labelStyleConfig}
                        labels={labelStyleConfig}
                        {...yAxisStyleProps}
                        stackLabels={{
                            style: {
                                color: 'currentColor',
                                textOutline: 'none',
                            },
                            enabled: true,
                            verticalAlign: 'top',
                            formatter: labelFormatter(formatMessage, unit),
                        }}
                    >
                        <YAxis.Title>{unit}</YAxis.Title>
                        <ColumnSeries
                            id="installation"
                            name="Installation"
                            data={data}
                            colors={colorTypes.map((colorType) => chartBarColorMap[colorType])}
                            borderWidth={0}
                            animation={!isStandalone}
                        />
                    </YAxis>
                </HighchartsChart>
            </div>
        );
    }

    const labelList = statisticsColumns(product, standardId).map((label) => {
        const id = LABEL_FORMATID_MAP[label] ?? label;
        let reading = (
            isVerificationMode ? measurement.content?.statistics?.primary : measurement.content?.statistics?.secondary
        )?.[label];
        switch (label) {
            case 'count':
                break;
            case 'relSpan':
                reading = reading === 0 ? '0 %' : `${getResolutionForScale(null, reading)}%`;
                break;
            case 'mode': {
                reading =
                    Array.isArray(reading) && !!reading
                        ? reading
                              .map(
                                  (read: string) =>
                                      `${getResolutionForScale(null, read, undefined, false, product)} ${unit}`
                              )
                              .join(', ')
                        : `0 ${unit}`;
                break;
            }
            default:
                reading =
                    reading === 0
                        ? `0 ${unit}`
                        : `${getResolutionForScale(null, reading, undefined, false, product)} ${unit}`;
                break;
        }
        return {
            key: id ?? label,
            label: id ? formatMessage({ id }) : label,
            value: String(reading),
        };
    });

    const settingsFields: { label: FormatIDs; value: React.ReactNode }[] = [
        {
            label: 'Proceq.ExportFieldUpperLimit',
            value:
                thresholdHiValue === null ? (
                    <FormattedMessage id="Proceq.ExportFieldOff" />
                ) : (
                    `${thresholdHiValue} ${unit}`
                ),
        },
        {
            label: 'Proceq.ExportFieldLowerLimit',
            value:
                thresholdLoValue === null ? (
                    <FormattedMessage id="Proceq.ExportFieldOff" />
                ) : (
                    `${thresholdLoValue} ${unit}`
                ),
        },
    ];

    if (isVerificationMode) {
        settingsFields.push({
            label: 'Proceq.ExportColumnVerificationStd',
            value: (
                <FormattedMessage
                    id={
                        (getLabelFromGlobalDataKey(
                            product === ProductCode.EQUOTIP ? 'standards' : 'standardsschmidt',
                            String(standardId)
                        ) as FormatIDs) ?? ' '
                    }
                />
            ),
        });

        if (settings.testBlock.name) {
            settingsFields.push({
                label:
                    product === ProductCode.EQUOTIP
                        ? 'Proceq.ExportColumnTestBlockName'
                        : 'Proceq.ExportColumnCalibrationAnvilName',
                value: settings.testBlock.name,
            });
        }

        settingsFields.push({
            label:
                product === ProductCode.EQUOTIP
                    ? 'Proceq.ExportColumnTestBlockSN'
                    : 'Proceq.ExportColumnCalibrationAnvilSN',
            value: settings.testBlock.serialNumber,
        });

        if (settings.triggerLoadId) {
            settingsFields.push({
                label: 'Proceq.ExportColumnTestLoadInfo',
                value: `UCI HV${settings.triggerLoadId}`,
            });
        }
        settingsFields.push(
            {
                label:
                    product === ProductCode.EQUOTIP
                        ? 'Proceq.ExportColumnTestBlockNominalHardness'
                        : 'Proceq.ExportColumnCalibrationAnvilReboundValue',
                value: `${settings.testBlock.value} ${
                    getScaleInfoOfScaleID(
                        formatMessage,
                        product === ProductCode.EQUOTIP
                            ? settingsData.scaleId
                            : getSchmidtScaleIdOfFromFactorAndUnitID(settingsData.scaleId, settingsData.formFactorId),
                        productModel
                    ).scaleUnits
                }`,
            },
            {
                label:
                    product === ProductCode.EQUOTIP
                        ? 'Proceq.ExportColumnTestBlockTolerance'
                        : 'Proceq.ExportColumnCalibrationAnvilTolerance',
                value: `${settings.testBlock.margin} ${
                    getScaleInfoOfScaleID(
                        formatMessage,
                        product === ProductCode.EQUOTIP
                            ? settingsData.scaleId
                            : getSchmidtScaleIdOfFromFactorAndUnitID(settingsData.scaleId, settingsData.formFactorId),
                        productModel
                    ).scaleUnits
                }`,
            },
            {
                label: 'Proceq.ExportColumnTestVerificationResult',
                value: measurement.content.properties.verificationResult ? (
                    <FormattedMessage
                        id={
                            (getLabelFromGlobalDataKey(
                                'verificationstatuses',
                                measurement.content.properties.verificationResult
                            ) as FormatIDs) ?? ' '
                        }
                    />
                ) : null,
            }
        );
    } else {
        switch (product) {
            case ProductCode.EQUOTIP:
                settingsFields.push(
                    {
                        label: 'Proceq.ExportFieldMaterial',
                        value: has(settings, 'customMaterial.name') ? (
                            settings.customMaterial.name
                        ) : (
                            <FormattedMessage
                                id={
                                    (getLabelFromGlobalDataKey(
                                        'materials',
                                        String(settings.materialId || 2)
                                    ) as FormatIDs) ?? ' '
                                }
                            />
                        ),
                    },
                    {
                        label: 'Proceq.ExportFieldName',
                        value: getScaleInfoOfScaleID(formatMessage, settingsData.scaleId, productModel).scaleName,
                    },
                    {
                        label: 'Proceq.ExportFieldUnit',
                        value: unit,
                    }
                );
                if (settings.triggerLoadId) {
                    settingsFields.push({
                        label: 'Proceq.ExportColumnTestLoadInfo',
                        value: `UCI HV${settings.triggerLoadId}`,
                    });
                }

                break;
            case ProductCode.SCHMIDT:
                settingsFields.push(
                    {
                        label: 'Proceq.ExportFieldStandard',
                        value: (
                            <FormattedMessage
                                id={
                                    (getLabelFromGlobalDataKey('standardsschmidt', String(standardId)) as FormatIDs) ??
                                    ' '
                                }
                            />
                        ),
                    },
                    {
                        label: 'Proceq.ExportFieldConversionCurve',
                        value: (() => {
                            const compositeSurfaceId = getSchmidtCompositeSurfaceId(
                                settingsData.strengthId,
                                settingsData.surfaceCorrectionId
                            );

                            if (has(settings, 'customMaterial.name')) {
                                return settings.customMaterial.name;
                            }
                            if ('conversionCurveId' in settingsData) {
                                return (
                                    <FormattedMessage
                                        id={
                                            (getLabelFromGlobalDataKey(
                                                'convcurveschmidt',
                                                String(settingsData.conversionCurveId)
                                            ) as FormatIDs) ?? ' '
                                        }
                                    />
                                );
                            }
                            if (compositeSurfaceId) {
                                return (
                                    <FormattedMessage
                                        id={
                                            (getLabelFromGlobalDataKey(
                                                'surfacestrengthandcorrschmidt',
                                                compositeSurfaceId
                                            ) as FormatIDs) ?? ' '
                                        }
                                    />
                                );
                            }
                            if ('surfaceConditionId' in settingsData) {
                                return (
                                    <FormattedMessage
                                        id={
                                            (getLabelFromGlobalDataKey(
                                                'surfaceconditionschmidt',
                                                settingsData.surfaceConditionId
                                            ) as FormatIDs) ?? ' '
                                        }
                                    />
                                );
                            }
                            return null;
                        })(),
                    }
                );

                if (settingsData.carbonationDepth) {
                    settingsFields.push({
                        label: 'Proceq.ExportFieldCarbonationDepth',
                        value: formatMessage(
                            { id: 'Proceq.ValueCarbonationDepthMms' },
                            { value: settingsData.carbonationDepth }
                        ),
                    });
                }

                if (settingsData.age) {
                    settingsFields.push({
                        label: 'Proceq.ExportFieldConcreteAge',
                        value: formatMessage(
                            { id: 'Proceq.ValueConcreteAgeDays' },
                            { value: getConcreteAgeSchmidt(settingsData.age) }
                        ),
                    });
                }

                settingsFields.push({
                    label: 'Proceq.ExportFieldName',
                    value: getScaleInfoOfScaleID(
                        formatMessage,
                        getSchmidtScaleIdOfFromFactorAndUnitID(settingsData.scaleId, settingsData.formFactorId),
                        productModel,
                        productModel === ROCK_SCHMIDT_PROBE
                    ).scaleName,
                });

                settingsFields.push({
                    label: 'Proceq.ExportFieldUnit',
                    value: unit,
                });
                break;
        }
    }

    const settingList = settingsFields.map(({ value, label }) => ({
        key: label,
        label: formatMessage({ id: label }),
        value,
    }));

    return (
        <div>
            <div className={styles.equotip_graph_wrapper}>
                <div className={styles.category_properties}>
                    <FormattedMessage id="Proceq.ExportFieldMeasurements" />
                </div>
                <div className={styles.graph}>{graphHTML}</div>
            </div>
            <div className={styles.extraInfo_wrapper}>
                <PropertiesTable
                    title={<FormattedMessage id="Proceq.ExportFieldStatistics" />}
                    properties={labelList}
                />
                <PropertiesTable
                    title={<FormattedMessage id="Proceq.ExportFieldSettings" />}
                    properties={settingList}
                />
            </div>
        </div>
    );
};

const EquotipReadingsWithHighchart: React.FunctionComponent<EquotipFamilyRendererProps> = (props) => {
    return (
        <HighchartsProvider Highcharts={Highcharts}>
            <EquotipReadings {...props} />
        </HighchartsProvider>
    );
};

export default EquotipReadingsWithHighchart;
