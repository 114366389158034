import React from 'react';
import { GPRLogbookEntryProps, Log } from '../../../../../types/logs';
import FormattedMessage from '../../../../../localization/FormatMessage';
import { convertAndDisplay } from '../../utils/conversionRates';
import { ScanType } from '../../../../../types/proceq';

const Curve = ({ log }: GPRLogbookEntryProps) => {
    const { curveType, statusType } = log.content;
    const isDGS = curveType === 'DGS';
    const isDeleted = statusType === 'deleted';
    const points = 'curveInfo' in log.content && 'points' in log.content.curveInfo ? log.content.curveInfo.points : '0';

    const deltaVk = isDGS && !isDeleted ? log.content.curveInfo.deltaVk.value : '0';
    const deltaVkUnit = isDGS && !isDeleted ? log.content.curveInfo.deltaVk.unit : 'metric';
    const ers = isDGS && !isDeleted ? log.content.curveInfo.ers.value : '0';
    const referenceType = isDGS && !isDeleted ? log.content.curveInfo.referenceType.value : '0';
    const testBlockAttenuation = isDGS && !isDeleted ? log.content.curveInfo.testBlockAttenuation.value : '0';
    const attenuationConvertor = convertAndDisplay(
        'FDL.Curve.DGA.testBlockAttenuation',
        ScanType.Indication,
        deltaVkUnit
    );

    return (
        <div>
            <b>
                <FormattedMessage
                    id="App.Logbook.FDL.Curve.CurveOverStatus"
                    values={{
                        curveType,
                        statusType,
                    }}
                />
            </b>

            <br />
            {!isDGS && !isDeleted && <FormattedMessage id="App.Logbook.FDL.Curve.Points" values={{ value: points }} />}

            {isDGS && statusType === 'created' && (
                <div>
                    <FormattedMessage id="App.Logbook.FDL.Curve.ReferenceType" values={{ value: referenceType }} />
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.Curve.TestBlockAttenuation"
                        values={{
                            value:
                                deltaVkUnit === 'metric'
                                    ? attenuationConvertor.roundNumber(testBlockAttenuation * 1000)
                                    : attenuationConvertor.roundNumber(testBlockAttenuation),
                            unit: attenuationConvertor.unit,
                        }}
                    />
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.Curve.DeltaVk"
                        values={{
                            value: deltaVk,
                            unit: convertAndDisplay('FDL.Curve.DGA.deltaVk', ScanType.Indication, deltaVkUnit).unit,
                        }}
                    />
                    <br />
                    <FormattedMessage
                        id="App.Logbook.FDL.Curve.ERS"
                        values={{
                            value: ers,
                            unit: convertAndDisplay('FDL.Curve.DGA.ers', ScanType.Indication, deltaVkUnit).unit,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export const color = (log: Log) => (log.content.statusType === 'deleted' ? 'red' : 'green');

export default {
    element: Curve,
    color,
};
