import { useEffect, useRef, useState } from 'react';
import { getMapKitInitStatus, initMapKit, MapKitInitStatus } from '../utils/mapUtils';
import { useMeasurementViewerContext } from '../components/StandaloneMeasurementView/MeasurementViewerContext';

function useMapKitStatus(onError?: (error: Error) => void) {
    const [status, setStatus] = useState<MapKitInitStatus>(getMapKitInitStatus());

    const { isHTMLView, isHTMLExport } = useMeasurementViewerContext();
    const performedInit = useRef(false);

    useEffect(() => {
        // Prevent init from being called again
        if (performedInit.current) {
            return;
        }
        initMapKit(
            (newStatus) => {
                setStatus(newStatus);
            },
            onError,
            isHTMLView,
            isHTMLExport
        );
        performedInit.current = true;
    }, [isHTMLExport, isHTMLView, onError]);
    return status;
}

export default useMapKitStatus;
